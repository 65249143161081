import { z } from "zod"

export const setSubAreaAtuacaoFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type setSubAreaAtuacaoFormData = z.infer<typeof setSubAreaAtuacaoFormSchema>

export const putSubAreaAtuacaoFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type putSubAreaAtuacaoFormData = z.infer<typeof putSubAreaAtuacaoFormSchema>