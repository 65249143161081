import {z} from 'zod'

export const FormSituacaoImovelFormSchema = z.object({
  id: z.string(),
  nome: z.string().nonempty('o campo é obrigatório!'),
  descricao: z.string().nonempty('o campo é obrigatório!'),
  status: z.boolean(),
  cor: z.string().optional()
})

export type FormSituacaoClienteFormData = z.infer<typeof FormSituacaoImovelFormSchema>
