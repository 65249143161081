import { useEffect, useState } from "react"

type Props = {
  itens: {
    id: number
    name: string
  }[]
  onChange:any
}

export function ToogleTokens({itens,onChange}: Props) {

  const [tokens, setTokens] = useState([]);
  
  useEffect(()=>{
    onChange(tokens)
  },[onChange, tokens])

  return (
    <div className='container p-0 mb-5 mt-1' id="toogle-tokens">
      <h3 className='mb-5 ms-5'>Qual é sua área de atuação ?</h3>
      <div className="options d-flex flex-wrap">
      {itens.map((item) => {
        return (
          <div key={item.id}>
            <input
              className='radio-btn'
              type='checkbox'
              name='check-toogle-tokens'
              id={`radio${item.id}`}
              title={item.name}
              value={item.id}
              onChange={(e)=>{
                let index = tokens.findIndex((i)=> i === item.id )
                if (e.target.checked){
                  if ( index === -1 ){
                    tokens.push(item.id)
                    setTokens([...tokens])
                  }
                }else{
                  if ( index ){
                    tokens.splice(index,1)
                  }
                }
              }}
            />
            <label className='btn btn-outline-success mr-1' htmlFor={`radio${item.id}`}>{item.name}</label>
          </div>
        )
      })}
      </div>
    </div>
  )
}
