import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { setAVMLeadImovelFormData } from "../formValidations"
import { LoginSchema, LoginSchemaData } from "../formValidations/auth"
import { MutationLoginResponse } from "../type/auth"
import { useMutation } from "@apollo/client"
import { LOGIN_GENERATED_API_TOKEN } from "../mutations/auth"
import Swal from "sweetalert2"

type Login = {
  email: string
  password: string
}
export function MutationLogin() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<LoginSchemaData>({
    resolver: zodResolver(LoginSchema),
  })

  const [LoginBody, { error, loading, data: DataLogin }] =
    useMutation<MutationLoginResponse>(LOGIN_GENERATED_API_TOKEN)
   
  const wait = (ms) => new Promise((res) => setTimeout(res, ms))
  const delayRefetchedQuery = async (observableQuery) => {
    console.log('foi?')
      await wait(3000) // 3s to make it super obvious if working or not
      console.log('agora foi....')
      observableQuery.refetch()
  }
  async function FormLogin(data:Login , path?:[string]) {

    try {
      return  await LoginBody({
        variables: {
          usuario: { ...data },
        },
        onQueryUpdated: delayRefetchedQuery,
      });

    } catch (e) {
      console.error('Erro na requisição:', e.message);
      return e.message
     }
  }

  return { register, handleSubmit, FormLogin, setValue, loading, errors, error, DataLogin, watch }
}