import {useParams} from 'react-router-dom'
import {MediaCard} from './components/Media'

export function TabMedia() {
  const {id} = useParams<{id: string}>()

  return (
    <div className='tab-pane fade' id='tab-media' role='tabpanel' aria-labelledby='tab-media-tab'>
      <div className='row ' style={{alignItems: 'stretch'}}>
        <div className='col-12 d-flex'>
          <MediaCard />
        </div>
      </div>
    </div>
  )
}
