import MapView from '../partials/Map'

interface MapVendaProps {
  height: number
}

const MapAluguel = ({height}: MapVendaProps) => {
  return (
    <>
      <MapView height={height} imoveis={[]}></MapView>
    </>
  )
}

export default MapAluguel
