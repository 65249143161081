import {BiPlus} from 'react-icons/bi'
import {CardTables} from '../../components/UI/CardTables'
import LoadingTable from '../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../components/UI/CardTables/ErrorTable'

export function TableMercadoSecundarioAluguel() {
  function novoProjeto() {}
  const loading = true
  const error = null

  return (
    <CardTables
      headerTitle={'Mercado Secundário - Aluguel'}
      headerSubtTitle={`
              Listando ${0} projetos registrados.`}
      headerToolbar={
        <>
          <button className='btn btn-sm btn-light-primary' onClick={novoProjeto}>
            <BiPlus className='fs-3'></BiPlus>
            Novo Projeto
          </button>
        </>
      }
      footer={<></>}
      // data ? (
      //   <PaginationComponent
      //     pagesInfo={data.GetInteligenciaMercado.pageInfo}
      //     setPagesInfo={(pagina: number, quantidade: number) => {
      //       setPaginacao({pagina: pagina, quantidade: quantidade})
      //     }}
      //   ></PaginationComponent>
      // ) : (
      //   <></>
      // )
      //   }
    >
      <div className='table-responsive  '>
        <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th className='w-50px'>ID</th>
              <th className='min-w-150px'>Nome</th>
              <th className='min-w-100px text-end'>Ações</th>
            </tr>
          </thead>
          <tbody>
            <LoadingTable loading={loading} />
            <ErrorTable error={error} />
            {/* {data ? (
              data.GetInteligenciaMercado.result.length > 0 ? (
                data.GetInteligenciaMercado.result.map((response) => {
                  return (
                    <tr key={response.id}>
                      <td>{response.id}</td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {response.nome}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <Buttons id={response.id} />
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={10} className='text-center'>
                    Nenhum registro encontrado
                  </td>
                </tr>
              )
            ) : (
              <></>
            )} */}
          </tbody>
        </table>
      </div>
    </CardTables>
  )
}
