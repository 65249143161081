import {z} from 'zod'

export const SetMercadoSecundarioFormSchema = z.object({
  nome: z.string(),
  tipo: z.string(),
  id_terreno: z.number().optional(),
  id_empreendimento: z.number().optional(),
  json: z.unknown(),
})

export type SetMercadoSecundarioFormData = z.infer<typeof SetMercadoSecundarioFormSchema>

export const PutMercadoSecundarioFormSchema = z.object({
  id: z.number(),
  nome: z.string(),
  tipo: z.string(),
  id_terreno: z.number().optional(),
  id_empreendimento: z.number().optional(),
  json: z.unknown().optional(),
})

export type PutMercadoSecundarioFormData = z.infer<typeof PutMercadoSecundarioFormSchema>

export const SetMercadoSecundarioVSOFormSchema = z.object({
  nome: z.string(),
  vso: z.any(),
})
export type SetMercadoSecundarioVSOFormData = z.infer<typeof SetMercadoSecundarioVSOFormSchema>

export const PutMercadoSecundarioVSOFormSchema = z.object({
  nome: z.string(),
  id: z.number(),
  vso: z.any(),
})
export type PutMercadoSecundarioVSOFormData = z.infer<typeof PutMercadoSecundarioVSOFormSchema>
