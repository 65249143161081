import {useMemo, useState} from 'react'
import {BarChart} from './BarChart'
import {Imoveis} from '../../types'
import {useImoveisStore} from '../../context/DataContext'
import {Dicionario} from '../../../../utils/Dicionario'
import {formatNumber} from '../../../../components/util'

type Props = {
  label?: string
  itens: Imoveis[]
}

export default function FaixaTicket({label, itens}: Props) {
  const {filtro, segmentacao} = useImoveisStore()
  const [selectedItem, setSelectedItem] = useState(null)
  let {chartDataVSOMetragem, chartDataVSOMetragemTable} = useMemo(() => {
    let ticketMedio = Array(8).fill(0, 0, 8)
    let precoMedioM2 = Array(8).fill(0, 0, 8)
    let totalUnidades = Array(8).fill(0, 0, 8)
    let chartDataVSOMetragemTable = []

    let chartDataVSOMetragem = [
      {
        name: 'Qtde',
        data: [],
      },
    ]
    if (itens && filtro !== null) {
      // eslint-disable-next-line array-callback-return
      itens.map((item) => {
        segmentacao.area[0].forEach((faixas, i) => {
          if (i >= 6) {
            if (item.area >= faixas[0]) {
              ticketMedio[i] = ticketMedio[i] + Number(item.valor)
              precoMedioM2[i] = precoMedioM2[i] + Number(item.valor) / Number(item.area)
              totalUnidades[i] = totalUnidades[i] + 1
            }
          } else if (item.area >= faixas[0] && item.area < faixas[1]) {
            ticketMedio[i] = ticketMedio[i] + Number(item.valor)
            precoMedioM2[i] = precoMedioM2[i] + Number(item.valor) / Number(item.area)
            totalUnidades[i] = totalUnidades[i] + 1
          }
        })
      })

      // eslint-disable-next-line array-callback-return
      segmentacao.area[0].map((data, index) => {
        const id = `metragem_${index}`

        let categoria = ''
        if (data[1] == null) {
          categoria = 'acima de ' + data[0]
        } else if (data[0] === 0) {
          categoria = 'abaixo de ' + data[1] + 'm²'
        } else {
          categoria = 'de ' + data[0] + 'm² até ' + data[1] + 'm²'
        }

        chartDataVSOMetragemTable.push({
          preco: categoria,
          ticketMedio: totalUnidades[index] === 0 ? 0 : ticketMedio[index] / totalUnidades[index],
          precoMedioM2: totalUnidades[index] === 0 ? 0 : precoMedioM2[index] / totalUnidades[index],
          totalUnidades: totalUnidades[index],
          id: id,
        })

        chartDataVSOMetragem[0].data.push({
          x: categoria,
          y: totalUnidades[index],
          total_unidades: totalUnidades[index],
          id: id,
        })
      })
    }
    return {chartDataVSOMetragem, chartDataVSOMetragemTable}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens, filtro])

  if (!itens) {
    return <>Carregando..</>
  }

  return (
    <BarChart
      className={'primary'}
      title={'Total de unidades por faixa de área privativa'}
      subtitle={''}
      data={chartDataVSOMetragem}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
      legenda={['Total Unidades']}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>
                      {formatNumber(item.ticketMedio, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>
                      {formatNumber(item.precoMedioM2, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>{item.totalUnidades}</td>
                  </tr>
                )
              })
            : null}
        </tbody>
      </table>
    </BarChart>
  )
}
