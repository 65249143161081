import  { useEffect, useState } from 'react'
import Select, { SingleValue } from 'react-select'
import { Control, useController } from 'react-hook-form'

interface DeveloperItem {
  id_developer_orulo: number;
  nome: string;
}

interface GetDevelopersChosenProps {
  itens: DeveloperItem[];
  control: Control;
  name: string;
  initValue?: number;
}

export function GetDevelopersChosen({
  itens,
  control,
  name,
  initValue,
  ...props
}:GetDevelopersChosenProps) {
  
  const [options, setOptions] = useState<{ value: number; label: string }[]>([]);
  const [selectedOption, setSelectedOption] = useState<{ value: number; label: string } | null>(null);
  const { field } = useController({ name, control });
  
  useEffect(() => {
    const newOptions = itens.map((item) => ({
      value: item.id_developer_orulo,
      label: item.nome,
    }));
    setOptions(newOptions);

    if (initValue) {
      const initialOption = newOptions.find(
        (option) => option.value === initValue
      );
      setSelectedOption(initialOption || null);
    }
  }, [initValue, itens]);


  const handleChange = (selected: SingleValue<{ value: number; label: string }>) => {
    setSelectedOption(selected);
    field.onChange(selected?.value);
  };

  if (!itens) {
    return (
            <div className='form-group'>
          <label className='col-form-label'>Construtora:</label>
          <Select
            options={[]}
            placeholder="Construtora"
          />
        </div>
    )
  }
  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Construtora:</label>
        <Select
          {...props}
          {...field}
          options={options}
          placeholder="Construtora"
          value={selectedOption}
          onChange={handleChange}
        />
      </div>
    </>
  )
}
