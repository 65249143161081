import {Text, View, Image} from '@react-pdf/renderer'
import secureLocalStorage from 'react-secure-storage'
import {styles} from '../Reports/partials/style'
let logo = `/media/logos/logo.png`
const logoEmpresa: any = secureLocalStorage.getItem('user_logo_empresa')
if (logoEmpresa) {
  logo = logoEmpresa
}

const empresaData: any = secureLocalStorage.getItem('empresa') || null

let dadosEmpresa = null
if (empresaData) {
  dadosEmpresa = JSON.parse(empresaData)
}

export const Header = () => {
  return (
    <View style={styles.rowTopo} fixed>
      {dadosEmpresa ? (
       <>
          <Image
            src={{uri: logo, method: 'GET', headers: {'Cache-Control': 'no-cache'}, body: ''}}
            style={styles.logo}
          />
          <View fixed style={styles.sobre}>
            {dadosEmpresa.nome && <Text style={styles.h2}>{dadosEmpresa.nome}</Text>}
            {dadosEmpresa.cnpj && <Text style={styles.h6}>CNPJ: {dadosEmpresa.cnpj}</Text>}
            <Text style={styles.h6}>{dadosEmpresa.endereco !== '' ? dadosEmpresa.endereco : ''}</Text>
            <Text style={styles.h6}>{dadosEmpresa.telefone !== '' ? `Whatsapp: ${dadosEmpresa.telefone}` : ''}</Text>
            <Text style={styles.h6}>{dadosEmpresa.email !== '' ? `Email: ${dadosEmpresa.email}` : ''}</Text>
          </View> 
          </>
      ) : 
        <>
          <Image src={logo} style={styles.logo} />
          <View fixed style={styles.sobre}>
            <Text style={styles.h2}>Urbit Serviços de Informática Ltda.</Text>
            <Text style={styles.h6}>CNPJ: 31.687.326/0001-62</Text>
            <Text style={styles.h6}>R. Dr. Antônio Bento, 746 - Santo Amaro, São Paulo - SP, 04750-001</Text>
            <Text style={styles.h6}>Whatsapp: (11) 94813-4631</Text>
            <Text style={styles.h6}>Email: contato@urbit.com.br</Text>
          </View>
          </>
      }
    </View>
  )
}
