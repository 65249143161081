import {
  DELETE_IMOVEL,
  DeleteImovelResponse,
  GET_IMOVEIS,
  MutationPutImovel,
  QueryGetImovel,
} from '../../../../../graphql'
import {useEffect, useState} from 'react'
import {useMutation} from '@apollo/client'
import GetTerrenoTagsCreatableSelect from '../../Chosen/GetImovelTagsCreatableSelect'
import {GetSituacaoClienteChosen} from '../../Chosen/GetSituacaoClienteChosen'
import {Loader} from '../../../../../components/UI/Loader/Loader'
import Swal from 'sweetalert2'
import {FormInputCurrencyFormat} from '../../../../../components/UI/Inputs/FormInputCurrencyFormat'
import {FormInputNumericFormat} from '../../../../../components/UI/Inputs/FormInputNumericFormat'
import {FormInputAndLabel} from '../../../../../components/UI/Inputs/FormInputAndLabel'
import {FormInputMaskAndLabel} from '../../../../../components/UI/Inputs/FormInputMaskAndLabel'
import {GetEstadoCidadeChosen} from '../../../../../components/UI/Chosens/GetEstadoCidadeChosen'
import {MapStatic} from '../../../../../components/UI/MapStatic'
import * as turf from '@turf/turf'
import {CoordinatesToPath, PathToCoordinates} from '../../../../../components/UI/MapView'
import {LoaderButton} from '../../../../../components/UI/Loader/LoaderButton'
import {MutationSetLogAcessoRecurso} from '../../../../../graphql/services/LogAcesso'

type Props = {
  id: number
  param: string
}

export const TabInformacoesLote: React.FC<Props> = ({id, param}) => {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const {data} = QueryGetImovel({variables: {getImovelId: id}})
  const [valueSituacao, setValueSituacao] = useState(null)
  const [precoTotal, setPrecoTotal] = useState(null)
  const [deleteImovel] = useMutation<DeleteImovelResponse>(DELETE_IMOVEL)
  const [showLoader, setShowLoader] = useState(false)
  const {
    register,
    DataImovelResponse,
    FormImovel,
    error,
    errors,
    handleSubmit,
    control,
    setValue,
    getValues,
  } = MutationPutImovel()

  useEffect(() => {
    if (error) {
      Swal.fire('Erro!', 'Ocorreu um erro ao atualizar o Empreendimento.', 'warning')
    } else if (DataImovelResponse) {
      Swal.fire('Sucesso!', 'Informações  atualizadas com sucesso.', 'success')
    }
  }, [DataImovelResponse, error])

  if (!data) {
    return <Loader text={'carregando...'}></Loader>
  }

  const latlng = []
  if (data.GetImovel.marcacao) {
    let path = null
    if (data.GetImovel.marcacao.type === 'MultiPolygon') {
      path = CoordinatesToPath(data.GetImovel.marcacao['coordinates'][0][0])
    } else if (data.GetImovel.marcacao.type === 'Polygon') {
      path = CoordinatesToPath(data.GetImovel.marcacao['coordinates'][0])
    } else if (data.GetImovel.marcacao.type === 'Point') {
      path = CoordinatesToPath([data.GetImovel.marcacao['coordinates']])
    }
    latlng.push({
      path: path,
      center:
        data.GetImovel.marcacao.type === 'Point'
          ? data.GetImovel.marcacao
          : turf.centroid(data.GetImovel.marcacao),
      id: data.GetImovel.id,
      endereco: data.GetImovel.endereco,
      area: data.GetImovel.area_iptu,
      editable: false,
      type: data.GetImovel.marcacao.type,
    })
  } else {
    let path = [{lat: data.GetImovel.latitude, lng: data.GetImovel.longitude}]

    latlng.push({
      path: path,
      center: {type: 'Point', coordinates: [path[0].lng, path[0].lat]},
      // data.GetImovel.marcacao.type === 'Point'
      //   ? data.GetImovel.marcacao
      //   : turf.centroid(data.GetImovel.marcacao),
      id: data.GetImovel.id,
      endereco: data.GetImovel.endereco,
      area: data.GetImovel.area_iptu,
      editable: false,
      type: 'Point',
    })
  }

  async function HandleDelete() {
    deleteImovel({
      variables: {
        deleteImovelId: id,
      },
      refetchQueries: [GET_IMOVEIS],
    })

    FormSetLogAcessoRecurso({
      ferramenta: 'meus-imoveis',
      funcionalidade: 'informacoes-lote',
      action: 'delete',
      url: '/meus-imoveis/details/' + id + '#informacoes-lote',
    })
  }

  async function syncInfos(id) {
    const response = await fetch(
      process.env.REACT_APP_API_URL_EXTRA_SERVICES + '/sync-imovel/' + id,
      // 'http://localhost:4001/sync-imovel/' + id,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    // const data = await response.json()
    setShowLoader(false)
    
    FormSetLogAcessoRecurso({
      ferramenta: 'meus-imoveis',
      funcionalidade: 'informacoes-lote',
      action: 'list',
      url: '/meus-imoveis/details/' + id + '#informacoes-lote',
    })
  }

  function onChangeMap(geom) {
    if (geom) {
      const geometria = PathToCoordinates(geom)
      const multiPolygon = {
        type: 'MultiPolygon',
        coordinates: [],
      }

      multiPolygon.coordinates.push([geometria])
      document.getElementById('marcacao')['value'] = JSON.stringify(multiPolygon)
      setValue('marcacao', JSON.stringify(multiPolygon))
    }
  }

  function calctotalArea(valor) {
    if (typeof valor !== 'undefined') {
      setValue('preco_total', getValues('preco_m2') * valor)
      setPrecoTotal(getValues('preco_m2') * valor)
    }
  }

  function calctotalPreco(valor) {
    if (typeof valor !== 'undefined') {
      setValue('preco_total', getValues('area_real') * valor)
      setPrecoTotal(getValues('area_real') * valor)
    }
  }

  const onSubmit = async (data) => {
    FormImovel(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'meus-imoveis',
          funcionalidade: 'informacoes-lote',
          action: 'update',
          url: '/empreendimentos/details/' + id + '#informacoes-lote',
        })
        Swal.fire('Sucesso!', 'Dados atualizados criada com sucesso.', 'success')
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  console.log(data.GetImovel.status_cliente || valueSituacao)
  console.log(data.GetImovel.status_cliente )
  console.log( valueSituacao)
  return (
    <div
      className={`tab-pane fade ${param === 'informacoes-lote' ? 'show active' : ''}`}
      id='informacoes-lote'
      role='tabpanel'
      aria-labelledby='informacoes-lote'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title '>Informações do Lote</h3>
          <div className='card-toolbar'>
            <button
              className='btn  btn-light-success'
              onClick={() => {
                setShowLoader(true)
                syncInfos(id)
              }}
            >
              {showLoader ? (
                <LoaderButton text={'Sincronizando...'}></LoaderButton>
              ) : (
                ' Sincronizar Informações'
              )}
            </button>
          </div>
        </div>

        <div className='card-body pt-5'>
          <form onSubmit={handleSubmit(onSubmit)} className='horizontal-form'>
            <input
              type='hidden'
              id='marcacao'
              defaultValue={JSON.stringify(data.GetImovel.marcacao)}
              {...register('marcacao', {required: false})}
            ></input>
            <div className='row'>
              <div className='col-xs-12'>
                <div className='row flex-md-row flex-sm-column-reverse'>
                  <div className='col-lg-12 col-md-12 col-sm-12 rounded'>
                    {data ? <MapStatic latLng={latlng} onChange={onChangeMap}></MapStatic> : ''}
                  </div>

                  <hr className='mt-8' />
                  <div className='col-lg-12 col-md-8 col-sm-12'>
                    <input
                      type='number'
                      hidden
                      className='form-control'
                      defaultValue={data.GetImovel.id}
                      {...register('id', {required: true, valueAsNumber: true})}
                    />
                    <div className='row mrb-1'>
                      <div className='col-md-9 col-xs-12'>
                        <FormInputAndLabel
                          label='Endereço'
                          defaultValue={data.GetImovel.endereco}
                          className='form-control'
                          placeholder='Logradouro'
                          register={register('endereco')}
                          error={errors ? errors.endereco?.message : ''}
                        />
                      </div>

                      <div className='col-xs-12 col-md-3'>
                        <FormInputAndLabel
                          label='Número'
                          defaultValue={data.GetImovel.numero}
                          className='form-control'
                          placeholder='Número'
                          register={register('numero')}
                          error={errors ? errors.numero?.message : ''}
                        />
                      </div>
                    </div>
                    <div className='row mrb-1'>
                      <div className='col-xs-12 col-md-4'>
                        <FormInputAndLabel
                          label='Complemento'
                          defaultValue={data.GetImovel.complemento}
                          className='form-control'
                          placeholder='Complemento'
                          register={register('complemento')}
                          error={errors ? errors.complemento?.message : ''}
                        />
                      </div>
                      <div className='col-xs-12 col-md-4'>
                        <div className='form-group'>
                          <FormInputMaskAndLabel
                            maskChar=' '
                            defaultValue={data.GetImovel.cep}
                            label='CEP'
                            className='form-control'
                            mask='99999-999'
                            placeholder='Ex: 00000-000'
                            register={register('cep')}
                            error={errors ? errors.cep?.message : ''}
                          />
                        </div>
                      </div>
                      <div className='col-xs-12 col-md-4'>
                        <FormInputAndLabel
                          label='Bairro'
                          type='text'
                          defaultValue={data.GetImovel.bairro}
                          className='form-control'
                          placeholder='Bairro'
                          register={register('bairro')}
                          error={errors ? errors.bairro?.message : ''}
                        />
                      </div>
                    </div>
                    <div className='row mrb-1'>
                      <GetEstadoCidadeChosen
                        classNameCity={'col-6 col-xs-12 mt-1'}
                        classNameEstado={'col-6 col-xs-12  mt-1'}
                        defaultValueEstado={data.GetImovel.id_estado}
                        defaultValueCidade={data.GetImovel.id_cidade}
                        SetValueEstado={(value) => {
                          // console.log('SetValueEstado', value)
                          // setValueEstado(value)
                          // console.log('estado')
                          // console.log(value)
                          setValue('id_estado', value)
                          // setValue('id_cidade', null)
                        }}
                        SetValueCidade={(value) => {
                          // setValueCidade(value)
                          setValue('id_cidade', value)
                        }}
                      />
                      {errors ? errors.id_cidade?.message : ''}
                      {errors ? errors.id_estado?.message : ''}
                    </div>

                    <div className='row mrb-1 '>
                      <div className='col-xs-8 col-md-4'>
                        <FormInputAndLabel
                          label='Responsavel'
                          defaultValue={data.GetImovel.responsavel}
                          className='form-control'
                          placeholder='Responsavel'
                          register={register('responsavel')}
                          error={errors ? errors.responsavel?.message : ''}
                        />
                      </div>
                      <div className='col-xs-8 col-md-4'>
                        <FormInputAndLabel
                          label='Número Contribuente'
                          defaultValue={data.GetImovel.numero_contribuinte}
                          className='form-control'
                          placeholder='Número Contribuente'
                          register={register('numero_contribuinte')}
                          error={errors ? errors.numero_contribuinte?.message : ''}
                        />
                      </div>
                      <div className='col-xs-8 col-md-4'>
                        <FormInputAndLabel
                          label='Nome Contribuinte'
                          defaultValue={data.GetImovel.nome_contribuinte}
                          className='form-control'
                          placeholder='Nome Contribuinte'
                          register={register('nome_contribuinte')}
                          error={errors ? errors.nome_contribuinte?.message : ''}
                        />
                      </div>
                    </div>

                    <div className='row mrb-1'>
                      <div className='col-xs-6 col-md-4'>
                        <label className='col-form-label'>Tags </label>
                        <GetTerrenoTagsCreatableSelect
                          defaultValue={data.GetImovel.tag ? data.GetImovel.tag.split(',') : []}
                          onValueChange={(tagsResult) => {
                            setValue('tag', tagsResult)
                          }}
                          register={register('tag')}
                        />
                      </div>
                      <div className='col-xs-12 col-md-4'>
                        <div className='form-group'>
                          <label className='col-form-label'>Situação</label>
                          <GetSituacaoClienteChosen
                            defaultValue={valueSituacao || data.GetImovel.status_cliente}
                            register={register('status_cliente')}
                            error={errors ? errors.status_cliente?.message : ''}
                            onValueChange={(value) => {
                              setValueSituacao(value)
                              setValue('status_cliente', value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className='pt-6'>Dados Técnicos</h3>
                <hr className='mb-5 bg-grey' />

                <div className='row mrb-1'>
                  <div className='col-xs-12 col-md-2'>
                    <FormInputAndLabel
                      label='Zoneamento'
                      defaultValue={data.GetImovel.zoneamento}
                      className='form-control'
                      placeholder='Zoneamento'
                      register={register('zoneamento')}
                      error={errors ? errors.zoneamento?.message : ''}
                    />
                  </div>

                  <div className='col-xs-12 col-md-2'>
                    <FormInputAndLabel
                      label='N° Matricula'
                      defaultValue={data.GetImovel.numero_matricula}
                      className='form-control'
                      placeholder='N° Matricula'
                      register={register('numero_matricula')}
                      error={errors ? errors.numero_matricula?.message : ''}
                    />
                  </div>

                  <div className='col-xs-12 col-md-4'>
                    <FormInputAndLabel
                      label='Macroarea'
                      defaultValue={data.GetImovel.macroarea}
                      className='form-control'
                      placeholder='Macroarea'
                      register={register('macroarea')}
                      error={errors ? errors.macroarea?.message : ''}
                    />
                  </div>

                  <div className='col-xs-12 col-md-4'>
                    <FormInputAndLabel
                      label='Macrozona'
                      defaultValue={data.GetImovel.macrozona}
                      className='form-control'
                      placeholder='Macrozona'
                      register={register('macrozona')}
                      error={errors ? errors.macrozona?.message : ''}
                    />
                  </div>
                </div>
                <div className='row mrb-1 all-center'>
                  <div className='col-xs-12 col-md-3'>
                    <FormInputAndLabel
                      label='Cód. Logradouro'
                      defaultValue={data.GetImovel.codlog}
                      className='form-control'
                      placeholder='Cód. Logradouro'
                      register={register('codlog')}
                      error={errors ? errors.codlog?.message : ''}
                    />
                  </div>
                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputNumericFormat
                        control={control}
                        label=' Área Real(m²)'
                        defaultValue={data.GetImovel.area_real}
                        className='form-control text-right'
                        placeholder='Área Real'
                        register={register('area_real')}
                        error={errors ? errors.area_real?.message : ''}
                        onValueChange={(e) => {
                          calctotalArea(e.floatValue)
                          setValue('area_real', e.floatValue)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputCurrencyFormat
                        control={control}
                        label='Preço(R$/m²)'
                        defaultValue={data.GetImovel.preco_m2}
                        className='form-control text-right'
                        placeholder='Preço Médio R$/m²'
                        register={register('preco_m2')}
                        error={errors ? errors.preco_m2?.message : ''}
                        onValueChange={(e) => {
                          setValue('preco_m2', e.floatValue)
                          calctotalPreco(e.floatValue)
                        }}
                      />
                    </div>
                  </div>

                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputCurrencyFormat
                        control={control}
                        label='Preço Total(R$/m²)'
                        id='preco_total'
                        defaultValue={data.GetImovel.preco_total}
                        value={precoTotal}
                        className='form-control text-right'
                        placeholder='Preço Total R$/m²'
                        register={register('preco_total')}
                        error={errors ? errors.preco_total?.message : ''}
                        onValueChange={(e) => {
                          setValue('preco_total', e.floatValue)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mrb-1 all-center'>
                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputNumericFormat
                        control={control}
                        label='Cabas'
                        defaultValue={data.GetImovel.cabas}
                        className='form-control text-right'
                        placeholder='00.00'
                        register={register('cabas')}
                        error={errors ? errors.cabas?.message : ''}
                        onValueChange={(e) => {
                          setValue('cabas', e.floatValue)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputNumericFormat
                        control={control}
                        label='Camax'
                        defaultValue={data.GetImovel.camax}
                        className='form-control text-right'
                        placeholder='00.00'
                        register={register('camax')}
                        error={errors ? errors.camax?.message : ''}
                        onValueChange={(e) => {
                          setValue('camax', e.floatValue)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputAndLabel
                        label='Operação Urbana'
                        defaultValue={data.GetImovel.operacao_urbana}
                        className='form-control'
                        placeholder='Operação Urbana'
                        register={register('operacao_urbana')}
                        error={errors ? errors.operacao_urbana?.message : ''}
                      />
                    </div>
                  </div>
                  <div className='col-xs-12 col-md-3'>
                    <FormInputNumericFormat
                      control={control}
                      label=' Área IPTU(m²)'
                      defaultValue={data.GetImovel.area_iptu}
                      className='form-control text-right'
                      placeholder='Área privativa total'
                      register={register('area_iptu')}
                      error={errors ? errors.area_iptu?.message : ''}
                      onValueChange={(e) => {
                        setValue('area_iptu', e.floatValue)
                      }}
                    />
                  </div>
                </div>

                <div className='row mrb-1 all-center'>
                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputNumericFormat
                        control={control}
                        label='Testada'
                        defaultValue={data.GetImovel.testada}
                        className='form-control text-right'
                        placeholder='00.00'
                        register={register('testada')}
                        error={errors ? errors.testada?.message : ''}
                        onValueChange={(e) => {
                          setValue('testada', e.floatValue)
                        }}
                      />
                    </div>
                  </div>

                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputNumericFormat
                        control={control}
                        label='FPR'
                        defaultValue={data.GetImovel.fpr}
                        className='form-control text-right'
                        placeholder='Área privativa total'
                        register={register('fpr')}
                        error={errors ? errors.fpr?.message : ''}
                        onValueChange={(e) => {
                          setValue('fpr', e.floatValue)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputNumericFormat
                        control={control}
                        label='FPNR'
                        defaultValue={data.GetImovel.fpnr}
                        className='form-control text-right'
                        placeholder='Área privativa total'
                        register={register('fpnr')}
                        error={errors ? errors.fpnr?.message : ''}
                        onValueChange={(e) => {
                          setValue('fpnr', e.floatValue)
                        }}
                      />
                    </div>
                  </div>

                  <div className='col-xs-12 col-md-3'>
                    <div className='form-group'>
                      <FormInputNumericFormat
                        control={control}
                        label='Quadro 14'
                        defaultValue={data.GetImovel.quadro14}
                        className='form-control text-right'
                        placeholder='Quadro 14'
                        register={register('quadro14')}
                        error={errors ? errors.quadro14?.message : ''}
                        onValueChange={(e) => {
                          setValue('quadro14', e.floatValue)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='row mrb-1 all-center'>
                  <div className='col-xs-12 col-md-2'>
                    <FormInputAndLabel
                      label='Setor Quadra'
                      defaultValue={data.GetImovel.setor_quadra}
                      className='form-control'
                      placeholder='Setor Quadra'
                      register={register('setor_quadra')}
                      error={errors ? errors.setor_quadra?.message : ''}
                    />
                  </div>
                  <div className='col-xs-12 col-md-10'>
                    <FormInputAndLabel
                      label='Uso Atual do Imóvel'
                      defaultValue={data.GetImovel.uso_atual_terreno}
                      className='form-control'
                      placeholder='Uso Atual do Imóvel'
                      register={register('uso_atual_terreno')}
                      error={errors ? errors.uso_atual_terreno?.message : ''}
                    />
                  </div>
                </div>

                <div className='row mrb-1 all-center'>
                  <div className='col-xs-12 col-md-12 all-end pt-4'>
                    <button
                      type='button'
                      onClick={HandleDelete}
                      className='btn btnDanger'
                      id='btn-toggle-terrain-status'
                    >
                      Descartar terreno
                    </button>

                    <button
                      type='submit'
                      className='btn btnSuccess'
                      id='btn-save-form'
                      style={{display: 'inline-block', marginLeft: '15px'}}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
