import React, {useContext, useEffect, useRef, useState} from 'react'
import {Autocomplete, GoogleMap, Marker, InfoBox} from '@react-google-maps/api'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {TMarcador, ItensImoveisProps} from './Types'
import Tootip from './Tootip'
import {FilterContext} from '../context/FilterContext'
import ZonaDeValor from './Filtro/AreaInfluencia/ZonaDeValor'
import Raio from './Filtro/AreaInfluencia/Raio'
import Isocrona from './Filtro/AreaInfluencia/Isocrona'
import useMap from '../hooks/useMap'
import useAutocomplete from '../hooks/useAutocomplete'
import {latLng} from '../../../components/util'
import {MaisInfoEmpreendimentos} from './MaisInfoEmpreendimentos'
import DefinirManualmente from './Filtro/AreaInfluencia/DefinirManualmente'
import {DataContext} from '../context/DataContext'
import { formatAdressSeachGoogleMapa } from '../../explorer/components/common'

type Props = {
  height: number
}
const centerInit = {
  lat: -23.56834983242366,
  lng: -46.65739491734664,
}

type PropMenu = {
  show: boolean
  id: number
}
const MapViewGeojson: React.FC<Props> = ({height}) => {
  const [center, setCenter] = useState<latLng>()
  const [show, setShow] = useState<PropMenu>({
    show: false,
    id: null,
  })
  const markerRef = useRef<Marker>(null)
  const {filter, geometria, endereco, setEndereco} = useContext(FilterContext)
  const {data} = useContext(DataContext)
  // const {data} = useImoveis()
  const {onLoad, onUnmount, map, handleMarkerClick, isOpen, handleMarkerMOuseOut} = useMap()
  const {
    marcador,
    setMarcador,
    onPlaceChanged,
    buscaPosition,
    setBuscaPosition,
    onLoadAutoComplete,
    setMarcadorAddress,
  } = useAutocomplete(map)

  useEffect(() => {
    setCenter(centerInit)
  }, [])

  useEffect(() => {
    if (marcador.length === 0) {
      if (endereco.latitude && endereco.longitude && geometria && map) {
        setMarcadorAddress(map, endereco)
        setBuscaPosition('')
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endereco, map])
  // function onchangeAdress(map, enderecoFormatado: Endereco) {
  //   if (map) {
  //     setMarcadorAddress(map, enderecoFormatado)
  //     setEndereco({...enderecoFormatado})
  //   }
  // }

  async function getGeocoder(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder()
    const latLng = new google.maps.LatLng(lat, lng)
    return geocoder.geocode({location: latLng}, async function (results, status) {
      if (status === 'OK') {
        var place = results[0]
        var adr_elements = formatAdressSeachGoogleMapa(place)
        return adr_elements
      }
    })
  }



  return (
    <>
      {/* <button
        onClick={() => {
          onchangeAdress(map, {
            latitude: -23.5986377,
            longitude: -46.6689923,
            endereco_completo: 'R. Diogo Jácome, 818 - Moema, São Paulo - SP, 04512-001, Brasil',
            logradouro: 'Rua Diogo Jácome',
            numero: '818',
            bairro: 'Moema',
            cidade: 'São Paulo',
            estado: 'SP',
            cep: '04512-001',
          })
          setBuscaPosition('')
        }}
      >
        Load Bairros
      </button> */}

      <GoogleMap
        mapContainerStyle={{width: '100%', height: height}}
        center={center}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          // mapId: 'a28cbce2ae82f5e3',
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              'roadmap',
              'satellite',
              'hybrid',
              'noturno',
              'comercios_map',
              'onibus_map',
              'ligth',
              'simples',
              // 'a28cbce2ae82f5e3',
            ],
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.LEFT_TOP,
          },
        }}
      >
        <div className={buscaPosition}>
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={() => {
              onPlaceChanged(map)
            }}
          >
            <input
              type='text'
              placeholder='Digite um endereço...'
              className='form-control'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `48%`,
                height: `32px`,
                padding: `18px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '200px',
                top: '11px',
              }}
            />
          </Autocomplete>
        </div>

        {
          // eslint-disable-next-line array-callback-return
          data?.map((obj: ItensImoveisProps, i) => {
            // if (obj.computed) {
            return (
              <Marker
                onClick={(e) => {
                  e.domEvent.preventDefault()
                  e.domEvent.stopImmediatePropagation()
                  handleMarkerClick(obj.id)
                }}
                onMouseDown={(e) => {
                  handleMarkerMOuseOut(obj.id)
                }}
                ref={markerRef}
                visible={true}
                key={obj.id_empreendimento}
                position={{lat: obj.geom.coordinates[1], lng: obj.geom.coordinates[0]}}
                label={{
                  text: obj.nome.split('-')[0],
                  className: `marker-label`,
                  color: '#4d3838',
                }}
                icon={{
                  scaledSize: new google.maps.Size(20, 20),
                  url: obj.removed
                    ? toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png')
                    : toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(0, 0),
                }}
                // animation={google.maps.Animation.DROP}
              >
                {isOpen && isOpen.find((item) => item.id === obj.id) && (
                  <InfoBox
                    options={{
                      closeBoxURL: `xx`,
                      enableEventPropagation: false,
                      maxWidth: 440,
                      boxClass: 'infoBox2',
                    }}
                  >
                    <div>
                      <Tootip
                        dataImovel={obj}
                        onClose={handleMarkerClick}
                        showInfo={setShow}
                      ></Tootip>
                    </div>
                  </InfoBox>
                )}
              </Marker>
            )
            // }
          })
        }

        {marcador ? (
          marcador.map(function (el: TMarcador, index: number) {
            return (
              <Marker
                key={index}
                position={{lat: el.lat, lng: el.lng}}
                draggable={true}
                icon={{
                  scaledSize: new google.maps.Size(35, 35),
                  url: toAbsoluteUrl('/media/placeholder.png'),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(25, 25),
                }}
                onDragEnd={async (e) => {
                  let latLng = e.latLng.toJSON()

                  let enderecoTmp = {
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                  }

                  let novoEndereco = await getGeocoder(latLng.lat, latLng.lng)
                  var adr_elements = formatAdressSeachGoogleMapa(novoEndereco.results[0])
                  console.log(adr_elements)
                  // console.log(enderecoTmp) 
                  // loadAreaInfluencia(latLng.lat, latLng.lng)
                  setEndereco({...endereco, ...adr_elements})
                  let m = [
                    {
                      lat: latLng.lat,
                      lng: latLng.lng,
                      endereco: 'novoendereo',
                    },
                  ]

                  setMarcador([...m])
                }}
              ></Marker>
            )
          })
        ) : (
          <></>
        )}
        {filter?.areaInfluencia.tipo === 'raio' ? (
          <Raio
            lat={endereco.latitude}
            lng={endereco.longitude}
            distancia={filter.areaInfluencia.valor}
            map={map}
          ></Raio>
        ) : (
          <></>
        )}
        {filter?.areaInfluencia.tipo === 'isocrona' ? (
          <Isocrona
            lat={endereco.latitude}
            lng={endereco.longitude}
            tempo={filter.areaInfluencia.valor}
            map={map}
          ></Isocrona>
        ) : (
          <></>
        )}
        {filter?.areaInfluencia.tipo === 'zonaValor' ? (
          <ZonaDeValor
            map={map}
            lat={endereco.latitude}
            lng={endereco.longitude}
            valor={filter.areaInfluencia.valor}
          ></ZonaDeValor>
        ) : (
          <></>
        )}

        {filter?.areaInfluencia.tipo === 'definirManualmente' ? (
          <DefinirManualmente map={map}></DefinirManualmente>
        ) : (
          <></>
        )}
      </GoogleMap>
      {/* <div className='menu-info-emp-im' style={{width: '100%', height: height - 92}}> */}

      <MaisInfoEmpreendimentos
        setShow={setShow}
        show={show}
        height={height}
      ></MaisInfoEmpreendimentos>

      {/* 
        <div className='row'>
          <div className='col-12'>
            
          <button className="btn btn-info pull-right me-5 my-5">Fechar</button>
          </div>
          <div className='col-12'>

        
        </div>
        </div> */}
      {/* </div> */}
    </>
  )
}

export default MapViewGeojson
