import { BiExport } from "react-icons/bi"
import { ExportTable } from "../../../utils/ExportXLSX"
import { Imoveis } from "../types"



type Props = {
    data:Imoveis[]
}

export function Exportar({data}: Props) {


    function exportar() {
        // eslint-disable-next-line array-callback-return
        let exportaFinal = data.filter((item) => {
          delete item.geom
          if (item.active) {
            return item
          }
        })
        ExportTable(exportaFinal, '', 'Mercado secundário - Venda.xlsx')
      }

      return ( <button className='btn btn-primary pull-right' onClick={exportar}>
        <BiExport></BiExport> Exportar
      </button>)
}