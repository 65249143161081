import { gql } from "@apollo/client";

export const GET_USER_AREA_ATUACAO = gql`
query GetUserAreaAtuacao{
  GetUserAreaAtuacao{
    id
    area_atuacao{
      id  
      name
    }
    user {
      id
      name
    }
  }
}
`
