/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef} from 'react'

import {Modal} from 'react-bootstrap'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'
type Props = {
  id: number
  closeModal: any
  show: boolean
}

export const ImprimirModal: React.FC<Props> = ({id, closeModal, show}) => {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  function onClick(id) {
    const itensPrint = [];
    document.getElementsByName('print').forEach((i) => {
      if (i['checked']) {
        itensPrint.push(i['value']);
      }
    });
    const itensPrintStr = itensPrint.join(',');
  
    const url = '/meus-imoveis/export-pdf/' + id + '?param=' + itensPrintStr;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newWindow = window.open(url, '_blank');
  
    // newWindow.addEventListener('load', () => {
      //  função dispara após o conteúdo da nova janela ser carregado
      FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'imprimir', action: 'print', url: `/meus-imoveis/details/${id}` });
    // });
  }

  const exportRef = useRef()
  // FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'imprimir', action: 'print', url: `/meus-imoveis/details/${id}` });
// console.log('asdasd')
  return (
    <Modal size='lg' centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Imprimir:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div ref={exportRef}>
          <form
            id='kt_account_profile_details_form'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
          >
            <div className='card-body'>
              <div className='row d-flex justify-content-center'>
                <div className='col-4'>
                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='info'
                      />
                    </div>
                    <span>Informações do Lote</span>
                  </div>
                  {/* <div className="d-flex mb-2">
                    <div className="form-check me-2 form-check-sm form-check-custom form-check-solid">
                      <input className="form-check-input widget-9-check" type="checkbox"  name="print" value="info" />
                    </div>
                    <span>Condominios</span>
                  </div> */}
                  {/* <div className="d-flex mb-2">
                    <div className="form-check me-2 form-check-sm form-check-custom form-check-solid">
                      <input className="form-check-input widget-9-check" type="checkbox" value="1" />
                    </div>
                    <span>Ficha Territorial</span>
                  </div> */}
                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='obs'
                      />
                    </div>
                    <span>Observações</span>
                  </div>
                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='fotos'
                      />
                    </div>
                    <span>Fotos</span>
                  </div>
                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='documentos'
                      />
                    </div>
                    <span>Documentos</span>
                  </div>
                </div>
                <div className='col-5'>
                  {/* <div className="d-flex mb-2">
                    <div className="form-check me-2 form-check-sm form-check-custom form-check-solid">
                      <input className="form-check-input widget-9-check" type="checkbox" value="1" />
                    </div>
                    <span>Painel de Mercado</span>
                  </div> */}

                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='condicaoComercial'
                      />
                    </div>
                    <span>Condição Comercial</span>
                  </div>
                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='corretores'
                      />
                    </div>
                    <span>Corretores</span>
                  </div>
                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='avaliacoes'
                      />
                    </div>
                    <span>Avaliações</span>
                  </div>
                  <div className='d-flex mb-2'>
                    <div className='form-check me-2 form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        name='print'
                        value='proprietarios'
                      />
                    </div>
                    <span>Proprietarios</span>
                  </div>
                  {/* <div className="d-flex mb-2">
                    <div className="form-check me-2 form-check-sm form-check-custom form-check-solid">
                      <input className="form-check-input widget-9-check" type="checkbox" value="1" name="" />
                    </div>
                    <span>Atividades</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='card-footer'></div>
            <div></div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-default' id='kt_account_profile_details_submit' onClick={closeModal}>
          Fechar
        </button>
        <button
          type='button'
          className='btn btn-primary'
          id='kt_account_profile_details_submit'
          onClick={() => onClick(id)}
        >
          Imprimir
        </button>
      </Modal.Footer>
    </Modal>
  )
}
