import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {BiAnalyse, BiBookOpen} from 'react-icons/bi'
import {GetEmpreendimentosChosen} from '../../../components/UI/Chosens/GetEmpreendimentosChosen'
import {GetImoveisChosen} from '../../../components/UI/Chosens/GetImoveisChosen'
import Swal from 'sweetalert2'
import {useImoveisStore} from '../context/DataContext'
import {MutationPutMercadoSecundario} from '../../../graphql'
import PanelEmpreendimento from './PanelEmpreendimento'
import PanelImovel from './PanelImovel'

type Props = {
  id: number
}
type Associacao = {
  tipo: 'TERRENO' | 'EMPREENDIMENTO'
  id: number
}
const AssociarProjeto: React.FC<Props> = ({id}) => {
  const [show, setShow] = useState(false)
  const {projeto} = useImoveisStore()
  const [associacao, setAssociacao] = useState<Associacao>({
    tipo: null,
    id: null,
  })
  const {setValue: setValuePut, FormMercadoSecundario} = MutationPutMercadoSecundario()

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }
  function onChange(id: number, tipo: 'EMPREENDIMENTO' | 'TERRENO') {
    setAssociacao({
      tipo: tipo,
      id: id,
    })
    console.log(id)
  }

  function associarProjeto() {
    let id = projeto.id
    const id_terreno = associacao.tipo === 'TERRENO' ? associacao.id : null
    const id_empreendimento = associacao.tipo === 'EMPREENDIMENTO' ? associacao.id : null

    console.log(id_terreno)
    console.log(id_empreendimento)
    if (!id_terreno && !id_empreendimento) {
      Swal.fire('', 'Informe o empreendimento ou terreno para associar', 'info')
      return false
    }
    Swal.fire({
      title: 'Deseja associar o terreno/imovel ?',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
    }).then((result) => {
      if (result.isConfirmed) {
        setValuePut('id', id)
        setValuePut('id_terreno', id_terreno)
        setValuePut('id_empreendimento', id_empreendimento)

        const data = {
          id: id,
          id_terreno: id_terreno,
          id_empreendimento: id_empreendimento,
        }
        FormMercadoSecundario(data)
          .then((retorno) => {
            // FormSetLogAcessoRecurso({
            //   ferramenta: 'Inteligencia Mercado',
            //   funcionalidade: 'Atualizar Projeto',
            //   action: 'update',
            //   url: `inteligencia-mercado/${id}`,
            // })
            console.log(retorno)
            if (retorno.data) {
              Swal.fire('Sucesso!', 'Associação realizada com sucesso.', 'success')
              closeModal()
            } else if (retorno.errors.length > 0) {
              Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
            }
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    })
  }
  function disssociar() {
    Swal.fire({
      title: 'Deseja disssociar o terreno/imovel ?',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
    }).then((result) => {
      if (result.isConfirmed) {
        setValuePut('id', id)
        setValuePut('id_terreno', null)
        setValuePut('id_empreendimento', null)

        const data = {
          id: id,
          id_terreno: null,
          id_empreendimento: null,
        }
        FormMercadoSecundario(data)
          .then((retorno) => {
            // FormSetLogAcessoRecurso({
            //   ferramenta: 'Inteligencia Mercado',
            //   funcionalidade: 'Atualizar Projeto',
            //   action: 'update',
            //   url: `inteligencia-mercado/${id}`,
            // })
            console.log(retorno)
            if (retorno.data) {
              Swal.fire('Sucesso!', 'Disssociação realizada com sucesso.', 'success')
              // closeModal()
            } else if (retorno.errors.length > 0) {
              Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
            }
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    })
  }

  return projeto ? (
    <>
      {!projeto.terreno && !projeto.empreendimento ? (
        <button className='btn  btn-primary ms-2 pulse ' onClick={openModal}>
          <BiAnalyse /> Associar Projeto
          <span className='pulse-ring ' style={{height: 'auto'}}></span>
        </button>
      ) : (
        <></>
      )}
      <Modal centered animation show={show} onHide={() => closeModal()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Associar projeto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row align-items-center'>
            <div className='col-5'>
              <GetEmpreendimentosChosen
                onChange={(id: number) => {
                  onChange(id, 'EMPREENDIMENTO')
                }}
                defaultValue={associacao.id}
              ></GetEmpreendimentosChosen>
            </div>
            <div className='col-2 text-center fs-1'>ou</div>
            <div className='col-5'>
              <GetImoveisChosen
                defaultValue={associacao.id}
                onChange={(id: number) => {
                  onChange(id, 'TERRENO')
                }}
              ></GetImoveisChosen>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className='row'>
            <div className='col-12'>
              <button className='btn btn-primary ' onClick={associarProjeto}>
                Associar
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {projeto.empreendimento ? (
        <PanelEmpreendimento id={projeto.empreendimento.id} nome={projeto.empreendimento.nome}>
          <button className='btn btn-danger pull-right' onClick={disssociar}>
          Disssociar Empreendimento
          </button>
        </PanelEmpreendimento>
      ) : (
        <></>
      )}
      {projeto.terreno ? (
        <PanelImovel id={projeto.terreno.id} nome={projeto.terreno.nome}>
          <button className='btn btn-danger pull-right' onClick={disssociar}>
          Disssociar Empreendimento
          </button>
        </PanelImovel>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  )
}

export default AssociarProjeto
