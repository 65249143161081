import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {loadIsocrona} from '../Common/Isocrona'
import {AllGeoJSON, FeatureCollection} from '@turf/turf'
import {FilterContext} from '../../context/FilterContext'
import {encodeJsonIntersect, getLayer} from '../MapLayers/Layer'
import html2canvas from 'html2canvas'
import {MapboxLayer} from './MapBox/MapBox'
import { sleep } from '../../../explorer/components/common'

export function Mapas({data, setMapImageExport}: {data: any; setMapImageExport: any}) {
  const {geometriaSelecionada, endereco} = useContext(FilterContext)
  const [isocronas, setIsocronas] = useState<FeatureCollection>(null)
  const [layers, setLayers] = useState(null)
  const refLegenda = useRef<HTMLDivElement>(null)
  const geometria = geometriaSelecionada.geometry
  const geojson = {
    type: 'FeatureCollection',
    features: [],
  }

  useEffect(() => {
    let isMounted = true; // controle de montagem
    if (isMounted) {
      loadIsocrona({lat: endereco.latitude, lng: endereco.longitude}, (data) => {
        const geojson: FeatureCollection = {
          type: 'FeatureCollection',
          features: data.features,
        }

        setIsocronas(geojson)
      })
    }
    return () => {
      isMounted = false; // Cleanup quando o componente for desmontado
    };
  }, [endereco])

  useEffect(() => {

    if (layers){
      if (layers[0].image &&layers[1].image && layers[2].image) {
        let mapImageTemp = {
          map1: layers[2].image,
          map2: layers[0].image,
          map3: layers[1].image,
        }
        setMapImageExport(mapImageTemp)
      }
    }
  }, [layers, setMapImageExport])



  useEffect(() => {
    //eslint-disable-next-line array-callback-return
    data?.map((obj, i) => {
      if (obj.computed && !obj.removed) {
        geojson.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [obj.geom.coordinates[0], obj.geom.coordinates[1]],
          },
          properties: {},
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const layersList = useMemo(() => {
    let json = geometria as AllGeoJSON
    const jsonEncoded = encodeJsonIntersect(json)



    const layerConfigRenda = getLayer('view_ibge_explorer_renda_mensal_domiciliar')
    let intersect = `${layerConfigRenda.config.tile}layer=${layerConfigRenda.name}_operacao&l1=${layerConfigRenda.name}&s=${layerConfigRenda.schema}&l2=&g1=${jsonEncoded}`
    layerConfigRenda.config.data = intersect

    /**LAyer Lançamentos */
    const geojsonLancamentos = {
      type: 'FeatureCollection',
      features: [],
    }

    data?.sort(function (a, b) {
      return a.distancia - b.distancia
    })

    
    let count = 1
    //eslint-disable-next-line array-callback-return
    data?.map((obj, i) => {
      if (obj.computed && !obj.removed) {
        if (count <= 15) {
          geojsonLancamentos.features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [obj.geom.coordinates[0], obj.geom.coordinates[1]],
            },
            properties: {
              id: i + 1,
              distance: obj.distance,
            },
          })
        }
        count++
      }
    })

    /** VENDA **/
    const layerConfigVenda = getLayer('view_explorer_apartamento_venda_')
    intersect = `${layerConfigVenda.config.tile}layer=${layerConfigVenda.name}_operacao&l1=${layerConfigVenda.name}&s=${layerConfigVenda.schema}&l2=&g1=${jsonEncoded}`
    layerConfigVenda.config.data = intersect
    
    let layersList = [
      {
        id: 'lancamentos',
        data: geojsonLancamentos,
        paint: {
          // 'circle-color': '#ff0000',
          // 'circle-radius': 5,
          // 'circle-stroke-width': 2,
          // 'circle-stroke-color': '#ffffff',

          'text-color': '#000000',
          'text-halo-color': '#ffffff',
          'text-halo-width': 1,
          // 'text-anchor': 'top',
          // 'text-allow-overlap': true,
          // 'icon-allow-overlap': true,
          // 'text-ignore-placement': true,

          // 'line-color': [
          //   "case",
          //   ["==", ["get", "value"], 300], "#3e6b2e",
          //   ["==", ["get", "value"], 600], "#f10808",
          //   "#f10808"
          // ],
          // 'line-width': 2,
          // 'line-dasharray': [3, 5],
        },
        paintText: {},
        type: 'geojson',
        layout: {
          'text-field': ['get', 'id'],
          'text-size': 14,
          'text-offset': [0, 0.1],
          'text-anchor': 'top',
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
          'text-ignore-placement': true,
        },
        typeLayer: 'symbol',
        visibility: 'visible',
        image:null
      },
         
     
      {
        id: 'venda',
        tiles: [layerConfigVenda.config.data],
        source: 'view_explorer_apartamento_venda__operacao',
        paint: {
          'circle-color': [
            'interpolate',
            ['linear'],
            ['to-number', ['get', 'preco_m2']],
            2000,
            '#FF3333',
            4000,
            '#FF3333',
            6000,
            '#FF9933',
            8000,
            '#FFFF33',
            10000,
            '#99FF33',
            12000,
            '#33FF33',
            14000,
            '#33FF99',
            16000,
            '#33FFFF',
            18000,
            '#3399FF',
          ],
          'circle-radius': 5,
          'circle-opacity': 1,
        },
        layout: {},
        paintText: {
          'text-color': '#000000', // Cor do texto
        },
        type: 'vector',
        typeLayer: 'circle',
        legandaTitle: 'Preço por m²',
        visibility: 'none',
        image:null
      }, 
      {
        id: 'renda',
        tiles: [layerConfigRenda.config.data],
        source: 'view_ibge_explorer_renda_mensal_domiciliar_operacao',
        paint: layerConfigRenda.style,
        layout: {},
        paintText: {
          'text-color': '#000000', // Cor do texto
        },
        type: 'vector',
        typeLayer: 'fill',
        legandaTitle: 'Renda',
        visibility: 'none',
        image:null
      },
    ]

    return layersList
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLayers([...layersList])
  }, [layersList])

  if (!geometriaSelecionada) {
    return <></>
  }
  if (!isocronas) {
    return <></>
  }

async function onload({ map }: { map:any }) {
    


     html2canvas(map.target.getCanvasContainer(), {
      useCORS: true,
      scale: 2,
      width: 850,
      height: 650,
    }).then(async (canvas) => { 

      await sleep(2000)
      let mapImageCanvas = canvas.toDataURL('image/png', 1.0)
      if (!layers[0].image) {
        
        let layersTmp = layers
        layersTmp[0].visibility = 'none'
        layersTmp[0].image = mapImageCanvas
        layersTmp[1].visibility = 'visible'
        layersTmp[2].visibility = 'none'
        setLayers([...layersTmp])
      } else if (!layers[1].image) {
        let layersTmp = layers

        layersTmp[0].visibility = 'none'
        layersTmp[1].image = mapImageCanvas
        layersTmp[1].visibility = 'none'
        layersTmp[2].visibility = 'visible'
        
        setLayers([...layersTmp])
      } else if (!layers[2].image) {
          let layersTmp = layers
          layersTmp[0].visibility = 'none'
          layersTmp[1].visibility = 'none'
          layersTmp[2].image = mapImageCanvas
          layersTmp[2].visibility = 'visible'
        
        
        // layersTmp[0].visibility = 'none'
        // layersTmp[1].visibility = 'none'
        setLayers([...layersTmp])
      }
    })
  }
  
  return (
    <div style={{position: 'fixed', width: '850', height: '850', left: '-1000px', top: 0}}>
      {layers ? (
        <MapboxLayer
          latLng={[endereco.latitude, endereco.longitude]}
          zoom={14.5}
          onLoad={(e) => {
         
            onload({ map: e })
          }}
          style={{width: 850, height: 650}}
          layers={[...[{
            data: isocronas,
            paint: {
              'line-color': [
                'case',
                ['==', ['get', 'value'], 300],
                '#3e6b2e',
                ['==', ['get', 'value'], 600],
                '#f10808',
                '#f10808',
              ],
              'line-width': 2,
              'line-dasharray': [3, 5],
            },
            paintText: {
              'text-color': '#000000', // Cor do texto
            },
            type: 'geojson',
            layout: {},
            typeLayer: 'line',
            visibility: 'visible',
          }],...layers ]}
          inputRefLeganda={refLegenda}
          areaInfluencia={geometria}
        ></MapboxLayer>
      ) : (
        <></>
      )}

      {/* <Mapa1 data={data} setMapImageExport={setMapaToExport1} isocronas={isocronas} geometria={geometriaSelecionada.geometry} endereco={endereco} ></Mapa1>
      { mapImage.map1 ? <Mapa2 data={data} setMapImageExport={setMapaToExport2} isocronas={isocronas} geometria={geometriaSelecionada.geometry} endereco={endereco}></Mapa2> :<></> }
      { mapImage.map1 && mapImage.map2 ? <Mapa3 data={data} setMapImageExport={setMapaToExport3} isocronas={isocronas} geometria={geometriaSelecionada.geometry} endereco={endereco}></Mapa3> : <></> } */}
    </div>
  )
}
