import {useMutation} from '@apollo/client'
import React, {useEffect, useState} from 'react'
import {
  GET_PROFILE,
  GetProfile,
  PUT_USUARIO,
  TypesPutUsuarioByIDResponse,
} from '../../../../graphql'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {HexToRgb, RgbToHex} from '../../../explorer/components/common'

type Props = {
  data: GetProfile
}

export function TabPerfil({data}: Props) {
  const [colorSelect, setColorSelect] = useState('')

  const [color, setColor] = useState({
    r: 241,
    g: 112,
    b: 19,
    a: 255,
  })
  const [colorFont, setColorFont] = useState({
    r: 241,
    g: 112,
    b: 19,
    a: 255,
  })
  const [bodyRequest] = useMutation<TypesPutUsuarioByIDResponse>(PUT_USUARIO, {
    refetchQueries: [GET_PROFILE],
  })

  async function handleUpdateUserData(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (colorSelect !== null) {
      document.documentElement.style.setProperty('--page-bg', colorSelect)
    }

    await bodyRequest({
      variables: {
        usuario: {
          name: (e.currentTarget.elements.namedItem('nome') as HTMLInputElement).value,
          email: (e.currentTarget.elements.namedItem('email') as HTMLInputElement).value,
          telefone: (e.currentTarget.elements.namedItem('telefone') as HTMLInputElement).value,
          tipo_pessoa: undefined,
          //   tipo_pessoa: (e.currentTarget.elements.namedItem('tipo_pessoa') as HTMLInputElement)
          //     .value,
          area_atuacao: undefined,
          //   area_atuacao: (e.currentTarget.elements.namedItem('area_atuacao') as HTMLInputElement)
          //     .value,

          cor_fonte: (e.currentTarget.elements.namedItem('cor_fonte') as HTMLInputElement).value,
          cor_background: (e.currentTarget.elements.namedItem('cor_background') as HTMLInputElement)
            .value,
        },
      },
      refetchQueries: [GET_PROFILE],
    })
  }

  useEffect(() => {
    if (data) {
      if (data.GetProfile.cor_background) {
        let corBg = HexToRgb(data.GetProfile.cor_background)
        if (corBg) {
          setColor({
            r: corBg[0],
            g: corBg[1],
            b: corBg[2],
            a: corBg[3],
          })
        }
      }
      if (data.GetProfile.cor_fonte) {
        let corFont = HexToRgb(data.GetProfile.cor_fonte)
        if (corFont) {
          setColorFont({
            r: corFont[0],
            g: corFont[1],
            b: corFont[2],
            a: corFont[3],
          })
        }
        // setColor)
        // setColorFont(data.GetProfile.cor_fonte)
      }
    }
  }, [data])

  // Tratativa de loading
  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  }

  const styles = {
    default: {
      color: {
        width: '36px',
        height: '28px',
        borderRadius: '5px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      colorFont: {
        width: '36px',
        height: '28px',
        borderRadius: '5px',
        background: `rgba(${colorFont.r}, ${colorFont.g}, ${colorFont.b}, ${colorFont.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  }

  const handleClick = () => {
    // setShow(1)
  }
  return (
    <div
      className='tab-pane fade active show'
      id='tab-perfil'
      role='tabpanel'
      aria-labelledby='tab-perfil-tab'
    >
      <div className='accordion' id='accordionExample'>
        <div className='accordion-item show'>
          <h2 className='accordion-header' id='headingOne'>
            <button
              className='accordion-button'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseOne'
              aria-expanded='true'
              aria-controls='collapseOne'
            >
              <h3 className='fw-bolder m-0'>Atualizar detalhes de perfil</h3>
            </button>
          </h2>
          <div
            id='collapseOne'
            className='accordion-collapse collapse show'
            aria-labelledby='headingOne'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              <form
                id='kt_account_profile_details_form'
                className='form fv-plugins-bootstrap5 fv-plugins-framework'
                onSubmit={handleUpdateUserData}
              >
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Nome Completo
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                          <input
                            type='text'
                            name='nome'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            defaultValue={data?.GetProfile.name}
                          />
                          <div className='fv-plugins-message-container invalid-feedback'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                    <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        name='email'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetProfile.email}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Telefone</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='telefone'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  {/* <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Tipo de Pessoa:</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-2 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='tipo_pessoa'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.tipo_pessoa}
                      />
                    </div>
                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                      <span className='required'>Area de Atuação:</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-2 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='area_atuacao'
                        className='form-control form-control-lg form-control-solid'
                        // defaultValue={data?.GetProfile.area_atuacao?.name}
                      />
                    </div>
                  </div> */}

                  <div className='row mb-6'>
                    <div className='col-4'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span className='required'>Cor Background:</span>
                        <i
                          className='fas fa-exclamation-circle ms-1 fs-7'
                          data-bs-toggle='tooltip'
                          title=''
                          data-bs-original-title='Phone number must be active'
                          aria-label='Phone number must be active'
                        ></i>
                      </label>
                    </div>
                    <div className='col-8'>
                      <div className='d-flex flex'>
                        <input
                          type='text'
                          maxLength={7}
                          name='cor_background'
                          className='form-control col-3 w-100px'
                          defaultValue={RgbToHex(color.r, color.g, color.b, color.a)}
                          onChange={(e) => {
                            if (e.target.value.length >= 7) {
                              let cor = HexToRgb(e.target.value)
                              if (cor) {
                                setColor({r: cor[0], g: cor[1], b: cor[2], a: cor[3]})
                              }
                            }
                            setColorSelect(e.target.value)
                          }}
                        ></input>
                        <div style={styles.default.swatch} onClick={handleClick}>
                          <div style={styles.default.color} />
                        </div>
                      </div>
                      {/* {show ? <Block
                       color={color}
                        onChangeComplete={(color) => {
                          setColor(color.rgb)
                        }} 
                      />: ''} */}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-4'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span className='required'>Cor Fonte:</span>
                        <i
                          className='fas fa-exclamation-circle ms-1 fs-7'
                          data-bs-toggle='tooltip'
                          title='Cor da fonte do sistema'
                        ></i>
                      </label>
                    </div>
                    <div className='col-8'>
                      <div className='d-flex flex'>
                        <input
                          type='text'
                          maxLength={7}
                          name='cor_fonte'
                          className='form-control col-3  w-100px'
                          defaultValue={RgbToHex(
                            colorFont.r,
                            colorFont.g,
                            colorFont.b,
                            colorFont.a
                          )}
                          onChange={(e) => {
                            if (e.target.value.length >= 7) {
                              let cor = HexToRgb(e.target.value)
                              if (cor) {
                                setColorFont({r: cor[0], g: cor[1], b: cor[2], a: cor[3]})
                              }
                            }
                          }}
                        ></input>
                        <div style={styles.default.swatch} onClick={handleClick}>
                          <div style={styles.default.colorFont} />
                        </div>
                      </div>
                      {/* {show ? <Block
                       color={color}
                        onChangeComplete={(color) => {
                          setColor(color.rgb)
                        }} 
                      />: ''} */}
                    </div>
                  </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 pe-9'>
                  {/* <button type='reset' className='btn btn-light btn-active-light-primary me-2'>
                    Descartar
                  </button> */}
                  <button
                    type='submit'
                    className='btn btn-primary'
                    id='kt_account_profile_details_submit'
                  >
                    Salvar mudanças
                  </button>
                </div>
                <input type='hidden' />
                <div></div>
              </form>
            </div>
          </div>
        </div>
        <div className='accordion-item hide'>
          <h2 className='accordion-header' id='headingTwo'>
            <button
              className='accordion-button collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseTwo'
              aria-expanded='false'
              aria-controls='collapseTwo'
            >
              <h3 className='fw-bolder m-0'>Atualizar Informações da Empresa</h3>
            </button>
          </h2>
          <div
            id='collapseTwo'
            className='accordion-collapse collapse'
            aria-labelledby='headingTwo'
            data-bs-parent='#accordionExample'
          >
            <div className='accordion-body'>
              <form
                id='kt_account_profile_details_form'
                className='form fv-plugins-bootstrap5 fv-plugins-framework'
                onSubmit={handleUpdateUserData}
              >
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nome:</label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                          <input
                            type='text'
                            name='nome'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            defaultValue={data?.GetProfile.name}
                          />
                          <div className='fv-plugins-message-container invalid-feedback'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                    <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        name='email'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        defaultValue={data?.GetProfile.email}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Telefone</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='telefone'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Site da Empresa</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-8 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='site_empresa'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <hr />
                  <br />
                  <h2>Informações do Endereço</h2>
                  <br />
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                      <span className='required'>Endereço</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-10 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='site_empresa'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                      <span className='required'>Codigo Postal</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-4 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='site_empresa'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>

                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                      <span className='required'>Complemento</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-4 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='complemento'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                      <span className='required'>Tipo de Imovel</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-4 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='site_empresa'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                    <label className='col-lg-2 col-form-label fw-bold fs-6'>
                      <span className='required'>Numero</span>
                      <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title=''
                        data-bs-original-title='Phone number must be active'
                        aria-label='Phone number must be active'
                      ></i>
                    </label>
                    <div className='col-lg-2 fv-row fv-plugins-icon-container'>
                      <input
                        type='tel'
                        name='site_empresa'
                        className='form-control form-control-lg form-control-solid'
                        defaultValue={data?.GetProfile.telefone}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='row mb-6'></div>
                  <div className='row mb-6'></div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='reset' className='btn btn-light btn-active-light-primary me-2'>
                    Descartar
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    id='kt_account_profile_details_submit'
                  >
                    Salvar mudanças
                  </button>
                </div>
                <input type='hidden' />
                <div></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
