import pptxgen from 'pptxgenjs'
import {formatHeader, formatLogo, formatSubHeader} from './common'
import {borderTopLeftRadius} from 'html2canvas/dist/types/css/property-descriptors/border-radius'

export class ExportSlide {
  pptx: pptxgen
  title: string
  STARLABS_LOGO_SM = '/media/logos/logo.png'

  constructor(title: string) {
    this.pptx = new pptxgen()
    this.pptx.layout = 'LAYOUT_WIDE'
    this.pptx.author = 'Rafael Butt Fernandes Farias'
    this.pptx.company = 'Urbit '
    this.pptx.revision = '1'
    this.pptx.subject = 'Relatório IM'
    this.pptx.title = title
    this.pptx.theme = {bodyFontFace: 'Arial', headFontFace: 'Tahoma'}
    const dateText = new Date().toLocaleDateString()

    // this.STARLABS_LOGO_SM

    this.pptx.defineSlideMaster({
      title: 'MASTER_SLIDE',
      margin: [0.5, 0.25, 1.0, 0.25],
      background: {color: 'FFFFFF'},

      objects: [
        // {image: {x: 11, y: 0.2, w: 1.87, h: 0.7, path: this.STARLABS_LOGO_SM}},
        {rect: {x: 0, y: 7, w: '100%', h: 0.5, fill: {color: '0F997D'}}},
        // {
        //   text: {
        //     text: 'Urbit',
        //     options: {x: 0, y: 3.9, w: '100%', align: 'center', color: '333', fontSize: 12},
        //   },
        // },
        {
          text: {
            text: dateText,
            options: {
              x: 11.2,
              y: 7,
              h: 0.5,
              w: 1.5,
              align: 'right',
              color: 'FFFFFF',
              fontSize: 12,
              fontFace: 'bold',
            },
          },
        },
      ],
      slideNumber: {x: 0.5, y: 7.1, h: 0.5, color: 'FFFFFF', fontFace: 'bold'},
    })
    // this.pptx.addSection({ title: "Tables" });
    // this.pptx.addSection({ title: "Tables", order: 3 });
  }

  filterAno:[number,number]
  init = () => {}

  addCapa = (titulo: string, endereco: string) => {
    let slide = this.pptx.addSlide({masterName: 'MASTER_SLIDE', sectionTitle: 'Home'})
    slide.addImage({path: this.STARLABS_LOGO_SM, x: 11, y: 0.3, w: 1.87, h: 0.55})

    // { line: { x: 3.5, y: 1.0, w: 6.0, line: { color: "0088CC", width: 5 } } },
    // slide.addText("ShapeType.line", {
    //   shape: pptx.ShapeType.line,
    //   line: { color: "FF0000", width: 1, dashType: "lgDash" },
    // });

    slide.addText(titulo, {
      x: 0.0,
      y: '24%',
      w: '100%',
      h: 1.5,
      align: 'center',
      fontSize: 34,
      color: '333',
      fontFace: 'Arial',
      //   fill: {color: 'EBEDEE'},
    })
    slide.addText(endereco, {
      x: 4.2,
      y: 3.5,
      w: 5,
      h: 1,
      align: 'right',
      fontSize: 20,
      color: '333',
      fontFace: 'Arial',
      //   fill: {color: 'EBEDEE'},
    })
  }

  addSlide = (title: string, addComponete?: any) => {
    let slide = this.pptx.addSlide({masterName: 'MASTER_SLIDE', sectionTitle: 'Performance'})

    // HEADER
    slide.addImage(formatLogo)
    slide.addText(`Inteligência de Mercado - ${title} `, formatHeader)
    slide.addText(`PERFORMANCE ${this.filterAno ? `${this.filterAno[0]} A ${this.filterAno[1]}` : '2000 A 2024'}`, formatSubHeader)
    if (typeof addComponete !== 'undefined') {
      addComponete(slide)
    }
  }

  addChart = (chartConfig) => {
    let chartObj = []
    chartConfig.charData.map((cData) => {
      chartObj.push({
        type: this.pptx.ChartType[cData.type],
        data: cData.data,
        options: cData.options,
      })
    })
    chartConfig.slide.addChart(chartObj, chartConfig.config)
  }

  addChartSingle = (chartConfig) => {
    chartConfig.slide.addChart(
      this.pptx.ChartType[chartConfig.type],
      chartConfig.charData,
      chartConfig.config
    )
  }

  addChartBox = (chartConfig) => {
    // Dados para o gráfico de caixa
    let opts = {
      x: 0.5,
      y: 4.0,
      w: 6.0,
      h: 3.0,
      chartArea: {fill: {color: 'F1F1F1'}},

      barDir: 'bar',
      barGrouping: 'stacked',
      catAxisLabelColor: '999999',
      catAxisLabelFontFace: 'Arial',
      catAxisLabelFontSize: 14,
      catAxisOrientation: 'minMax',
      showLegend: false,
      showTitle: false,
      valAxisMaxVal: 100,
      valAxisMinVal: 0,
      valAxisMajorUnit: 20,

      lineSize: 0,
      lineDataSymbolSize: 20,
      lineDataSymbolLineSize: 2,
      lineDataSymbolLineColor: 'FF0000',

      //dataNoEffects: true,

      valAxes: [
        {
          showValAxisTitle: true,
          valAxisTitle: 'Primary Value Axis',
        },
        {
          showValAxisTitle: true,
          valAxisTitle: 'Secondary Value Axis',
          catAxisOrientation: 'maxMin',
          valAxisMajorUnit: 1,
          valAxisMaxVal: 10,
          valAxisMinVal: 1,
          valGridLine: {style: 'none'},
        },
      ],
      catAxes: [
        {
          catAxisTitle: 'Primary Category Axis',
        },
        {
          catAxisHidden: true,
        },
      ],
    }

    let labels = ['Q1', 'Q2', 'Q3', 'Q4', 'OT']
    let chartTypes = [
      {
        type: this.pptx.ChartType.bar,
        data: [
          {
            name: 'Bottom',
            labels: labels,
            values: [17, 26, 53, 10, 4],
          },
          {
            name: 'Middle',
            labels: labels,
            values: [55, 40, 20, 30, 15],
          },
          {
            name: 'Top',
            labels: labels,
            values: [10, 22, 25, 35, 70],
          },
        ],
        options: {
          barGrouping: 'stacked',
        },
      },
      {
        type: this.pptx.ChartType.line,
        data: [
          {
            name: 'Current',
            labels: labels,
            values: [5, 3, 2, 4, 7],
          },
        ],
        options: {
          barGrouping: 'standard',
          secondaryValAxis: !!opts.valAxes,
          secondaryCatAxis: !!opts.catAxes,
          chartColors: ['FFFF00'],
        },
      },
    ]
    chartConfig.slide.addChart(chartTypes, opts)
  }

  addText = (texto, config, slide) => {
    slide.addText(texto, config)
  }
  addTable = (chartConfig) => {
    let arrTextObjects = chartConfig.tableData
    chartConfig.slide.addTable(arrTextObjects, chartConfig.config) // DEPRECATED: `margin` in points
  }

  addBoxList = (config) => {
    config.slide.addText(
      [
        {
          text: config.titulo,
          options: {fontSize: 13, fontFace: 'Arial', color: '333333', breakLine: true},
        },
        {
          text: config.subtitulo,
          options: {fontSize: 12, fontFace: 'Arial', color: '81A35E', breakLine: true},
        },
      ],
      config.config
    )

    config.slide.addTable(config.lista, {
      x: config.config.x + 0.1,
      y: config.config.y + 1,
      w: config.config.w - 0.5,
      margin: 8,
      fill: {color: 'ffffff'},
      border: {pt: 1, color: 'd0d0d0'},
    })
  }

  addBox = (config) => {
    config.slide.addText(
      [
        {
          text: config.titulo,
          options: {fontSize: 13, fontFace: 'Arial', color: '333333', breakLine: true},
        },
        {
          text: config.subtitulo,
          options: {fontSize: 12, fontFace: 'Arial', color: '81A35E', breakLine: true},
        },
        {text: config.valor, options: {fontSize: 14, fontFace: 'Arial', color: '333333'}},
      ],
      config.config
    )
  }

  salvar = (nome: string) => {
    
     this.pptx.writeFile({fileName: nome})
  }
}
