import { gql } from "@apollo/client";

export const GET_AREA_ATUACAO = gql`
query GetAreaAtuacao($pagination: Pagination) {
  GetAreaAtuacao(pagination: $pagination) {
    result {
      id
      name
    }
    pageInfo {
      totalPages
      totalItems
      hasPreviousPage
      hasNextPage
      currentPage
    }
  }
}
`
