import {useQuery} from '@apollo/client'
import {GET_SUB_AREA_ATUACAO} from '../query'
import {
  TypesGetSubAreaAtuacaoResponse,
} from '../type'

interface QueryProps {
  variables: any
}

export function QueryGetSubAreaAtuacao({variables}: QueryProps) {
  const {data, loading, error} = useQuery<TypesGetSubAreaAtuacaoResponse>(GET_SUB_AREA_ATUACAO, {
    variables: {...variables},
  })
  return {data, loading, error}
}
