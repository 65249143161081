import {Fragment} from 'react'
import {useTable, useSortBy, useFilters, useExpanded, usePagination} from 'react-table'
import {Table, Row} from 'react-bootstrap'
import {Filter, DefaultColumnFilter} from './filters'

const TableContainer = ({columns, data, filter = ''}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {Filter: DefaultColumnFilter},
      initialState: {pageIndex: 0, pageSize: 10},
      autoResetGlobalFilter: false,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''
  }

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  let url = '#'
  return (
    <Fragment>
      <div className='table-responsive'>
        <Table bordered hover {...getTableProps()} className='table'>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div
                      {...column.getSortByToggleProps()}
                      className='d-flex  justify-content-between'
                    >
                      <div>{column.render('Header')}</div>
                      <div> {generateSortingIndicator(column)}</div>
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                  {/* {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )} */}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      <Row
        style={{maxWidth: 1000, margin: '0 auto', textAlign: 'center'}}
        className='d-flex  align-items-center mt-10 mb-5'
      >
        <nav aria-label='Paginação Análise de Concorrentes'>
          <ul className='pagination flex-row d-flex align-items-center'>
            <li className='page-item'>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </li>
            <li className='page-item'>
              <select
                // type='select'
                value={pageSize}
                onChange={onChangeInSelect}
                className='form-control input-sm'
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </li>
            <li className='page-item'>
              <button
                className={`page-link ${!canPreviousPage ? 'disabled' : ''} `}
                aria-label='Primeira página'
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span aria-hidden='true'>&laquo;&laquo;</span>
                <span className='sr-only'>Primeira</span>
              </button>
            </li>
            <li className='page-item'>
              <button
                className={`page-link ${!canPreviousPage ? 'disabled' : ''} `}
                aria-label='Página Anterior'
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                <span aria-hidden='true'>&laquo;</span>
                <span className='sr-only'>Anterior</span>
              </button>
            </li>
            <li className='page-item'>
              <input
                type='number'
                className='form-control'
                min={1}
                style={{width: 70}}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </li>
            {/* <li className="page-item"><a className="page-link" href={url}>1</a></li>
            <li className="page-item"><a className="page-link" href={url}>2</a></li>
            <li className="page-item"><a className="page-link" href={url}>3</a></li> */}
            <li className='page-item'>
              <button
                className='page-link'
                aria-label='Next'
                onClick={nextPage}
                disabled={!canNextPage}
              >
                <span aria-hidden='true'>&raquo;</span>
                <span className='sr-only'>Next</span>
              </button>
            </li>
            <li className='page-item'>
              <button
                className='page-link'
                aria-label='Next'
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span aria-hidden='true'>&raquo;&raquo;</span>
                <span className='sr-only'>Next</span>
              </button>
            </li>
          </ul>
        </nav>
      </Row>
    </Fragment>
  )
}

export default TableContainer
