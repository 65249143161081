import {Text, View, StyleSheet} from '@react-pdf/renderer'
import {CurrencyFormat} from '../../util'
const borderColor = '#838383'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    fontSize: 11,
    fontWeight: 'light',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  rowCemPorcento: {
    display: 'flex',
    flexDirection: 'row',
    // borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'left',
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#2B8E57',
  },
  header: {
    borderTop: 'none',
    backgroundColor: '#dbdbdb',
  },
  bold: {
    fontWeight: 'black',
  },
  rowDefault: {
    width: '20%',
  },
  rowCenter: {
    width: '20%',
    textAlign: 'center',
  },
  decimal: {
    width: '20%',
    textAlign: 'center',
  },
  currency: {
    width: '20%',
    textAlign: 'center',
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'center',
  },
})
export const CondicaoComercial = ({condicaoComercial}) => {
  if (condicaoComercial) {
    return (
      <View style={styles.table}>
        <Text style={styles.title}>Condição Comercial</Text>
        <View style={[styles.row, styles.bold, styles.header]}>
          <Text style={styles.rowCenter}>Dinheiro</Text>
          <Text style={styles.rowCenter}>Permuta Física</Text>
          <Text style={styles.rowCenter}>Permuta Financeira</Text>
          <Text style={styles.rowCenter}>Valor Total</Text>
        </View>
        {condicaoComercial.GetImovelCondicaoComercialByTerreno.result.length === 0 && 
          <Text style={styles.rowErro}>Nenhum registro encontrado</Text>
         }

        {condicaoComercial.GetImovelCondicaoComercialByTerreno.result.map((row, i) => (
          <View key={`${i}_principal`}>
            <View style={styles.row} wrap={false}>
              <Text style={styles.rowCenter}>{row.dinheiro_valor}</Text>
              <Text style={styles.rowCenter}>{row.permuta_fisica_pecentual}</Text>
              <Text style={styles.rowCenter}>{row.permuta_financeira_percentual}</Text>
              <Text style={styles.currency}>{CurrencyFormat(row.valor_total)}</Text>
            </View>
            <View style={styles.row} wrap={false}>
              <Text style={styles.rowCemPorcento}>Observação:{row.observacoes}</Text>
            </View>
          </View>
        ))}
      </View>
    )
  }
}
