import {Text, View, StyleSheet} from '@react-pdf/renderer'
import {RenderListElement} from './RenderListElement'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 12,
    marginTop: 20,
    lineHeight: 1.3,
  },
  row2: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 12,
    lineHeight: 1.3,
  },
  logo: {
    width: 200,
  },
  sobre: {
    marginTop: 10,
    marginRight: 5,
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 8,
    width: 520,
  },
  nomeEmpresa: {
    flexDirection: 'column',
    textAlign: 'right',
    paddingBottom: 2,
    fontSize: 13,
  },
  space: {
    paddingBottom: 2,
  },
  h1: {
    fontSize: 18,
  },
  h2: {
    fontSize: 16,
  },
  h3: {
    fontSize: 14,
  },
  h4: {
    marginTop: 20,
    marginBottom: 5,
    fontSize: 12,
    borderBottomColor: '#09591d',
    borderBottomWidth: 2,
  },
  h5: {
    fontSize: 10,
  },
  h6: {
    marginTop: 6,
    fontSize: 9,
  },
  p: {
    fontSize: 9,
  },
})

export const ContentService = ({titulo, servicos,servicosVisualizacao,data,municipio}) => {

  console.log(servicos)
  if (servicos.length > 0 ){
    return (
      <View>
        <View style={styles.row}>
          <Text style={styles.h2}>{titulo}</Text>
        </View>

        {
          
          // eslint-disable-next-line array-callback-return
          servicos.map((servico, y) => {
        
              return (
                <View style={styles.row2} key={y}>
                  <Text style={styles.h4}>{servico['nome']}</Text>
                  <RenderListElement
                    url={servico['url_api']}
                    lat={data.GetImovel.latitude}
                    lng={data.GetImovel.longitude}
                    municipio={municipio}
                    distancia={servico['distancia']}
                    key={y}
                  />
                </View>
              )
          
          })
        }
      </View>
    )
    }else{

      return   <></>
    }
}
