import {AllGeoJSON} from '@turf/turf'
import {useImoveisStore} from '../../context/DataContext'
import {getLayer, getLayers} from '../../../inteligencia-mercado/components/MapLayers/Layer'
import {MapView} from './MapView'
import {Legenda} from './Legenda'
import CheckServices from './CheckServices'
import {Exportar} from '../../partials/BtnExportar'

type Props = {
  label?: string
}

export function PerformanceSocio({label}: Props) {
  const {imoveis, geometria, endereco} = useImoveisStore()
  if (!imoveis) {
    return <>Carregando...</>
  }
  if (!geometria) {
    return <></>
  }

  let json = geometria.geometry as AllGeoJSON
  const layersList = getLayers(['view_ibge_explorer_renda_mensal_domiciliar'], json)
  const layerConfig = getLayer('view_ibge_explorer_renda_mensal_domiciliar')
  let center = {
    lat: -46.6,
    lng: -23.6,
  }
  if (endereco) {
    center = {lat: endereco.latitude, lng: endereco.longitude}
  }
  const filtered = imoveis.filter((item) => item.active)

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <div className='header-report d-flex all-start-between '>
          <div>
            <h1 className='fw-bolder'>Sociodemográfico</h1>
            <span>Censo 2022/2010</span>
          </div>
          <div>
            <Exportar data={imoveis}></Exportar>
          </div>
        </div>

        <div className='content pt-10'>
          <div className='row'>
            <div className='col-12'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <div className='map-painel d-flex w-100'>
                  <div className='mapa col-8'>
                    <MapView
                      data={filtered}
                      layer={[layersList]}
                      height={'650px'}
                      zoom={15}
                      center={center}
                      geometria={geometria}
                    >
                      <Legenda style={layerConfig.style} title={'Renda'} visivel={true}></Legenda>
                    </MapView>
                  </div>
                  <div className='painel-lateral col-4 px-5'>
                    <CheckServices onChange={undefined}></CheckServices>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
