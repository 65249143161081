import ReactPivot from 'react-pivot-webpack'
import {useEffect, useState} from 'react'
import { Template } from '../../../explorer/components/Util/TemplateReplace'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { BoxPrice } from '../../../explorer/components/Map/LayoutControl/Relatorios/painelImobiliario/ApartamentosVenda/BoxPrice'
// import {Template} from '../../../Util/TemplateReplace'

interface Props {
  itens: any[]
}
export const PivotTable: React.FC<Props> = ({itens}) => {
  const [result, setResult] = useState(null)
  var dimensions = [
    {value: 'quartos', title: 'Quartos'},
    {value: 'tipo', title: 'Tipo'},
    {value: 'banheiros', title: 'Banheiros'},
    {value: 'vaga', title: 'Vaga'},
    {value: 'endereco', title: 'Endereço'},
    {value: 'area', title: 'Área'},
  ]

  useEffect(() => {
    setResult(itens)
  }, [itens])

  var reduce = function (row, memo) {
    memo.count = (memo.count || 0) + 1
    memo.valor = (memo.valor || 0) + row.valor
    memo.media = (memo.valor || 0) / memo.count
    memo.area = (memo.area || 0) + row.area
    return memo
  }

  var calculations = [
    {
      title: 'Qtde',
      value: function (memo) {
        return memo.count
      },
      template: function (val, row) {
        return val.toFixed(0)
      },
      sortBy: function (row) {
        return isNaN(row.count) ? 0 : row.count
      },
      className: 'text-center',
    },
    {
      title: 'Preços (R$/M2)',
      value: function (memo) {
        return memo.valor / memo.area
      },
      template: function (val, row) {
        return Template.formatNumber(val.toFixed(2), ``)
      },
      className: 'text-right',
    },
    {
      title: 'Ticket Médio (R$/Unidade)',
      value: function (memo) {
        return memo.valor / memo.count
      },
      template: function (val, row) {
        return Template.formatNumber(val.toFixed(2), ``)
      },
      className: 'text-right',
    },
    {
      title: 'Área Privativa (m²)',
      value: function (memo) {
        return memo.area / memo.count
      },
      template: function (val, row) {
        return val.toFixed(0)
      },
      className: 'text-center',
    },
  ]

  if (!result) {
    return <Loader text={'Carregando...'}></Loader>
  }

  if (result) {
    var rows = result
    var precoMedioM2 = 0
    var ticketMedioM2 = 0
    var arePrivativaMediaM2 = 0
    var totalItens = 0
    var precoMedioM2PorTipologia = Array(7).fill(0, 0, 7)
    var ticketMedioM2PorTipologia = Array(7).fill(0, 0, 7)
    var areaPorTipologia = Array(7).fill(0, 0, 7)
    var totalItensTipologia = Array(7).fill(0, 0, 7)

    // eslint-disable-next-line array-callback-return
    result.map(function (element) {
      totalItens += 1
      precoMedioM2 += element.valor / element.area
      ticketMedioM2 += element.valor
      arePrivativaMediaM2 += element.area

      precoMedioM2PorTipologia[element.quartos] += element.valor / element.area
      ticketMedioM2PorTipologia[element.quartos] += element.valor
      areaPorTipologia[element.quartos] += element.area
      totalItensTipologia[element.quartos] += 1
    })

    precoMedioM2 = precoMedioM2 / totalItens
    ticketMedioM2 = ticketMedioM2 / totalItens
    arePrivativaMediaM2 = arePrivativaMediaM2 / totalItens
  }
  return (
    <>
      {/* <h1 className='text-gray-800 fs-2 fw-bolder me-1 py-10 mx-1 m-0'>Apartamentos à Venda</h1> */}
      <div className='row'>

      <div className='col-12 col-12'>
          <div className='row'>
            <h2 className='py-5'> Valores acumulados </h2>
            <div className='col-3 text-right'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(precoMedioM2, ''))}
                description='Preços ( R$/M2)'
              />
            </div>
            <div className='col-3 text-right'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(ticketMedioM2, ''))}
                description='Ticket Médio (R$/Unidade)'
              />
            </div>
            <div className='col-3 text-right'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(arePrivativaMediaM2, 'decimal')) + 'm²'}
                description='Área Privativa média (m²)'
              />
            </div>
            <div className='col-3 text-right'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(totalItens)}
                description='Total de amostras'
              />
            </div>
          </div>
        </div>
        <div className='col-12 col-12'>
          {result ? (
            <ReactPivot
              tableClassName='table table-striped'
              rows={rows}
              dimensions={dimensions}
              activeDimensions={['Tipo', 'Quartos']}
              reduce={reduce}
              calculations={calculations}
              soloText={'Filtro'}
              subDimensionText={'Agrupamento'}
            />
          ) : (
            <div>Carregando...</div>
          )}
        </div>

        

        {/* <div className='row'>
          {precoMedioM2PorTipologia.map((item, index) => {
            if (areaPorTipologia[index] > 0) {
              return (
                <div className='col-6' key={index}>
                  <div className='row'>
                    <h2 className='py-15'> {index} Dormitório(s) </h2>

                    <div className='col-xl-4 text-right'>
                      <BoxPrice
                        className='card-xl-stretch mb-xl-8'
                        svgIcon='/media/icons/duotune/general/gen032.svg'
                        color='white'
                        iconColor='success'
                        title={String(Template.formatNumber(item / totalItensTipologia[index], ''))}
                        description='Preços ( R$/M2)'
                      />
                    </div>
                    <div className='col-xl-4 text-right'>
                      <BoxPrice
                        className='card-xl-stretch mb-xl-8'
                        svgIcon='/media/icons/duotune/general/gen032.svg'
                        color='white'
                        iconColor='success'
                        title={String(
                          Template.formatNumber(
                            ticketMedioM2PorTipologia[index] / totalItensTipologia[index],
                            ''
                          )
                        )}
                        description='Ticket Médio (R$/Unidade)'
                      />
                    </div>
                    <div className='col-xl-4 text-right'>
                      <BoxPrice
                        className='card-xl-stretch mb-xl-8'
                        svgIcon='/media/icons/duotune/general/gen032.svg'
                        color='white'
                        iconColor='success'
                        title={
                          String(
                            Template.formatNumber(
                              areaPorTipologia[index] / totalItensTipologia[index],
                              'decimal'
                            )
                          ) + 'm²'
                        }
                        description='Área Privativa (m²)'
                      />
                    </div>
                  </div>
                </div>
              )
            } else {
              return <div key={index}></div>
            }
          })}
        </div> */}
        {/* </div> */}
      </div>
    </>
  )
}
