import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { QueryGetEmpreendimentos } from '../../../../graphql/services/Empreendimento'

export function GetEmpreendimentosChosen({
  defaultValue,
  onChange
}) {
  let itensArray = []

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    onChange(value)
  }, [value])

  const { data } = QueryGetEmpreendimentos({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      }
    }
  })

  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Empreendimentos:</label>
          <Select
            options={itensArray}
            onChange={(e) => {
              setValue(e.value)
            }}
            value={itensArray.filter((e) => e.value)[0]}
            name='id_corretor'
            placeholder='Empreendimentoses Disponiveis'
          />
        </div>
      </>
    )
  }
  itensArray.push({ value:'', label:'--selecione--'})
  data.GetEmpreendimentos.result.map((Options) => {
    itensArray.push({ value: Options.id, label: Options.nome })
    return []
  })



  return (
    <>
      <div className='form-group'>
        <label className='col-form-label'>Empreendimentos:</label>
        <Select
          options={itensArray}
          onChange={(e) => {
            setValue(e.value)
          }}
          name='id_corretor'
          placeholder='Empreendimentoss Disponiveis'
        />
      </div>
    </>
  )
}
