import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {
  MutationPutCondicaoComercial,
  QueryGetCondicaoComercialByID,
} from '../../../../../../graphql/services/CondicaoComercial'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {FormInputCurrencyFormat} from '../../../../../../components/UI/Inputs/FormInputCurrencyFormat'
import {FormTextareaAndLabel} from '../../../../../../components/UI/Inputs/FormTextareaAndLabel'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'

interface Props {
  id: number
  show: boolean
  closeModal: any
  idTerreno: string
}

export function EditForm({id, show, closeModal, idTerreno}: Props) {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const {data, loading: LoadingGet} = QueryGetCondicaoComercialByID({
    variables: {condicaoComercial: {id: id}},
  })

  const {FormCondicaoComercial, loading, handleSubmit, register, errors, control, setValue} =
    MutationPutCondicaoComercial()

  const onSubmit = async (data) => {
    FormCondicaoComercial(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'meus-imoveis',
          funcionalidade: 'condicao-comercial',
          action: 'create',
          url: '/meus-imoveis/details/' + idTerreno + '#condicao-comercial',
        })
        Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return (
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Condição Comercial:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader text={'caregando...'}></Loader>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Condição Comercial:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id='kt_account_profile_details_form'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
          >
            <div className='card-body'>
              <div className='row mb-6'>
                <input
                  type='number'
                  hidden
                  className='form-control'
                  defaultValue={data.GetImovelCondicaoComercial.id}
                  {...register('id', {required: true, valueAsNumber: true})}
                />
                <input
                  type='number'
                  hidden
                  className='form-control'
                  defaultValue={parseInt(idTerreno)}
                  {...register('id_terreno', {required: true, valueAsNumber: true})}
                />

                <label className='col-md-4 col-form-label fw-bold fs-6'>Dinheiro:</label>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <FormInputCurrencyFormat
                        control={control}
                        className='form-control form-control-solid text-right'
                        placeholder='R$ 00,00'
                        defaultValue={data.GetImovelCondicaoComercial.dinheiro_valor}
                        register={register('dinheiro_valor')}
                        error={errors ? errors.dinheiro_valor?.message : ''}
                        onValueChange={(e) => {
                          setValue('dinheiro_valor', e.floatValue)
                        }}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6' htmlFor='permuta_fisica'>
                  Permuta Fisica:
                </label>

                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <FormInputCurrencyFormat
                        control={control}
                        className='form-control form-control-solid text-right'
                        placeholder='R$ 00,00'
                        defaultValue={data.GetImovelCondicaoComercial.permuta_fisica_pecentual}
                        register={register('permuta_fisica_pecentual')}
                        error={errors ? errors.permuta_fisica_pecentual?.message : ''}
                        onValueChange={(e) => {
                          setValue('permuta_fisica_pecentual', e.floatValue)
                        }}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label
                  className='col-md-4 col-form-label fw-bold fs-6'
                  htmlFor='permuta_financeira'
                >
                  Permuta Financeira:
                </label>

                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <FormInputCurrencyFormat
                        control={control}
                        className='form-control form-control-solid text-right'
                        placeholder='R$ 00,00'
                        defaultValue={data.GetImovelCondicaoComercial.permuta_financeira_percentual}
                        register={register('permuta_financeira_percentual')}
                        error={errors ? errors.permuta_financeira_percentual?.message : ''}
                        onValueChange={(e) => {
                          setValue('permuta_financeira_percentual', e.floatValue)
                        }}
                      />

                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-form-label fw-bold fs-6' htmlFor='valor_total'>
                  Valor Total:
                </label>
                <div className='col-md-8'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <FormInputCurrencyFormat
                        control={control}
                        disabled
                        className='form-control form-control-solid text-right'
                        placeholder='R$ 00,00'
                        defaultValue={data.GetImovelCondicaoComercial.valor_total}
                        register={register('valor_total')}
                        error={errors ? errors.valor_total?.message : ''}
                        onValueChange={(e) => {
                          setValue('valor_total', e.floatValue)
                        }}
                      />

                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-md-4 col-lg-4 col-form-label fw-bold fs-6'>Observações</label>
                <div className='col-lg-8 '>
                  <FormTextareaAndLabel
                    rows={6}
                    defaultValue={data.GetImovelCondicaoComercial.observacoes}
                    className='form-control form-control-solid'
                    placeholder='Descreva suas observações...'
                    register={register('observacoes')}
                    error={errors ? errors.observacoes?.message : ''}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              </div>
            </div>
            <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
              <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
                Fechar
              </button>
              <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                Salvar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
