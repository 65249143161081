// import { useParams } from 'react-router-dom';

import {TypesGetEmpreendimentoImovelResponse} from '../../../../../graphql/type'
import {Loader} from '../../../../../components/UI/Loader/Loader'
import {Buttons} from './partials/Actions'
import {CoordinatesToPath, MapView} from '../../../../../components/UI/MapView'
import {DateTimToView, marcadoresMap} from '../../../../../components/util'
import {CreateForm} from './components/CreateForm'
import {useState} from 'react'
import {BiExport, BiPlus} from 'react-icons/bi'
import * as turf from '@turf/turf'
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx'

import {
  MutationSetImovelAddEmpreedimento,
  QueryGetEmpreendimentoImoveis,
} from '../../../../../graphql'
import {MutationSetLogAcessoRecurso} from '../../../../../graphql/services/LogAcesso'
import {formatAdressSeachGoogleMapa} from '../../../../explorer/components/common'
import {Endereco} from '../../../../explorer/components/Map/ComponentsControl/EnderecoControl/endereco'
import {ApiUrbit} from '../../../../../../services/http/urbit'
type Props = {
  imoveis: TypesGetEmpreendimentoImovelResponse
  OpenModalMeusImoveis: any
  idEmpreendimento: number
  infoEmp: any
  param: string
}

export const TabComposicaoTerrenos: React.FC<Props> = ({
  imoveis,
  OpenModalMeusImoveis,
  idEmpreendimento,
  infoEmp,
  param,
}) => {
  const [showCreate, setShowCreate] = useState(false)
  const {FormImovelAddEmpreendimento} = MutationSetImovelAddEmpreedimento()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  let latlng = []

  const {data: dataImoveis} = QueryGetEmpreendimentoImoveis({
    variables: {
      idEmpreendimento: idEmpreendimento,
      pagination: {
        pagina: 0,
        quantidade: 50,
      },
    },
  })

  if (!dataImoveis) {
    return <Loader text={'caregando...'}></Loader>
  }

  async function ExportKMZ(uuid) {
    await fetch(
      // process.env.REACT_APP_API_URL_EXTRA_SERVICES + '/export-kmz/8adc0938-681d-11ee-b708-2618765f0467',
      process.env.REACT_APP_API_URL_EXTRA_SERVICES + '/export-kmz/' + uuid,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = infoEmp.nome + '.kmz'
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() //afterwards we remove the element again
      })
      .catch((e) => {
        console.log(e)
      })

    FormSetLogAcessoRecurso({
      action: 'export',
      ferramenta: 'empreendimento',
      funcionalidade: 'composicao-terrenos',
      url: `/empreendimentos/details/${idEmpreendimento}#composicao-terrenos`,
    })
  }
  function ExportXls() {
    const dataToExport = []
    let somaarea = 0
    let somaareareal = 0
    let somatestada = 0
    // eslint-disable-next-line array-callback-return
    dataImoveis.GetEmpreendimentoImovel.result.map((item) => {
      somaarea += item.terreno.area_iptu
      somaareareal += item.terreno.area_real
      somatestada += item.terreno.testada
      dataToExport.push({
        Endereço: item.terreno.endereco,
        Número: item.terreno.numero,
        // 'Bairro':item.terreno.bairro,
        Cidade: item.terreno.cidade,
        Latitude: item.terreno.latitude,
        Longitude: item.terreno.longitude,
        Zoneamento: item.terreno.zoneamento,
        'Área Iptu': item.terreno.area_iptu,
        'Área Real': item.terreno.area_real,
        // "Situação":item.terreno.si
        'Data Cadastro': item.terreno.data_cadastro,
        Codlog: item.terreno.codlog,
        'Número Contribuinte': item.terreno.numero_contribuinte,
        'Nome Contribuinte': item.terreno.nome_contribuinte,
        'Testada Para Cálculo': item.terreno.testada,
      })
    })
    dataToExport.push({
      Endereço: null,
      Número: null,
      Cidade: null,
      Latitude: null,
      Longitude: null,
      Zoneamento: 'Total',
      'Área Iptu': somaarea,
      'Área Real': somaareareal,
      'Data Cadastro': null,
      Codlog: null,
      'Número Contribuinte': null,
      'Nome Contribuinte': null,
      'Testada Para Cálculo': somatestada,
    })

    const worksheet = XLSX.utils.json_to_sheet(dataToExport)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, infoEmp.nome.substring(0, 29))
    XLSX.writeFile(wb, infoEmp.id + '-' + infoEmp.nome + '.xlsx')

    FormSetLogAcessoRecurso({
      action: 'export',
      ferramenta: 'empreendimento',
      funcionalidade: 'composicao-terrenos',
      url: `/empreendimentos/details/${idEmpreendimento}#composicao-terrenos`,
    })
  }
  function OpenModalCreate(id) {
    setShowCreate(true)
  }

  function CloseModalCreate() {
    setShowCreate(false)
  }

  async function getGeocoder(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder()
    const latLng = new google.maps.LatLng(lat, lng)
    return geocoder.geocode({location: latLng}, async function (results, status) {
      if (status === 'OK') {
        var place = results[0]
        var adr_elements = formatAdressSeachGoogleMapa(place)
        return adr_elements
      }
    })
  }
  async function onClickMap(lat, lng, cidade = 'sao-paulo') {

    // console.log(dataImoveis)
    // if (dataImoveis.GetEmpreendimentoImovel.result.length > 0) {
      const result = await ApiUrbit.getServico('lote-fiscal', lat, lng, cidade, 0)
      
      if (result) {
        Swal.close()
        if (result) {
          if (result.itens.length > 0) {
            const lote = result.itens[0]
            result.itens[0].lat = lat
            result.itens[0].lng = lng
            let loteJson = ''
            if (lote.geom) {
              loteJson = JSON.parse(lote.geom)
            }
            let dados = result.itens[0]
            if (!dados.logradouro) {
              let result = await getGeocoder(lat, lng)
              let municipio = await Endereco.getMunicipioAPI(lat, lng)
              var adr_elements = formatAdressSeachGoogleMapa(result.results[0])

              dados['logradouro'] = adr_elements['logradouro']
              dados['bairro'] = adr_elements['bairro']
              dados['numero'] = adr_elements['numero']
              dados['cep'] = adr_elements['cep']
              dados['cidade'] = municipio['nome']
              dados['id_cidade'] = municipio['id']
              dados['id_estado'] = municipio['estado_id']
            }
            Swal.fire({
              title: 'Adicionar este lote ao meu empreendimento?',
              confirmButtonText: 'Adicionar',
              showConfirmButton: true,
              cancelButtonText: 'Cancelar',
              showCancelButton: true,
            }).then((resultQuestion) => {
              if (resultQuestion.value === true) {
                const data = {
                  idEmpreendimento: idEmpreendimento,
                  nome: dados.logradouro + ',' + dados.numero,
                  endereco: dados.logradouro,
                  numero: dados.numero,
                  complemento: dados.complemento,
                  cep: dados.cep,
                  area_iptu: Number(dados.area_lote),
                  area_real: Number(dados.area_lote),
                  numero_contribuinte: dados.numero_contribuinte,
                  id_situacao: 1,
                  latitude: String(lat),
                  longitude: String(lng),
                  setor_quadra: dados.setor + '' + dados.quadra,
                  // setor:dados.setor,
                  // quadra: dados.quadra,
                  uso_atual_terreno: dados.uso_atual,
                  codlog: dados.codlog,
                  terreno_esquina: String(dados.quantidade_de_esquinas_frentes),
                  postgis_municipio_id: Number(dados.id_cidade),
                  terrain_type: 'IMOVEL',
                  testada: Number(dados.testada),
                  metragem: Number(dados.area_lote),
                  cod_condomio: dados.lote === '0000' ? '00' : null,
                  id_estado: Number(dados.id_estado),
                  id_cidade: Number(dados.id_cidade),
                  nome_contribuinte: dados.nome_contribuinte,
                  numero_lote: String(dados.id),
                  marcacao: JSON.stringify(loteJson),
                }
                FormImovelAddEmpreendimento(data)
              }
            })
          }
        }
      // }
    } else {
      Swal.close()
    }
  }

  let label = dataImoveis ? dataImoveis.GetEmpreendimentoImovel.result.map((imovel)=>{
    if (imovel.terreno.numero_contribuinte){
      return imovel.terreno.numero_contribuinte.substring(7, 10) 
    }else{
      return null
    }
  }) : []
  console.log(label)
  // `${ Response.terreno?.numero_contribuinte ? Response.terreno.numero_contribuinte.substring(6, 10) : '' }`
  let totalArea = 0
  let totalTestada = 0
  return (
    <div
      className={`tab-pane fade ${param === 'composicao-terrenos' ? ' show active' : ''}`}
      id='composicao-terrenos'
      role='tabpanel'
      aria-labelledby='composicao-terrenos'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Composição de Terrenos</h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-sm btn-light-primary me-3'
              onClick={(e) => {
                e.preventDefault()
                ExportKMZ(infoEmp.uuid)
              }}
            >
              <BiExport className='fs-3'></BiExport>
              Exportar KMZ
            </button>
            <button
              className='btn btn-sm btn-light-primary me-3'
              onClick={(e) => {
                e.preventDefault()
                ExportXls()
              }}
            >
              <BiExport className='fs-3'></BiExport>
              Exportar XLS
            </button>

            <button
              className='btn btn-sm btn-light-primary'
              onClick={(e) => {
                e.preventDefault()
                OpenModalCreate(null)
              }}
            >
              <BiPlus className='fs-3'></BiPlus>
              Adicionar Terreno
            </button>
            <CreateForm
              idEmpreendimento={idEmpreendimento}
              closeModal={CloseModalCreate}
              show={showCreate}
            />
          </div>
        </div>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table  table-row-dashed  gy-2 gs-6 table-row-bordered  table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted' key={0}>
              <th className='min-w-20px'>x</th>
                <th className='min-w-20px'>Cód Lote</th>
                <th className='min-w-20px'>Situação</th>
                <th className='min-w-150px'>Terreno</th>
                <th className='min-w-100px'>Área IPTU</th>
                <th className='min-w-100px'>Testada</th>
                <th className='min-w-100px '>Zona</th>
                <th className='min-w-150px'>Nº Contribuinte</th>
                <th className='min-w-100px '>Data Cadastro</th>
                <th className='min-w-100px '>Ações</th>
              </tr>
            </thead>

            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {dataImoveis ? (
                dataImoveis.GetEmpreendimentoImovel.result.length > 0 ? (
                  // eslint-disable-next-line array-callback-return
                  dataImoveis.GetEmpreendimentoImovel.result.map((Response, item) => {
                    totalArea += Response.terreno ? Response.terreno.area_iptu : 0
                    totalTestada += Response.terreno ? Response.terreno.testada : 0
                    if (Response.terreno) {
                      if (Response.terreno.marcacao) {
                        let path = null
                        if (Response.terreno.marcacao.type === 'MultiPolygon') {
                          path = CoordinatesToPath(Response.terreno.marcacao['coordinates'][0][0])
                        } else if (Response.terreno.marcacao.type === 'Polygon') {
                          path = CoordinatesToPath(Response.terreno.marcacao['coordinates'][0])
                        }

                        latlng.push({
                          path: path,
                          center: turf.centroid(Response.terreno.marcacao),
                          id: Response.terreno.id,
                          endereco: Response.terreno.endereco,
                          area: Response.terreno.area_iptu,
                          id_cidade: Response.terreno.id_cidade,
                          editable: false,
                        })
                      }

                      return (
                        <tr key={item} id={'row_' + Response.terreno.id} className='row-composicao'>
                            <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <p className='text-dark text-hover-primary fs-6'>
                                  { Response.terreno?.numero_contribuinte ? Response.terreno.numero_contribuinte.substring(6, 10) : '' }
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>{marcadoresMap[item]}</td>
                          <td className='d-flex justify-content-center align-items-center gap-1 h-85px'><span  className='badge  fw-bold me-auto px-1 py-2'style={{backgroundColor:Response.terreno.terreno_situacao_cliente?.cor,color:Response.terreno.terreno_situacao_cliente?.cor}}>.</span> <span className='flex-fill'> {Response.terreno.terreno_situacao_cliente?.nome}</span></td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <p className='text-dark text-hover-primary fs-6'>
                                  {Response.terreno.endereco}, {Response.terreno.numero}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='text-center'>
                            
                                <p className='text-dark text-hover-primary fs-6 text-center'>
                                  {Response.terreno.area_iptu}
                                </p>
                           
                            </div>
                          </td>
                          <td>
                            <div className=' text-center'>
                           
                                <p className='text-dark text-hover-primary fs-6'>
                                  {Response.terreno.testada}
                                </p>
                             
                            </div>
                          </td>

                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <p className='text-dark text-hover-primary fs-6'>
                                  {' '}
                                  {Response.terreno.zoneamento}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <p className='text-dark text-hover-primary fs-6'>
                                  {' '}
                                  {Response.terreno.numero_contribuinte}
                                </p>
                              </div>
                            </div>
                          </td>

                          <td>
                            <p className='text-dark text-hover-primary d-block fs-6'>
                              {DateTimToView(Response.terreno.data_cadastro)}
                            </p>
                          </td>

                          <td>
                            <Buttons
                              id={Response.terreno.id}
                              idEmpreendimentoImovel={Response.id}
                              response={Response}
                              OpenModalMeusImoveis={OpenModalMeusImoveis}
                            />
                          </td>
                        </tr>
                      )
                    }
                  })
                ) : (
                  <tr key={1}>
                    <td colSpan={8} className='text-center'>
                      Nenhum terreo cadastrado
                    </td>
                  </tr>
                )
              ) : (
                ''
              )}
            </tbody>
            <tfoot>
              <tr style={{backgroundColor: '#d0d0d0', fontWeight: '600'}}>
                <td></td>
                <td></td>
                <td></td>
                <td>Área Total</td>
                <td className='text-center'>{totalArea}</td>
                <td className='text-center'>{totalTestada.toFixed(2)}</td>
                
              
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className='card-body p-6 pt-5'>
          <div className='col-md-12'>
            <div className='row '>
              {latlng ? (
                <MapView
                  idEmpreendimento={idEmpreendimento}
                  latLng={latlng}
                  onClickMap={onClickMap}
                  key={1}
                  label={label}
                ></MapView>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
