import {FC, useContext, useEffect, useRef, useState} from 'react'
import {AVMConfig, AvmContext, Origem} from './context/AvmContext'
import {BiChart, BiPin} from 'react-icons/bi'
import {Button, Modal} from 'react-bootstrap'
import {AvmPasso1} from './AvmPasso1'
import {AvmPasso2} from './AvmPasso2'
import {AvmPasso0} from './AvmPasso0'
import uuid from 'react-uuid'
import {useAvm} from './hooks/useAvm'
import {AvmPasso3} from './AvmPasso3'
import {MutationCreateAVMV3} from '../../../../graphql'
import Swal from 'sweetalert2'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {Layout} from '../../../../pages/explorer/components/Map/Layout'
import { GetAcessoUsuarioProduto } from '../../../../utils/PermissoesAcesso'

type Props = {
  origemEntrada?: Origem
  showModal: boolean
}
interface ResultAVM {
  minimo: number
  central: number
  maximo: number
  arquivo: string
}

const api = new APiUrbit()
export const AvmStep: FC<Props> = ({origemEntrada, showModal = false}) => {
  const [show, setShow] = useState(false)
  const [errors, setErrors] = useState({
    tipo: null,
    subtipo: null, // event.target.subtipo.value,
    area: null, //  Number(event.target.areaUtil.value),
    quartos: null, //  Number(event.target.quartos.value),
    vagas: null, //  Number(event.target.vagas.value),
    suites: null, //  Number(event.target.suites.value),
    banheiros: null, //  Number(event.target.banheiros.value),
    condominio: null, //  event.target.condominio.value,
    ano_construcao: null,
    lancamento: null,
  })
  const {
    step,
    setStep,
    endereco,
    setEndereco,
    setFormAvm,
    formAvm,
    setResult,
    result,
    dummies,
    setOrigem,
    origem,
    setAvmConfig,
    avmConfig,
  } = useContext(AvmContext)
  const refFormAvm = useRef(null)
  const {FormAVM, loading} = MutationCreateAVMV3()
  const [resultAVM, setResultAVM] = useState<ResultAVM | null>(null)

  useEffect(() => {
    setShow(showModal)
  }, [showModal])

  useEffect(() => {
    if (origemEntrada.endereco) {
      setEndereco(origemEntrada.endereco)
      setStep(1)
    }
  }, [origemEntrada.endereco, setEndereco, setStep])

  useEffect(() => {
    let config: AVMConfig = {
      avm: null,
      features: false,
      etapaFeaturesPulada: null,
      cidadeComAmostras: true,
      ignoreDummies: false,
    }

    if (origemEntrada) {
      if (origemEntrada.endereco) {
        if (origemEntrada.endereco.latitude) {
          getDadosAvmConfig(origemEntrada)
          setOrigem(origemEntrada)
        }
      } else {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origemEntrada, setAvmConfig, setOrigem])

  useEffect(() => {
    if (result && result.data && result.data.length > 0) {
      setResultAVM(result.data[0])
    }
  }, [result])

  // useEffect(()=>{

  // },[endereco])

  function getDadosAvmConfig(enderecoEntrada) {
    const getMunicipio = async (latitude: number, longitude: number) => {
      const municipio = await api.get(`municipios/${longitude}/${latitude}`)
      return municipio
    }
    getMunicipio(enderecoEntrada.endereco.latitude, enderecoEntrada.endereco.longitude).then(
      (resultado) => {
        if (resultado) {
          const modelos = JSON.parse(resultado.avm_modelo)

          const config = {
            avm: modelos[0],
            features: modelos[0] === 'gam' ? true : false,
            etapaFeaturesPulada: null,
            cidadeComAmostras: true,
            ignoreDummies: false,
          }
          setAvmConfig(config)
        }
      }
    )
  }
  function closeModal() {
    setShow(false)
    setFormAvm({
      ano_construcao: null,
      area: null,
      bairro: null,
      banheiros: null,
      cep: null,
      cidade: null,
      condominio: null,
      quartos: null,
      subtipo: null,
      suites: null,
      tipo: null,
      vagas: null,
      lancamento: null,
      endereco_completo: null,
      estado: null,
      latitude: null,
      logradouro: null,
      longitude: null,
      numero: null,
      imovel_id: null,
      id_tipo_imovel: 0,
    })
    if (Layout.onMapaConfig) {
      Layout.onMapaConfig({showAVM: false, latLng: null})
    }
    // Layout.setShowContextMenu(false)
  }

  function openModal() {
    setShow(true)
  }

  function stepFormAvm() {
    const anoAtual = new Date().getFullYear()
    const formData = new FormData(refFormAvm.current)
    if (formData.get('tipo') === '' || formData.get('tipo') === null) {
      errors.tipo = 'Selecione o tipo'
      setErrors({...errors})
      return false
    } else {
      errors.tipo = null
      setErrors({...errors})
    }

    if (formData.get('subtipo') === '' || formData.get('subtipo') === null) {
      errors.subtipo = 'Selecione o subtipo'
      setErrors({...errors})
      return false
    } else {
      errors.subtipo = null
      setErrors({...errors})
    }

    if (Number(formData.get('lancamento')) === 0) {
      if (formData.get('ano_construcao') === '' || formData.get('ano_construcao') === null) {
        errors.ano_construcao = 'Selecione o ano de construção'
        setErrors({...errors})
        return false
      } else {
        errors.ano_construcao = null
        setErrors({...errors})
      }
    }

    if (formData.get('area') === null || formData.get('area') === '') {
      errors.area = 'Informe a área'
      setErrors({...errors})
      return false
    } else {
      errors.area = null
      setErrors({...errors})
    }

    if (formData.get('quartos') === null || formData.get('quartos') === '') {
      errors.quartos = 'Informe a quantidade de  quartos'
      setErrors({...errors})
      return false
    } else {
      errors.quartos = null
      setErrors({...errors})
    }
    if (formData.get('suites') === null || formData.get('suites') === '') {
      errors.suites = 'Informe a quantidade de  suítes, se não existe informe 0 '
      setErrors({...errors})
      return false
    } else {
      errors.suites = null
      setErrors({...errors})
    }
    if (formData.get('banheiros') === null || formData.get('banheiros') === '') {
      errors.banheiros = 'Informe a quantidade de banheiros'
      setErrors({...errors})
      return false
    } else {
      errors.vagas = null
      setErrors({...errors})
    }
    if (formData.get('vagas') === null || formData.get('vagas') === '') {
      errors.vagas = 'Informe a quantidade de  vagas'
      setErrors({...errors})
      return false
    } else {
      errors.vagas = null
      setErrors({...errors})
    }

    if (formData.get('condominio') === null || formData.get('condominio') === '') {
      errors.condominio = 'Informe o valor aproximado do condomínio'
      setErrors({...errors})
      return false
    } else {
      errors.condominio = null
      setErrors({...errors})
    }
    const param = {
      latitude: endereco.latitude,
      longitude: endereco.longitude,
      origem_lead: origemEntrada.origem,
      endereco: endereco.logradouro,
      tipo: formData.get('tipo'),
      subtipo: formData.get('subtipo'), // event.target.subtipo.value,
      area: Number(formData.get('area')), //  Number(event.target.areaUtil.value),
      quartos: Number(formData.get('quartos')), //  Number(event.target.quartos.value),
      vagas: Number(formData.get('vagas')), //  Number(event.target.vagas.value),
      suites: Number(formData.get('suites')), //  Number(event.target.suites.value),
      banheiros: Number(formData.get('banheiros')), //  Number(event.target.banheiros.value),
      condominio: Number(formData.get('condominio')), //  event.target.condominio.value,
      ano_construcao:
        Number(formData.get('lancamento')) === 1
          ? anoAtual + 3
          : Number(formData.get('ano_construcao')),
      lancamento: Number(formData.get('lancamento')), //  event.target.lancamento.value === '1' ? Number(event.target.lancamento.value) : 0,
      imovel_id: uuid(), // 1,
      id_tipo_imovel: formData.get('tipo') === 'apartamento' ? 2 : 1,
      modelo: avmConfig.avm,
    }
    setFormAvm({...param})
    return true
  }

  function onClickNextStepSelectAddress() {
    if (endereco.latitude) {
      origemEntrada.endereco = endereco

      getDadosAvmConfig(origemEntrada)
      setOrigem(origemEntrada)

      // origemEntrada({...origemEntrada})
    }
  }

  function onClickNextStep() {
    let stepAtual = step

    switch (stepAtual) {
      case 1: {
        if (stepFormAvm()) {
          setStep(stepAtual + 1)
        }

        break
      }
      case 2: {
        setStep(stepAtual + 1)
        break
      }
      default: {
        setStep(stepAtual + 1)
        break
      }
    }
  }
  const permissao = GetAcessoUsuarioProduto(21)
  function salvar() {

    if (!origemEntrada.idMercadoSecundario && !origemEntrada.idInteligenciaMercado){
      Swal.fire('', 'É preciso saval o projeto para salvar o resultado da avaliação.`', 'warning')
    } else{

      const selecionados = dummies.filter((dummy) => dummy.selecionado)
      const nomesSelecionados = selecionados.map((dummy) => dummy.name)
      const nomesConcatenados = nomesSelecionados.join(', ')
      // Caso não haja nenhum item selecionado, define a string como vazia
      const resultadoDummiesSelecionados = nomesConcatenados || ''
      FormAVM({
        id_finalidade: 2,
        id_tipo_imovel: formAvm.id_tipo_imovel,
        id_situacao: 3,
        tipo: formAvm.tipo,
        subtipo: formAvm.subtipo,
        area: formAvm.area,
        suites: String(formAvm.suites),
        quartos: formAvm.quartos,
        banheiros: formAvm.banheiros,
        vagas: formAvm.vagas,
        latitude: formAvm.latitude,
        longitude: formAvm.longitude,
        features: resultadoDummiesSelecionados || '', // Valor padrão para evitar erros
        ano_construcao_cliente: formAvm.ano_construcao,
        bairro: endereco.bairro,
        cidade: endereco.cidade,
        condominio: formAvm.condominio,
        estado: endereco.estado,
        origem_lead: origem.origem,
        modelo: avmConfig.avm,
        // json_output: formAvm.json_output,
        logradouro: formAvm.logradouro,
        result_maximo: resultAVM.maximo,
        result_medio: resultAVM.central,
        result_minimo: resultAVM.minimo,
        aquivo: resultAVM.arquivo,
        id_empreendimento: origemEntrada.idEmpreendimento,
        id_terreno: origemEntrada.idTerreno,
        id_inteligencia_mercado_projeto: origemEntrada.idInteligenciaMercado,
        id_mercado_secundario: origemEntrada.idMercadoSecundario,
      })
        .then((result) => {
          if (!result.data) {
            Swal.fire('Erro', 'Erro ao criar AVM!', 'error')
          } else {
            Swal.fire('Sucesso', 'Formulário enviado com sucesso!', 'success')
          }
        })
        .catch((error) => {
          // Error! Show error message
          Swal.fire('Erro', 'Ocorreu um erro ao enviar o formulário.', 'error')
        })
    }
  }

  function reiniciar() {
    if (origemEntrada.origem === 'avaliacoes') {
      setStep(0)
    } else {
      setStep(1)
    }
    setResult(null)
    setFormAvm({
      ano_construcao: null,
      area: null,
      bairro: null,
      banheiros: null,
      cep: null,
      cidade: null,
      condominio: null,
      quartos: 1,
      subtipo: null,
      suites: null,
      tipo: null,
      vagas: null,
      lancamento: null,
      endereco_completo: null,
      estado: null,
      latitude: null,
      logradouro: null,
      longitude: null,
      numero: null,
      imovel_id: null,
    })
  }

  if (!avmConfig && origemEntrada.origem !== 'avaliacoes') {
    return <div>Carregando...</div>
  }

  return (
    <>
      {!showModal ? (
        <button
          onClick={openModal}
          className='btn btn-light-primary btn-sm d-block me-2 w-100'
          style={{width: '100% !important'}}
        >
          <BiChart className='fs-2 me-1' />
          Nova Avaliação
        </button>
      ) : (
        <></>
      )}

      <Modal centered animation show={show} onHide={() => closeModal()} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>AVM - Avaliação Automática de Imóveis</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-xl' style={{width: '100%'}}>
          {endereco ? (
            endereco.endereco_completo ? (
              <h6 className='pb-5'>
                <BiPin className='fs-2 me-2'></BiPin>
                {endereco.endereco_completo}
              </h6>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {avmConfig ? (
            avmConfig.features ? (
              <>
                {step === 0 ? <AvmPasso0></AvmPasso0> : <></>}
                {step === 1 ? (
                  <AvmPasso1
                    refFormAvm={refFormAvm}
                    errors={errors}
                    setErrors={setErrors}
                  ></AvmPasso1>
                ) : (
                  <></>
                )}
                {step === 2 ? <AvmPasso2></AvmPasso2> : <></>}
                {step === 3 ? <AvmPasso3></AvmPasso3> : <></>}
              </>
            ) : (
              <>
                {step === 0 ? <AvmPasso0></AvmPasso0> : <></>}
                {step === 1 ? (
                  <AvmPasso1
                    refFormAvm={refFormAvm}
                    errors={errors}
                    setErrors={setErrors}
                  ></AvmPasso1>
                ) : (
                  <></>
                )}
                {step === 2 ? <AvmPasso3></AvmPasso3> : <></>}
              </>
            )
          ) : (
            <>{step === 0 ? <AvmPasso0></AvmPasso0> : <></>}</>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex align-items-center justify-content-between flex-lg-grow-1'>
            <div>
              <Button className='btn btn-secondary' onClick={() => closeModal()}>
                Fechar
              </Button>
            </div>
            <div>
              {origemEntrada.origem === 'avaliacoes' && step === 1 ? (
                <Button
                  className='btn btn-secondary me-2'
                  disabled={endereco ? false : true}
                  onClick={() => {
                    setStep(step - 1)
                  }}
                >
                  Voltar
                </Button>
              ) : (
                <></>
              )}
              {step > 1 ? (
                <Button
                  className='btn btn-secondary me-2'
                  disabled={endereco ? false : true}
                  onClick={() => {
                    setStep(step === 0 ? 0 : step - 1)
                  }}
                >
                  Voltar
                </Button>
              ) : (
                <></>
              )}
              {avmConfig ? (
                avmConfig.features && step === 2 ? (
                  <Button
                    className='btn btn-warning me-2'
                    disabled={endereco.logradouro ? false : true}
                    onClick={() => {
                      avmConfig.ignoreDummies = true
                      setAvmConfig({...avmConfig})
                      onClickNextStep()
                    }}
                  >
                    Pular etapa
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {avmConfig && permissao ? (
                (avmConfig.features && step < 3) || (!avmConfig.features && step < 2) ? (
                  <Button
                    className='btn btn-primary '
                    disabled={endereco.logradouro ? false : true}
                    onClick={(e) => {
                      avmConfig.ignoreDummies = false
                      setAvmConfig({...avmConfig})
                      onClickNextStep()
                    }}
                  >
                    Próximo
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {avmConfig ? (
                (avmConfig.features && step === 3) || (!avmConfig.features && step === 2) ? (
                  <>
                    <Button
                      className='btn btn-warning me-2'
                      onClick={(e) => {
                        reiniciar()
                      }}
                    >
                      Reiniciar
                    </Button>
                    {origemEntrada.origem !== 'explorer' ? (
                      <Button
                        className='btn btn-primary'
                        disabled={loading}
                        onClick={(e) => {
                          salvar()
                        }}
                      >
                        Salvar
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {!avmConfig ? (
                step === 0 ? (
                  <Button
                    className='btn btn-primary '
                    disabled={endereco.logradouro ? false : true}
                    onClick={(e) => {
                      onClickNextStepSelectAddress()
                    }}
                  >
                    Próximo
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
