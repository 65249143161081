import {useQuery} from '@apollo/client'
import {GET_AREA_ATUACAO} from '../query'
import {TypesGetAreaAtuacaoResponse} from '../type'

interface QueryAreaAtuacaoProps {
  variables: any
}

export function QueryGetAreaAtuacao({variables}: QueryAreaAtuacaoProps) {
  const {data, loading, error} = useQuery<TypesGetAreaAtuacaoResponse>(GET_AREA_ATUACAO, {
    variables: {...variables},
  })
  return {data, loading, error}
}

// export function QueryGetAreaAtuacaoByID({variables}: QueryAreaAtuacaoProps) {
//   const {data, loading} = useQuery<TypesGetCorretorById>(GET_CORRETOR_BY_ID, {
//     variables: {...variables},
//   })
//   return {data, loading}
// }

// export function MutationDeleteAreaAtuacao() {
//   const [deleteCorretor, {loading, data, error}] = useMutation<TypesDeleteCorretorResponse>(
//     DELETE_CORRETOR,
//     {
//       refetchQueries: [GET_AreaAtuacao, GET_CORRETOR_BY_ID],
//     }
//   )

//   async function HandleDeleteAreaAtuacao({variables}: QueryAreaAtuacaoProps) {
//     try {
//       const result = await deleteCorretor({
//         variables: {...variables},
//         refetchQueries: [GET_AreaAtuacao],
//       })
//       return result
//     } catch (e) {
//       if (e) {
//         return false
//       }
//     }
//   }

//   return {HandleDeleteAreaAtuacao, loading, error, data}
// }

// export function MutationCreateAreaAtuacao() {
//   const {
//     register,
//     handleSubmit,
//     formState: {errors},
//   } = useForm<setCorretorFormData>({
//     resolver: zodResolver(setCorretorFormSchema),
//   })

//   const [setCorretorBody, {error, loading, data: DataSetCorretor}] =
//     useMutation<TypesSetCorretorResponse>(SET_CORRETOR, {
//       refetchQueries: [GET_AreaAtuacao, GET_CORRETOR_BY_ID],
//     })

//   async function FormCorretor(data: setCorretorFormData) {
//     delete data.id
//     await setCorretorBody({
//       variables: {
//         corretor: {...data},
//       },
//     })

//   }

//   return {register, handleSubmit, FormCorretor, loading, errors, error, DataSetCorretor}
// }


// export function MutationPutAreaAtuacao() {
//   const {
//     register,
//     handleSubmit,
//     formState: {errors},
//   } = useForm<putCorretorFormData>({
//     resolver: zodResolver(putCorretorFormSchema),
//   })

//   const [setCorretorBody, {error, loading, data: DataSetCorretor}] =
//     useMutation<TypesPutCorretorResponse>(PUT_CORRETOR, {
//       refetchQueries: [GET_AreaAtuacao, GET_CORRETOR_BY_ID],
//     })

//   async function FormCorretor(data: putCorretorFormData) {
//     const id = data.id
//     delete data.id
//     await setCorretorBody({
//       variables: {
//         putCorretorId: parseInt(id),
//         corretor: {...data},
//       },
//     })

//   }


//   return {register, handleSubmit, FormCorretor, loading, errors, error, DataSetCorretor}
// }
