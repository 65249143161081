import {AddressesExtended} from './AddressesExtended'
import {BasicData} from './BasicaData'
import {dadosTabelas} from './dadosTabelas'
import {EmailsExtended} from './EmailsExtended'
import {PhonesExtended} from './PhonesExtended'
import {RelatedPeople} from './RelatedPeople'
import {RelatedPeopleEmails} from './RelatedPeopleEmails'
import {RelatedPeoplePhones} from './RelatedPeoplePhones'

export const Php3aData = ({resultPeapleData, response, typeDoc}) => {
  // if (typeof data['Result'] !== 'undefined') {
  //   data = data['Result']
  // }
  // let list = []
  // for (var i in estrutura['coluns']) {
  //   for (var j in data[0]['BasicData']) {
  //     if (j === estrutura['coluns'][i]) {
  //       list.push({nome: j, valor: data[0]['BasicData'][j]})
  //     }
  //   }
  // }

  let basicdata = null
  if (response.basic_data) {
    if (typeof response.basic_data['Result'] !== 'undefined') {
      basicdata = {...response.basic_data['Result']}
    } else {
      basicdata = {...response.basic_data}
    }
  }

  function statusPhones(status) {
    switch (status) {
      case 1:
        return 'Ativo'
      case 2:
        return 'Verificado'
      case 3:
        return 'Hot'
    }
  }

  let obito = 'Não'
  if (basicdata) {
    if (typeof basicdata[0]['BasicData']?.HasObitIndication !== 'undefined') {
      if (basicdata[0]['BasicData']['HasObitIndication']) {
        obito = 'Sim'
      } else {
        obito = 'Não'
      }
    } else {
      if (typeof basicdata[0]['BasicData']['ObitIndicationYear'] !== 'undefined') {
        obito = 'Óbito em ' + basicdata[0]['BasicData']['ObitIndicationYear']
      } else {
        // obito = 'Não';
      }
    }
  }
  return (
    <>
      <div>
        <h3 className='fw-semibold mb-5'>Dados Pessoais</h3>
        {resultPeapleData ? (
          resultPeapleData.DocumentType === 0 ? (
            <>
              <p className='fs-6'>Nome: {resultPeapleData.Name} </p>
              <p className='fs-6'>Idade: {resultPeapleData.Age} </p>
              <p className='fs-6'>Documento: {resultPeapleData.DocumentFormatted} </p>
              <p className='fs-6'>Mãe: {resultPeapleData.Person.MotherName} </p>
              {/* <p className='fs-6'>Renda: {resultPeapleData.Income.Presumed} </p> */}
              {basicdata ? <p className='fs-6'>Indicios de Óbito:{obito}</p> : <></>}
            </>
          ) : (
            <>
              <p className='fs-6'>Nome: {resultPeapleData.Name} </p>
              <p className='fs-6'>Idade: {resultPeapleData.Age} </p>
              <p className='fs-6'>Documento: {resultPeapleData.DocumentFormatted} </p>
              {/* <p className="fs-6">Renda: {resultPeapleData.Income.Presumed} </p> */}
            </>
          )
        ) : (
          <>
            <BasicData data={basicdata} estrutura={dadosTabelas[typeDoc]['BasicData']}></BasicData>
          </>
        )}
        {resultPeapleData || response.phones_extended ? (
          <h3 className='fw-semibold mt-15  mb-5'>Telefones</h3>
        ) : (
          <></>
        )}
        {resultPeapleData ? (
          resultPeapleData.Phones && resultPeapleData.Phones.length > 0 ? (
            // eslint-disable-next-line array-callback-return
            resultPeapleData.Phones.map((phones: any, index: number) => {
              if (phones.Status >= 1) {
                return (
                  <div key={index}>
                    <p className='fs-6'>
                      Telefone: {phones.FormattedNumber} - Operador {phones.Operator} <br />
                      Whatsapp: {phones.IsWhatsapp ? 'Sim' : 'Não'}
                      <br />
                      Status: {statusPhones(phones.Status)}
                    </p>
                    {/* <hr /> */}
                  </div>
                )
              }
            })
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {response.phones_extended ? (
          <PhonesExtended
            data={response.phones_extended}
            estrutura={dadosTabelas[typeDoc]['PhonesExtended']}
          ></PhonesExtended>
        ) : (
          ''
        )}
        {resultPeapleData && (resultPeapleData.Emails || response.emails_extended) ? (
          <h3 className='fw-semibold mt-15  mb-5'>E-mails</h3>
        ) : (
          <></>
        )}
        {resultPeapleData ? (
          resultPeapleData.Emails && resultPeapleData.Emails.length > 0 ? (
            resultPeapleData.Emails.map((phones: any, index: number) => {
              return (
                <div key={index}>
                  <p className='fs-6'>Email: {phones.Email} </p>
                </div>
              )
            })
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {response.emails_extended ? (
          <EmailsExtended
            data={response.emails_extended}
            estrutura={dadosTabelas[typeDoc]['EmailsExtended']}
          ></EmailsExtended>
        ) : (
          ''
        )}

        {typeDoc === 'cpf' ? (
          <>
            {response.related_people ? <h3 className='pt-10'>Pesosas Relacionadas</h3> : <></>}
            {response.related_people ? (
              <RelatedPeople
                data={response.related_people}
                estrutura={dadosTabelas[typeDoc]['RelatedPeople']}
              ></RelatedPeople>
            ) : (
              ''
            )}
            {response.related_people_emails ? (
              <h3 className='pt-10'>Pesosas Relacionadas - Emails</h3>
            ) : (
              <></>
            )}
            {response.related_people_emails ? (
              <RelatedPeopleEmails
                data={response.related_people_emails}
                estrutura={dadosTabelas[typeDoc]['RelatedPeopleEmails']}
              ></RelatedPeopleEmails>
            ) : (
              ''
            )}

            {response.related_people_phones ? (
              <h3 className='pt-10'>Pesosas Relacionadas Telefones</h3>
            ) : (
              <></>
            )}
            {response.related_people_phones ? (
              <RelatedPeoplePhones
                data={response.related_people_phones}
                estrutura={dadosTabelas[typeDoc]['RelatedPeoplePhones']}
              ></RelatedPeoplePhones>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}

        <h3 className='fw-semibold mt-15  mb-5'>Endereço</h3>
        {resultPeapleData ? (
          resultPeapleData.Addresses && resultPeapleData.Addresses.length > 0 ? (
            resultPeapleData.Addresses.map((address: any, index: number) => {
              return (
                <div key={index}>
                  <p className='fs-6'>Endereço: {address.Alias}</p>
                  <p className='fs-6'>Complemento: {address.ComplementFormatted}</p>
                  <p className='fs-6'>Bairro: {address.District}</p>
                  <p className='fs-6'>Cidade: {address.City}</p>
                  <p className='fs-6'>Estado: {address.State}</p>
                  <p className='fs-6'></p>
                  <hr />
                </div>
              )
            })
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {response.addresses_extended ? (
          <AddressesExtended
            data={response.addresses_extended}
            estrutura={dadosTabelas[typeDoc]['AddressesExtended']}
          ></AddressesExtended>
        ) : (
          ''
        )}
        {/* <h3 className="fw-semibold mt-15  mb-5">Pessoas relacionadas</h3> */}
      </div>
    </>
  )
}
