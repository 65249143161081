import {useContext, useEffect, useState} from 'react'
import {FilterContext} from '../context/FilterContext'
import {DataContext} from '../context/DataContext'
import Swal from 'sweetalert2'
import {MutationPutInteligenciaMercado} from '../../../graphql/services/inteligencia-mercado'
import {BiFolderOpen} from 'react-icons/bi'

export default function SaveAutomatic({idParam, nome}: {idParam: number; nome: string}) {
  const [loading, setLoading] = useState(false)
  const {endereco, filter, geometria} = useContext(FilterContext)
  const {getNaoComputados} = useContext(DataContext)
  const {setValue, FormInteligenciaMercado} = MutationPutInteligenciaMercado()

  const nComputados = getNaoComputados()

  // useEffect(() => {
  //   setInterval(() => {
  //     console.log('save')
  //     setLoading(true)
  //      saveProjectAuto(idParam)

  //   }, 60000)
  // }, [])

  const dataSave = {
    endereco: endereco,
    filter: filter,
    geometria: geometria,
    imoveisNaoComputados: nComputados,
  }

  function save(idParam: number) {
    Swal.fire({
      title: 'Deseja salvar as alterações?',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
    }).then((result) => {
      if (result.isConfirmed) {
        const id = idParam
        // const nome =  result.value
        let json = dataSave

        setValue('id', id)
        setValue('nome', nome)
        const data = {
          id: id,
          json: json,
        }
        FormInteligenciaMercado(data)
          .then((retorno) => {
            // FormSetLogAcessoRecurso({
            //   ferramenta: 'Inteligencia Mercado',
            //   funcionalidade: 'Atualizar Projeto',
            //   action: 'update',
            //   url: `inteligencia-mercado/${id}`,
            // })
            Swal.fire('Sucesso!', 'Projeto atualizado com sucesso.', 'success')
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    })
  }

  function saveProjectAuto(idParam: number) {

    let dataSave = {
        endereco: endereco,
        filter: filter,
        geometria: geometria,
        imoveisNaoComputados: nComputados,
      }

    const id = idParam
    // const nome =  result.value
    let json = dataSave
      
    setValue('id', id)
    setValue('nome', nome)

    // FormInteligenciaMercado({id, json})
    //   .then((retorno) => {
    //     setLoading(false)
    //   })
    //   .catch((error) => {
    //     Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    //   })
  }

  return (
    <>
      {/* {loading ? (
        'Salvando projeto...'
      ) : ( */}
        <button
          onClick={(e) => {
            save(idParam)
          }}
          className='btn  btn-primary ms-2'
          style={{width: '100% !important'}}
        >
          <BiFolderOpen /> Salvar Estudo
        </button>
      {/* )} */}
    </>
  )
}
