import { gql } from "@apollo/client";

export const SET_SUB_AREA_ATUACAO = gql`
mutation SetUsuarioSubAreaAtuacao($data: [usuarioSubAreaAtuacao!]!) {
  SetUsuarioSubAreaAtuacao(data: $data) {
    id
    id_area_atuacao
    id_user
    situacao
  }
}
`

export const PUT_SUB_AREA_ATUACAO = gql`
mutation PutSubAreaAtuacao($subareaatuacao: subareaatuacao!, $putSubAreaAtuacaoId: Float!) {
  PutSubAreaAtuacao(subareaatuacao: $subareaatuacao, id: $putSubAreaAtuacaoId) {
    id
    nome
  }
}
`

export const DELETE_SUB_AREA_ATUACAO = gql`
mutation DeleteSubAreaAtuacao($deleteSubAreaAtuacaoId: Float!) {
  DeleteSubAreaAtuacao(id: $deleteSubAreaAtuacaoId) {
    nome
  }
}
`