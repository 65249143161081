import Swal from 'sweetalert2'
import {BiFolderOpen} from 'react-icons/bi'
import {MutationCreateMercadoSecundario, MutationPutMercadoSecundario} from '../../graphql'
import {useImoveisStore} from './context/DataContext'
import {MutationSetLogAcessoRecurso} from '../../graphql/services/LogAcesso'
import {useHistory, useParams} from 'react-router-dom'

export default function SalvarProjeto({idParam}: {idParam: number}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {tipo, id: idProjeto} = useParams<{tipo: string; id: string}>()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const {endereco, filtro, getComputados, geometria} = useImoveisStore()
  const navigate = useHistory()
  const {setValue: setValueSet, FormMercadoSecundarioSet} = MutationCreateMercadoSecundario()
  const {setValue: setValuePut, FormMercadoSecundario} = MutationPutMercadoSecundario()

  const dataSave = {
    endereco: endereco,
    filter: filtro,
    geometria: geometria,
    computados: getComputados(),
  }

  function salvarProjeto() {
    Swal.fire({
      title: 'Nome do projeto',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let json = dataSave
        let nome = result.value

        setValueSet('nome', nome)
        let tipo = 'VENDA'
        FormMercadoSecundarioSet({nome, json, tipo})
          .then((retorno) => {
            const idProjeto = retorno.data.SetMercadoSecundario.id
            FormSetLogAcessoRecurso({
              ferramenta: 'Mercado Secundário - Venda',
              funcionalidade: 'Criar Projeto',
              action: 'create',
              url: `mercado-secundario/venda`,
            })
            Swal.fire({
              title: 'Sucesso!',
              text: 'Projeto atualizado com sucesso.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                navigate.push(`/mercado-secundario/venda/${idProjeto}`)
              }
            })
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    })
  }

  // useEffect(() => {
  //   setInterval(() => {
  //     console.log('save')
  //     setLoading(true)
  //      saveProjectAuto(idParam)

  //   }, 60000)
  // }, [])

  function atualizarProjeto(idParam: number) {
    Swal.fire({
      title: 'Deseja salvar as alterações?',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
    }).then((result) => {
      if (result.isConfirmed) {
        const id = idParam
        let json = dataSave

        setValuePut('id', id)
        // setValuePut('nome', nome)

        FormMercadoSecundario({id, json})
          .then((retorno) => {
            // FormSetLogAcessoRecurso({
            //   ferramenta: 'Inteligencia Mercado',
            //   funcionalidade: 'Atualizar Projeto',
            //   action: 'update',
            //   url: `inteligencia-mercado/${id}`,
            // })
            Swal.fire('Sucesso!', 'Projeto atualizado com sucesso.', 'success')
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    })
  }

  //   function saveProjectAuto(idParam: number) {

  //     let dataSave = {
  //         endereco: endereco,
  //         filter: filtro,
  //         geometria: geometria,
  //         computador: computados,
  //       }

  //     const id = idParam
  //     // const nome =  result.value
  //     let json = dataSave

  //     setValueSet('id', id)
  //     setValueSet('nome', nome)

  //     // FormInteligenciaMercado({id, json})
  //     //   .then((retorno) => {
  //     //     setLoading(false)
  //     //   })
  //     //   .catch((error) => {
  //     //     Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
  //     //   })
  //   }

  return (
    <>
      {/* {loading ? (
        'Salvando projeto...'
      ) : ( */}
      <button
        onClick={(e) => {
          if (idProjeto) {
            atualizarProjeto(Number(idProjeto))
          } else {
            salvarProjeto()
          }
        }}
        className='btn  btn-primary ms-2'
        style={{width: '100% !important'}}
      >
        <BiFolderOpen /> Salvar Estudo
      </button>
      {/* )} */}
    </>
  )
}
