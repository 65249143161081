import FaixaMetragem from './Performance/FaixaMetragem'
import FaixaTicket from './Performance/FaixaTicket'
import NumeroOfertas from './Performance/NumeroOfertas'
import PrecoMedioM2 from './Performance/PrecoMedioM2'
import TicketMedio from './Performance/TicketMedio'
import {useImoveisStore} from '../context/DataContext'
import {Exportar} from '../partials/BtnExportar'

export function Estatisticas() {
  const {imoveis} = useImoveisStore()
  const filtered = imoveis.filter((item) => item.active)

  return (
    <div className=' d-flex flex-column justify-content-start p-10' style={{height: '92.1vh'}}>
      <div className='header-report d-flex all-start-between '>
        <div>
          <h1 className='fw-bolder'>Análise Gráfica</h1>
          <span>Imóveis anunciados nos ultimos 60 dias.</span>
        </div>
        <div>
          <Exportar data={imoveis}></Exportar>
        </div>
      </div>

      <div className='content pt-10'>
        <div className=' g-5 g-xl-8'>
          <div className='card mb-6'>
            <div className='card-body'>
              <div className='row'>
                <div className='row'>
                  <div className='col-6'>
                    <FaixaMetragem itens={filtered}></FaixaMetragem>
                  </div>
                  <div className='col-6'>
                    <FaixaTicket itens={filtered}></FaixaTicket>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <NumeroOfertas itens={filtered}></NumeroOfertas>
                  </div>
                  <div className='col-6'>
                    <PrecoMedioM2 itens={filtered}></PrecoMedioM2>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <TicketMedio itens={filtered}></TicketMedio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
