import {Text, View, StyleSheet} from '@react-pdf/renderer'
import {DateTimToView} from '../util'
// const borderColor = '#838383'
const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    // marginTop: 24,
    // paddingVertical: 10,
    border: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: 11,
    // backgroundColor: '#f5f8fa',
  },
  hr: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#c7c7c7',
  },
  container: {
    margin: 'auto',
    width: '100%',
  },
  card: {
    // marginTop: 5,
    marginBottom: 10,
    // paddingVertical: 10,

    border: 0,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  col6: {
    width: '50%',
    // paddingHorizontal: 10,
    paddingTop: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    // paddingHorizontal:15,
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: '#dbdbdb',
    paddingTop: 8,
    paddingBottom: 5,
    paddingHorizontal: 10,
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth: 2,
    borderBottomColor: '#2B8E57',
  },
  titleCard: {
    fontSize: 12,
    fontWeight: 'heavy',
  },
  desc: {
    fontSize: 12,
    paddingVertical: 15,
    // paddingHorizontal:15,
    textAlign: 'justify',
  },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'center',
  }
})

export const Observacoes = ({observacoes}) => {

  
  const rows = observacoes.GetEmpreendimentosObservacao.result.map((item) => (
    <View style={styles.card} key={item.id}>
      <View style={styles.header}>
        <Text style={styles.titleCard}>Asunto:{item.titulo}</Text>
        <Text style={styles.titleCard}>Data: {DateTimToView(item.data_cadastro)}</Text>
      </View>
      <View style={styles.hr} />
      <Text style={styles.desc}>Descrição: {item.descricao}</Text>
    </View>
  ))

  return (
    <View >
      <Text style={styles.title}>Observações</Text>
      <View style={styles.content}>

      {observacoes.GetEmpreendimentosObservacao.result.length === 0 && 
          <Text style={styles.rowErro}>Nenhum registro encontrado</Text>
        }

        <View style={styles.container}>{rows}</View>
      </View>
    </View>
  )
}
