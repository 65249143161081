import {useContext, useEffect, useState} from 'react'
import {MapViewRevenda} from '../MapViewRevenda'
import {FilterContext} from '../../context/FilterContext'
import {AllGeoJSON} from '@turf/turf'
import {PivotTable} from './PerformanceRevenda/PivotTable'
import {TResultAPI} from '../Types'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {getLayer} from '../MapLayers/Layer'
import {Legenda} from '../Legenda'
import {DataContext} from '../../context/DataContext'
import {quantile} from 'simple-statistics'
import {BiExport, BiLinkExternal, BiPlus, BiTrash} from 'react-icons/bi'
import {ExportTable} from '../../../../utils/ExportXLSX'
import FaixaMetragem from './PerformanceRevenda/FaixaMetragem'
import FaixaTicket from './PerformanceRevenda/FaixaTicket'
import PrecoMedioM2 from './PerformanceRevenda/PrecoMedioM2'
import NumeroOfertas from './PerformanceRevenda/NumeroOfertas'
import TicketMedio from './PerformanceRevenda/TicketMedio'
import SelectDormitoriosFiltro from '../UI/SelectDormitoriosFiltro'
import {RangeSlider} from '../../../../components/UI'
import {formatNumber} from '../../../../components/util'
import SelectVagasFiltro from '../UI/SelectVagasFiltro'
import DataTable from 'react-data-table-component'
import {customStylesTable} from '../Common/common'

type Props = {
  label?: string
}
type PropsFiltro = {
  dormitorios: number[]
  vagas: number[]
  faixaMetragem: [number, number]
  faixaTicket: [number, number]
  precoMedioM2: [number, number]
  distancia: [number, number]
}
const api = new APiUrbit()
export function PerformanceMercadoRevenda({label}: Props) {
  // const {data} = useContext(DataContext)
  const {geometria, endereco} = useContext(FilterContext)
  const {cacheResult, setCacheResult} = useContext(DataContext)
  const [dataImoveis, setDataImoveis] = useState([])
  const [filtro, setFiltro] = useState<PropsFiltro>({
    dormitorios: [],
    vagas: [],
    faixaMetragem: null,
    faixaTicket: null,
    precoMedioM2: null,
    distancia: null,
  })

  useEffect(() => {
    if (geometria) {
      loadRevenda(geometria)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometria])

  if (!endereco) {
    return <>Carregando...</>
  }

  if (!geometria) {
    return <>Carregando...</>
  }
  if (!dataImoveis) {
    return <>Carregando...</>
  }

  async function loadRevenda(geom) {
    // const centroid = turf.centroid(geom)
    const lat = endereco.latitude //centroid.geometry.coordinates[1]
    const lng = endereco.longitude //centroid.geometry.coordinates[0]

    if (geom.type === 'Feature') {
      geom = geom.geometry
    }

    // const intersectString = btoa(unescape(encodeURIComponent(JSON.stringify(geom))))

    let url = `service/apartamentos-ofertas/${lng}/${lat}?distance=800`
    // intersect=${intersectString}`
    await api.get(url, (result: TResultAPI) => {
      if (result.itens.length) {
        let arrayValores = []
        // let precomedioM2 = 0
        result.itens.map((item, index) => {
          item.geom = JSON.parse(item.geom)
          arrayValores.push(item.valor / item.area)
          item.active = true
          return item
        })
        let quant = quantile(arrayValores, [0.25, 0.5, 0.75])

        cacheResult.performanceMercadoRevenda.precoMedianoM2 = quant[1]
        cacheResult.performanceMercadoRevenda.precoQ1m2 = quant[0]
        cacheResult.performanceMercadoRevenda.precoQ3m2 = quant[2]
        cacheResult.performanceMercadoRevenda.totalAmostras = result.total

        setCacheResult(cacheResult)

        setDataImoveis(result.itens)
      }
    })
  }

  function exportar() {
    // eslint-disable-next-line array-callback-return
    let exportaFinal = dataImoveis.filter((item) => {
      delete item.geom
      if (item.active) {
        return item
      }
    })
    ExportTable(exportaFinal, '', 'Resumo Mercado.xlsx')
  }

  function onchangeFilter(tipo: string, valor: string | number | []) {
    // if (filtro) {

    let fiterTemp = filtro
    fiterTemp[tipo] = valor
    setFiltro(fiterTemp)
    // }
  }

  function applyFilter() {
    
    let newData = dataImoveis.map((item: any) => {
      let filtroDormitorios = true

      if (filtro.dormitorios.length > 0) {
        if (filtro.dormitorios.indexOf(Number(item.quartos)) !== -1) {
          filtroDormitorios = true
        } else {
          filtroDormitorios = false
        }
      }
      let filtroVagas = true
      if (filtro.vagas.length > 0) {
        if (filtro.vagas.indexOf(Number(item.vaga)) !== -1) {
          filtroVagas = true
        } else {
          filtroVagas = false
        }
      }

      let filtroValor = true
      if (filtro.faixaTicket) {
        if (item.valor >= filtro.faixaTicket[0] && item.valor <= filtro.faixaTicket[1]) {
          filtroValor = true
        } else {
          filtroValor = false
        }
      }
      let filtroPrecoM2 = true
      if (filtro.precoMedioM2) {
        if (item.preco_m2 >= filtro.precoMedioM2[0] && item.preco_m2 <= filtro.precoMedioM2[1]) {
          filtroPrecoM2 = true
        } else {
          filtroPrecoM2 = false
        }
      }

      let filtroArea = true
      if (filtro.faixaMetragem) {
        if (item.area >= filtro.faixaMetragem[0] && item.area <= filtro.faixaMetragem[1]) {
          filtroArea = true
        } else {
          filtroArea = false
        }
      }

      let filtroDistancia = true
      if (filtro.distancia) {
        if (item.distancia >= filtro.distancia[0] && item.distancia <= filtro.distancia[1]) {
          filtroDistancia = true
        } else {
          filtroDistancia = false
        }
      }

      // console.log('filtroDormitorios', '-', filtroDormitorios)
      // console.log('filtroVagas', '-', filtroVagas)
      // console.log('filtroValor', '-', filtroValor)
      // console.log('filtroPrecoM2', '-', filtroPrecoM2)
      // console.log('filtroArea', '-', filtroArea)
      // console.log('filtroDistancia', '-', filtroDistancia)
      if (
        filtroDormitorios &&
        filtroVagas &&
        filtroValor &&
        filtroPrecoM2 &&
        filtroArea &&
        filtroDistancia
      ) {
        item.active = true
      } else {
        item.active = false
      }
      return item
    })
    // console.log(newData)
    setDataImoveis([...newData])
  }
  // console.log(dataImoveis)

  let json = geometria as AllGeoJSON
  const layersList = [] // getLayers(['view_explorer_apartamento_venda_'], json)
  const layerConfig = getLayer('view_explorer_apartamento_venda_')

  let center = {
    lat: endereco.latitude,
    lng: endereco.longitude,
  }

  function remove(id) {
    let newData = dataImoveis.map((item: any) => {
      if (item.id === id) {
        item.active = false
      }
      return item
    })

    setDataImoveis([...newData])
  }

  
  function add(id) {
    let newData = dataImoveis.map((item: any) => {
      if (item.id === id) {
        item.active = true
      }
      return item
    })

    setDataImoveis([...newData])
  }

  let columnsData = [
    {
      name: '',
      selector: (row) => {
        return (
          <button onClick={() => remove(row.id)} className='btn btn-icon  btn-sm  ' style={{color:"#ff0000"}}>
            <BiTrash className='fs-1' />
          </button>
        )
      },
      sortable: true,
      wrap: true,
      width: '80px',
    },
    {
      name: '',
      selector: (row) => {
        return (
          <a href={row.link} target='_blank' rel='noreferrer' className="fs-2">
            <BiLinkExternal />
          </a>
        )
      },
      sortable: true,
      wrap: true,
      width: '55px',
    },
    {
      name: 'Endereco',
      selector: (row) => (row.endereco ? row.endereco : 'Não informado'),
      sortable: true,
      wrap: true,
      width: '200px',
    },
    {
      name: 'Área',
      selector: (row) => row.area,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Quartos/Suítes',
      selector: (row) => row.quartos + '/' + (row.suites ? row.suites : 0) + '',
      sortable: true,
      width: '170px',
      center:true
    },
    {
      name: 'Banheiros',
      selector: (row) => row.banheiros,
      sortable: true,
      center:true
    },
    {
      name: 'Vaga',
      selector: (row) => row.vaga,
      sortable: true,
      width:'120px',
      center:true
    },
    {
      name: 'Valor',
      selector: (row) => formatNumber(row.valor, 'currency_inteiro'),
      sortable: true,
      width:'120px',
      right:true
    },
    {
      name: 'Valor m²',
      selector: (row) => formatNumber(row.preco_m2, 'currency_inteiro'),
      sortable: true,
      right:true
    },
    {
      name: 'Distância',
      selector: (row) => row.distancia,
      sortable: true,
      center:true
    },
  ]

  let columnsDataRemoved = [
    {
      name: 'Adicionar',
      selector: (row) => {
        return (
          <button onClick={() => add(row.id)} className='btn btn-icon  btn-sm  ' style={{color:"#1900ff"}}>
            <BiPlus className='fs-1' />
          </button>
        )
      },
      sortable: true,
      wrap: true,
      width: '120px',
    },
    {
      name: '',
      selector: (row) => {
        return (
          <a href={row.link} target='_blank' rel='noreferrer' className="fs-2">
            <BiLinkExternal />
          </a>
        )
      },
      sortable: true,
      wrap: true,
      width: '55px',
    },
    {
      name: 'Endereco',
      selector: (row) => (row.endereco ? row.endereco : 'Não informado'),
      sortable: true,
      wrap: true,
      width: '200px',
    },
    {
      name: 'Área',
      selector: (row) => row.area,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Quartos/Suítes',
      selector: (row) => row.quartos,
      format: (row) => row.quartos + '/' + (row.suites ? row.suites : 0) + '',
      sortable: true,
      width: '170px',
      center:true
    },
    {
      name: 'Banheiros',
      selector: (row) => row.banheiros,
      sortable: true,
      center:true
    },
    {
      name: 'Vaga',
      selector: (row) => row.vaga,
      sortable: true,
      width:'120px',
      center:true
    },
    {
      name: 'Valor',
      selector: (row) => row.valor,
      format: (row) => formatNumber(row.valor, 'currency_inteiro'),
      sortable: true,
      width:'120px',
      right:true
    },
    {
      name: 'Valor m²',
      selector: (row) =>row.preco_m2,
      format: (row) => formatNumber(row.preco_m2, 'currency_inteiro'),
      sortable: true,
      right:true
    },
    {
      name: 'Distância',
      selector: (row) => row.distancia,
      sortable: true,
      center:true
    },
  ]
  const conditionalRowStyles = [
    {
      when: (row) => !row.active,
      style: (row) => ({
        backgroundColor: '#fcebee',
      }),
    },
  ]

  let filtered = dataImoveis.filter((item) => item.active)
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10' style={{height: '92.1vh'}}>
        <div className='header-report d-flex all-start-between '>
          <div>
            <h1 className='fw-bolder'>Mercado Revenda</h1>
            <span>Imóveis anunciados nos ultimos 60 dias.</span>
          </div>
          <div>
            <button className='btn btn-primary pull-right' onClick={exportar}>
              <BiExport></BiExport> Exportar
            </button>
          </div>
        </div>
        <div className='content pt-5'>
          <div className=' g-5 g-xl-8'>
            <div className='card mb-6'>
              <form className='form-horizontal'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='  col-md-6 col-lg-6  col-xl-4 mb-2  pe-10'>
                      <label className='form-label form-label'>Área Privativa</label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('faixaMetragem', e)
                        }}
                        defaultValue={filtro.faixaMetragem ? filtro.faixaMetragem : [0, 1000]}
                        min={0}
                        max={1000}
                        step={10}
                        formatTip={(value) => `${formatNumber(value, 'inteiro')} m²`}
                        showHideImput={true}
                      />
                      {/* <input type='text' className='form-control' placeholder='name@example.com' /> */}
                    </div>

                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10'>
                      <label className='form-label form-label'>Ticket </label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('faixaTicket', e)
                        }}
                        defaultValue={filtro.faixaTicket ? filtro.faixaTicket : [100000, 30000000]}
                        min={100000}
                        max={30000000}
                        step={100000}
                        formatTip={(value) => {
                          if (value > 1000000) {
                            return `${value / 1000000}Mi `
                          } else {
                            return `${formatNumber(value, 'currency')} `
                          }
                        }}
                        showHideImput={true}
                      />
                    </div>
                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10'>
                      <label className='form-label form-label'>Preço m2 </label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('precoMedioM2', e)
                        }}
                        defaultValue={filtro.precoMedioM2 ? filtro.precoMedioM2 : [0, 50000]}
                        min={0}
                        max={50000}
                        step={100}
                        formatTip={(value) => `${formatNumber(value, 'currency')} `}
                        showHideImput={true}
                      />
                    </div>

                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10 pt-10'>
                      <label className='form-label form-label'>Dormitórios</label>
                      <SelectDormitoriosFiltro
                        onChange={(e) => {
                          onchangeFilter('dormitorios', e)
                        }}
                        selected={null}
                      ></SelectDormitoriosFiltro>
                    </div>
                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10 pt-10'>
                      <label className='form-label form-label'>Vagas</label>
                      <SelectVagasFiltro
                        onChange={(e) => {
                          onchangeFilter('vagas', e)
                        }}
                        selected={null}
                      ></SelectVagasFiltro>
                    </div>

                    <div className='  col-md-6 col-lg-6  col-xl-4 mb-2  pe-10 pt-10'>
                      <label className='form-label form-label'>Distância</label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('distancia', e)
                        }}
                        defaultValue={filtro.distancia ? filtro.distancia : [0, 800]}
                        min={0}
                        max={800}
                        step={1}
                        formatTip={(value) => `${formatNumber(value, 'inteiro')} m`}
                        showHideImput={true}
                      />
                      {/* <input type='text' className='form-control' placeholder='name@example.com' /> */}
                    </div>
                  </div>
                </div>
                <div className='card-footer'>
                  <button type='button' className='btn btn-primary' onClick={applyFilter}>
                    Aplicar Filtro
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className=' g-5 g-xl-8'>
            <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance p-0'>
              <div className='p-10'>
                <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                      Itens Computados
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link ' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                      Não Computados
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link ' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                      Mapa
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link ' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                      Estatisticas gerais
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                      Gráficos
                    </a>
                  </li>
                </ul>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade  show active' id='kt_tab_pane_2' role='tabpanel'>
                    <div className='row'>
                      <div className='col-12'>
                        <DataTable
                          title='Mercado Secundário'
                          noHeader={true}
                          customStyles={customStylesTable}
                          columns={columnsData}
                          subHeader={false}
                          data={filtered}
                          pagination={true}
                          conditionalRowStyles={conditionalRowStyles}
                          highlightOnHover
                          pointerOnHover
                          striped
                          // responsive
                          dense
                          paginationPerPage={20}
                          onColumnOrderChange={(cols) => console.log(cols)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane fade ' id='kt_tab_pane_3' role='tabpanel'>
                    <div className='row'>
                      <div className='col-12'>
                        <DataTable
                          title='Mercado Secundário'
                          noHeader={true}
                          customStyles={customStylesTable}
                          columns={columnsDataRemoved}
                          subHeader={false}
                          data={dataImoveis.filter((item) => !item.active)}
                          pagination={true}
                          conditionalRowStyles={conditionalRowStyles}
                          highlightOnHover
                          pointerOnHover
                          striped
                          // responsive
                          dense
                          paginationPerPage={20}
                          onColumnOrderChange={(cols) => console.log(cols)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane fade ' id='kt_tab_pane_1' role='tabpanel'>
                    <div className='row'>
                      <div className='col-12'>
                        <MapViewRevenda
                          center={center}
                          layer={[layersList]}
                          json={json}
                          zoom={15}
                          dataImoveis={filtered}
                          style={layerConfig.style}
                        >
                          <Legenda
                            style={layerConfig.style}
                            title={'Renda'}
                            visivel={true}
                          ></Legenda>
                        </MapViewRevenda>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_pane_4' role='tabpanel'>
                    <PivotTable itens={filtered}></PivotTable>
                  </div>
                  <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
                    <div className='row'>
                      <div className='col-6'>
                        <FaixaMetragem itens={filtered}></FaixaMetragem>
                      </div>
                      <div className='col-6'>
                        <FaixaTicket itens={filtered}></FaixaTicket>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <NumeroOfertas itens={filtered}></NumeroOfertas>
                      </div>
                      <div className='col-6'>
                        <PrecoMedioM2 itens={filtered}></PrecoMedioM2>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-6'>
                        <TicketMedio itens={filtered}></TicketMedio>
                      </div>
                    </div>
                  </div>
                  {/* <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
    Sint sit mollit irure quis est nostrud cillum consequat Lorem
    esse do quis dolor esse fugiat sunt do. Eu ex commodo veniam
    Lorem aliquip laborum occaecat qui Lorem esse mollit dolore anim
    cupidatat. eserunt officia id Lorem nostrud aute id commodo elit
    eiusmod enim irure amet eiusmod qui reprehenderit nostrud
    tempor. Fugiat ipsum excepteur in aliqua non et quis aliquip ad
    irure in labore cillum elit enim. Consequat aliquip incididunt
    ipsum et minim laborum laborum laborum et cillum labore.
    Deserunt adipisicing cillum id nulla minim nostrud labore
    eiusmod et amet.
  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
