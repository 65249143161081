import {useEffect, useState} from 'react'

export default function SelectDormitoriosFiltro({
  onChange,
  selected,
}: {
  onChange: any
  selected?: number[]
}) {
  const [checked, setChecked] = useState(selected || [])

  useEffect(() => {
    if(selected){
      if (selected.length > 0){
        setChecked(selected)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

   useEffect(() => {
    onChange(checked)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  function changeCheck(e) {
    const value = Number(e.target.value)
    const checkSelected = e.target.checked
    if (checkSelected) {
      console.log('add checked')
      console.log(value)
      setChecked([...checked, value])

    } else {
      console.log('remove checked')
      console.log(checked)
      let filter = checked.findIndex((item) => item === value)
      checked.splice(filter, 1)
      setChecked([...checked])

    }
  }

  return (
    <div className='mydict'>
      <div>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='1'
            checked={ checked.find((el) => el === 1) >= 1 ?  true :false }
            onChange={(e) => changeCheck(e)}
          />
          <span>1 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='2'
            checked={ checked.find((el) => el === 2) >= 1 ?  true :false }
            onChange={(e) => changeCheck(e)}
          />
          <span>2 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='3'
            checked={ checked.find((el) => el === 3) >= 1 ?  true :false }
            onChange={(e) => changeCheck(e)}
          />
          <span>3 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='4'
            checked={ checked.find((el) => el === 4) >= 1 ?  true :false }
            onChange={(e) => changeCheck(e)}
          />
          <span>4 </span>
        </label>
        <label>
          <input
            type='checkbox'
            name='radio'
            value='5'
            checked={ checked.find((el) => el === 5) >= 1 ?  true :false }
            onChange={(e) => changeCheck(e)}
          />
          <span>5 + </span>
        </label>
      </div>
    </div>
  )
}
