import { gql } from "@apollo/client";

export const SET_USER_AREA_ATUACAO = gql`
 mutation SetUsuarioAreaAtuacao($data: [usuarioAreaAtuacao!]!) {
  SetUsuarioAreaAtuacao(data: $data) {
    id
    id_area_atuacao
    id_user
    situacao
  }
}
`

export const PUT_USER_AREA_ATUACAO = gql`
mutation PutUserAreaAtuacao($userareaatuacao: userareaatuacao!, $putUserAreaAtuacaoId: Float!) {
  PutUserAreaAtuacao(userareaatuacao: $userareaatuacao, id: $putUserAreaAtuacaoId) {
    id
    nome
  }
}
`

export const DELETE_USER_AREA_ATUACAO = gql`
mutation DeleteUserAreaAtuacao($deleteUserAreaAtuacaoId: Float!) {
  DeleteUserAreaAtuacao(id: $deleteUserAreaAtuacaoId) {
    nome
  }
}
`