import {useQuery} from '@apollo/client'
import React from 'react'
import Select from 'react-select'
import {GET_USUARIO_GRUPO_SERVICO_BY_USER} from '../../../graphql/query/usuario-grupo-servico'
import {GetUsuarioGrupoServicoByUserIdResponse} from '../../../graphql/type/usuarioGrupoServico'

type Props = {
  idGrupo: number
  onChange?:any
  idCidade:number
}

export const GetUsuarioGrupoServicoByUserChosen: React.FC<Props> = ({idGrupo, onChange,idCidade=5351}) => {
  let options = []
  const {data} = useQuery<GetUsuarioGrupoServicoByUserIdResponse>(GET_USUARIO_GRUPO_SERVICO_BY_USER, {
    variables: {
      "idMunicipio":idCidade,
      "pagination": {
        "pagina": 0,
        "quantidade": 30
      }
    },
  })

  if (!data) {
    return (
      <Select
        options={options}
        isDisabled={false}
        placeholder='Selecione o grupo'
      />
    )
  }

  options[0] = {label: '--Selecione--', value: 0}
  data.GetUsuarioGrupoServicosByUserId.result.map((Options) => {
    options[Options['id']] = {
      value: Options['id'],
      label: Options['name'] + '(' + Options.servico.length+')',
    }
    return []
  })
  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      padding:'5px' ,
    })
  }
  return (
    <Select
      options={options}
      defaultValue={{label: 'Todas as informações', value: 0}}
      styles={CustomStyle}
      onChange={(e) => {
        let retorno = data.GetUsuarioGrupoServicosByUserId.result.filter((Options) => {
          return Options['id'] === e.value
        })
        onChange(retorno)
      }}
      placeholder='Selecione o grupo'
    />
  )
}
