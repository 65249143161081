import { useState } from "react";
import { AsideDefault } from "../../../../_metronic/layout/components/aside/AsideDefault";
import MenuTopo from "../partials/MenuTopo";
import useLayoutControl from "../hooks/useLayoutControl";
import { Col, Tab } from "react-bootstrap";
import MapAluguel from "./MapAluguel";
import { Filtro } from "../partials/Filtro/Filtro";
import { TabItens } from "../venda/TabsItens";



export function MercadoSecundarioAluguel() {

    let nome = 'TEste'

    const  {windowHeight}  = useLayoutControl()
    
  const [showMap, setShowMap] = useState(false)

  function handleShowPanel() {
    setShowMap(false)
  }
  function setActiveTab() {
    // setShowMap(false)
  }

return  (
    <>
      <AsideDefault />
      <MenuTopo nome={nome} tipo={"venda"} />
      <div className='wrapper d-flex flex-column flex-row-fluid'>
        <div
          className='content p-0 d-flex flex-column flex-column-fluid'
          // style={{paddingTop: '35px'}}
        >
          <div className='container-fluid p-0 border-1 border-radius'>
            <div className='' style={{height: windowHeight.h}}>
              <Tab.Container id='left-tabs-example' defaultActiveKey='item1'>
                <div style={{display: 'flex', height: windowHeight.h}}>
                  <Col
                    sm={3}
                    md={3}
                    xl={3}
                    xls={3}
                    className='d-flex flex-column justify-content-between p-0'
                    style={{
                      width: '243px',
                      backgroundColor: '#fff',
                      // zIndex: 99,
                      // bordersLeft: '1px solid #d0d0d0',
                      // borderTop: '1px solid #e7e7e7',
                    }}
                  >
                    <TabItens windowHeight={windowHeight.h} setActiveTab={setActiveTab}></TabItens>
                    <div className='dados-position'>
                      {/* <ModalDados></ModalDados> */}
                    </div>
                  </Col>
                  <Col
                    // ref={tableRef}
                    sm={9}
                    className='p-0 '
                    style={{
                      width: windowHeight.w,
                      borderLeft: '1px solid #d0d0d0',
                      height: windowHeight.h,
                      overflowY: 'auto',
                      // overflow: 'auto'
                    }}
                  >
                    <Tab.Content>
                      <Tab.Pane eventKey='item1'>
                        <div className='all-start-between' style={{height: windowHeight.h}}>
                          {/* <Scrollbars style={{ height: windowHeight-100}}> */}
                          <Filtro height={windowHeight.h} />
                          {/* </Scrollbars> */}
                          <div
                            id='map'
                            className='flex-row position-relative'
                            style={{width: '100%', height: windowHeight.h}}
                          >
                            {showMap && (
                              <button
                                className='btn btn-primary position-absolute top-0 end-0 m-3 z-index-1'
                                onClick={handleShowPanel}
                              >
                                Mostrar Painel
                              </button>
                            )}
                             <MapAluguel height={windowHeight.h} />
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey='item2'>
                        {/* <Segmentacao></Segmentacao> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item3'>
                        {/* {activeTab === 'resumoMercado' ? <ResumoMercado></ResumoMercado> : <></>} */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item4'>
                        {/* {activeTab === 'performance' ? <Performance></Performance> : <></>} */}
                        {/* <Performance></Performance> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item41'>
                        {/* {activeTab === 'sociodemografico' ? (
                          <PerformanceSocioDemografico></PerformanceSocioDemografico>
                        ) : (
                          <></>
                        )} */}
                        {/* <PerformanceSocioDemografico></PerformanceSocioDemografico> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item42'>
                        {/* {activeTab === 'performanceResumoMercado' ? (
                          <PerformanceResumoMercado></PerformanceResumoMercado>
                        ) : (
                          <></>
                        )} */}
                        {/* <PerformanceResumoMercado></PerformanceResumoMercado> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item43'>
                        {/* {activeTab === 'performanceAnaliseConcorrentes' ? (
                          <PerformanceAnaliseConcorrentes></PerformanceAnaliseConcorrentes>
                        ) : (
                          <></>
                        )} */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item49'>
                        {/* {activeTab === 'desempenhovendas' ? (
                          <PerformanceDesempenhoVendas></PerformanceDesempenhoVendas>
                        ) : (
                          <></>
                        )} */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item44'>
                        {/* {activeTab === 'performanceVendas' ? (
                          <PerformanceVendas></PerformanceVendas>
                        ) : (
                          <></>
                        )} */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item45'>
                        {/* <PerformanceVendas></PerformanceVendas> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item46'>
                        {/* {activeTab === 'performanceBrevesLancamentos' ? (
                          <PerformanceBrevesLancamentos></PerformanceBrevesLancamentos>
                        ) : (
                          <></>
                        )} */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item47'>
                        {/* {activeTab === 'mercadoRevenda' ? (
                          <PerformanceMercadoRevenda></PerformanceMercadoRevenda>
                        ) : (
                          <></>
                        )} */}
                        {/* <PerformanceMercadoRevenda></PerformanceMercadoRevenda> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item48'>
                        {/* {activeTab === 'pontosInteresse' ? (
                          <PerformancePontosInteresse></PerformancePontosInteresse>
                        ) : (
                          <></>
                        )} */}
                      </Tab.Pane>
                      <Tab.Pane eventKey='item5'>
                        {/* {activeTab === 'avm' ? <Avm id={Number(idProjetoURL)}></Avm> : <></>} */}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}