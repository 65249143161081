/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../_metronic/partials'
import {getCSS} from '../../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  title: string
  subtitle: string
  data: any
  onChangeDormitorio: any
  onChange: any
  vsoIdeal: any[]
}

const MixedChart2: React.FC<Props> = ({
  title,
  subtitle,
  className,
  data,
  onChangeDormitorio,
  onChange,
  children,
  vsoIdeal,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [chartObj, setChartObj] = useState(null)
  const {mode} = useThemeMode()

  const refreshChart = (onChange) => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data, onChange, vsoIdeal)
    )

    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(onChange)
    setChartObj(chart)
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data, vsoIdeal])

  function toogleSeries(dormitorio) {
    chartObj.toggleSeries(dormitorio + ' Dorm.')
    onChangeDormitorio(dormitorio)
  }

  let cores = ['#2cd8e8', '#e84b2c', '#7cd164', '#010979', '#2eb8ac', '#7cbc9a', '#1d5e69']
  return (
    <div className={`card ${className} `}>
      <div className='card-header border-0 pt-5 p-10'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1 '>{title}</span>
          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* <SelectDormitorios onChange={onChangeDormitorio}></SelectDormitorios> */}
        </div>
      </div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '350px'}}></div>

        <div id='chart-wrap'>
          <div id='chart'>
            {data ? (
              // eslint-disable-next-line array-callback-return
              data.map((item, index) => {
                let dorm = item.dormitorios
                let cor = cores[item.dormitorios]
                return (
                  <div className='legend' key={index}>
                    <div className='form-check form-check-custom '>
                      <input
                        className='form-check-input'
                        style={{backgroundColor: cor}}
                        type='checkbox'
                        defaultChecked={item.visible === true ? true : false}
                        onClick={() => toogleSeries(dorm)}
                        id={dorm + 'dorm'}
                      />
                      <label className='form-check-label' htmlFor={dorm + 'dorm'}>
                        {dorm} Dorm.
                      </label>
                    </div>
                  </div>
                )
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        {children}
      </div>
    </div>
  )
}

export {MixedChart2}

function getChartOptions(
  height: number,
  chartData: any,
  onChange: any,
  vsoIdeal: any[]
): ApexOptions {
  let vsoChart = []

  vsoChart = [
    {x: 0, y: 0},
    {x: 3, y: null},
    {x: 6, y: null},
    {x: 8, y: null},
    {x: 11, y: null},
    {x: 14, y: null},
    {x: 17, y: null},
    {x: 19, y: null},
    {x: 22, y: null},
    {x: 25, y: null},
    {x: 28, y: null},
    {x: 31, y: null},
    {x: 33, y: null},
    {x: 36, y: null},
    {x: 39, y: null},
    {x: 42, y: null},
    {x: 44, y: null},
    {x: 47, y: null},
    {x: 50, y: null},
    {x: 53, y: null},
    {x: 56, y: null},
    {x: 58, y: null},
    {x: 61, y: null},
    {x: 64, y: null},
    {x: 67, y: null},
    {x: 69, y: null},
    {x: 72, y: null},
    {x: 75, y: null},
    {x: 78, y: null},
    {x: 81, y: null},
    {x: 83, y: null},
    {x: 86, y: null},
    {x: 89, y: null},
    {x: 92, y: null},
    {x: 94, y: null},
    {x: 97, y: null},
    {x: 100, y: null},
  ]

  // eslint-disable-next-line array-callback-return
  vsoIdeal.map((item, index) => {
    vsoChart[index].y = item.vso
  })

  return {
    series: [
      {
        name: 'VSO Ideal',
        type: 'line',
        data: vsoChart,
      },
      ...chartData,
    ],
    chart: {
      height: 550,
      type: 'line',
      events: {
        dataPointSelection: function (event, chartContext, config) {
          let series = config.seriesIndex
          let id = config.w.config.series[series].data[config.dataPointIndex].id || null
          if (id) {
            window.location.href = `#line${id}`
            onChange(Number(id))
          }
        },

        mounted: (chart) => {
          if (chart.data){
            // eslint-disable-next-line array-callback-return
            chartData.map((item, index) => {
              let dorm = item.dormitorios
              if (item.visible) {
                chart.showSeries(dorm + ' Dorm.')
              } else {
                chart.hideSeries(dorm + ' Dorm.')
              }
            })
          }
        },
      },
    },
    fill: {
      type: 'solid',
    },

    markers: {
      size: [3, 8, 8, 8, 8, 8, 8],
      hover: {
        size: 10,
      },
      colors: ['#304758', '#e84b2c', '#7cd164', '#010979', '#2eb8ac', '#7cbc9a', '#1d5e69'],
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%'
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        if (seriesIndex !== 0) {
          let data = w.config.series[seriesIndex].data[dataPointIndex]
          if (data) {
            let mes = Math.round((36 * data['x']) / 100)
            return (
              '<ul class="arrow_box">' +
              '<li>Nome: ' +
              data['nome'] +
              '</li>' +
              '<li>Dormitórios: ' +
              data['dormitorios'] +
              '</li>' +
              '<li>VSO: ' +
              data['y'] +
              '%</li>' +
              '<li>Mês: ' +
              mes +
              '</li>' +
              '<li>Fase do Projeto: ' +
              data['x'] +
              '%</li>' +
              '<li>Total Unidades: ' +
              data['total_unidades'] +
              '</li>' +
              '<li>Tipo: ' +
              data['tipologia'] +
              '</li>' +
              '<li>Situação: ' +
              data['situacao'] +
              '</li>' +
              '</ul>'
            )
          }
        } else {
          let data = w.config.series[seriesIndex].data[dataPointIndex]
          let mes = Math.round((36 * data['x']) / 100)

          return (
            '<ul class="arrow_box">' +
            '<li>Mês: ' +
            mes +
            '</li>' +
            '<li>VSO: ' +
            data['y'] +
            '%</li>' +
            '<li>Fase do Projeto: ' +
            data['x'] +
            '%</li>' +
            '</ul>'
          )
        }
      },
      x: {
        formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
          let mes = Math.round((36 * value) / 100)
          return 'Mês ' + mes + '<br />Fase do projeto ' + value + '%'
        },
      },
      y: {
        formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
          return 'projeto ' + value + '%'
        },
      },
      shared: false,
      intersect: true,
    },
    legend: {
      show: false,
      // height:100
    },
    xaxis: {
      title: {
        text: 'Fase do Projeto',
      },
      tickAmount: 10,
      // type: 'text',
      min: 0,
      max: 100,
      labels: {
        formatter: function (value, index) {
          let mesText = Math.floor((37 / 100) * index)
          return value + '% - Mês ' + mesText
        },
      },
    },
    yaxis: {
      title: {
        text: 'VSO',
      },
      // type: 'numeric',
      min: 0,
      max: 100,
      labels: {
        formatter: function (value) {
          return value + '%' // formatar os valores do eixo Y, se necessário
        },
      },
    },
  }
}
