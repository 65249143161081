import React, {useEffect, useState} from 'react'
import {BiAddToQueue} from 'react-icons/bi'
import {Link} from 'react-router-dom'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'
import {CardTables} from '../../../../components/UI/CardTables'
import {getLaunches} from '../../../../../services/http/urbit-gis/routes'
import {RealEstateLaunch} from '../../../../../services/http/urbit-gis/routes/types'
import {DateTimToView} from '../../../../components/util'
import {Buttons} from './Buttons'
import PaginationComponentGIS from '../../../../components/UI/PaginationComponentGis'
import {PageInfo} from '../../../../components/UI/PaginationComponents'

interface Props {
  filtro: any[] // Certifique-se de definir corretamente o tipo de 'filtro'
}

export function TableAdminEmpreendimentos({filtro}: Props) {
  const [data, setData] = useState<RealEstateLaunch[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo>()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [paginacao, setPaginacao] = useState({pagina: 1, quantidade: 10})

  async function fetchLaunches() {
    try {
      const launches = await getLaunches({
        page: paginacao.pagina,
        limit: paginacao.quantidade,
        filtro: {
          fields: filtro,
        },
      })

      // console.log('Lançamentos:', launches)

      // Ordenar os lançamentos pelo campo created_at em ordem decrescente
      const sortedLaunches = launches.data.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      })

      setData(sortedLaunches)
      setPageInfo(launches.pagination)
    } catch (error) {
      console.error('Erro ao buscar lançamentos:', error)
    }
  }

  useEffect(() => {
    fetchLaunches()
  }, [paginacao, filtro])

  const handleRefetch = () => {
    fetchLaunches()
  }

  return (
    <>
      <CardTables
        headerTitle={'Empreendimentos'}
        headerSubtTitle={`Listando 10 empresas registradas.`}
        headerToolbar={
          <Link
            to={'/backoffice/empreendimentos/create'}
            className='btn btn-light-primary btn-sm d-block me-2'
            style={{width: '100px !important'}}
          >
            <BiAddToQueue className='fs-2 me-1' />
            Criar Empreendimento
          </Link>
        }
        footer={
          <PaginationComponentGIS
            pagesInfo={pageInfo}
            setPagesInfo={(pagina: number, quantidade: number) => {
              setPaginacao({pagina: pagina, quantidade: quantidade})
            }}
          />
        }
      >
        <div className='table-responsive'>
          <table className='table table-striped table-row-dashed gy-2 gs-6 table-row-bordered table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='w-50px'>ID</th>
                <th className='min-w-140px'>Nome da Empresa</th>
                <th className='min-w-100px'>Endereço</th>
                <th className='min-w-180px'>Data de Lançamento</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data && data.length > 0 ? (
                data.map((response) => (
                  <tr key={response.id}>
                    <td>{response.id}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bold d-block fs-6'>{response.name}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bold d-block fs-6'>{response.address}</span>
                        </div>
                      </div>
                    </td>
                    <td>{DateTimToView(response.launch_date)}</td>
                    <td>
                      <Buttons handleRefetch={handleRefetch} id={response.id} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12} className='text-center'>
                    Nenhum registro encontrado
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}
