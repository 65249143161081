import { AiOutlineUserSwitch } from "react-icons/ai"
import { CreateForm } from "../../../../../pessoas/components/CreateForm"
import { useState } from "react"

type Props = {
  data: any
  reportTemplateRef: any
  params?: {
    endereco?: string;
    cep?: string;
    number?: number;
    name?: string;
  }
}

export const TableCondominios: React.FC<Props> = ({ data, reportTemplateRef, params }) => {
  const [showCreate, setShowCreate] = useState(false)
  function CloseModalCreate() {
    setShowCreate(false)
  }

  function OpenModalCreate(name: string) {
    setShowCreate(true)
    if (params) {
      params.name = name
    }
  }


  if (!data) {
    return <></>
  }

  return (
    <div className='table-responsive'>
      <table className='table table-striped gs-7 ' ref={reportTemplateRef}>
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-50px'>Nome</th>
            <th className='min-w-140px'>Complemento</th>
            <th className='min-w-140px'>Número Contribuinte</th>
            <th className='min-w-50px'>Área Construída</th>
            <th className='min-w-50px'>Fração Ideal</th>
            <th className='min-w-50px'></th>
          </tr>
        </thead>
        <tbody>
          {
            // eslint-disable-next-line array-callback-return
            data.itens.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        {item.nome_contribuinte_1}
                      </div>
                    </div>
                  </td>
                  <td>{item.complemento_do_imovel}</td>
                  <td>{item.numero_contribuinte}</td>
                  <td>{item.area_construida}</td>
                  <td>{item.fracao_ideal}</td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <button
                        onClick={() => {
                          OpenModalCreate(item.nome_contribuinte_1)
                        }}
                        className='btn btn-icon btn-light-success btn-sm'
                      >
                        <AiOutlineUserSwitch size={28}></AiOutlineUserSwitch>
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {showCreate ? <CreateForm origin='Imoveis' params={params} closeModal={CloseModalCreate} show={showCreate} /> : <></>}
    </div>
  )
}
