import {useImoveisStore} from '../context/DataContext'
import Map1Dorm from './Mapas/Map1Dorm'
import Map2Dorm from './Mapas/Map2Dorm'
import Map3Dorm from './Mapas/Map3Dorm'
import Map4Dorm from './Mapas/Map4Dorm'
import Map5Dorm from './Mapas/Map5Dorm'

interface MapVendaProps {
  height: number
}

const MapVenda = ({height}: MapVendaProps) => {
  const {imoveis} = useImoveisStore()
  if (!imoveis) {
    return <></>
  }

  const filtered = imoveis.filter((item) => item.active)

  return (
    <>
      <div className='row p-10'>
        <div className='card col-12'>
          <div className='card-header p-5'>
            <h3>1 Dormitório</h3>
          </div>
          <div className='card-body'>
            <Map1Dorm height={height} amostras={filtered}></Map1Dorm>
          </div>
        </div>
      </div>
      <div className='row p-10'>
        <div className='card col-12'>
          <div className='card-header p-5'>
            <h3>2 Dormitório</h3>
          </div>
          <div className='card-body'>
            <Map2Dorm height={height} amostras={filtered}></Map2Dorm>
          </div>
        </div>
      </div>
      <div className='row p-10'>
        <div className='card col-12'>
          <div className='card-header p-5'>
            <h3>3 Dormitório</h3>
          </div>
          <div className='card-body'>
            <Map3Dorm height={height} amostras={filtered}></Map3Dorm>
          </div>
        </div>
      </div>
      <div className='row p-10'>
        <div className='card col-12'>
          <div className='card-header p-5'>
            <h3>4 Dormitório</h3>
          </div>
          <div className='card-body'>
            <Map4Dorm height={height} amostras={filtered}></Map4Dorm>
          </div>
        </div>
      </div>
      <div className='row p-10'>
        <div className='card col-12'>
          <div className='card-header p-5'>
            <h3>5 Dormitório</h3>
          </div>
          <div className='card-body'>
            <Map5Dorm height={height} amostras={filtered}></Map5Dorm>
          </div>
        </div>
      </div>
    </>
  )
}

export default MapVenda
