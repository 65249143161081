import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {TypesDeleteCorretorResponse} from '../type'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {
  TypesDeleteInteligenciaMercadoVSOResponse,
  TypesGetInteligenciaMercadoByIDResponse,
  TypesGetInteligenciaMercadoResponse,
  TypesGetInteligenciaMercadoVSOByIDResponse,
  TypesGetInteligenciaMercadoVSOResponse,
  TypesPutInteligenciaMercadoResponse,
  TypesPutInteligenciaMercadoVSOResponse,
  TypesSetInteligenciaMercadoResponse,
  TypesSetInteligenciaMercadoVSOResponse,
} from '../type/inteligencia-mercado'
import {
  GET_INTELIGENCIA_MERCADO,
  GET_INTELIGENCIA_MERCADO_BY_ID,
  GET_INTELIGENCIA_MERCADO_VSO,
  GET_INTELIGENCIA_MERCADO_VSO_BY_ID,
} from '../query/inteligencia-mercado'
import {
  DELETE_INTELIGENCIA_MERCADO,
  DELETE_INTELIGENCIA_MERCADO_VSO,
  PUT_INTELIGENCIA_MERCADO,
  PUT_INTELIGENCIA_MERCADO_VSO,
  SET_INTELIGENCIA_MERCADO,
  SET_INTELIGENCIA_MERCADO_VSO,
} from '../mutations/inteligencia-mercado'
import {
  PutInteligenciaMercadoVSOFormData,
  PutInteligenciaMercadoVSOFormSchema,
  SetInteligenciaMercadoVSOFormData,
  SetInteligenciaMercadoVSOFormSchema,
  PutInteligenciaMercadoFormData,
  PutInteligenciaMercadoFormSchema,
  SetInteligenciaMercadoFormData,
  SetInteligenciaMercadoFormSchema,
} from '../formValidations/InteligenciaMercado'

interface Props {
  variables: any
}

export function QueryGetInteligenciaMercado({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetInteligenciaMercadoResponse>(
    GET_INTELIGENCIA_MERCADO,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error}
}

export function QueryGetInteligenciaMercadoByID({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetInteligenciaMercadoByIDResponse>(
    GET_INTELIGENCIA_MERCADO_BY_ID,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error}
}

export function MutationDeleteInteligenciaMercado() {
  const [deleteInteligenciaMercado, {loading, data, error}] =
    useMutation<TypesDeleteCorretorResponse>(DELETE_INTELIGENCIA_MERCADO, {
      refetchQueries: [GET_INTELIGENCIA_MERCADO, GET_INTELIGENCIA_MERCADO_BY_ID],
    })

  async function HandleDeleteInteligenciaMercado({variables}: Props) {
    try {
      const result = await deleteInteligenciaMercado({
        variables: {...variables},
        refetchQueries: [GET_INTELIGENCIA_MERCADO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteInteligenciaMercado, loading, error, data}
}

export function MutationCreateInteligenciaMercado() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<SetInteligenciaMercadoFormData>({
    resolver: zodResolver(SetInteligenciaMercadoFormSchema),
  })

  const [setInteligenciaMercadoBody, {error, loading, data: DataSetInteligenciaMercado}] =
    useMutation<TypesSetInteligenciaMercadoResponse>(SET_INTELIGENCIA_MERCADO, {
      refetchQueries: [GET_INTELIGENCIA_MERCADO],
    })

  async function FormInteligenciaMercado({nome, json}: SetInteligenciaMercadoFormData) {
    return await setInteligenciaMercadoBody({
      variables: {
        data: {
          nome,
          json,
        },
      },
    })
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormInteligenciaMercado,
    loading,
    errors,
    error,
    DataSetInteligenciaMercado,
  }
}

export function MutationPutInteligenciaMercado() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<PutInteligenciaMercadoFormData>({
    resolver: zodResolver(PutInteligenciaMercadoFormSchema),
  })

  const [PutInteligenciaMercadoBody, {error, loading, data: DataSetInteligenciaMercado}] =
    useMutation<TypesPutInteligenciaMercadoResponse>(PUT_INTELIGENCIA_MERCADO, {
      refetchQueries: [GET_INTELIGENCIA_MERCADO, GET_INTELIGENCIA_MERCADO_BY_ID],
    })

  async function FormInteligenciaMercado(data: PutInteligenciaMercadoFormData) {
    return await PutInteligenciaMercadoBody({
      variables: {
        data: data,
        putInteligenciaMercadoId: data.id,
      },
    })
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormInteligenciaMercado,
    loading,
    errors,
    error,
    DataSetInteligenciaMercado,
  }
}

// VSO SERVICES

export function QueryGetInteligenciaMercadoVSO({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetInteligenciaMercadoVSOResponse>(
    GET_INTELIGENCIA_MERCADO_VSO,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error}
}

export function QueryGetInteligenciaMercadoVSOByID() {
  const [getData, {data, loading, error}] =
    useLazyQuery<TypesGetInteligenciaMercadoVSOByIDResponse>(GET_INTELIGENCIA_MERCADO_VSO_BY_ID)
  return {data, loading, error, getData}
}

export function MutationCreateInteligenciaMercadoVSO() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<SetInteligenciaMercadoVSOFormData>({
    resolver: zodResolver(SetInteligenciaMercadoVSOFormSchema),
  })

  const [SetInteligenciaMercadoBody, {error, loading, data: DataSetInteligenciaMercadoVSO}] =
    useMutation<TypesSetInteligenciaMercadoVSOResponse>(SET_INTELIGENCIA_MERCADO_VSO, {
      refetchQueries: [GET_INTELIGENCIA_MERCADO_VSO],
    })

  async function FormInteligenciaMercadoVSO({vso, nome}: SetInteligenciaMercadoVSOFormData) {
    try {
      return await SetInteligenciaMercadoBody({
        variables: {
          inteligenciaMercadoVso: {
            vso: vso,
            nome: nome,
          },
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {
    register,
    handleSubmit,
    reset,
    setValue,
    FormInteligenciaMercadoVSO,
    loading,
    errors,
    error,
    DataSetInteligenciaMercadoVSO,
  }
}

export function MutationPutInteligenciaMercadoVSO() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<PutInteligenciaMercadoVSOFormData>({
    resolver: zodResolver(PutInteligenciaMercadoVSOFormSchema),
  })

  const [PutInteligenciaMercadoBody, {error, loading, data: DataSetInteligenciaMercadoVSO}] =
    useMutation<TypesPutInteligenciaMercadoVSOResponse>(PUT_INTELIGENCIA_MERCADO_VSO, {
      refetchQueries: [
        GET_INTELIGENCIA_MERCADO_VSO,
        GET_INTELIGENCIA_MERCADO_BY_ID,
        GET_INTELIGENCIA_MERCADO_VSO_BY_ID,
      ],
    })

  async function FormInteligenciaMercadoVSO({vso, id, nome}: PutInteligenciaMercadoVSOFormData) {
    try {
      return await PutInteligenciaMercadoBody({
        variables: {
          inteligenciaMercadoVso: {
            vso: vso,
            nome: nome,
            id: id,
          },
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {
    register,
    handleSubmit,
    reset,
    setValue,
    FormInteligenciaMercadoVSO,
    loading,
    errors,
    error,
    DataSetInteligenciaMercadoVSO,
  }
}

export function MutationDeleteInteligenciaMercadoVSO() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<SetInteligenciaMercadoVSOFormData>({
    resolver: zodResolver(SetInteligenciaMercadoVSOFormSchema),
  })

  const [DeleteInteligenciaMercadoBody, {error, loading, data: DataSetInteligenciaMercadoVSO}] =
    useMutation<TypesDeleteInteligenciaMercadoVSOResponse>(DELETE_INTELIGENCIA_MERCADO_VSO, {
      refetchQueries: [GET_INTELIGENCIA_MERCADO_VSO],
    })

  async function FormInteligenciaMercadoVSO(idDelete: number) {
    try {
      return await DeleteInteligenciaMercadoBody({
        variables: {
          inteligenciaMercadoVso: {
            id: idDelete,
          },
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormInteligenciaMercadoVSO,
    loading,
    errors,
    error,
    DataSetInteligenciaMercadoVSO,
  }
}
