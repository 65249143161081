import React, {useEffect, useState} from 'react'
import '../global.css'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import {useLocation} from 'react-router'
import {Footer} from '../../../_metronic/layout/components/Footer'
import {TableMeusImoveis} from './components'
import {KTSVG} from '../../../_metronic/helpers'
import {Form, FormLabel} from 'react-bootstrap'
import {GetImovelTagsChosen} from './components/Chosen/GetImovelTagsAsyncSelect'
import '../../../_metronic/layout/components/header/header.css'
import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {GetSituacaoClienteChosen} from './components/Chosen/GetSituacaoClienteChosen'
import { GetCorretoresChosen } from '../../components/UI/Chosens/GetCorretoresChosen'

export function MeusImoveis() {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const [toogleFilter, setToogleFilter] = useState(true)
  const [tags, setTags] = React.useState([])

  const [filter, setFilter] = useState([])
  const [filtroValores, setFiltroValores] = useState({
    id_corretor:null,
    id_situacao:null
  })
  const [objectFilter, setObjectFilter] = useState({})

  function addFilter(e) {
    e.preventDefault()

    const data = new FormData(e.target)
    const filterArray = []

    if (data.get('nome')) {
      filterArray.push({
        field: ['nome', 'endereco'],
        operator: 'LIKE',
        value: data.get('nome'),
      })
    }

    if (tags.length > 0) {
      filterArray.push({
        field: ['tag'],
        operator: 'like',
        value: tags,
      })
    }
    if (data.get('zoneamento')) {
      filterArray.push({
        field: ['zoneamento'],
        operator: 'LIKE',
        value: data.get('zoneamento'),
      })
    }

    if (data.get('status-cliente')) {
      filterArray.push({
        field: ['status_cliente'],
        operator: 'and',
        value: data.get('status-cliente'),
      })
    }


    if (data.get('id_corretor')) {

      console.log(data.get('id_corretor'))
      if (data.get('id_corretor')===''){
        setObjectFilter({})
      }else{
        setObjectFilter({objectFilter: {
            "terreno_corretor":{
              "some": {
                "corretor":{
                  "id":Number(data.get('id_corretor')),
                  "situacao":1
                }
              }
            }
        }})
      }
    }


    setFilter([...filterArray])
    return false
  }

  function toggleTeste() {
    if (toogleFilter === false) {
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }
  function setTag(tag) {
    setTags(tag)
  }
  function limpar() {
    setFilter([])
    setObjectFilter({})
    setFiltroValores({id_corretor:'', id_situacao:''})
    setTags([])
  }

  console.log(filtroValores)
  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Meus Imóveis' />

      <div className='wrapper d-flex flex-column flex-row-fluid'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div className='container-fluid'>
              <div className='py-6  d-flex align-items-center justify-content-between'>
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Listagem</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/' className='text-muted'>
                        Dashboard
                      </a>
                    </li>
                    <li className='breadcrumb-item text-muted'>Imóveis</li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <button
                    className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={toggleTeste}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filtros
                  </button>
                </div>
              </div>

              <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                <div id='acc' className={toogleFilter === true ? 'show' : ''}>
                  <div className='card-form px-12 py-12 mb-6'>
                    <Form className='row form form-horizontal' onSubmit={addFilter}>
                      <div className='row'>
                        <div className='col-md-3'>
                          <FormLabel className='form-label'>Nome/Endereço</FormLabel>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Digite o nome ou Endereço'
                            name='nome'
                          />
                        </div>
                        <div className='col-md-3 mb-2'>
                          <FormLabel className='form-label'>Situação</FormLabel>
                          <GetSituacaoClienteChosen
                            onValueChange={(value) => {
                              setFiltroValores({...filtroValores, ...{id_situacao:value}} )
                            }}
                            defaultValue={filtroValores.id_situacao}
                          />
                        </div>
                        <div className='col-md-3'>
                          <FormLabel className='form-label'>Zoneamento</FormLabel>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='ZM'
                            name='zoneamento'
                          />
                        </div>
                        <div className='col-md-3'>
                        {/* <FormLabel className='form-label'>Corretor</FormLabel> */}
                        <GetCorretoresChosen
                        
                                                valueCorretor={filtroValores.id_corretor}
                                                SetValueCorretor={(value: number) => {
                                                 
                                                   setFiltroValores({...filtroValores, ...{id_corretor:value}} )
                                                    // setValue('id_corretor', value)
                                                    // setValueCorretor(value)
                                                }}
                                            />
                                    </div>
                        <div className='col-md-3'>
                          <FormLabel className='form-label'>Tags</FormLabel>
                          <GetImovelTagsChosen settag={setTag} />
                        </div>

                        <div className='w-100 all-end mrt-2'>
                        <button type='reset' className='btn btn-secondary me-5' onClick={limpar}>
                            Limpar
                          </button>
                          <button type='submit' className='btn btn-primary'>
                            Buscar
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
                  <div className='col-xl-12'>
                    <TableMeusImoveis filtro={filter} objectFilter={objectFilter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
