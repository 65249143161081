import {useEffect, useState} from 'react'
import {Color, SliderPicker} from 'react-color'

export const ColorPicker = ({hex, onChange}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [displayColorPicker, setDisplayColorPicker] = useState(1)
  const defaultColocar = hex
  const [color, setColor] = useState<Color>('#000000')

  useEffect(() => {
    if (defaultColocar) {
      setColor(defaultColocar)
    }
  }, [defaultColocar])

  const handleChange = (color) => {
    setColor({r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: 100})
  }

  return (
    <div className='p-2'>
      {displayColorPicker ? (
        <div className='mt-3'>
          <SliderPicker
            color={color}
            onChangeComplete={(color) => {
              handleChange(color)
              // setBlockPickerColor(color.rgb)
              let cor = Object.values(color.rgb)
              cor.splice(3, 3)
              // cor[3] = 255
              onChange(cor)
            }}
          />
        </div>
      ) : null}
    </div>
  )
}
