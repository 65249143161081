import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'
import {MutationDeleteInteligenciaMercado} from '../../../graphql/services/inteligencia-mercado'
import {BiFolderOpen} from 'react-icons/bi'
import {useHistory} from 'react-router-dom'

type Props = {
  id: number
}

export const Buttons: React.FC<Props> = ({id}) => {
  const {HandleDeleteInteligenciaMercado, loading} = MutationDeleteInteligenciaMercado()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const navigate = useHistory()

  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }
  function NavigateToInteligenciaMercado(id: number) {
    navigate.push(`/inteligencia-mercado/${id}`)
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await HandleDeleteInteligenciaMercado({
          variables: {deleteInteligenciaMercadoId: id},
        })
        if (!result) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          FormSetLogAcessoRecurso({
            ferramenta: 'Inteligencia Mercado',
            funcionalidade: 'listagem',
            action: 'delete',
            url: '/profile#funcionarios',
          })
          Swal.fire('Sucesso!', 'Projeto deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          className='btn btn-light-success btn-sm me-2'
          onClick={(e) => {
            NavigateToInteligenciaMercado(id)
          }}
        >
          <BiFolderOpen /> Abrir Projeto
        </button>
        <button
          disabled={loading}
          onClick={(e) => {
            confirmDelete(id)
          }}
          className='btn btn-icon btn-light-danger  btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
