import ImageUploading, { ImageListType,ImageType } from 'react-images-uploading'
import { useEffect, useState } from 'react'
import { BiPen, BiTrash } from 'react-icons/bi'
import { useFormContext } from 'react-hook-form';
import { RealEstateLaunchFormData } from '../../../partials/typologies/formValidations';
import { useEmpreendimentos } from '../../../context/useEmpreendimentos';
import { updateLaunchImages } from '../../../../../../../services/http/urbit-gis/routes';


export function MediaCard() {
  const { register, setValue, formState: { errors } } = useFormContext<RealEstateLaunchFormData>();
  const { launch } = useEmpreendimentos()
  const [images, setImages] = useState<ImageType[]>([]);
  // console.log(images);
  const maxNumber = 69;

  useEffect(() => {
    if (launch && launch.images && typeof launch.images === 'object') {

      console.log(launch.images)

      // Transforme o objeto em um array de ImageType
      const imageArray = Object.keys(launch.images).map((key,item) => {

        console.log(launch.images[key])
          return {
            dataURL: launch.images[key],
            nome:key // ou como você precisa mapear os valores
          }
        }
      );
      setImages(imageArray);
    } else {
      setImages([]);
    }
  }, [launch]);
  

  const onChange = async (imageList: ImageType[], addUpdateIndex: number[]) => {

    const formData = new FormData();
    addUpdateIndex.forEach((item, index) => {
      formData.append('files', imageList[item].file);
    });

    const result = await updateLaunchImages(launch.id, formData)
    console.log(result)
    setImages(imageList);
  }
  const uploadImages = async () => {

    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append('files', image.file);
    });
    const result = await updateLaunchImages(launch.id, formData)
    console.log(result)
  }
  // const onSubmit = async (data: TypologiesPriceFormData) => {
  //   try {
  //     const updatedData = { ...data, launch_price: checkboxValue ? 1 : 0 };
  //     const result = await updateTypologyPrice(id, updatedData)

  //     if (result && result.affected === 1) {
  //       Swal.fire({
  //         title: 'Sucesso!',
  //         text: id ? 'Preço atualizado com sucesso.' : 'Preço adicionado com sucesso.',
  //         icon: 'success',
  //         confirmButtonText: 'OK',
  //       }).then(() => {
  //         closeModal();
  //         reset();
  //         handleRefetch();
  //       });
  //     } else {
  //       throw new Error(result.message || 'Ocorreu um erro desconhecido');
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       title: 'Erro!',
  //       text: error.message || 'Houve um erro ao salvar o empreendimento.',
  //       icon: 'error',
  //       confirmButtonText: 'OK',
  //     });
  //   }
  // };

  return (
    <div className='card flex-grow-1 mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h2 className='fw-bolder pt-4 px-8 m-0'>Fotos e Videos</h2>
      </div>
      <div className='card-body py-3'>
        <div className='row px-0'>
          <div className='form-group col-md-12 form-line '>
            <div className='row'>
              <div className='col-md-12'>
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  acceptType={['jpg', 'png']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors
                  }) => (
                    <div className='w-100'>
                      <button
                        type='button'
                        className='btn btn-primary float-end'
                        style={isDragging ? { color: 'red' } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Enviar fotos
                      </button>
                      <button type="button"  className="btn btn-info" onClick={uploadImages}>Salvar descrições</button>

                      <div className='upload__image-wrapper clearfix pt-15'>
                        {imageList.map((image, index) => (
                          <div className='col-12 col-sm-4 col-xl-4 image-item' key={index}>
                            <div className='card h-250px'>
                              <div className='d-flex justify-content-center text-center flex-column'>
                                <div className=' mb-2 h-150px   '>
                                  <img src={image.dataURL} alt='' className='img-fluid img-thumbnail border   border-hover border-gray-500' style={{maxHeight:200}} />
                                </div>
                                <div className='fs-5 fw-bolder mb-2'>
                                  <input type="text" className="form-control" defaultValue={image.nome} placeholder="Sem informação" />
                                </div>
                                <div className='fs-7 fw-bold text-gray-500 mt-auto'>
                                  <div className='image-item__btn-wrapper'>
                                    <button
                                      type="button"
                                      className='btn btn-icon btn-sm btn-light-primary me-3'
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <BiPen />
                                    </button>
                                    <button
                                      type="button"
                                      className='btn btn-icon btn-light-danger'
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <BiTrash />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                       {errors && <div>
                  {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                  {errors.acceptType && <span>Your selected file type is not allow</span>}
                  {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                  {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                </div>}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
