import {useEffect, useState} from 'react'
import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import MenuTopo from '../partials/MenuTopo'
import useLayoutControl from '../hooks/useLayoutControl'
import {Col, Tab} from 'react-bootstrap'
import MapVenda from './MapVenda'
import {Filtro} from '../partials/Filtro/Filtro'
import {TabItens} from './TabsItens'
import {Amostras} from './Amostras'
import {Estatisticas} from './Estatisticas'
import {Performance} from './Performance'
import {PerformanceSocio} from './SocioDemografico/PerformanceSocio'
import Mapas from './Mapas'
import {Avm} from './Avm'
import {useParams} from 'react-router-dom'
import {QueryGetMercadoSecundarioByID} from '../../../graphql/services/mercado-secundario'
import {useImoveisStore} from '../context/DataContext'
import { GetAcessoUsuarioProduto } from '../../../utils/PermissoesAcesso'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export function MercadoSecundarioVenda() {
  const {id: idProjetoURL} = useParams<{id: string}>()
  const {windowHeight} = useLayoutControl()
  const {setGeometria, setEndereco, setFiltro, setProjeto, setComputados} = useImoveisStore()

  const {data: dataProjeto} = QueryGetMercadoSecundarioByID({
    variables: {
      getMercadoSecundarioById: parseInt(idProjetoURL),
    },
  })

  const [showMap, setShowMap] = useState(false)
  const [activeTab, setActiveTab] = useState('zonaDeValor')
  function handleShowPanel() {
    setShowMap(false)
  }

  // SET DOS ESTADOS E LOGS DOS ESTADOS
  useEffect(() => {
    if (dataProjeto) {
      setGeometria(dataProjeto.GetMercadoSecundarioById.json.geometria)

      if (dataProjeto.GetMercadoSecundarioById.json.computados) {
        setComputados([...dataProjeto.GetMercadoSecundarioById.json.computados])
      }

      if (dataProjeto.GetMercadoSecundarioById.json.endereco) {
        setEndereco(dataProjeto.GetMercadoSecundarioById.json.endereco)
      }

      if (dataProjeto.GetMercadoSecundarioById) {
        setProjeto(dataProjeto.GetMercadoSecundarioById)
      }

      if (dataProjeto.GetMercadoSecundarioById.json.filter) {
        setFiltro(dataProjeto.GetMercadoSecundarioById.json.filter)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjeto])

  // if (!dataProjeto) {
  //   return <SplashScreenComponent />
  // }

  const permissao = GetAcessoUsuarioProduto(67)
  
  let nome = dataProjeto ? dataProjeto.GetMercadoSecundarioById?.nome : 'Sem nome'
  return (
    <>
      <AsideDefault />
      <MenuTopo nome={nome} tipo={'venda'} />
      {!permissao ? (
        <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
          <div className='card-form px-4 py-12 mb-6' style={{height: '74vh'}}>
            <div id='kt_content_container' className='container-fluid'>
              <div className='alert alert-warning'>
                <h3 className='pt-2 ps-2'>
                  Você não tem este produto contratado, por favor entre em contato no email:{' '}
                  <a href='mailto:contato@urbit.com.br?subject=Sobre Avaliação de Imóveis&body=Gostaria de informações sobre o produto de avaliação de imóveis.'>
                    contato@urbit.com.br
                  </a>
                </h3>
                {/* <MensagemAlertAcesso mensagem='Somente para assinantes do Produto - Urbit Pricing.'></MensagemAlertAcesso> */}
              </div>
              <div className='d-flex justify-content-center align-content-center'>
                <img src={toAbsoluteUrl('/media/prin-ms-venda.png')} alt='print-im' />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {permissao ? 
        <div className='wrapper d-flex flex-column flex-row-fluid'>
          <div
            className='content p-0 d-flex flex-column flex-column-fluid'
            // style={{paddingTop: '35px'}}
          >
            <div className='container-fluid p-0 border-1 border-radius'>
              <div className='' style={{height: windowHeight.h}}>
                <Tab.Container id='left-tabs-example' defaultActiveKey='item1'>
                  <div style={{display: 'flex', height: windowHeight.h}}>
                    <Col
                      sm={3}
                      md={3}
                      xl={3}
                      xls={3}
                      className='d-flex flex-column justify-content-between p-0'
                      style={{
                        width: '243px',
                        backgroundColor: '#fff',
                        // zIndex: 99,
                        // bordersLeft: '1px solid #d0d0d0',
                        // borderTop: '1px solid #e7e7e7',
                      }}
                    >
                      <TabItens windowHeight={windowHeight.h} setActiveTab={setActiveTab}></TabItens>
                      <div className='dados-position'>{/* <ModalDados></ModalDados> */}</div>
                    </Col>
                    <Col
                      // ref={tableRef}
                      sm={9}
                      className='p-0 '
                      style={{
                        width: windowHeight.w,
                        borderLeft: '1px solid #d0d0d0',
                        height: windowHeight.h,
                        overflowY: 'auto',
                        // overflow: 'auto'
                      }}
                    >
                      <Tab.Content>
                        <Tab.Pane eventKey='item1'>
                          <div className='all-start-between' style={{height: windowHeight.h}}>
                            {/* <Scrollbars style={{ height: windowHeight-100}}> */}
                            <Filtro height={windowHeight.h} />
                            {/* </Scrollbars> */}
                            <div
                              id='map'
                              className='flex-row position-relative'
                              style={{width: '100%', height: windowHeight.h}}
                            >
                              {showMap && (
                                <button
                                  className='btn btn-primary position-absolute top-0 end-0 m-3 z-index-1'
                                  onClick={handleShowPanel}
                                >
                                  Mostrar Painel
                                </button>
                              )}
                              <MapVenda height={windowHeight.h} />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='item2'>
                          <Amostras label={'Amostras'}></Amostras>
                        </Tab.Pane>
                        <Tab.Pane eventKey='item3'>
                          <Estatisticas></Estatisticas>
                          {/* {activeTab === 'resumoMercado' ? <ResumoMercado></ResumoMercado> : <></>} */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item4'>
                          {/* {activeTab === 'performance' ? <Performance></Performance> : <></>} */}
                          <Performance></Performance>
                        </Tab.Pane>
                        <Tab.Pane eventKey='item41'>
                          <PerformanceSocio></PerformanceSocio>
                          {/* {activeTab === 'sociodemografico' ? (
                            <PerformanceSocioDemografico></PerformanceSocioDemografico>
                          ) : (
                            <></>
                          )} */}
                          {/* <PerformanceSocioDemografico></PerformanceSocioDemografico> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item7'>
                          {activeTab === 'mapas' ? <Mapas height={windowHeight.h}></Mapas> : <></>}
                          {/* <PerformanceResumoMercado></PerformanceResumoMercado> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item43'>
                          {/* {activeTab === 'performanceAnaliseConcorrentes' ? (
                            <PerformanceAnaliseConcorrentes></PerformanceAnaliseConcorrentes>
                          ) : (
                            <></>
                          )} */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item49'>
                          {/* {activeTab === 'desempenhovendas' ? (
                            <PerformanceDesempenhoVendas></PerformanceDesempenhoVendas>
                          ) : (
                            <></>
                          )} */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item44'>
                          {/* {activeTab === 'performanceVendas' ? (
                            <PerformanceVendas></PerformanceVendas>
                          ) : (
                            <></>
                          )} */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item45'>
                          {/* <PerformanceVendas></PerformanceVendas> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item46'>
                          {/* {activeTab === 'performanceBrevesLancamentos' ? (
                            <PerformanceBrevesLancamentos></PerformanceBrevesLancamentos>
                          ) : (
                            <></>
                          )} */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item47'>
                          {/* {activeTab === 'mercadoRevenda' ? (
                            <PerformanceMercadoRevenda></PerformanceMercadoRevenda>
                          ) : (
                            <></>
                          )} */}
                          {/* <PerformanceMercadoRevenda></PerformanceMercadoRevenda> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item48'>
                          {/* {activeTab === 'pontosInteresse' ? (
                            <PerformancePontosInteresse></PerformancePontosInteresse>
                          ) : (
                            <></>
                          )} */}
                        </Tab.Pane>
                        <Tab.Pane eventKey='item5'>
                          {activeTab === 'avm'  ? (
                            <Avm id={Number(idProjetoURL)}></Avm>
                          ) : (
                            <></>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      :<></>}
    </>
  )
}
