import {useMutation, useQuery} from '@apollo/client'
import {GET_USER_AREA_ATUACAO,} from '../query'
import {TypesGetAreaAtuacaoResponse, TypesSetAreaAtuacaoResponse,TypesPutAreaAtuacaoResponse, TypesGetUserAreaAtuacaoResponse, TypesPutUserAreaAtuacaoResponse, TypesSetUserAreaAtuacaoResponse} from '../type'
import {DELETE_CORRETOR, PUT_CORRETOR, SET_CORRETOR} from '../mutations'
import {putCorretorFormData, putCorretorFormSchema, putUserAreaAtuacaoFormData, putUserAreaAtuacaoFormSchema, setCorretorFormData, setCorretorFormSchema, setUserAreaAtuacaoFormData, setUserAreaAtuacaoFormSchema} from '../formValidations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import { SET_AREA_ATUACAO } from '../mutations/area_atuacao'
import { PUT_USER_AREA_ATUACAO, SET_USER_AREA_ATUACAO } from '../mutations/user_area_atuacao'
import { SET_SUB_AREA_ATUACAO } from '../mutations/sub_area_atuacao'

interface QueryUserAreaAtuacaoProps {
  variables: any
}

export function QueryGetUserAreaAtuacao({variables}: QueryUserAreaAtuacaoProps) {
  const {data, loading, error} = useQuery<TypesGetUserAreaAtuacaoResponse>(GET_USER_AREA_ATUACAO, {
    variables: {...variables},
  })
  return {data, loading, error}
}

// export function QueryGetUserAreaAtuacaoByID({variables}: QueryUserAreaAtuacaoProps) {
//   const {data, loading} = useQuery<TypesGetCorretorById>(GET_CORRETOR_BY_ID, {
//     variables: {...variables},
//   })
//   return {data, loading}
// }

// export function MutationDeleteUserAreaAtuacao() {
//   const [deleteCorretor, {loading, data, error}] = useMutation<TypesDeleteCorretorResponse>(
//     DELETE_CORRETOR,
//     {
//       refetchQueries: [GET_UserAreaAtuacao, GET_CORRETOR_BY_ID],
//     }
//   )

//   async function HandleDeleteUserAreaAtuacao({variables}: QueryUserAreaAtuacaoProps) {
//     try {
//       const result = await deleteCorretor({
//         variables: {...variables},
//         refetchQueries: [GET_UserAreaAtuacao],
//       })
//       return result
//     } catch (e) {
//       if (e) {
//         return false
//       }
//     }
//   }

//   return {HandleDeleteUserAreaAtuacao, loading, error, data}
// }

export function MutationCreateUserAreaAtuacao() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<setUserAreaAtuacaoFormData>({
    resolver: zodResolver(setUserAreaAtuacaoFormSchema),
  })

  const [setBody, {error, loading, data: DataSet}] =
    useMutation<TypesSetUserAreaAtuacaoResponse>(SET_USER_AREA_ATUACAO, {
      refetchQueries: [GET_USER_AREA_ATUACAO],
    })

  async function Form(data: setUserAreaAtuacaoFormData) {
    await setBody({
      variables: {data}
    })

  }

  return {register, handleSubmit, Form, loading, errors, error, DataSet}
}


export function MutationPutUserAreaAtuacao() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<putUserAreaAtuacaoFormData>({
    resolver: zodResolver(putUserAreaAtuacaoFormSchema),
  })

  const [setBody, {error, loading, data: DataSet}] =
    useMutation<TypesPutUserAreaAtuacaoResponse>(PUT_USER_AREA_ATUACAO, {
      refetchQueries: [GET_USER_AREA_ATUACAO]
    })

  async function Form(data: putUserAreaAtuacaoFormData) {
    const id = data.id
    delete data.id
    await setBody({
      variables: data
    })

  }


  return {register, handleSubmit, Form, loading, errors, error, DataSet}
}
