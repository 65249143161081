import {z} from 'zod'

export const setEmpresaFormSchema = z.object({
  id_cidade: z.number().optional(),
  id_estado: z.number().optional(),
  nome: z.string(),
  nome_fantasia: z.string().optional(),
  endereco: z.string().optional(),
  numero: z.string().optional(),
  telefone: z.string().optional(),
  email: z.string().optional(),
  cnpj: z.string().optional(),
  cep: z.string().optional(),
  cor_fonte: z.string().optional(),
  cor_background: z.string().optional(),
  complemento: z.string().optional(),
  situacao: z.number(),
  usuario_orulo: z.string().transform((value) => (value === 'on' ? 1 : null)),
})

export type setEmpresaFormData = z.infer<typeof setEmpresaFormSchema>

export const putEmpresaFormSchema = z.object({
  id: z.number(),
  nome: z.string(),
  nome_fantasia: z.string(),
  endereco: z.string(),
  telefone: z.string(),
  email: z.string(),
  cnpj: z.string(),
  cep: z.string(),
  complemento: z.string(),
  usuario_orulo: z
    .boolean()
    .transform((value) => {
      return value === true ? 1 : null
    })
    .optional()
    .nullish(),
})

export type putEmpresaFormData = z.infer<typeof putEmpresaFormSchema>

export const setEmpresaUsuarioFormSchema = z.object({
  id_user: z.number(),
  id_empresa: z.number(),
  edicao: z.string(),
  exclusao: z.string(),
  visualizacao: z.string(),
  tipo_usuario: z.string(),
})

export type setEmpresaUsuarioFormData = z.infer<typeof setEmpresaUsuarioFormSchema>

export const putEmpresaUsuarioFormSchema = z.object({
  id: z.number(),
  id_user: z.number(),
  id_empresa: z.number(),
})

export type putEmpresaUsuarioFormData = z.infer<typeof putEmpresaUsuarioFormSchema>

export const putEmpresaUsuarioSchema = z.object({
  id: z.number(),
  id_user: z.number(),
  id_empresa: z.number(),
  visualizacao: z.string(),
  edicao: z.string(),
  exclusao: z.string(),
})

export type putEmpresaUsuarioData = z.infer<typeof putEmpresaUsuarioSchema>

export const setEmpresaProdutoFormSchema = z.object({
  id_produto: z.number(),
  id_periodo: z.number(),
  id_empresa: z.number(),
  data_inicio: z.date().optional(),
  data_fim: z.date().optional(),
})

export type setEmpresaProdutoFormData = z.infer<typeof setEmpresaProdutoFormSchema>

export const putEmpresaProdutoFormSchema = z.object({
  id: z.number(),
  id_periodo: z.number(),
  id_produto: z.number(),
  id_empresa: z.number(),
  data_inicio: z.date().optional(),
  data_fim: z.date().optional(),
})

export type putEmpresaProdutoFormData = z.infer<typeof putEmpresaProdutoFormSchema>

export const recoveryEmpresaFormSchema = z.object({
  id: z.number(),
  deleted_at: z.number(),
})

export type recoveryEmpresaFormData = z.infer<typeof recoveryEmpresaFormSchema>
