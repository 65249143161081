import React from 'react'
import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import store, {persistor} from './setup/redux/Store'
// Axios
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
// import { ErrorBoundary } from "react-error-boundary";
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {ExplorerPorvider} from './context/ExplorerContext'
// import { Error500 } from './app/modules/errors/components/Error500'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: '48e513366f5b470cef621cd322f4b5dc',
  plugins: [new BugsnagPluginReact()],
})

BugsnagPerformance.start({
  apiKey: '48e513366f5b470cef621cd322f4b5dc',
  enabledReleaseStages: ['production'],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// import { ConfirmationServiceProvider } from './context/ConfirmationServiceContext'cls
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/* const mock = */
//  _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <ExplorerPorvider>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <React.StrictMode>
            {process.env.NODE_ENV === 'production' ? (
              <ErrorBoundary>
                <App basename={PUBLIC_URL} />
              </ErrorBoundary>
            ) : (
              <></>
            )}
            {process.env.NODE_ENV === 'development' ? <App basename={PUBLIC_URL} /> : <></>}
          </React.StrictMode>
        </PersistGate>
      </ExplorerPorvider>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
