import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';

export interface PageInfo {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
}

interface PaginationProps {
    pagesInfo: PageInfo;
    setPagesInfo: (pagina: number, quantidade: number) => void;
}

const PaginationComponent: React.FC<PaginationProps> = ({ pagesInfo, setPagesInfo }) => {
    const { currentPage, totalPages, hasNextPage, hasPreviousPage } = pagesInfo;
    const [quantity, setQuantity] = useState(() => {
        const storedQuantity = localStorage.getItem('selectedQuantity');
        return storedQuantity ? parseInt(storedQuantity) : 10;
    });

    useEffect(() => {
        localStorage.setItem('selectedQuantity', String(quantity));
    }, [quantity]);

    const handleQuantityChange = (e) => {
        const selectedQuantity = parseInt(e.target.value);
        setQuantity(selectedQuantity);
    };

    useEffect(() => {
        setPagesInfo(currentPage, quantity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity, currentPage]);

    const renderPageNumbers = () => {
        const maxButtons = 5;
        let startPage = 0;
        let endPage = totalPages;

        if (totalPages > maxButtons) {
            if (currentPage < Math.ceil(maxButtons / 2)) {
                endPage = maxButtons;
            } else if (currentPage >= totalPages - Math.floor(maxButtons / 2)) {
                startPage = totalPages - maxButtons;
            } else {
                startPage = currentPage - Math.floor(maxButtons / 2);
                endPage = currentPage + Math.floor(maxButtons / 2);
            }
        }

        const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
        return pageNumbers.map((pageNumber) => (
            <li key={pageNumber} className="page-item">
                <button
                    className={pageNumber === currentPage
                        ? 'page-link btn btn-sm btnSuccess'
                        : 'page-link btn btn-sm'}
                    onClick={() => onPageChange(pageNumber)}
                >
                    {pageNumber + 1}
                </button>
            </li>
        ));
    };


    const onPageChange = (page: number, quantity?: number) => {
        setPagesInfo(page, quantity);
    };


    return (
        <nav aria-label="..." className='d-flex justify-content-start'>
            <div className='d-flex all-center'>
                Quantidade
                <select
                    className='form-control mx-5'
                    value={quantity}
                    onChange={handleQuantityChange}
                >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
            <ul className="pagination all-center">
                <li className={!hasPreviousPage ? "page-item previous disabled" : "page-item prev"}>
                    <button
                        disabled={!hasPreviousPage}
                        className={!hasPreviousPage ? 'page-link disabled' : 'page-link'}
                        onClick={() => onPageChange(0)}
                    >
                        <BiChevronsLeft className="fs-3" />
                    </button>
                </li>
                <li className={!hasPreviousPage ? "page-item previous disabled" : "page-item prev"}>
                    <button
                        disabled={!hasPreviousPage}
                        className={!hasPreviousPage ? 'page-link disabled' : 'page-link'}
                        onClick={() => onPageChange(currentPage - 1)}
                    >
                        <BiChevronLeft className="fs-3" />
                    </button>
                </li>

                {renderPageNumbers()}

                <li className="page-item next">
                    <button
                        disabled={!hasNextPage}
                        className={!hasNextPage ? 'page-link disabled' : 'page-link'}
                        onClick={() => onPageChange(currentPage + 1)}
                    >
                        <BiChevronRight className="fs-3" />
                    </button>
                </li>
                <li className="page-item next">
                    <button
                        disabled={!hasNextPage}
                        className={!hasNextPage ? 'page-link disabled' : 'page-link'}
                        onClick={() => onPageChange(totalPages)}
                    >
                        <BiChevronsRight className="fs-3" />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default PaginationComponent;
