import {PDFViewer, Document, StyleSheet, Page} from '@react-pdf/renderer'
import {Header} from './components/Header'
import {Footer} from './components/Footer'
import {ContentService} from './components/ContentService'
import {HeaderDetalhes} from './components/HeaderDetalhes'
import {
  QueryGetGruposServicosByID,
  QueryGetImovel,
  QueryGetServicosCatetorias,
} from '../../../../graphql/services'
import QRCode from 'react-qr-code'
import {useLocation, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {MapboxLayer} from '../../../../components/UI/Mapbox/MapboxLayer'
import html2canvas from 'html2canvas'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { ContentService2 } from './components/ContentService2'
import { Console } from 'console'
export function ExportFichaPdf() {
  const {uuid} = useParams<{uuid: string}>()
  const [mapImage, setMapImage] = useState(null)
  // const [mapImageServices, setMapImageServices] = useState([])
  const mapRef = useRef()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const [municipio, setMunicipio] = useState(null)
  let idCidade = null
  if (searchParams.get('idCidade')) {
    idCidade = parseInt(searchParams.get('idCidade'))
  }
  let idGrupo = null
  if (searchParams.get('idGrupo')) {
    idGrupo = parseInt(searchParams.get('idGrupo'))
  }

  const {data} = QueryGetImovel({variables: {uuid: uuid}})

  const dataServicoCategoria = QueryGetServicosCatetorias({
    variables: {
      idMunicipio: idCidade,
    },
  })
  const refLoader = useRef(null)
  useEffect(() => {
    async function getMunicipio(data) {
      const api = new APiUrbit()
      const municipio = await api.get(
        `municipios/${data.GetImovel.longitude}/${data.GetImovel.latitude}`
      )
      setMunicipio(municipio)
    }
    if (data) {
      getMunicipio(data)
    }
  }, [data])

  function onloadMap(image) {
    html2canvas(image.target.getCanvasContainer(), {useCORS: true, width: 600, height: 350}).then(
      (canvas) => {
        let map1Image = canvas.toDataURL('image/png', 1.0)
        setMapImage(map1Image)
      }
    )
  }

  
    setTimeout(()=>{
      if (mapImage ){
        try{
        document.getElementsByClassName('fakeLoader')[0]['style'].display = 'none'
        }catch{
          console.log('fakeloader não encontrado ')
        }
      }
    },15000)
    
  
  // function onloadMapServices(image, service) {
  //   let mapservice = []
  //   html2canvas(image.target.getCanvasContainer(), {useCORS: true, width: 600, height: 350}).then(
  //     async (canvas) => {
  //       let map1Image = await canvas.toDataURL('image/png', 1.0)

  //       console.log(map1Image)
  //       if (typeof mapservice[service] == 'undefined') {
  //         mapservice[service] = map1Image
  //       }
  //       if (map1Image) {
  //         mapservice[service] = map1Image
  //         console.log(service)
  //         console.log(mapservice)

  //         console.log([...mapImageServices, ...mapservice])
  //         setMapImageServices(mapservice)
  //       }
  //     }
  //   )
  // }
  
  const servicosIdGrupo = []

  if (idGrupo) {
    const {data: dataGrupo} = QueryGetGruposServicosByID({
      variables: {
        getUsuarioGrupoServicoId: idGrupo,
      },
    })
   
    if (!dataGrupo) {
      return <>Carregando grupo de serviços...</>
    }
    // eslint-disable-next-line array-callback-return
    dataGrupo?.GetUsuarioGrupoServico.servico.map((servico) => {
      servicosIdGrupo.push(servico.id)
    })

  

  }
  if (!data) {
    return <>Carregando informações do imóvel...</>
  }
  if (!municipio) {
    return <>Carregando informações do município...</>
  }

  if (!dataServicoCategoria) {
    return <>Carregando serviços...</>
  }

  const ServicosVisualizacao = []

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      paddingTop: 10,
      paddingLeft: 30,
      paddingRight: 30,
      lineHeight: 1.5,
      flexDirection: 'column',
      color: '#1d1d1deb',
      paddingBottom: 55,
    },
    logo: {
      width: 150,
    },
    aviso: {
      color: '#ff0000',
    },
  })
  function onload() {
    // console.log('load qrcode')
  }

  // const showMapServices = [
  //   'zoneamento',
  //   'operacoes-urbanas',
  //   'areas-contaminadas',
  //   'area-vulnerabilidade',
  // ]
  // const layerConfig = {
  //   zoneamento: {
  //     tiles: ['https://tile.urbit.com.br/tile/zoneamento/{z}/{x}/{y}.pbf?s=sao_paulo'],
  //     source: 'zoneamento',
  //     paint: {'fill-color': ['get', 'cor_zona'], 'fill-opacity': 0.4},
  //     layout: {
  //       'text-field': ['get', 'zona'], // Nome do atributo com o texto
  //       'text-size': 12, // Tamanho do texto
  //       'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
  //     },
  //     paintText: {
  //       'text-color': '#000000', // Cor do texto
  //     },
  //   },
  //   'areas-contaminadas': {
  //     tiles: ['https://tile.urbit.com.br/tile/areas_contaminadas/{z}/{x}/{y}.pbf?s=sao_paulo'],
  //     source: 'areas_contaminadas',
  //     paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
  //     layout: {
  //       'text-field': ['get', 'zona'], // Nome do atributo com o texto
  //       'text-size': 12, // Tamanho do texto
  //       'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
  //     },
  //     paintText: {
  //       'text-color': '#000000', // Cor do texto
  //     },
  //   },
  //   'area-vulnerabilidade': {
  //     tiles: ['https://tile.urbit.com.br/tile/geosampa_assentamentos/{z}/{x}/{y}.pbf?s=sao_paulo'],
  //     source: 'geosampa_assentamentos',
  //     paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
  //     layout: {
  //       // 'text-field': ['get', 'nome'], // Nome do atributo com o texto
  //       'text-size': 12, // Tamanho do texto
  //       'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
  //     },
  //     paintText: {
  //       'text-color': '#000000', // Cor do texto
  //     },
  //   },
  //   'risco-geologico': {
  //     tiles: ['https://tile.urbit.com.br/tile/areas_risco_geologico/{z}/{x}/{y}.pbf?s=sao_paulo'],
  //     source: 'areas_risco_geologico',
  //     paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
  //     layout: {
  //       // 'text-field': ['get', 'nome'], // Nome do atributo com o texto
  //       'text-size': 12, // Tamanho do texto
  //       'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
  //     },
  //     paintText: {
  //       'text-color': '#000000', // Cor do texto
  //     },
  //   },
  //   'area-alagamento': {
  //     tiles: ['https://tile.urbit.com.br/tile/alagamentos/{z}/{x}/{y}.pbf?s=sao_paulo'],
  //     source: 'alagamentos',
  //     paint: {'fill-color': '#FF0000', 'fill-opacity': 0.4},
  //     layout: {
  //       // 'text-field': ['get', 'nome'], // Nome do atributo com o texto
  //       'text-size': 12, // Tamanho do texto
  //       'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
  //     },
  //     paintText: {
  //       'text-color': '#000000', // Cor do texto
  //     },
  //   },
  //   'operacoes-urbanas': {
  //     tiles: [
  //       'https://tile.urbit.com.br/tile/view_explorer_operacoes_urbanas/{z}/{x}/{y}.pbf?s=sao_paulo',
  //     ],
  //     source: 'view_explorer_operacoes_urbanas',
  //     paint: {
  //       'fill-color': [
  //         'match',
  //         ['get', 'zona'],
  //         'OUC AGUA BRANCA',
  //         '#d7191c',
  //         'OUC AGUA ESPRAIADA',
  //         '#fdae61',
  //         'OUC CENTRO',
  //         '#ffffbf',
  //         'OUC FARIA LIMA',
  //         '#abdda4',
  //         'PIU Arco Jurubatuba',
  //         '#2b83ba',
  //         '#FF0000',
  //       ],
  //       'fill-opacity': 0.4,
  //     },
  //     layout: {
  //       'text-field': ['get', 'zona'], // Nome do atributo com o texto
  //       'text-size': 12, // Tamanho do texto
  //       'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
  //     },
  //     paintText: {
  //       'text-color': '#000000', // Cor do texto
  //     },
  //   },
  // }

  // console.log(mapImageServices)
  return (
    <>
      <div style={{display: 'none'}}>
        <QRCode
          id='QRCode'
          size={256}
          style={{height: 'auto', width: '150px'}}
          value={`${process.env.REACT_APP_HOST}/fichas/`}
          viewBox={`0 0 256 256`}
          onLoad={onload}
        />
      </div>
      {!mapImage ? (
        <>
          {/* {
            // eslint-disable-next-line array-callback-return
            showMapServices.map((item) => {
              
               return  <MapboxLayer
                  latLng={[data.GetImovel.latitude, data.GetImovel.longitude]}
                  inputRef={mapRef}
                  onLoad={(map) => {
                    console.log(item)
                    console.log(map)
                    onloadMapServices(map, item)
                  }}
                  options={layerConfig[item]}
                  style={{width: 'auto', height: 350}}
                ></MapboxLayer>
              
            })
          } */}
          <div className="" style={{marginLeft:-1000}}>
            <MapboxLayer
              latLng={[data.GetImovel.latitude, data.GetImovel.longitude]}
              inputRef={mapRef}
              onLoad={(map) => {
                onloadMap(map)
              }}
              options={{
                tiles: [
                  'https://tile.urbit.com.br/tile/view_explorer_lotes_acessoria/{z}/{x}/{y}.pbf?s=sao_paulo',
                ],
                source: 'view_explorer_lotes_acessoria',
                paint: {'fill-color': '#d0d0d0', 'fill-outline-color': '#000', 'fill-opacity': 0.2},
                layout: {
                  'text-field': ['get', 'endereco'], // Nome do atributo com o texto
                  'text-size': 12, // Tamanho do texto
                  'text-anchor': 'top', // Âncora do texto (pode ser 'top', 'bottom', 'center', etc.)
                },
                paintText: {
                  'text-color': '#000000', // Cor do texto
                },
              }}
              zoom={19}
              style={{width: 650, height: 350}}
            ></MapboxLayer>
          </div>
        </>
      ) : (
        <></>
      )}

      {mapImage ? (
        <>
        <div className='fakeLoader' ref={refLoader}>
        <Loader text={'Carregando...'}></Loader>
      </div>
        <div style={{width: '148rem'}}>
          <PDFViewer >
            <Document onRender={()=>{
              console.log('rendered')
            }} 
            >
              <Page size='A4' style={styles.page}>
                <Header />
                <HeaderDetalhes data={data} map={mapImage} />
                 {
                  // eslint-disable-next-line array-callback-return
                  dataServicoCategoria.GetServicoCategoria.map((categoriasServicos, index) => {
                    // eslint-disable-next-line array-callback-return
                    let servicos = categoriasServicos.servico.filter((el) => {
                      if (servicosIdGrupo.length > 0) {
                        if (servicosIdGrupo.indexOf(el.id) !== -1) {
                          return true
                        }
                      } else {
                        return true
                      }
                    })
                    return (
                      <ContentService
                        key={index}
                        titulo={categoriasServicos.nome}
                        servicos={servicos}
                        servicosVisualizacao={ServicosVisualizacao}
                        data={data}
                        municipio={municipio.municipio_url}
                      />
                    )
                  })
                } 
                <Footer />
              </Page>
            </Document>
          </PDFViewer>
          
        </div>
        </>
      ) : (
        <div className='overlay loading'>
          <div className='loading-fullscreen'>Carregando mapa...</div>
        </div>
      )}
    </>
  )
}
