import {MapStatic} from '../../../../components/Map'
import {useEmpreendimentos} from '../../context/useEmpreendimentos'

export function MapComponent() {
  const {setLaunchData, launch} = useEmpreendimentos()

  function onChangeMarker(data) {
    const latitude = data.latLng.lat().toString() // Convert to string
    const longitude = data.latLng.lng().toString() // Convert to string

    console.log(latitude)
    console.log(longitude)
    setLaunchData({
      latitude: Number(latitude),
      longitude: Number(longitude),
      geom: {
        type: 'Point',
        coordinates: [Number(longitude),Number(latitude)],
      }
  
    })
  }
  
  return <MapStatic latLng={launch ? launch.geom : null} onChange={onChangeMarker}></MapStatic>
}
