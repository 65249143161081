import {useEffect, useState} from 'react'
import {Button, Carousel, Offcanvas} from 'react-bootstrap'
import {Loader} from '../../../components/UI/Loader/Loader'
import {ApiUrbit} from '../../../../services/http/urbit'
import {Imoveis} from '../types'
type Props = {
  id: number
  nome: string
  startShow: boolean
}

const PanelAnuncio: React.FC<Props> = ({id, nome, startShow, children}) => {
  const [show, setShow] = useState(null)
  const [anuncio, setAnuncio] = useState<Imoveis>(null)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    setShow(startShow)
  }, [id, startShow])

  useEffect(() => {
    async function getAnuncio(lat, lng, id) {
      let url = `service/anuncio/${lng}/${lat}?distance=10&id=${id}`
      const imoveis = await ApiUrbit.get(url)

      if (imoveis.itens.length > 0) {
        imoveis.itens[0].imagesarray = JSON.parse(imoveis.itens[0].images)
        setAnuncio(imoveis.itens[0])
      }
    }
    getAnuncio(-46.6, -23.6, id)
  }, [id])

  function getimage(item, fonte) {
    if (fonte === 'vivareal') {
      if (item.type === 'IMAGE') {
        return item.url
          .replace('{action}', 'fit-in')
          .replace('{width}', '614')
          .replace('{height}', '297')
          .replace('{description}', 'foto')
      }
    } else if (fonte === 'imovelweb') {
      return item
    }
  }
  return (
    <>
      {/* <Button variant='primary' onClick={handleShow}>
        {nome} Ver Detalhes
      </Button> */}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h1>Anúncio</h1>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-5'>
          {!anuncio ? <Loader text={'Carregando dados do imóvel...'}></Loader> : <></>}
          {anuncio ? (
            <>
              <div className='row' style={{height: '90vh', overflow: 'scroll'}}>
                <div className='col-12 '>
                  <div className='row mb-3 d-flex flex-row  align-items-center justify-content-between'>
                    <h2 className='w-50'>{nome}</h2>
                  </div>

                  <div className='row mb-3'>
                    {/* <label className='col-12 fw-semibold text-muted'>Fotos </label> */}
                    <div className='col-lg-12'>
                      <Carousel>
                        {anuncio.images.length > 0 ? (
                          anuncio.imagesarray.map((item) => {
                            return (
                              <Carousel.Item>
                                <img
                                  alt='img1'
                                  className='d-block w-100'
                                  src={getimage(item, anuncio.fonte)}
                                />
                                {/* <Carousel.Caption>
                                    <h3>First slide label</h3>
                                    <p>
                                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                                    </p>
                                  </Carousel.Caption> */}
                              </Carousel.Item>
                            )
                          })
                        ) : (
                          <></>
                        )}
                      </Carousel>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>descricao </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.descricao || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>caracteristicas </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {anuncio.caracteristicas === '' || anuncio.caracteristicas === '[""]'
                          ? '-'
                          : anuncio.caracteristicas}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Tipo </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.tipo || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Data Coleta </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {anuncio.data_coleta || '-'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Endereço</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.endereco}</span>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Fonte</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.fonte || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Cep</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.cep || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Quartos </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.quartos || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Suítes </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.suites || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Banheiros </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.banheiros || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Vagas </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.vagas || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Área </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.area || '-'}</span>
                    </div>
                  </div>
                  <h3>Valores</h3>

                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Valor Venda </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{anuncio.valor || '-'}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Valor Aluguel </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {anuncio.valor_aluguel || '-'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Condôminio </label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {anuncio.condominio || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default PanelAnuncio
