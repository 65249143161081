import React, {useEffect, useState} from 'react'
import Map, {Layer, Marker, Source} from 'react-map-gl'
import type {FeatureCollection} from 'geojson'
import type {FillLayer, SourceProps} from 'react-map-gl'
import * as turf from '@turf/turf'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import Pin from './Pin'
import 'mapbox-gl/dist/mapbox-gl.css'

import mapboxgl from 'mapbox-gl'
import {Legenda} from '../../Legenda'
import {loadIsocrona} from '../../Common/Isocrona'
import {fit} from '../../Common/common'
import {Feature} from '../../Types'
import {BiPointer} from 'react-icons/bi'
import LegendaIsocronas from './LegendaIsocronas'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl['workerClass'] = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default


export type LayerMapbox={
    type: any
    data?: any
    tiles?: string[]
    source?: string
    paint: object
    layout: object
    paintText: object
    typeLayer: any
    legandaTitle?: string
    visibility?:string
}
type PropsContextMenu = {
  latLng: number[]

  inputRefLeganda: any
  onLoad: any
  zoom?: number
  style?: any
  areaInfluencia: any
  layers: LayerMapbox[]
}

const geojson: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
}

const layerStyle: FillLayer = {
  id: 'point',
  type: 'fill',
  paint: {
    'fill-color': '#8a8a8a',
    'fill-outline-color': '#333',
    'fill-opacity': 0.5,
  },
}

export const MapboxLayer: React.FC<PropsContextMenu> = ({
  inputRefLeganda,
  onLoad,
  latLng,
  zoom = 17,
  style = {width: 600, height: 350},
  layers,
  areaInfluencia,
}) => {


  
  return (
    <Map
      mapboxAccessToken='pk.eyJ1Ijoia29seCIsImEiOiJjajI0eHA1MzMwMDVuMzNvNWRlMDBnd2U0In0.ZPJhRHqplNUji9ZRi89wCg'
      initialViewState={{
        longitude: latLng[1],
        latitude: latLng[0],
        zoom: zoom,
        fitBoundsOptions:{
          minZoom: 17,
          maxZoom: 17,
        }
        
      }}
      style={style}
      mapStyle='mapbox://styles/kolx/cko8xwo2p07g218mpjig6a0fd'
      onIdle={async (map) => {
        onLoad(map)
      }}
      onLoad={async (map) => {
        map.target.resize()
        await fit(map, areaInfluencia)
      }}
    >
      {layers.map((options, index) => {

        return options.visibility === 'visible' ? options.type === 'vector' ? (
          <Source key={index} id={options.source} type={options.type} tiles={options.tiles}>
            <Layer
              id={`my-layer_${index}`}
              type={options.typeLayer}
              source={options.source}
              source-layer={options.source}
              paint={options.paint}
            />

            {/* <Layer
          id='text-layer'
          type='symbol'
          source={options.source}
          source-layer={options.source}
          layout={options.layout}
          paint={options.paintText}
        /> */}
            <Legenda
              style={options.paint}
              title={options.legandaTitle}
              visivel={true}
              innerRef={inputRefLeganda}
            ></Legenda>
          </Source>
        ) : (
          <Source key={index} id={'_' + index} type={options.type} data={options.data} >
            <Layer
              id={`my-layer${index}`}
              type={options.typeLayer}
              // source={options.source}
              // source-layer={options.source}
              paint={options.paint}
              layout={options.layout}
            />

            {/* <Legenda style={options.paint} title={'Renda'} visivel={true} innerRef={inputRefLeganda}></Legenda> */}
          </Source>
        )
        :<></>


      })}

      <Source id={'_area_influencia'} type={'geojson'} data={areaInfluencia}>
        <Layer
          id={`my-area-i`}
          type={'line'}
          // source={options.source}
          // source-layer={options.source}
          paint={{'line-color': '#333', 'line-width': 4, 'line-dasharray': [3, 3]}}
        />

        {/* <Legenda style={options.paint} title={'Renda'} visivel={true} innerRef={inputRefLeganda}></Legenda> */}
      </Source>
      <Marker longitude={latLng[1]} latitude={latLng[0]} scale={0.5}></Marker>
      <LegendaIsocronas setLegendaDistancia={() => {}}></LegendaIsocronas>
      {/* <div className='legenda-left' style={{right: '61px'}} >
            <ul className='list'>
              <li>
                <div className='box-legenda' style={{border: '3px dotted #094e08'}}></div>
                <div> 5min</div>
              </li>
              <li>
                <div className='box-legenda' style={{border: '3px dotted #e10c0c'}}></div>
                <div>10 min</div>
              </li>
            </ul> 
          </div> */}
    </Map>
  )
}
