import React, { createContext, useState, useCallback, ReactNode, useEffect } from 'react';
import { getLaunchByID, getLaunchesPartners, getTypologiesByID } from "../../../../../services/http/urbit-gis/routes";
import { RealEstateLaunch, RealEstateLaunchPartners, TypologyData } from "../../../../../services/http/urbit-gis/routes/types";

interface EmpreendimentosContextType {
  launch: RealEstateLaunch | null;
  partnersLaunch: RealEstateLaunchPartners[] | null; 
  typologies: TypologyData[];
  fetchLaunchByID: (id: number) => Promise<void>;
  setLaunchData:(data:RealEstateLaunch)=> void
  fetchTypologies: (id: number, pagina: number, quantidade: number) => Promise<void>;
  fetchPartnersLaunch: (pagina: number, quantidade: number) => void
  refetchLaunch: (id: number) => Promise<void>;
  refetchTypologies: (id: number) => Promise<void>;
  loading: boolean;
  error: string | null;
}

const EmpreendimentosContext = createContext<EmpreendimentosContextType | undefined>({} as EmpreendimentosContextType);

const EmpreendimentosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [launch, setLaunch] = useState<RealEstateLaunch | null>(null);
  const [partnersLaunch, setPartnersLaunch] = useState<RealEstateLaunchPartners[] | null>([]);

  const [typologies, setTypologies] = useState<TypologyData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  // console.log(launch)


  const fetchLaunchByID = async (id: number) => {
    try {
      const launch = await getLaunchByID(id);
      setLaunch(launch);
    } catch (error) {
      console.error('Error fetching launch:', error);
      setError('Erro ao buscar lançamento. Tente novamente mais tarde.');
    } 
  };

  const fetchPartnersLaunch = useCallback(async (pagina: number, quantidade: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getLaunchesPartners( pagina, quantidade);
      // console.log(response)
      setPartnersLaunch(response.data);
    } catch (error) {
      setError('Houve um erro ao buscar os dados. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchTypologies = useCallback(async (id: number, pagina: number, quantidade: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getTypologiesByID(id, pagina, quantidade);
      setTypologies(response.data);
    } catch (error) {
      setError('Houve um erro ao buscar os dados. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  }, []);
    
  useEffect(() => {
    // Chama fetchPartnersLaunch com parâmetros padrão quando o componente for montado
    fetchPartnersLaunch(1, 1500); // exemplo de parâmetros
  }, [fetchPartnersLaunch]);

  const refetchLaunch = async (id: number) => {
    await fetchLaunchByID(id);
  };

  const refetchTypologies = async (id: number) => {
    await fetchTypologies(id, 1, 100); // Exemplo com página 1 e quantidade 100
  };

  function setLaunchData(data:RealEstateLaunch){

    // console.log('setLaunchData')
    // console.log(data)
    setLaunch({...launch,...data})
  }
  return (
    <EmpreendimentosContext.Provider
      value={{
        launch,
        partnersLaunch,
        setLaunchData,
        typologies,
        fetchLaunchByID,
        fetchPartnersLaunch,
        fetchTypologies,
        refetchLaunch,
        refetchTypologies,
        loading,
        error
      }}
    >
      {children}
    </EmpreendimentosContext.Provider>
  );
};

const useEmpreendimentos = () => {
  const context = React.useContext(EmpreendimentosContext);
  if (typeof context === undefined) {
    throw new Error('useEmpreendimentos must be used within an EmpreendimentosProvider');
  }
  return context;
};

export { EmpreendimentosProvider, useEmpreendimentos };
