import {gql} from '@apollo/client'

export const GET_MERCADO_SECUNDARIO = gql`
  query GetMercadoSecundario($pagination: Pagination!, $filter: filter) {
    GetMercadoSecundario(pagination: $pagination, filter: $filter) {
      result {
        id
        json
        nome
        situacao
        id_usuario_cadastro
        data_cadastro
        id_usuario_alteracao
        data_alteracao
        id_usario_exclusao
        data_exclusao
        terreno{
          id
          nome
        }
        empreendimento {
          id
          nome
        }
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_MERCADO_SECUNDARIO_BY_ID = gql`
  query GetMercadoSecundarioById($getMercadoSecundarioById: Float!) {
    GetMercadoSecundarioById(id: $getMercadoSecundarioById) {
      id
      json
      nome
      situacao
      id_usuario_cadastro
      data_cadastro
      id_usuario_alteracao
      data_alteracao
      id_usario_exclusao
      data_exclusao
      terreno{
        id
        nome
      }
      empreendimento {
        id
        nome
      }
    }
  }
`

export const GET_MERCADO_SECUNDARIO_VSO = gql`
  query GetMercadoSecundarioVSO($pagination: Pagination) {
    GetMercadoSecundarioVSO(pagination: $pagination) {
      result {
        id
        vso
        situacao
        id_usuario_cadastro
        data_cadastro
        id_usuario_alteracao
        data_alteracao
        id_usario_exclusao
        data_exclusao
        nome
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_MERCADO_SECUNDARIO_VSO_BY_ID = gql`
  query GetMercadoSecundarioVSOById($getMercadoSecundarioVsoByIdId: Float) {
    GetMercadoSecundarioVSOById(id: $getMercadoSecundarioVsoByIdId) {
      id
      vso
      situacao
      id_usuario_cadastro
      data_cadastro
      id_usuario_alteracao
      data_alteracao
      id_usario_exclusao
      data_exclusao
      nome
    }
  }
`
