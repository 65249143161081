import React, {useEffect} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { useAuth } from './components/Context/authcontext'

export function Logout() {

  const { setAuthenticated } = useAuth()

  useEffect(() => {
    localStorage.clear()
    secureLocalStorage.clear();
    setAuthenticated(false)
  })

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
