import {Modal} from 'react-bootstrap'
import {MutationCreateImovelSituacaoCliente} from '../../../../graphql/services/ImovelSituacaoCliente'
import {ErrorMessageComponent} from '../../../../backoffice/components/UI/ErrorMessage'
import Swal from 'sweetalert2'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {MutationSetLogAcessoRecurso} from '../../../../graphql/services/LogAcesso'
import {ColorPicker} from './ColorPicker'
import {useState} from 'react'
import {RgbToHex} from '../../../explorer/components/common'

interface situacaoTerrenoProps {
  closeModal: any
  show: boolean
}

export function CreateForm({closeModal, show}: situacaoTerrenoProps) {
  const [color, setColor] = useState(null)

  const {FormImovelSituacaoCliente, loading, handleSubmit, register, errors} =
    MutationCreateImovelSituacaoCliente()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    data.cor = color
    FormImovelSituacaoCliente(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'cadastro',
          funcionalidade: 'situacao-terrenos',
          action: 'create',
          url: '/cadastro/situacao-terrenos',
        })
        Swal.fire('Sucesso!', 'Situacao dos terrenos criada com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // Tratativa de loading
  if (loading) {
    return <Loader text={'caregando...'}></Loader>
  }

  function onChange(color) {
    setColor(RgbToHex(color[0], color[1], color[2]))
  }
  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Nova Situação de Terreno</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            className='form-control form-control-solid'
            placeholder='id'
            hidden={true}
            {...register('id', {
              pattern: /^[0-9]+$/i,
            })}
          />
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-6'>
                <label htmlFor='nome' className='required form-label'>
                  Nome:
                </label>
                <input
                  className='form-control form-control-solid'
                  placeholder='Nome da Situação'
                  defaultValue={''}
                  {...register('nome', {
                    pattern: /^[A-Za-z]+$/i,
                  })}
                />
                {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}
              </div>
            </div>
            <div className='col-md-12'>
              <div className='mb-6'>
                <label htmlFor='descricao' className='required form-label'>
                  Descrição:
                </label>
                <textarea
                  style={{resize: 'none'}}
                  rows={4}
                  className='form-control form-control-solid'
                  placeholder='Descrição da Situação'
                  defaultValue={''}
                  {...register('descricao', {
                    pattern: /^[A-Za-z]+$/i,
                  })}
                />
                {errors.descricao && <ErrorMessageComponent message={errors.descricao?.message} />}
              </div>
            </div>
            <div className='mb-15 col-12'>
              <div className=''>
                <label htmlFor='descricao' className='required form-label'>
                  Cor
                </label>
                <ColorPicker hex={null} onChange={onChange}></ColorPicker>
                {errors.cor && <ErrorMessageComponent message={errors.cor?.message} />}
              </div>
            </div>
            <div className='mb-6 d-flex'>
              <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                <label>
                  <input
                    className=' form-check-input'
                    type='checkbox'
                    id='status'
                    defaultChecked={true}
                    {...register('status', {required: false})}
                  />
                  Ativado
                </label>
                {errors.status && <ErrorMessageComponent message={errors.status?.message} />}
              </div>
            </div>
            <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
              <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
                Fechar
              </button>
              <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
