import {Nav} from 'react-bootstrap'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {useImoveisStore} from '../context/DataContext'

export function TabItens({windowHeight, setActiveTab}) {
  const {endereco} = useImoveisStore()
  // const {id: idProjetoURL} = useParams<{id: string}>()
  
  return (
    <Scrollbars style={{width: '100%', height: windowHeight - 110}}>
      <Nav variant='pills' className='flex-column m-0 nav-im'>
        <Nav.Item className='m-0'>
          <Nav.Link
            eventKey='item1'
            className='fs-6'
            onClick={() => {
              setActiveTab('zonaDeValor')
            }}
          >
            1. Definir área
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item2'
            className='fs-6'
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('definiramostras')
            }}
          >
            2. Definição da amostra
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item3'
            className='fs-6'
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('analisegrafica')
            }}
          >
            3. Análise Gráfica
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item4'
            className='fs-6'
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('tabeladinamica')
            }}
          >
            4. Tabela Dinâmica
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item41'
            className='fs-6'
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('sociodemografico')
            }}
          >
            5. Sociodemográfico
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item7'
            className='fs-6'
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('mapas')
            }}
          >
            6. Mapas
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item5'
            className='fs-6'
            disabled={endereco ? false : true}
            onClick={() => {
              setActiveTab('avm')
            }}
          >
            7. AVM<br></br>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Scrollbars>
  )
}
