import React from 'react'

import {Link} from 'react-router-dom'
import {FaHospitalUser, FaServer, FaCaretRight} from 'react-icons/fa'
import { MutationSetLogAcessoRecurso } from '../../../../../app/graphql/services/LogAcesso'
import { BiArrowBack, BiArrowFromRight, BiArrowToRight, BiRightArrow } from 'react-icons/bi'

export function MenuIM() {
const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  return (
    <>
    
      <div className='m-0'>

      <h1 className='mb-5'>Inteligência de Mercado</h1>
      {/* <span className='menu-link'>
        <span className='menu-icon'>
          <i className='ki-duotone ki-address-book fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        </span>
        <span className='menu-title'>Mercado Primário</span>
        <span className='menu-arrow'></span>
      </span> */}

        <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold'>
          <li className='nav-item mt-5'>
            <Link
              to='/inteligencia-mercado'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'corretores', action: 'list', url: '/cadastro/corretores' })}
              className='sub-menu-secondary nav-link text-dark text-active-primary  p-0 border-0 '
            >
              {/* <BiRightArrow></BiRightArrow> */}
              {/* <FaHospitalUser 
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              /> */}
              Mercado Primário - Lançamentos
            </Link>
          </li>
        
          <li className='nav-item mt-2'>
            <Link
              to='/mercado-secundario/venda'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'mecado-secundario', funcionalidade: 'mecado-secundario', action: 'list', url: '/mercado-secundario/venda' })}
              className='sub-menu-secondary nav-link text-dark text-active-primary  p-0 border-0 '
            >
              {/* <FaHospitalUser
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              /> */}
              Mercado Secundário - Venda
            </Link>
          </li>
         
          
        </ul>
      </div>
    </>
  )
}
