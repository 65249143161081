import {gql} from '@apollo/client'

export const SET_MERCADO_SECUNDARIO = gql`
  mutation SetMercadoSecundario($data: mercadoSecundario!) {
    SetMercadoSecundario(data: $data) {
      id
    }
  }
`

export const PUT_MERCADO_SECUNDARIO = gql`
  mutation PutMercadoSecundario($data: mercadoSecundario!, $putMercadoSecundarioId: Float!) {
    PutMercadoSecundario(data: $data, id: $putMercadoSecundarioId) {
      id
    }
  }
`

export const DELETE_MERCADO_SECUNDARIO = gql`
  mutation DeleteMercadoSecundario($deleteMercadoSecundarioId: Float!) {
    DeleteMercadoSecundario(id: $deleteMercadoSecundarioId) {
      id
      nome
    }
  }
`

export const SET_MERCADO_SECUNDARIO_VSO = gql`
  mutation SetMercadoSecundarioVSO($mercadoSecundarioVso: vso) {
    SetMercadoSecundarioVSO(mercadoSecundarioVSO: $mercadoSecundarioVso) {
      id
    }
  }
`

export const PUT_MERCADO_SECUNDARIO_VSO = gql`
  mutation PutMercadoSecundarioVSO($mercadoSecundarioVso: vso) {
    PutMercadoSecundarioVSO(mercadoSecundarioVSO: $mercadoSecundarioVso) {
      id
    }
  }
`

export const DELETE_MERCADO_SECUNDARIO_VSO = gql`
  mutation DeleteMercadoSecundarioVSO($mercadoSecundarioVso: vso) {
    DeleteMercadoSecundarioVSO(mercadoSecundarioVSO: $mercadoSecundarioVso) {
      id
    }
  }
`
