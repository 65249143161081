import {useContext, useEffect, useMemo, useState} from 'react'
import {DataContext} from '../../context/DataContext'
import {formatNumber} from '../../../explorer/components/common'
import {HeaderPerformance} from '../Common/HeaderPerformance'
import {ExportTable} from '../../../../utils/ExportXLSX'
import {BiExport, BiInfoCircle} from 'react-icons/bi'
import {MaisInfoEmpreendimentos} from '../MaisInfoEmpreendimentos'
import TableContainer from '../../../../components/UI/TableContainer'
import {
  DefaultColumnFilter,
  FilterRange,
  RangeColumnFilter,
} from '../../../../components/UI/TableContainer/filters'
import {MapViewDesempenhoVendas} from '../MapViewDesempenhoVendas'
import {FilterContext} from '../../context/FilterContext'
import SelectDormitorios from '../UI/SelectDormitorios'
import secureLocalStorage from 'react-secure-storage'

type Props = {
  label?: string
}
type PropMenu = {
  show: boolean
  id: number
}
export function PerformanceVendas({label}: Props) {
  const {data, vsoIdeal} = useContext(DataContext)
  const {endereco, geometriaSelecionada} = useContext(FilterContext)
  const [filterText, setFilterText] = useState('')
  const [dormitorios, setDormitorios] = useState(1)
  const [dataMapa, setDataMapa] = useState([])
  const [show, setShow] = useState<PropMenu>({
    show: false,
    id: null,
  })
  let buffer = 2.5
  // const TextField = styled.input`
  //   height: 32px;
  //   width: 200px;
  //   border-radius: 3px;
  //   border-top-left-radius: 5px;
  //   border-bottom-left-radius: 5px;
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  //   border: 1px solid #e5e5e5;
  //   padding: 0 32px 0 16px;

  //   &:hover {
  //     cursor: pointer;
  //   }
  // `
  //     let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //     return row.vso >= vsoIdeal[fase].min && row.vso <= vsoIdeal[fase].max ? row.vso : false
  //

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Filter: (row) => {
          // console.log(row)

          // if (filterText){
          //           row.columns[1].filterValue = filterText
          //           // row.columns[1].setFilter('id',filterText )
          // }
          return DefaultColumnFilter(row, filterText)
        },
      },
      {
        Header: () => null,
        id: 'expander', // 'id' is required
        Cell: ({row}) => {
          return (
            <button
              onClick={() => {
                setShow({
                  show: true,
                  id: row.original.id_empreendimento_orulo,
                })
              }}
              className='btn btn-icon-info btn-text-info'
            >
              <BiInfoCircle></BiInfoCircle>
            </button>
          )
        },
      },
      {
        Header: 'Nome',
        accessor: 'nome',
      },
      {
        Header: 'Incorporadora',
        accessor: 'incorporadora',
      },
      // {
      //   Header: 'Bairro',
      //   accessor: 'bairro',
      // },
      // {
      //   Header: 'Endereço',
      //   accessor: 'endereco',
      // },
      {
        Header: 'Data Lanç.',
        accessor: 'data_lancamento',
        sortType: (rowA, rowB, columnId, desc) => {
          var dateString = rowA.values[columnId] // Oct 23
          var dateParts = dateString.split('/')
          var dateObjectA = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

          var dateStringB = rowB.values[columnId] // Oct 23
          var datePartsB = dateStringB.split('/')
          var dateObjectB = new Date(+datePartsB[2], datePartsB[1] - 1, +datePartsB[0])

          // console.log(rowA.values[columnId])
          // console.log(rowB.values[columnId])
          // const dateA = new Date(rowA.values[columnId]);

          // console.log(dateA)
          // const dateB = new Date(rowB.values[columnId]);
          // console.log(dateB)
          return dateObjectA > dateObjectB ? 1 : -1
        },
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>Área Privativa</div>,
        accessor: 'private_area',
        Cell: ({row}) => (
          <div style={{textAlign: 'center'}}>
            {' '}
            {formatNumber(row.original.private_area, 'inteiro')}
          </div>
        ),
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>Quartos</div>,
        accessor: 'bedrooms',
        Cell: ({row}) => (
          <div style={{textAlign: 'center'}}> {formatNumber(row.original.bedrooms, 'inteiro')}</div>
        ),
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>Vagas</div>,
        accessor: 'vagas',
        Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.vagas}</div>,
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>Total unidades</div>,
        accessor: 'total_units',
        Cell: ({row}) => (
          <div style={{textAlign: 'center'}}>
            {' '}
            {formatNumber(row.original.total_units, 'inteiro')}
          </div>
        ),
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },

      {
        Header: () => <div style={{textAlign: 'center'}}>VSO</div>,
        accessor: 'vso',
        Cell: ({row}) => {
          let fase = Math.round((Number(row.original.fase_projeto) * 36) / 100)
          if (row.original.vso >= vsoIdeal[fase].vso) {
            return (
              <div
                style={{
                  textAlign: 'center',
                  backgroundColor: 'rgba(63, 195, 128, 0.9)',
                  color: 'white',
                  padding: 13,
                }}
              >
                {' '}
                {formatNumber(row.original.vso, 'inteiro')}
              </div>
            )
          } else if (
            row.original.vso >= vsoIdeal[fase].vso - buffer &&
            row.original.vso <= vsoIdeal[fase].vso + buffer
          ) {
            return (
              <div
                style={{
                  textAlign: 'center',
                  backgroundColor: 'rgba(248, 148, 6, 0.9)',
                  color: 'white',
                  padding: 13,
                }}
              >
                {' '}
                {formatNumber(row.original.vso, 'inteiro')}
              </div>
            )
          } else {
            return (
              <div
                style={{
                  textAlign: 'center',
                  backgroundColor: 'rgba(242, 38, 19, 0.9)',
                  color: 'white',
                  padding: 13,
                }}
              >
                {' '}
                {formatNumber(row.original.vso, 'inteiro')}
              </div>
            )
          }
        },
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>% Diferença</div>,
        accessor: 'diferenca',
        Cell: ({row}) => {
          //  let fase = Math.round((Number(row.original.fase_projeto) * 36) / 100)

          //  formatNumber(row.original.vso, 'inteiro')
          return <div style={{textAlign: 'center'}}> {row.original.diferenca + '%'}</div>
        },
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>Estoque</div>,
        accessor: 'stock',
        Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.stock}</div>,
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },

      {
        Header: () => <div style={{textAlign: 'center'}}>Preço m²</div>,
        accessor: 'preco_m2',
        Cell: ({row}) => (
          <div style={{textAlign: 'right'}}> {formatNumber(row.original.preco_m2, 'currency')}</div>
        ),
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>Preço Total</div>,
        accessor: 'original_price',
        Cell: ({row}) => {
          return (
            <div style={{textAlign: 'right'}}>
              {' '}
              {formatNumber(row.original.original_price, 'currency')}
            </div>
          )
        },
        Filter: RangeColumnFilter,
        filter: FilterRange,
        className: 'text-center',
      },
      {
        Header: () => <div style={{textAlign: 'center'}}>Distância</div>,
        accessor: 'distancia',
        Cell: ({row}) => {
          return (
            <div style={{textAlign: 'center'}}>
              {' '}
              {Number(formatNumber(row.original.distancia, 'inteiro'))}
            </div>
          )
        },
        Filter: RangeColumnFilter,
        filter: FilterRange,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterText]
  )

  // const subHeaderComponentMemo = useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle)
  //       setFilterText('')
  //     }
  //   }

  //   return (
  //     <div style={{marginTop: 15}}>
  //       <TextField
  //         id='search'
  //         type='text'
  //         placeholder='Filtrar pelo nome ou incorporadora'
  //         aria-label='Search Input'
  //         value={filterText}
  //         onChange={(e) => setFilterText(e.target.value)}
  //         autoFocus
  //       />
  //       <Button
  //         type='button'
  //         onClick={handleClear}
  //         className=''
  //         style={{padding: '5px 12px 5px 12px'}}
  //       >
  //         X
  //       </Button>
  //     </div>
  //   )
  // }, [TextField, filterText, resetPaginationToggle])
  useEffect(() => {
    let tableData = []
    console.log(dormitorios)
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      // eslint-disable-next-line array-callback-return
      item.tipologias.map((itemTipologia) => {
        if (itemTipologia.computed) {
          if (Number(itemTipologia.bedrooms) === dormitorios || dormitorios === 0) {
            let fase = Math.round((Number(item.fase_projeto) * 36) / 100)
            let valorDif = ((36 * (vsoIdeal[fase].vso - itemTipologia.vendas_perc_tip)) / 100) * -1

            tableData.push({
              id: itemTipologia.id_tipologia,
              id_empreendimento: item.id_empreendimento,
              id_empreendimento_orulo: item.id_empreendimento_orulo,
              nome: item.nome,
              incorporadora: item.nome_incorporador,
              data_lancamento: item.data_lancamento,
              private_area: itemTipologia.private_area,
              bedrooms: itemTipologia.bedrooms,
              total_units: itemTipologia.total_units,
              vso: Number(itemTipologia.vendas_perc_tip),
              stock: itemTipologia.stock,
              vagas: itemTipologia.parking,
              vendas_perc_tip: Number(itemTipologia.vendas_perc_tip),
              original_price: itemTipologia.original_price,
              fase_projeto: item.fase_projeto,
              fase_projeto_ideal: item.fase_projeto_ideal,
              distancia: item.distancia,
              preco_m2: itemTipologia.original_price / itemTipologia.private_area,
              diferenca: valorDif,
              geom: item.geom,
            })
          }
        }
      })
    })

    setDataMapa(tableData)
  }, [data, dormitorios, vsoIdeal])

  if (!data) {
    return <>Carregando...</>
  }

  // let columnsData = [
  //   {
  //     name: 'Info',
  //     center: true,
  //     cell: (row) => {
  //       return (
  //         <button
  //           onClick={() =>
  //             setShow({
  //               show: true,
  //               id: row.id_empreendimento,
  //             })
  //           }
  //           className='btn btn-icon-info btn-text-info'
  //         >
  //           <BiInfoCircle></BiInfoCircle>
  //         </button>
  //       )
  //     },
  //     // sortable: true,
  //     // wrap: true,
  //     width: '100px',
  //     // allowGroups: true,
  //   },
  //   {
  //     name: 'Empreendimento',
  //     selector: (row) => row.nome,
  //     sortable: true,
  //     wrap: true,
  //     width: '240px',
  //   },
  //   {
  //     name: 'Incorporadora',
  //     selector: (row) => row.incorporadora,
  //     sortable: true,
  //     wrap: true,
  //     // width: '300px',
  //   },
  //   {
  //     name: 'Lançando em',
  //     selector: (row) => row.data_lancamento,
  //     sortable: true,
  //   },
  //   {
  //     name: 'Área Privativa',
  //     selector: (row) => row.private_area,
  //     sortable: true,
  //     right: true,
  //   },
  //   {
  //     name: 'Nº Quartos',
  //     selector: (row) => row.bedrooms,
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: 'Vagas',
  //     selector: (row) => row.vagas,
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: 'TT Uni.',
  //     selector: (row) => row.total_units,
  //     sortable: true,
  //     center: true,
  //   },

  //   {
  //     name: 'VSO',
  //     selector: (row) => row.vso,
  //     sortable: true,
  //     right: true,
  //     conditionalCellStyles: [
  //       {
  //         when: (row) => {
  //           let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //           if (row.vso >= vsoIdeal[fase].vso) {
  //             return row.vso
  //           }
  //         },
  //         style: {
  //           backgroundColor: 'rgba(63, 195, 128, 0.9)',
  //           color: 'white',
  //           '&:hover': {
  //             cursor: 'pointer',
  //           },
  //         },
  //       },
  //       // {
  //       //   when: (row) => {
  //       //     let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //       //     return row.vso >= vsoIdeal[fase].min && row.vso <= vsoIdeal[fase].max ? row.vso : false
  //       //   },
  //       //   style: {
  //       //     backgroundColor: 'rgba(248, 148, 6, 0.9)',
  //       //     color: 'white',
  //       //     '&:hover': {
  //       //       cursor: 'pointer',
  //       //     },
  //       //   },
  //       // },
  //       {
  //         when: (row) => {
  //           let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //           return row.vso < vsoIdeal[fase].vso
  //         },
  //         style: {
  //           backgroundColor: 'rgba(242, 38, 19, 0.9)',
  //           color: 'white',
  //           '&:hover': {
  //             cursor: 'not-allowed',
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     name: '% Diferença',
  //     selector: (row) => {
  //       let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //       return (36 * (vsoIdeal[fase].vso - row.vso)) / 100 + '%'
  //     },
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: 'Mês',
  //     selector: (row) => {
  //       let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //       return fase
  //     },
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: 'Desempemho',
  //     selector: (row) => {
  //       let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //       if (row.vso >= vsoIdeal[fase].vso) {
  //         return 'Acima'
  //       }
  //       fase = Math.round((Number(row.fase_projeto) * 36) / 100)
  //       if (row.vso < vsoIdeal[fase].vso) {
  //         return 'Abaixo'
  //       }

  //       return 1
  //     },
  //     sortable: true,
  //     center: true,
  //   },

  //   {
  //     name: 'Estoque',
  //     selector: (row) => row.stock,
  //     sortable: true,
  //     center: true,
  //   },
  //   {
  //     name: 'Ticket Médio',
  //     selector: (row) => formatNumber(row.original_price, 'currency'),
  //     sortable: true,
  //     right: true,
  //   },
  //   {
  //     name: 'Distância',
  //     selector: (row) => formatDistancia(row.distancia),
  //     sortable: true,
  //     right: true,
  //   },
  //   // {
  //   //   name: '% fase_projeto ',
  //   //   selector: (row) => row.fase_projeto,
  //   //   sortable: true,
  //   //   center: true,
  //   // },
  //   // {
  //   //   name: 'fase_projeto mes',
  //   //   selector: (row) => row.fase_projeto_ideal,
  //   //   sortable: true,
  //   //   center: true,
  //   // },
  // ]

  function exportar() {
    let exportaFinal = dataMapa.map((item) => {
      delete item.fase_projeto_ideal
      return item
    })
    ExportTable(exportaFinal, '', 'Performance Vendas.xlsx')
  }

  function onChangeDormitorio(event) {
    setDormitorios(Number(event.target.value))
  }
  let usuarioOrulo = secureLocalStorage.getItem('usuario_orulo')
  
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Vendas'}>
          <div className='col-12 d-flex flex-row justify-content-end align-items-center pb-5'>
            <div className='pe-5 pt-2'>
              <h6>Dormitórios</h6>
            </div>
            <SelectDormitorios onChange={onChangeDormitorio} defaultValue={1}></SelectDormitorios>

            {usuarioOrulo ? <button className='btn btn-primary pull-right ms-5' onClick={exportar}>
              <BiExport></BiExport> Exportar
            </button> : <></>}
          </div>
        </HeaderPerformance>
        <div className='content pt-5'>
          <div className='row g-5 g-xl-8'>
            <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance pt-5'>
              <MapViewDesempenhoVendas
                data={dataMapa}
                layer={[]}
                zoom={14}
                height='500px'
                center={{lat: endereco.latitude, lng: endereco.longitude}}
                geometria={geometriaSelecionada}
                onClickMarker={(id) => {
                  setFilterText(id + '')
                }}
              ></MapViewDesempenhoVendas>

              <div className=' col-12 p-5 '>
                <TableContainer columns={columns} data={dataMapa}></TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MaisInfoEmpreendimentos
        setShow={setShow}
        show={show}
        height={'calc(100vh)'}
      ></MaisInfoEmpreendimentos>
    </>
  )
}
