import { gql } from "@apollo/client";

// Criação de um empreendimento com campos e com ID do usuario
export const SET_EMPREENDIMENTO = gql`
mutation SetEmpreendimentos($empreendimentos: empreendimentos!) {
  SetEmpreendimentos(empreendimentos: $empreendimentos) {
    id
    nome
  }
}
`
// Criação de um empreendimento com campos e com ID do usuario
export const CREATE_EMPREENDIMENTOS_IMOVEIS = gql`
mutation SetEmpreendimentosImovel($empreendimentos: empreendimentos!) {
  SetEmpreendimentosImovel(empreendimentos: $empreendimentos) {
    id
    nome
    uuid
    empreendimento_terreno {
      id
      nome
      uuid
    }
  }
}
`


// Deletar um empreendimento pelo ID
export const DELETE_EMPREENDIMENTO = gql`
mutation DeleteEmpreendimento($deleteEmpreendimentoId: Float!,$motivo: String! ) {
  DeleteEmpreendimento(id: $deleteEmpreendimentoId, motivo: $motivo) {
    nome
  }
}
`

// Ediar um empreendimento
export const PUT_EMPREENDIMENTO = gql`
mutation PutEmpreendimento($empreendimentos: empreendimentos!) {
  PutEmpreendimento(empreendimentos: $empreendimentos) {
    nome
    id
  }
}
`

export const GET_EMPREENDIMENTOS_RELATORIO = gql`query GetEmpreendimentosRelatorio($filtro: filter) {
  GetEmpreendimentosRelatorio(filtro: $filtro) {
    areaTotalTerreno
    areaTotalPrivativa
    totalEmpreendimentos
    totalTerrenos
    totalMediaTerrenosEmpreendimentos
    vgv
    vgvMedio
  }
}`
