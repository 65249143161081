import MapView from '../partials/Map'
import {useImoveisStore} from '../context/DataContext'

interface MapVendaProps {
  height: number
}

const MapVenda = ({height}: MapVendaProps) => {
  const {imoveis} = useImoveisStore()
  return (
    <>
      <MapView height={height} imoveis={imoveis}></MapView>
    </>
  )
}

export default MapVenda
