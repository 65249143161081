import {Polygon} from '@react-google-maps/api'
import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import * as turf from '@turf/turf'

import {APiUrbit} from '../../../../../services/api/apiUrbit'
import {
  CloseLoaderInfoOverlay,
  LoaderInfoOverlay,
} from '../../../../../components/UI/Loader/LoaderOverlay'
import { TBairros, TBairrosInputAPI } from '../../../../inteligencia-mercado/components/Types'
import { useImoveisStore } from '../../../context/DataContext'
import { CoordinatesToPath } from '../../../../explorer/public/MapView'


const options = {
  fillColor: 'lightgreen',
  fillOpacity: 0.1,
  strokeColor: 'green',
  strokeOpacity: 1,
  strokeWeight: 3,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

const optionsEditable = {
  fillColor: 'lightred',
  fillOpacity: 0.6,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

const optionsSelected = {
  fillColor: 'green',
  fillOpacity: 0.4,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}
const api = new APiUrbit()
function ZonaDeValor({
  map,
  lat,
  lng,
  valor,
}: {
  map: any
  lat: number
  lng: number
  valor: number[]
}) {

  const {setGeometria,imoveis, fetchData,setFiltro,filtro, setGeometriaSelecionada,updateData,endereco} = useImoveisStore()
  const [bairrosGeom, setBairrosGeom] = useState<TBairros[]>()
  const polygonRef = useRef(null)
  const listenersRef = useRef([])
  const onEdit = useCallback(
    (el) => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return {lat: latLng.lat(), lng: latLng.lng()}
          })
        // setPath(nextPath);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setBairrosGeom]
  )
  const onLoadPoligon = useCallback(
    (polygon) => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )

  async function atualizaSelecionados(data, geomBairros) {
    let geomBairrosSelecionados = []

    if (geomBairros) {
      // eslint-disable-next-line array-callback-return
      geomBairros.map((el) => {
        if (el.selected) {
          geomBairrosSelecionados.push(el.geojson) //,geomBairrosSelecionados)
        }
      })
      
      if (geomBairrosSelecionados.length > 0) {
        let geomFinal = geomBairrosSelecionados.reduce(function (union, el) {
          return turf.union(union, el)
        })

        let newData = data.filter((imovel) => {
          var pt1 = {
            type: 'Feature',
            properties: {},
            geometry: imovel.geom,
          }

          if (turf.inside(pt1 as turf.Coord, geomFinal)) {
            imovel.intersect = true
            imovel.active = false
          } else {
            imovel.intersect = false
            imovel.active = true
          }

          return imovel
        })
        if (geomFinal.type === 'MultiPolygon') {
          for (var i = 0; i < geomFinal.coordinates.length; i++) {
            var polygon = {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates: geomFinal.coordinates[i],
              },
            }
          }
          setGeometriaSelecionada({...polygon})
        } else {
          setGeometriaSelecionada({...geomFinal})
        }
        return newData
      }
    }
  }

  async function click(e: google.maps.MapMouseEvent, el: TBairros) {
    // LoaderInfoOverlay('Carregando...')
    let geomFiltro = []
    let bairrosSelecionados = []
    // eslint-disable-next-line array-callback-return
    let bairrosGeomNew = bairrosGeom.map((item: TBairros) => {
      if (el.selected && item.id === el.id) {
        item.selected = false
      } else {
        if (item.id === el.id) {
          item.selected = true
        }
      }
      if (item.selected) {
        geomFiltro.push(item.geojson)
        bairrosSelecionados.push(item.id)
      }

      return item
    })

    // loadImoveis(geomFinal)
    // if (geomFiltro.length > 0) {
    //   let geomFinal = geomFiltro.reduce(function (union, el) {
    //     return turf.union(union, el)
    //   })

    // }
    setFiltro({...filtro, ...{areaInfluencia: {tipo: 'ZONAVALOR', valor: bairrosSelecionados}}})
    
    
    
    setBairrosGeom([...bairrosGeomNew])

    // let newData = await atualizaSelecionados(imoveis, bairrosGeomNew)
    // updateData(newData)
    // CloseLoaderInfoOverlay()
    // atualizaSelecionados()

    
  }

  async function loadZonaDeValor(lat, lng, map) {
    if (map) {
      map.panTo(new google.maps.LatLng(lat, lng))
      map.setZoom(13)
    }
    const bairros = await api.get(`service/bairros/${lng}/${lat}?distance=200`)
    let geomFiltro = []
    // eslint-disable-next-line array-callback-return
    let bairrosWithGeojson = bairros.itens.map((bairro: TBairrosInputAPI) => {
      let geom = JSON.parse(bairro.geojson)
      var options = {tolerance: 0.0002, highQuality: true}
      var simplified = turf.simplify(geom, options)
      bairro.geojson = simplified
      bairro.editable = false
      bairro.selected = valor ? (valor.indexOf(bairro.id) !== -1 ? true : false) : false
      bairro.paths = CoordinatesToPath(bairro.geojson['coordinates'][0][0])
      geomFiltro.push(bairro.geojson)
      return bairro
    })

    let geomFinal = geomFiltro.reduce(function (union, el) {
      return turf.union(union, el)
    })
    
    var polygon = {
      type: 'Feature',
      properties: {},
      geometry: geomFinal,
    }

    setBairrosGeom(bairrosWithGeojson)
    setGeometria(polygon)
    fetchData(polygon, 'ZONAVALOR'
    //  , atualizaSelecionados, bairrosWithGeojson
    )
    // atualizaSelecionados()

    return bairrosWithGeojson
  }

  useEffect(() => {
    if (endereco.latitude && endereco.longitude && map) {
      loadZonaDeValor(endereco.latitude, endereco.longitude, map)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endereco, map])

  useEffect(() => {
    if (endereco.latitude && endereco.longitude && map) {
      loadZonaDeValor(endereco.latitude, endereco.longitude, map)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* {edit.edit ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button
            className='btn btn-primary'
            onClick={handleSave}
            style={{position: 'absolute', bottom: '20px'}}
          >
            Salvar Geometria
          </button>
        </div>
      ) : (
        ''
      )} */}
      {bairrosGeom
        ? bairrosGeom.map(function (el: TBairros) {
            return (
              <Polygon
                onLoad={onLoadPoligon}
                paths={el.paths}
                key={el.id}
                options={el.editable ? optionsEditable : el.selected ? optionsSelected : options}
                onDblClick={(e) => {
                  // dbClick(e, el, el.id)
                }}
                // onClick={(e) => {
                //   click(e, el)
                // }}
                // Event used when manipulating and adding points
                onMouseUp={onEdit}
                // Event used when dragging the whole Polygon
                // onDragEnd={onEdit}
              />
            )
          })
        : ''}
    </>
  )
}

export default ZonaDeValor
