import * as turf from '@turf/turf'
export function formatValores(valor) {
  if (valor < 1000000) {
    return valor / 1000 + 'Mil'
  } else if (valor >= 1000000) {
    return valor / 1000000 + 'MI'
  }
}

export function CoordinatesToPath(geom) {
  let paths = geom.map(function (el) {
    return {lat: el[1], lng: el[0]}
  })

  return paths
}

export function debounce(fn: any, ms: number) {
  let timer: any = ''
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, ms)
  }
}

export const customStylesTable = {
  headRow: {
    style: {
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      // borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
    removed: {
      backgroundColor: '#ffcad3',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
}

export function isAllEmpty(array) {
  return array.every(function (item) {
    return item === undefined || item === null || item === ''
  })
}

export async function fit(map, geojson) {
  if (geojson) {
    const envelope = turf.bbox(geojson)
    map.target.fitBounds(
      [
        [envelope[0], envelope[1]],
        [envelope[2], envelope[3]],
      ],
      {padding: 50}
    )
  }
  // callback(map)
}
