import {AxiosHttpClientAdapter} from '../../../infra/adapters/axios-adapter'
import {HttpClient} from '../../../infra/adapters/type'

export type ApiResult = {
  itens: any[]
  service: string
  total: number
  message?: string
}
export class APiUrbit {
  host: string = 'https://api.urbit.com.br/'
  bearer: string =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZDBhODAxNDYwODVjMjA5Zjg1NTVjNWYiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2ODE0MDk0NjR9.PUObCxMX4TyDPyCesyytKG1wW849Dfnt_h_yPDbrRP8'
  clienteConect: any
  // static host = 'https://api.urbit.com.br/'
  constructor(httpClientRequest: HttpClient) {
    this.clienteConect = httpClientRequest
  }
  init() {
    //api
    // this.setHost()
    return []
    // setHor
  }
  setBearer(bearer) {
    this.bearer = bearer
  }
  setHost() {
    // this.host = 'https://api.urbit.com.br/'
  }

  async request(method: string, url: string, data = '') {
    let body = data ? JSON.stringify(data) : null
    const result = await this.clienteConect.request({
      url: this.host + url,
      method: method,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${this.bearer}`,
      },
      body,
    })

    if (result.statusCode === 200) {
      return result.body
    } else {
      return {service: 'error', message: `Erro status: ${result.status}`, itens: [], total: 0}
    }
  }

  async get(url: string, callback?: any) {
    if (typeof callback == 'function') {
      fetch(this.host + url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.bearer}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            return null
          }
        })
        .then((data) => {
          if (!data || typeof data === 'undefined') {
            callback({service: 'erro', message: data, itens: [], total: 0})
          } else {
            callback(data)
          }
        })
        .catch((err) => {
          callback({service: 'error', message: err.message, itens: [], total: 0})
        })
    } else {
      try {
        return this.request('GET', url)
      } catch (error) {
        console.error('Error:', error)
        return {service: 'error', message: error, itens: [], total: 0}
      }
    }
  }

  async getServico(
    servico: string,
    lat: number,
    lng: number,
    municipio: string,
    distancia?: number,
    callback?: any,
    intersect?: string
  ): Promise<ApiResult> {
    distancia = !distancia ? 500 : distancia

    // console.log(callback)
    // console.log(intersect)
    if (servico !== '') {
      let urlParam = `municipio/[MUNICIPIO]/[SERVICO]/[LONGITUDE]/[LATITUDE]?distance=[DISTANCIA]`
        .replace('[LONGITUDE]', String(lng))
        .replace('[LATITUDE]', String(lat))
        .replace('[SERVICO]', servico)
        .replace('[MUNICIPIO]', municipio)
        .replace('[DISTANCIA]', distancia + '')

      if (intersect) {
        urlParam = urlParam + '&intersect=' + intersect
      }
      if (typeof callback == 'function') {
        fetch(this.host + urlParam, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.bearer}`,
            // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZTBmZTA2NzkzMmVkODQyODY5OTZjYjMiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2Mjg2OTUwNjR9.qJmY1qf0ioySnj0S9QizKfCSmL3jPYemPGJgEni60Kc',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json()
            } else {
              return null
            }
          })
          .then((data) => {
            if (!data || typeof data === 'undefined') {
              callback({service: 'erro', message: data, itens: [], total: 0})
            } else {
              callback(data)
            }
          })
          .catch((err) => {
            callback({service: 'error', message: err.message, itens: [], total: 0})
          })
      } else {
        try {
          const result = await this.request('GET', urlParam)
          if (typeof result.message !== 'undefined') {
            return {service: 'error', message: result.message, itens: [], total: 0}
          } else {
            return result
          }
        } catch (error) {
          console.error('Service:', servico)
          console.error('Error:', error)
          return {service: 'error', message: error, itens: [], total: 0}
        }
      }
    } else {
      return {service: 'error', message: null, itens: [], total: 0}
    }
  }

  async post(url: string, dataInput: any, callback?: any) {
    if (typeof callback == 'function') {
      fetch(this.host + url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${this.bearer}`,
          // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZTBmZTA2NzkzMmVkODQyODY5OTZjYjMiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2Mjg2OTUwNjR9.qJmY1qf0ioySnj0S9QizKfCSmL3jPYemPGJgEni60Kc',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(dataInput),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            return null
          }
        })
        .then((data) => {
          if (!data || typeof data === 'undefined') {
            callback({service: 'erro', message: data, itens: [], total: 0})
          } else {
            callback(data)
          }
        })
        .catch((err) => {
          callback({service: 'error', message: err.message, itens: [], total: 0})
        })
    } else {
      return this.request('POST', url, JSON.stringify(dataInput))
      //   let data = null
      //   try {
      //     const response = await fetch(this.host + url, {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${this.bearer}`,
      //       },
      //       body: JSON.stringify(dataInput),
      //     })
      //     data = await response.json()
      //   } catch (error) {
      //     data = {service: 'error', message: error, itens: [], total: 0}
      //     console.error('Error:', error)
      //   }

      //   return data
    }
  }
}

export const ApiUrbit = new APiUrbit(new AxiosHttpClientAdapter())
