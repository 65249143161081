import React, {useEffect} from 'react'
import ImageUploading, {ImageListType} from 'react-images-uploading'
import {UploadImageTerreno} from '../../../../../../services/api'
import {GET_IMOVEL_IMAGENS} from '../../../../../graphql/query/imovel-imagens'
import {GetImovelImagensResponse} from '../../../../../graphql/type/imovel-imagens'
import {useQuery} from '@apollo/client'
import {Loader} from '../../../../../components/UI/Loader/Loader'

export function TabFotos({id, param}) {
  const [images, setImages] = React.useState([])
  const maxNumber = 69
  const {data} = useQuery<GetImovelImagensResponse>(GET_IMOVEL_IMAGENS, {
    variables: {idImovel: id},
  })
  useEffect(() => {
    let imagensList = []
    if (data) {
      // eslint-disable-next-line array-callback-return
      data.GetImovelImagens.map((image, index) => {
        imagensList.push({dataURL: image.caminho_thumb})
      })
      setImages(imagensList)
    }
  }, [data, setImages])
  if (!data) {
    return <Loader text={'Carregando imagens'}></Loader>
  }

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    imageList.forEach(async (item) => {
      let returnData = await UploadImageTerreno(id, item.dataURL)
      if (returnData.message === 'ok') {
        setImages(imageList)
      }
    })
  }
  function removeList(index) {
    images.splice(index)
    setImages([...images])
  }

  return (
    <div
      className={`tab-pane fade ${param === 'fotos' ? 'show active' : ''}`}
      id='fotos'
      role='tabpanel'
      aria-labelledby='fotos'
    >
      <div className=''>
        <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber}>
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-title'>Fotos</h3>
                <div className='card-toolbar'>
                  <button
                    className='btn btnSuccess btn-round pr-10'
                    style={isDragging ? {color: 'red'} : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Enviar imagem
                  </button>{' '}
                  &nbsp;
                  {/* <button onClick={onImageRemoveAll}  className='btn btnDanger btn-round'>Remove all images</button> */}
                </div>
              </div>
              <div className='card-body p-0 pt-5'>
                <div className='upload__image-wrapper'>
                  <div className='row'>
                    {
                      // data.GetImovelImagens.map((image, index) => (
                      images
                        ? images.map((image, index) => (
                            <div className='col-md-4' key={index}>
                              <div className='card  overlay  image-item w-100'>
                                <div className='card-body p-0'>
                                  <div className='overlay-wrapper'>
                                    <img
                                      src={image.dataURL}
                                      alt=''
                                      // alt={`Tamanho:${image.size}`}
                                      height='140'
                                      className='h-100 w-90 rounded'
                                    />
                                    {/* <img src="assets/media/stock/600x400/img-1.jpg" alt="" className="w-100 rounded"/> */}
                                  </div>
                                  <div className='overlay-layer bg-dark bg-opacity-25'>
                                    {/* <button
                                onClick={() => onImageUpdate(index)}
                                className='btn btn-primary btn-shadow'
                              >
                                Atualizar
                              </button> */}
                                    <button
                                      onClick={() => {
                                        onImageRemove(index)
                                        removeList(index)
                                      }}
                                      className='btn btn-light-primary btn-shadow ms-2'
                                    >
                                      Remover
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </ImageUploading>
        {/* {({ imageList, onImageUpload, onImageRemoveAll, errors }) => (
          errors && <div>
            {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
            {errors.acceptType && <span>Your selected file type is not allow</span>}
            {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
            {errors.resolution && <span>Selected file is not match your desired resolution</span>}
          </div>
        )} */}
      </div>
    </div>
  )
}
