
export const styleM2 = {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['to-number', ['get', 'preco_m2']],
      2000,
      '#FF3333',
      4000,
      '#FF3333',
      6000,
      '#FF9933',
      8000,
      '#FFFF33',
      10000,
      '#99FF33',
      12000,
      '#33FF33',
      14000,
      '#33FF99',
      16000,
      '#33FFFF',
      18000,
      '#3399FF',
    ],
    'circle-radius': 15,
    'circle-opacity': 1,
  }

  export const  styleTicket = {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['to-number', ['get', 'valor']],
      255000,
      '#FF3333',
      350000,
      '#FF3333',
      500000,
      '#FF9933',
      750000,
      '#FFFF33',
      1000000,
      '#33FF33',
      1500000,
      '#33FFFF',
      10000000,
      '#3399FF'
    ],
    'circle-radius': 15,
    'circle-opacity': 1,
  }