import { useEffect, useState } from "react"

type Props = {
  itens: {
    id: number
    name: string
  }[]
  onChange:any
}


export function ListCheck({itens,onChange}: Props) {

  
  const [tokens, setTokens] = useState([]);
  
  useEffect(()=>{
    onChange(tokens)
  },[onChange, tokens])

  return (
    <div className='container p-5 mb-5' id="list-check">
      <h3 className='mb-5 ms-0'>O que vc gostaria de fazer ?</h3>
      {itens.map((item) => {
        return (<div className='form-check form-check-custom form-check-solid mb-3 fs-6' key={item.id}>
              <input
                className='form-check-input'
                type='checkbox'
                value={item.id}
                id={`listcheck${item.id}`}
                onChange={(e)=>{
                  let index = tokens.findIndex((i)=> i === item.id )
                  if (e.target.checked){
                    if ( index === -1 ){
                      tokens.push(item.id)
                      setTokens([...tokens])
                    }
                  }else{
                    if ( index ){
                      tokens.splice(index,1)
                    }
                  }
                }}
              />
              <label className='form-check-label' htmlFor={`listcheck${item.id}`}>
                {item.name}
              </label>
            </div>
        )
      })}
    </div>
  )
}
