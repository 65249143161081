import {Modal} from 'react-bootstrap'
import {useState} from 'react'
import {BiListCheck} from 'react-icons/bi'
import { Dicionario } from '../../../../../utils/Dicionario'


interface QueryGetCorretores {
  list: any
}

export function ModalServices({list}: QueryGetCorretores) {
  const [show, setShow] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  return (
    <>
      <span className=' badge badge-secondary ms-3' onClick={openModal}>
        <BiListCheck></BiListCheck>{' '}
      </span>
      <Modal centered animation show={show} onHide={() => closeModal()} size='lg' fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Resultado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='table table-striped'>
            <thead>
              <tr>
                {
                  // eslint-disable-next-line array-callback-return
                  list ? (
                    list.length > 0 ? (
                      // eslint-disable-next-line array-callback-return
                      Object.entries(list[0]).map((col, index) => {
                        if (col[0] !== 'id' && col[0] !== 'geom') {
                          return <th key={index}>{Dicionario.translate(col[0])}</th>
                        }
                      })
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <tr key={index}>
                    {Object.entries(item).map((col, indexCol) => {
                      if (col[0] !== 'id' && col[0] !== 'geom') {
                        return <td  key={indexCol}>{col[1]}</td>
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  )
}
