import {useParams} from 'react-router-dom'
import {Tab, Tabs} from 'react-bootstrap'
import {TabMercadoSecundarioVenda} from './MercadoSecundarioVenda/Index'
import {TabInteligenciaMercado} from './InteligenciaMercado/Index'

type Props = {
  param: string
}

export function TabProjetosAssociados({param}: Props) {
  const {id: idTerreno} = useParams<{id: string}>()

  return (
    <div
      className={`tab-pane fade ${param === 'projetos-associados' ? 'show active' : ''}`}
      id='projetos-associados'
      role='tabpanel'
      aria-labelledby='projetos-associados'
    >
      <Tabs defaultActiveKey='profile' id='justify-tab-example' className='mb-3 gap-2'>
        <Tab eventKey='longer-tab' title='Inteligência de Mercado' tabClassName='p-5 fs-5'>
          <TabInteligenciaMercado idTerreno={idTerreno}></TabInteligenciaMercado>
        </Tab>
        <Tab eventKey='home' title='Mercado secundário - Venda' tabClassName='p-5 fs-5'>
          <TabMercadoSecundarioVenda idTerreno={idTerreno}></TabMercadoSecundarioVenda>
        </Tab>
      </Tabs>
    </div>
  )
}
