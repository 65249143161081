import { useParams } from 'react-router-dom'
import { TableHistory } from './components/Table'
import { useEffect, useRef, useState } from 'react'
import { PageInfo } from '../../../../../components/UI/PaginationComponents'
import { TypologyData, TypologyPrice } from '../../../../../../services/http/urbit-gis/routes/types'
import {
  getTypologyByID,
  getTypologyPrices,
} from '../../../../../../services/http/urbit-gis/routes'
import { BiAddToQueue } from 'react-icons/bi'
import { useEmpreendimentos } from '../../context/useEmpreendimentos'
import { ModalHistoryCreate } from './components/create'
import { ModalHistoryUpdate } from './components/update'
import { DropdownFilters } from './components/TooltipFilters'

export function TabHistory() {
  const { id: initialId } = useParams<{ id: string }>()
  const { typologies, fetchTypologies } = useEmpreendimentos()
  const [dataTypologyPrices, setDataTypologyPrices] = useState<TypologyPrice[]>([])
  // const [pageInfoTypologyPrices, setPageInfoTypologyPrices] = useState<PageInfo>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [paginacao, setPaginacao] = useState({ pagina: 1, quantidade: 10 })
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [showUpdate, setShowUpdate] = useState<boolean>(false)
  const [activeTabID, setActiveTabID] = useState<number | null>(undefined)
  const [activeTab, setActiveTab] = useState<TypologyData | null>(null)
  const [priceUpdateID, setPriceUpdateID] = useState(null)
  const [filter, setFilter] = useState([])
  const prevActiveTabIDRef = useRef(null);
  useEffect(() => {
    if (initialId) {
      fetchTypologies(parseInt(initialId), paginacao.pagina, paginacao.quantidade)
    }
  }, [initialId, paginacao, fetchTypologies])

  useEffect(() => {
    if (typologies) {
      if (typologies.length > 0) {
        if (!activeTabID ) {
          setActiveTabID(typologies[0].id)
        }else{
          const currentTab = typologies.find((tab) => tab.id === activeTabID)
          setActiveTab(currentTab || null)
        }
      }
    }
  }, [typologies, activeTabID])

  useEffect(() => {
    if (activeTabID) {
      fetchTypologiesPrices(activeTabID)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabID, filter])

  useEffect(() => {
    if (prevActiveTabIDRef.current !== null && prevActiveTabIDRef.current !== activeTabID) {
      setFilter([]);
    }

    prevActiveTabIDRef.current = activeTabID;
  }, [activeTabID]);

  async function fetchTypologiesPrices(idTypology: number) {
    try {
      setLoading(true)

      const typologies_Prices = await getTypologyPrices(
        idTypology,
        {
          page: paginacao.pagina,
          limit: paginacao.quantidade,
          filtro: {
            fields: filter,
          },
        })

      setDataTypologyPrices(typologies_Prices.data)
      // setPageInfoTypologyPrices(typologies_Prices.pagination)
    } catch (error) {
      setError('Erro ao buscar lançamentos.')
    } finally {
      setLoading(false)
    }
  }

  const fetchDataTypology = async (id: number) => {
    try {
      const result = await getTypologyByID(id);
      setActiveTab(result);
      // console.log('Tipologia Refetch:' + activeTab)
    } catch (error) {
      console.error('Failed to fetch typology data', error);
      // Handle error appropriately
    }
  };

  const handleRefetchTypologiesPrices = () => {
    if (activeTabID) {
      fetchTypologiesPrices(activeTabID)
      fetchDataTypology(activeTabID)
    }
  }

  function CloseModalCreate() {
    setShowCreate(false)
  }

  function OpenModalCreate(id: number | null) {
    setShowCreate(true)
  }

  function CloseModalUpdate() {
    setShowUpdate(false)
  }

  function OpenModalUpdate(id: number | null) {
    setShowUpdate(true)
  }

  return (
    <div
      className='tab-pane fade'
      id='tab-historico'
      role='tabpanel'
      aria-labelledby='tab-historico-tab'
    >
      <div className='card'>
        <div className='card-body pt-2'>
          {typologies ? (
            typologies.length > 0 ? (
              <>
                <div className='card-header px-0'>
                  <div className='col-12 d-flex flex-wrap all-center-between'>
                    <div>
                      <label htmlFor='' className='col-form-label me-2'>
                        Tipologia:
                      </label>
                      <select
                        className='form-select form-select-solid radius-2  py-2'
                        value={activeTabID}
                        onChange={(e) => {
                          setActiveTabID(parseInt(e.target.value))
                        }}
                      >
                        {typologies.map((response) => (
                          <option key={response.id} value={response.id}>
                            {response.type} - Dormitorios {response.bedrooms} - Vagas{' '}
                            {response.parking}
                          </option>
                        ))}
                      </select>
                    </div>



                    <div className='card-toolbar' data-kt-buttons='true'>
                      {/* <div style={{ position: 'relative' }}>
                        <DropdownFilters setFilter={setFilter} />
                      </div> */}

                      <button
                        className='btn btn-sm btn-light-primary'
                        onClick={(e) => {
                          e.preventDefault()
                          OpenModalCreate(activeTabID)
                        }}
                      >
                        <BiAddToQueue className='fs-2 me-1' />
                        Cadastrar
                      </button>
                    </div>
                  </div>
                </div>
                <div className='tab-content' id='pills-tabContent'>
                  <div className='table-responsive'>
                    <div className='row m-0'>
                      <div className='col-12'>
                        {typologies.map((response, index) => (
                          <div
                            key={response.id}
                            className={`tab-pane fade ${activeTabID === response.id ? 'show active' : ''
                              }`}
                            id={`pills-content-${index}`}
                            role='tabpanel'
                            aria-labelledby={`pills-tab-${index}`}
                          >
                            {activeTabID === response.id && (
                              <TableHistory
                                setId={setPriceUpdateID}
                                setShowUpdate={setShowUpdate}
                                handleRefetch={handleRefetchTypologiesPrices}
                                dataTypologyPrices={dataTypologyPrices}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      {/* <div className="col-3">
                      <div className='px-4 py-4'>
                        <FeaturesHistory InfoTabActive={activeTab} />
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1 className='fts-1-5 text-center'>Nenhuma tipologia adicionada</h1>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      {showCreate && (
        <ModalHistoryCreate
          area={activeTab?.private_area}
          stockUnits={activeTab?.stock}
          sales={activeTab?.sales}
          totalUnits={activeTab?.total_units}
          handleRefetch={handleRefetchTypologiesPrices}
          closeModal={CloseModalCreate}
          show={showCreate}
        />
      )}
      {showUpdate && (
        <ModalHistoryUpdate
          area={activeTab.private_area}
          stockUnits={activeTab.stock}
          sales={activeTab.sales}
          totalUnits={activeTab.total_units}
          handleRefetch={handleRefetchTypologiesPrices}
          idTypology={activeTabID}
          id={priceUpdateID}
          closeModal={CloseModalUpdate}
          show={showUpdate}
        />
      )}
    </div>
  )
}
