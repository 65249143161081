import {useMutation, useQuery} from '@apollo/client'
import {GET_USER_AREA_ATUACAO, GET_USER_SUB_AREA_ATUACAO,} from '../query'
import {TypesGetAreaAtuacaoResponse, TypesSetAreaAtuacaoResponse,TypesPutAreaAtuacaoResponse, TypesGetSubAreaAtuacaoResponse, TypesSetUserSubAreaAtuacaoResponse} from '../type'
import {DELETE_CORRETOR, PUT_CORRETOR, SET_CORRETOR, SET_USER_SUB_AREA_ATUACAO} from '../mutations'
import {putCorretorFormData, putCorretorFormSchema, setCorretorFormData, setCorretorFormSchema, setUserSubAreaAtuacaoFormData, setUserSubAreaAtuacaoFormSchema} from '../formValidations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import { SET_AREA_ATUACAO } from '../mutations/area_atuacao'
import { PUT_USER_AREA_ATUACAO, SET_USER_AREA_ATUACAO } from '../mutations/user_area_atuacao'
import { SET_SUB_AREA_ATUACAO } from '../mutations/sub_area_atuacao'

interface QueryUserSubAreaAtuacaoProps {
  variables: any
}

export function QueryGetUserSubAreaAtuacao({variables}: QueryUserSubAreaAtuacaoProps) {
  const {data, loading, error} = useQuery<TypesGetSubAreaAtuacaoResponse>(GET_USER_SUB_AREA_ATUACAO, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function MutationCreateUserSubAreaAtuacao() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<setUserSubAreaAtuacaoFormData>({
    resolver: zodResolver(setUserSubAreaAtuacaoFormSchema),
  })

  const [setBody, {error, loading, data: DataSet}] =
    useMutation<TypesSetUserSubAreaAtuacaoResponse>(SET_USER_SUB_AREA_ATUACAO, {
      refetchQueries: [GET_USER_SUB_AREA_ATUACAO],
    })

  async function Form(data: setUserSubAreaAtuacaoFormData) {

   return await setBody({
      variables:  {data}
    })

  }

  return {register, handleSubmit, Form, loading, errors, error, DataSet}
}

