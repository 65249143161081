import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { QueryGetCorretores } from '../../../../graphql/services/Corretores'
import { FormLabel } from 'react-bootstrap'

export function GetCorretoresChosen({
  SetValueCorretor,
  valueCorretor
}) {
  let corretoresArray = []

  const [corretor, setCorretor] = useState('')

  useEffect(() => {
    setCorretor(valueCorretor)
  }, [valueCorretor])

  const { data } = QueryGetCorretores({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      }
    }
  })



  if (!data) {
    return (
      <>
        <div className='form-group'>
        <FormLabel className='form-label'>Corretores</FormLabel>
          {/* <label className='col-form-label'>Corretores Disponiveis:</label> */}
          <Select
            options={corretoresArray}
            onChange={(e) => {
              setCorretor(e.value)
            }}
            value={corretoresArray.filter((e) => e.value)[0]}
            name='id_corretor'
            placeholder='Corretores Disponiveis'
          />
        </div>
      </>
    )
  }
  corretoresArray.push({ value:'', label: '--selecione--' })
  data.GetCorretores.result.map((Options) => {
    corretoresArray.push({ value: Options.id, label: Options.nome })
    return []
  })



  return (
    <>
      <div className='form-group'>
      <FormLabel className='form-label'>Corretores</FormLabel>
        {/* <label className='col-form-label'>Corretores Disponiveis:</label> */}
        <Select
          value={corretoresArray.find((e) => e.value === corretor) || null}
          options={corretoresArray}
          onChange={(e) => {
            setCorretor(e.value)
            SetValueCorretor(e.value)
          }}
          name='id_corretor'
          placeholder='Corretores'
        />
      </div>
    </>
  )
}
