import {gql} from '@apollo/client'

export const GET_EMPREENDIMENTO_IMOVEIS = gql`
  query GetEmpreendimentoImovel(
    $idEmpreendimento: Float!
    $filtro: filter
    $pagination: Pagination
  ) {
    GetEmpreendimentoImovel(
      id_empreendimento: $idEmpreendimento
      filtro: $filtro
      pagination: $pagination
    ) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        uuid
        nome
        endereco
        area_total_terreno
        hash_id
        area_total_privativa
        vgv
        preco_medio_m2
        responsavel
        arquiteto
        tag
        parceiro
        parceiro_nome
        parceiro_percentual
        parceiro_percentual_proprio
        terreno_id
        incorporadora_id
        situacao
        empreendimento_id
        terreno {
          id
          nome
          endereco
          numero
          complemento
          bairro
          cidade
          estado
          cep
          numero_contribuinte
          codigo_contribuinte
          nome_contribuinte
          area_iptu
          area_real
          zoneamento
          setor_quadra
          uso_atual_terreno
          codlog
          fpr
          fpnr
          cabas
          camax
          quadro14
          testada
          operacao_urbana
          data_cadastro
          latitude
          longitude
          marcacao
          id_cidade
          terreno_situacao_cliente{
            id
            nome
            cor
          }
        }
      }
    }
  }
`
