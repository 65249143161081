import {useState} from 'react'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {DateTimToView} from '../../../../../../components/util'
import {BiLinkExternal} from 'react-icons/bi'
import {QueryGetInteligenciaMercado} from '../../../../../../graphql/services/inteligencia-mercado'

type Props = {
  idTerreno: string
}

export function TabInteligenciaMercado({idTerreno}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paginacao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  const {data} = QueryGetInteligenciaMercado({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade,
      },
      filter: {
        fields: [
          {
            field: ['id_terreno'],
            operator: 'IN',
            value: [idTerreno],
          },
        ],
      },
    },
  })
  if (!data) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <div className='card p-0'>
      <div className='card-header'>
        <h3 className='card-title'>Estudos de Inteligência de Mercados</h3>
        <div className='card-toolbar'>
          <a href='/inteligencia-mercado' className='btn btn-sm btn-light-primary'>
            Novo Estudo
          </a>
        </div>
      </div>
      <div className='card-body p-0 py-3'>
        <div className='table-responsive'>
          <table className='table table-striped table-row-dashed  gs-6 gy-4 table-row-bordered  table-hover table-row-gray-300 align-middle'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-140px'>Data Cadastro</th>
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {data
                ? data.GetInteligenciaMercado.result.map((Response, item) => {
                    return (
                      <tr key={Response.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {Response.nome}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {/* {Response.usuario_cadastro.nome} */}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {DateTimToView(Response.data_cadastro)}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              href={`/inteligencia-mercado/${Response.id}`}
                              target='_blank'
                              className='btn btn-icon btn-light-success btn-sm me-2'
                              rel='noreferrer'
                            >
                              <BiLinkExternal></BiLinkExternal>
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                : ''}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
