import { z } from "zod"

export const setUserAreaAtuacaoFormSchema = z.object({
    id_user: z.number(),
    id_area_atuacao: z.number(),
    situacao: z.number(),
}).array()

export type setUserAreaAtuacaoFormData = z.infer<typeof setUserAreaAtuacaoFormSchema>

export const putUserAreaAtuacaoFormSchema = z.object({
    id: z.number(),
    id_user: z.number(),
    id_area_atuacao: z.number(),
    situacao: z.number(),
})

export type putUserAreaAtuacaoFormData = z.infer<typeof putUserAreaAtuacaoFormSchema>