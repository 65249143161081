import {useState} from 'react'
import {Button, Offcanvas} from 'react-bootstrap'
import {BiBookOpen, BiLink} from 'react-icons/bi'
import { QueryGetEmpreendimentoById } from '../../graphql'
import { Loader } from './Loader/Loader'


type Props = {
  id: number
  nome: string
}
const PanelEmpreendimento: React.FC<Props> = ({id, nome, children}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const data = QueryGetEmpreendimentoById({variables: {idEmpreendimento: id}})

  return (
    <>
      <Button variant='info' className='ms-2 pulse' onClick={handleShow}>
        {nome} <BiBookOpen />
        <span className='pulse-ring ' style={{height: 'auto'}}></span>
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h1>Empreendimento</h1>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-5'>
          {!data ? <Loader text={'Carregando dados do empreendimento...'}></Loader> : <></>}
          {data ? (
            <div className='row'>
              <div className='col-12 '>
                <div className='row mb-7 d-flex flex-row  align-items-center justify-content-between'>
                  <h2 className='w-50'>{nome}</h2>
                  <button className='btn btn-info w-25'>
                    Abrir <BiLink></BiLink>
                  </button>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>Empreendimento</label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>
                      {data.GetEmpreendimento.nome}
                    </span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>Área total do terreno</label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>
                      {data.GetEmpreendimento.area_total_terreno}m²
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>
                    Área privativa do terreno
                  </label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>
                      {data.GetEmpreendimento.area_total_privativa}m²
                    </span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>Endereço</label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>
                      {data.GetEmpreendimento.endereco}
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>Tags</label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>{data.GetEmpreendimento.tag}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>Situação</label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>
                      {data.GetEmpreendimento.situacao_id}
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <h5>Valores</h5>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>Preço m²</label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>
                      {data.GetEmpreendimento.preco_medio_m2}m²
                    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-semibold text-muted'>VGV</label>
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>{data.GetEmpreendimento.vgv}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default PanelEmpreendimento
