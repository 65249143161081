import {InputHTMLAttributes, useRef} from 'react'
import {ErrorMessageComponent} from '../../../backoffice/components/UI/ErrorMessage'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  register?: any
  error?: string
  hide?: boolean
}

export function FormInputAndLabel({label, hide, register, error, ...props}: InputProps) {
  const myRef = useRef(null)
    return (
      <div className={hide ? 'hide' : 'form-group'} hidden={hide}>
        {label ? <label className={hide ? 'd-none' : 'col-form-label'}>{label}</label> : ''}
        <input ref={myRef}  refs={myRef} {...props} {...register} />
        {error && <ErrorMessageComponent message={error} />}
      </div>
    )
 
}
