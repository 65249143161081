import { gql } from "@apollo/client";

export const SET_USER_SUB_AREA_ATUACAO = gql`
 mutation SetUsuarioSubAreaAtuacao($data: [usuarioSubAreaAtuacao!]!) {
  SetUsuarioSubAreaAtuacao(data: $data) {
    id
    id_sub_area_atuacao
    id_user
    situacao
  }
}
`

export const PUT_USER_SUB_AREA_ATUACAO = gql`
mutation PutUserSubAreaAtuacao($userareaatuacao: userareaatuacao!, $putUserAreaAtuacaoId: Float!) {
  PutUserSubAreaAtuacao(userareaatuacao: $userareaatuacao, id: $putUserAreaAtuacaoId) {
    id
    nome
  }
}
`

export const DELETE_USER_SUB_AREA_ATUACAO = gql`
mutation DeleteUserAreaAtuacao($deleteUserAreaAtuacaoId: Float!) {
  DeleteUserAreaAtuacao(id: $deleteUserAreaAtuacaoId) {
    nome
  }
}
`