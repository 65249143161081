import {Modal, Spinner} from 'react-bootstrap'

import Swal from 'sweetalert2'
import {useEffect,  useState} from 'react'

import {FormInputMaskAndLabel} from '../../../components/UI/Inputs/FormInputMaskAndLabel'
import {Loader} from '../../../components/UI/Loader/Loader'
import {
  MutationPutProprietario
} from '../../../graphql/services/Proprietarios'
import {FormInputAndLabel} from '../../../components/UI/Inputs/FormInputAndLabel'
import {GetEstadoCidadeChosen} from '../../../components/UI/Chosens/GetEstadoCidadeChosen'
import TableContainer from '../../../components/UI/TableContainer'
import {ModalPH3aData} from './ModalPH3aData'

interface proprietarioProps {
  closeModal: any
  show: boolean
  origin: 'Pessoas' | 'Imoveis' | 'Empreendimentos'
  params?: Params
}
interface Params {
  id?: number
  endereco?: string
  cep?: string
  number?: number
  name?: string
}

export function CreateForm({closeModal, show, origin, params}: proprietarioProps) {
  const [personType, setPersonType] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [documento, setDocumento] = useState('')
  const [resultFindPeaple, setResultFindPeaple] = useState([])
  const [erroSyncVerify, setErrorVerify] = useState('')
  const [syncSuccess, setSyncSuccess] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataSync, setDataSync] = useState<any>()
  const [dataSearch, setDataSearch] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)

  useEffect(() => {
    setPersonType('pf') // A
    setValue('tipo', 'pf')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [dataModal, setDataModal] = useState<Params>({})
  useEffect(() => {
    if (params) {
      setDataModal({
        cep: params.cep || '',
        endereco: params.endereco || '',
        name: params.name || '',
        number: params.number || null,
        id: Number(params.id) || null,
      })
    } else {
      setDataModal({
        cep: '',
        endereco: '',
        name: '',
        number: null,
      })
    }
  }, [params])

  const handlePersonTypeChange = (event) => {
    setPersonType(event.target.value)
    setSyncSuccess(false)
    setValue('tipo', event.target.value)
  }
  const columns =  [
      {
        Header: 'Nome',
        accessor: 'Name',
      },
      {
        Header: 'Idade',
        accessor: 'Age',
      },
      {
        Header: 'CPF/CN`PJ',
        accessor: 'DocumentFmt',
      },
      {
        Header: 'Nome da Mãe',
        accessor: 'MotherNameFmt',
      },
      {
        Header: 'Local',
        accessor: 'Location',
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({row}) => {
          return (
            <ModalPH3aData
              idTerreno={dataModal?.id}
              origin={origin}
              data={row.original.SequentialId}
            ></ModalPH3aData>
          )
        },
      },
    ]

  const handleDocumentChange = (event) => {
    setDocumento(event.target.value)
    setSyncSuccess(false)
    setErrorVerify('')
  }

  const {FormProprietarios, errors, loading, setValue} =
    MutationPutProprietario()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async (data) => {
    FormProprietarios(data)
      .then((result) => {
        Swal.fire('Sucesso!', 'Proprietário criado com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o procwesso.', 'error')
      })
  }

  async function syncPH3a(body) {
    setResultFindPeaple([])

    let formData = new FormData(body.target)

    let PersonType = formData.get('PersonType') || undefined
    let Name = formData.get('Name') || undefined
    let Document = formData.get('Document') || undefined
    let Street = formData.get('Street') || undefined
    let ZipCode = formData.get('ZipCode') || undefined
    let AddressNumber = formData.get('AddressNumber') || undefined
    let CNAE = formData.get('CNAE') || undefined
    let Url = formData.get('Url') || undefined

    let sendData = {
      ...(PersonType && {PersonType}),
      ...(Name && {Name}),
      ...(Document && {Document}),
      ...(Street && {Street}),
      ...(ZipCode && {ZipCode}),
      ...(AddressNumber && {AddressNumber}),
      ...(CNAE && {CNAE}),
      ...(Url && {Url}),
    }

    if (Object.keys(sendData).length <= 1) {
      Swal.fire('', 'Informe um campo para busca.', 'warning')
      return false
    } else {
      if (
        AddressNumber !== '' &&
        typeof AddressNumber !== 'undefined' &&
        typeof ZipCode === 'undefined'
      ) {
        Swal.fire('', 'Informe um CEP para efetuar a busca.', 'warning')
        return false
      }

      try {
        setDataLoading(true)

        const response = await fetch(`${process.env.REACT_APP_API_URL_EXTRA_SERVICES}find-peaple`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(sendData),
        })

        const result = await response.json()

        if (result.error) {
          console.error('Error fetching data:', result.error)
          setErrorVerify(result.message)
        } else {
          setDataSearch(result.data)
          setResultFindPeaple(result.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        console.log(error)
        setErrorVerify('Ocorreu um erro ao buscar dados.')
      } finally {
        setDataLoading(false)
      }
    }
    return false
  }

  // Tratativa de loading
  if (loading) {
    return (
      <div className='flex-1 h-screen w-full absolute flex justify-center'>
        <div className='flex flex-column all-center '>
          <br />
          <Spinner variant='success' animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <br />
          <span className='text-black font-semibold text-6xl px-2'>Carregando...</span>
        </div>
      </div>
    )
  }


  return (
    <Modal centered animation size='xl' show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Nova Consulta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='card-body'>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              return syncPH3a(e)
            }}
            id='form-buscar'
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            method='POST'
          >
            <div className='row mb-3'>
              <div className='col-12 all-start'>
                <div className='form-check me-4'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault1'
                    value='pf'
                    checked={personType === 'pf'}
                    onChange={handlePersonTypeChange}
                  />
                  <label className='form-check-label' htmlFor='flexRadioDefault1'>
                    Pessoa Física
                  </label>
                </div>
                <div className='form-check me-4'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault2'
                    value='pj'
                    checked={personType === 'pj'}
                    onChange={handlePersonTypeChange}
                  />
                  <label className='form-check-label' htmlFor='flexRadioDefault2'>
                    Pessoa Jurídica
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-1'>
              <div className='col-12 mb-6'>
                {personType === 'pf' ? (
                  <>
                    <div className='row'>
                      <div className='col-6'>
                        <input type='hidden' name='PersonType' value='0' />
                        <FormInputMaskAndLabel
                          name='Document'
                          label='CPF:'
                          maskChar=' '
                          mask='999.999.999-99'
                          className='form-control'
                          placeholder='Ex: 000.000.000-00'
                          onChange={handleDocumentChange}
                          error={errors ? errors.cpf?.message : ''}
                          showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                      <div className='col-6'>
                        <FormInputAndLabel
                          name='Name'
                          label='Nome:'
                          className='form-control'
                          placeholder='Nome do titular'
                          defaultValue={dataModal.name ? dataModal.name : ''}
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <div className='col-4'>
                        <FormInputAndLabel
                          name='Street'
                          label='Endereço:'
                          className='form-control'
                          placeholder='Ex: Rua Alameda Novaes'
                          defaultValue={dataModal.endereco ? dataModal.endereco : ''}
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                      <div className='col-4'>
                        <FormInputAndLabel
                          name='AddressNumber'
                          label='Número:'
                          className='form-control'
                          placeholder='N°'
                          defaultValue={dataModal.number ? dataModal.number : ''}
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                      <div className='col-4'>
                        <FormInputMaskAndLabel
                          name='ZipCode'
                          label='CEP:'
                          maskChar=' '
                          mask='99.999-999'
                          className='form-control'
                          placeholder='Ex: 00.000-00'
                          defaultValue={dataModal.cep}
                          onChange={handleDocumentChange}
                          error={errors ? errors.cep?.message : ''}
                          showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {personType === 'pj' ? (
                  <>
                    <div className='row'>
                      <div className='col-6'>
                        <input type='hidden' name='PersonType' value='1' />
                        <FormInputMaskAndLabel
                          name='Document'
                          label='CNPJ:'
                          maskChar=' '
                          mask='99.999.999/9999-99'
                          className='form-control'
                          placeholder='Ex: 00.000.000/0000-00'
                          onChange={handleDocumentChange}
                          error={errors ? errors.cnpj?.message : ''}
                          showHide={personType === 'pj' ? true : false}
                        />
                      </div>
                      <div className='col-6'>
                        <FormInputAndLabel
                          name='Name'
                          label='Nome Empresa:'
                          className='form-control'
                          placeholder='Nome da empresa'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                    </div>
                    <div className='row mt-2'>
                      <div className='col-6'>
                        <FormInputAndLabel
                          name='CNAE'
                          label='CNAE:'
                          className='form-control'
                          placeholder='Nome da empresa'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                      <div className='col-6'>
                        <FormInputAndLabel
                          name='Url'
                          label='E-mail:'
                          className='form-control'
                          placeholder='E-mail'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                    </div>
                    <div className='row mt-2'>
                      <div className='col-8'>
                        <FormInputAndLabel
                          name='Street'
                          label='Endereço:'
                          className='form-control'
                          placeholder='Ex: Rua Alameda Novaes'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                      <div className='col-4'>
                        <FormInputAndLabel
                          name='AddressNumber'
                          label='Número:'
                          className='form-control'
                          placeholder='N°'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                    </div>
                    <div className='row mt-2'>
                      <div className='col-4'>
                        <FormInputMaskAndLabel
                          name='ZipCode'
                          label='CEP:'
                          maskChar=' '
                          mask='99.999-999'
                          className='form-control'
                          placeholder='Ex: 00.000-00'
                          onChange={handleDocumentChange}
                          error={errors ? errors.cep?.message : ''}
                        />
                      </div>
                      <GetEstadoCidadeChosen
                        defaultValueCidade={''}
                        defaultValueEstado={''}
                        classNameCity={'col-4 col-xs-12 mt-1'}
                        classNameEstado={'col-4 col-xs-12  mt-1'}
                        SetValueEstado={(value) => {
                          setValue('id_estado', Number(value))
                        }}
                        SetValueCidade={(value) => {
                          setValue('id_cidade', Number(value))
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div></div>
                )}

                {personType === 'both' ? (
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <input type='hidden' name='PersonType' value='2' />
                        <FormInputAndLabel
                          label='Nome:'
                          className='form-control'
                          placeholder='Nome do titular'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <div className='col-8'>
                        <FormInputAndLabel
                          label='Endereço:'
                          className='form-control'
                          placeholder='Ex: Rua Alameda Novaes'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                      <div className='col-4'>
                        <FormInputAndLabel
                          label='Número:'
                          className='form-control'
                          placeholder='N°'
                          onChange={handleDocumentChange}
                          error={errors ? errors.nome?.message : ''}
                          // showHide={personType === 'pf' ? true : false}
                        />
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <div className='col-4'>
                        <FormInputMaskAndLabel
                          label='CEP:'
                          maskChar=' '
                          mask='99.999-999'
                          className='form-control'
                          placeholder='Ex: 00.000-00'
                          onChange={handleDocumentChange}
                          error={errors ? errors.cep?.message : ''}
                        />
                      </div>
                      <GetEstadoCidadeChosen
                        defaultValueCidade={''}
                        defaultValueEstado={''}
                        classNameCity={'col-4 col-xs-12 mt-1'}
                        classNameEstado={'col-4 col-xs-12  mt-1'}
                        SetValueEstado={(value) => {
                          setValue('id_estado', Number(value))
                        }}
                        SetValueCidade={(value) => {
                          setValue('id_cidade', Number(value))
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className='row mb-6'>
                <div className='w-100 mt-4 d-flex align-items-end justify-content-end'>
                  <button
                    type='reset'
                    className='btn btn-light btn-active-light-primary me-4'
                    onClick={() => closeModal()}
                  >
                    Cancelar
                  </button>

                  <button
                    disabled={syncSuccess === true && dataSync?.error === false}
                    // onClick={(e) => {
                    //   handleSearch(documento)
                    // }}
                    type='submit'
                    className='btn btn-primary'
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </form>

          {dataLoading ? (
            <div className='w-100 badge badge-light'>
              <Loader text='carregando...' />
            </div>
          ) : (
            <div>
              {erroSyncVerify && erroSyncVerify.length > 0 ? (
                <span className='text-center w-100 badge badge-light py-5 fs-6'>
                  {erroSyncVerify}
                </span>
              ) : (
                <>
                  {resultFindPeaple && resultFindPeaple.length > 0 ? (
                    <TableContainer columns={columns} data={resultFindPeaple} />
                  ) : null}
                  {dataSearch && dataSearch.length === 0 ? (
                    <span className='text-center w-100 badge badge-light py-5 fs-6'>
                      Nenhum registro encontrado
                    </span>
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
