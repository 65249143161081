import {useState} from 'react'
import {Buttons} from '../partials/Buttons'
import {BiPlus} from 'react-icons/bi'
import PaginationComponent from '../../../components/UI/PaginationComponents'
import {
  MutationCreateInteligenciaMercado,
  QueryGetInteligenciaMercado,
} from '../../../graphql/services/inteligencia-mercado'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'
import Swal from 'sweetalert2'
import {useHistory} from 'react-router-dom'
import {ModalVSO} from '../partials/ModalVSO'
import {CardTables} from '../../../components/UI/CardTables'
import LoadingTable from '../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../components/UI/CardTables/ErrorTable'

type Props = {
  filter: any[]
}

export function TableInteligenciaMercado({filter}: Props) {
  const [paginacao, setPaginacao] = useState({pagina: 0, quantidade: 10})
  const {setValue, FormInteligenciaMercado} = MutationCreateInteligenciaMercado()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const navigate = useHistory()

  const {data, error, loading} = QueryGetInteligenciaMercado({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade,
      },
      filter: {
        fields: filter,
      },
    },
  })

  function NovoProjeto() {
    Swal.fire({
      title: 'Nome do projeto',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let json = {}
        let nome = result.value

        setValue('nome', nome)

        FormInteligenciaMercado({nome, json})
          .then((retorno) => {
            const idProjeto = retorno.data.SetInteligenciaMercado.id
            FormSetLogAcessoRecurso({
              ferramenta: 'Inteligencia Mercado',
              funcionalidade: 'Criar Projeto',
              action: 'create',
              url: `inteligencia-mercado`,
            })
            Swal.fire({
              title: 'Sucesso!',
              text: 'Projeto atualizado com sucesso.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                navigate.push(`inteligencia-mercado/${idProjeto}`)
              }
            })
          })
          .catch((error) => {
            Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
          })
      }
    })
  }

  return (
    <CardTables
      headerTitle={'Projetos'}
      headerSubtTitle={`
                Listando ${
                  data ? data.GetInteligenciaMercado.pageInfo.totalItems : 0
                } projetos registrados.`}
      headerToolbar={
        <>
          <ModalVSO />
          <button className='btn btn-sm btn-light-primary' onClick={NovoProjeto}>
            <BiPlus className='fs-3'></BiPlus>
            Novo Projeto
          </button>
        </>
      }
      footer={
        data ? (
          <PaginationComponent
            pagesInfo={data.GetInteligenciaMercado.pageInfo}
            setPagesInfo={(pagina: number, quantidade: number) => {
              setPaginacao({pagina: pagina, quantidade: quantidade})
            }}
          ></PaginationComponent>
        ) : (
          <></>
        )
      }
    >
      <div className='table-responsive  '>
        <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th className='w-50px'>ID</th>
              <th className='min-w-150px'>Nome</th>
              <th className='min-w-100px text-end'>Ações</th>
            </tr>
          </thead>
          <tbody>
            <LoadingTable loading={loading} />
            <ErrorTable error={error} />
            {data ? (
              data.GetInteligenciaMercado.result.length > 0 ? (
                data.GetInteligenciaMercado.result.map((response) => {
                  return (
                    <tr key={response.id}>
                      <td>{response.id}</td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                              {response.nome}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <Buttons id={response.id} />
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={10} className='text-center'>
                    Nenhum registro encontrado
                  </td>
                </tr>
              )
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </CardTables>
  )
}
