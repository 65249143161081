import {FC} from 'react'
import {AvmContextProvider} from './context/AvmContext'
import {AvmStep} from './AvmStep'
import {Endereco} from '../../../../pages/inteligencia-mercado/components/Types'

type Props = {
  idTerreno?: number
  idEmpreendimento?: number
  idInteligenciaMercado?: number
  idMercadoSecundario?: number
  endereco?: Endereco | null
  origem: 'explorer' | 'imovel' | 'empreendimento' | 'inteligencia-mercado' | 'avaliacoes' | 'mercado-secundario'
  show?: boolean
}

export const ModalAvm: FC<Props> = ({
  endereco,
  origem,
  idTerreno,
  idEmpreendimento,
  idInteligenciaMercado,
  idMercadoSecundario,
  show,
}) => {
  let origemEntrada = {
    idTerreno: idTerreno,
    idEmpreendimento: idEmpreendimento,
    idInteligenciaMercado: idInteligenciaMercado,
    idMercadoSecundario: idMercadoSecundario,
    origem: origem,
    endereco: endereco,
  }

  return (
    <AvmContextProvider>
      <AvmStep origemEntrada={origemEntrada} showModal={show}></AvmStep>
    </AvmContextProvider>
  )
}
