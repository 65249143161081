import { gql } from "@apollo/client";

export const SET_AREA_ATUACAO = gql`
mutation SetAreaAtuacao($areaatuacao: areaatuacao!) {
  SetAreaAtuacao(areaatuacao: $areaatuacao) {
    id
    nome
  }
}
`

export const PUT_AREA_ATUACAO = gql`
mutation PutAreaAtuacao($areaatuacao: correareaatuacaotor!, $putAreaAtuacaoId: Float!) {
  PutAreaAtuacao(areaatuacao: $areaatuacao, id: $putAreaAtuacaoId) {
    id
    nome
  }
}
`

export const DELETE_AREA_ATUACAO = gql`
mutation DeleteAreaAtuacao($deleteAreaAtuacaoId: Float!) {
  DeleteAreaAtuacao(id: $deleteAreaAtuacaoId) {
    nome
  }
}
`