import {Text, View, StyleSheet, Image} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    fontSize: 11,
    fontWeight: 'light',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    // borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  column: {
    width: 150,
    // display: 'flex',
    // flexDirection: 'column',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
  },
  // column: {
  //   width: 150,
  //   flexDirection: 'column',
  //   // flexDirection:'start',
  // },
  rowErro: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 5,
    textAlign: 'center',
  },
  title: {
    marginTop: 25,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#2B8E57',
  },
  header: {
    display: 'flex',
    borderTop: 'none',
    backgroundColor: '#dbdbdb',
    paddingHorizontal: 10,
    flexDirection: 'column',
    width: 150,
  },
  rowDefault: {
    // width: '33%',
  },
  foto: {
    // width: '150',
    height: '150',
    marginRight: 10,
    // flexDirection: 'row',
  },

  card: {
    marginTop: 5,
    marginBottom: 10,
    // paddingVertical: 10,

    border: 0,
    width: '100%',
    backgroundColor: '#fff',
    // borderRadius: 5,
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
})

export const Fotos = ({fotos}) => {
  if (fotos) {
    const itens = []
    let count = 0
    // eslint-disable-next-line array-callback-return
    fotos.GetImovelImagens.map((row, i) => {
      if (i % 3 === 0) {
        count += 1
        if (typeof itens[count] == 'undefined') {
          itens[count] = []
        }
      }
      itens[count].push(row)
    })

    return (
      <View style={styles.table}>
        <Text style={styles.title}>Fotos</Text>

        {fotos.GetImovelImagens.length === 0 &&
          <Text style={styles.rowErro}>Nenhum registro encontrado</Text>
        }

        {
          // eslint-disable-next-line array-callback-return
          itens.map((it, y) => {
            return (
              <View style={styles.row} wrap={false} key={y}>
                {it.map((it, y) => {
                  return <Image src={it.caminho} style={[styles.foto]} key={y} />
                })}
              </View>
            )
          })
        }

        {/* {fotos.GetImovelImagens.map((row, i) => {
   
       return (
            <View style={(i+1) % 3 === 0 ? [] :[]} wrap={false} key={i}  debug={true}>
              <Image src={row.caminho} style={[styles.rowDefault]} />
            </View>
          )

  }
          
          )} */}
        {/* <Image src={`https://urbit-bucket.s3.sa-east-1.amazonaws.com/terrenos/fotos/b1d03ca4-58b8-4e3e-bf7c-cd668ebab969_canvasImage.jpg`} style={styles.rowDefault} /> */}
      </View>
    )
  }
}
