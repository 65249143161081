import {useEffect, useState} from 'react'
import Select from 'react-select'
import {QueryGetEmpreendimentoTags} from '../../../../graphql'

export function GetEmpreendimentosTagsChosen({defaultValue, onChange}) {
  let itensArray = []

  const [value, setValue] = useState([] )

  useEffect(() => {
    onChange(value)
  }, [onChange, value])

  const {data} = QueryGetEmpreendimentoTags()

  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Empreendimentos:</label>
          <Select
            options={itensArray}
            onChange={(e) => {
              setValue(e.value)
            }}
            value={itensArray.filter((e) => e.value)[0]}
            name='id_corretor'
            placeholder='Empreendimentos Tags'
          />
        </div>
      </>
    )
  }
  itensArray.push({value: '', label: '--selecione--'})
  data.GetEmpreendimentoTags.map((Options) => {
    itensArray.push({value: Options.tag, label: Options.tag})
    return []
  })

  

  return (
    <Select
      options={itensArray}
      defaultValue={itensArray.filter((item)=>item.label === defaultValue )}
      onChange={(e) => {
        console.log(e)
        console.log(e.values)
        
        // e.values.map((item)=>{
        //     return item.value

        // })
        setValue(e.label)
      }}
    //   isMulti
      name='id_tag'
      placeholder='Empreendimentos Tags'
    />
  )
}
