import { z } from "zod"

export const setAreaAtuacaoFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type setAreaAtuacaoFormData = z.infer<typeof setAreaAtuacaoFormSchema>

export const putAreaAtuacaoFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type putAreaAtuacaoFormData = z.infer<typeof putAreaAtuacaoFormSchema>