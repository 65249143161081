import { z } from "zod"

export const setUserSubAreaAtuacaoFormSchema = z.object({
    id_user: z.number(),
    id_sub_area_atuacao: z.number(),
    situacao: z.number(),
}).array()


export type setUserSubAreaAtuacaoFormData = z.infer<typeof setUserSubAreaAtuacaoFormSchema>

export const putUserSubAreaAtuacaoFormSchema = z.object({
    id: z.string(),
    id_user: z.number(),
    id_sub_area_atuacao: z.number(),
    situacao: z.number(),
}).array()


export type putUserSubAreaAtuacaoFormData = z.infer<typeof putUserSubAreaAtuacaoFormSchema>