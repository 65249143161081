import React, {useEffect, useState} from 'react'

export const Filter = ({column}) => {
  return <div style={{marginTop: 5}}>{column.canFilter && column.render('Filter')}</div>
}

export const DefaultColumnFilter = (
  {
    column: {
      filterValue,
      setFilter,
      preFilteredRows: {length},
    },
  },
  filterText: string
) => {
  useEffect(() => {
    if (typeof filterText === 'string') {
      setFilter(filterText)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      className='form-control input-sm min-w-100px'
      placeholder={`Buscar...`}
    />
  )
}

export const FilterRange = (rows, id, filterValue) => {
  const [min, max] = filterValue
  if (!min && !max) return rows

  return rows.filter((row) => {
    const rowValue = row.values[id]
    if (min && max) {
      return Number(rowValue) >= Number(min) && Number(rowValue) <= Number(max)
    } else if (min) {
      return Number(rowValue) >= Number(min)
    } else if (max) {
      return Number(rowValue) <= Number(max)
    }
    return true
  })
}

export const RangeColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: {length},
  },
}) => {
  const [min, setMin] = useState('')
  const [max, setMax] = useState('')

  useEffect(() => {
    setFilter([min, max])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max])

  return (
    <div className='w-200px flex-row d-flex all-start-between'>
      <input
        value={min || ''}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            setMin(e.target.value || undefined)
          }
        }}
        className='form-control input-sm w-100px'
        placeholder={`De`}
      />
      <input
        value={max || ''}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            setMax(e.target.value || undefined)
          }
        }}
        className='form-control input-sm w-100px'
        placeholder={`Até`}
      />
    </div>
  )
}

export const FilterSelect = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    if (rowValue === filterValue) {
      return true
    } else {
      return false
    }
  })
}

export const SelectColumnFilter = ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      if (row.values[id] !== null) {
        options.add(row.values[id])
      }
    })

    return options
  }, [id, preFilteredRows])

  return (
    <select
      id='custom-select'
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      className='form-control input-sm w-80px'
    >
      <option value=''>Todos</option>
      {[...options].map((option, index) => {
        return <option key={index}>{option}</option>
      })}
    </select>
  )
}
