import {useState} from 'react'
import {Form} from 'react-bootstrap'
import {SliderSingle} from '../../../../../components/UI/Slider'

export function AreaInfluencia({setFiltroLocal, filtro}: {setFiltroLocal: any; filtro: any}) {
  const [areaInfluencia, setAreaInfluencia] = useState<String>('')
  
  function selectAreaInfluencia(
    tipo: 'RAIO' | 'ZONAVALOR' | 'ISOCRONA' | 'DEFINIRMANUALMENTE',
    valor: any
  ) {
    setAreaInfluencia(tipo)
    setFiltroLocal({...filtro,...{
      areaInfluencia: {tipo: tipo, valor: valor},
    }})
  }


  let marksDistancia = {}
  marksDistancia[0] = '0 metros'
  // marksDistancia[700] = '700m'
  marksDistancia[1500] = '1,5km'

  let marksTempo = {}
  marksTempo[0] = '0 min'
  // marksTempo[5] = '5 min'
  marksTempo[10] = '10 min'

  return (
    <>
      <div className='radio-inputs'>
        <label>
          <input
            className='radio-input'
            type='radio'
            name='areainfluencia'
            checked={filtro?.areaInfluencia.tipo === 'RAIO' ? true : false}
            onChange={() => {
              selectAreaInfluencia(
                'RAIO',
                filtro?.areaInfluencia.tipo === 'RAIO' ? filtro?.areaInfluencia.valor : 700
              )
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Raio</span>
          </span>
        </label>
        <label>
          <input
            className='radio-input disabled'
            type='radio'
            name='areainfluencia'
            checked={filtro?.areaInfluencia.tipo === 'ZONAVALOR' ? true : false}
            onChange={() => {
              selectAreaInfluencia('ZONAVALOR', null)
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Zona de Valor</span>
          </span>
        </label>

        <label>
          <input
            className='radio-input'
            type='radio'
            name='areainfluencia'
            checked={filtro?.areaInfluencia.tipo === 'ISOCRONA' ? true : false}
            onChange={() => {
              selectAreaInfluencia(
                'ISOCRONA',
                filtro?.areaInfluencia.tipo === 'ISOCRONA' ? filtro?.areaInfluencia.valor : 10
              )
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Isocrona</span>
          </span>
        </label>

        <label>
          <input
            className='radio-input disabled'
            type='radio'
            name='definirManualmente'
            checked={filtro?.areaInfluencia.tipo === 'DEFINIRMANUALMENTE' ? true : false}
            onChange={() => {
              selectAreaInfluencia('DEFINIRMANUALMENTE', null)
            }}
          />
          <span className='radio-tile'>
            <span className='radio-label'>Desenhar Área</span>
          </span>
        </label>
      </div>
      {areaInfluencia === 'raio' || filtro?.areaInfluencia.tipo === 'RAIO' ? (
        <div className='row row ps-4'>
          <Form.Label column sm='12' className='fs-7 px-0'>
            Distância (Metros)
          </Form.Label>

          <SliderSingle
            defaultValue={
              filtro?.areaInfluencia.tipo === 'RAIO' ? filtro?.areaInfluencia.valor : 700
            }
            min={0}
            max={1500}
            step={100}
            onChange={(e) => {
              selectAreaInfluencia('RAIO', e)
            }}
            tipFormatter={(value) => `${value} metros `}
            marks={marksDistancia}
          ></SliderSingle>
        </div>
      ) : (
        <></>
      )}
      {areaInfluencia === 'ISOCRONA' || filtro?.areaInfluencia.tipo === 'ISOCRONA' ? (
        <div className='row row ps-4'>
          <Form.Label column sm='12' className='fs-7 px-0'>
            Tempo (minutos)
          </Form.Label>
          <SliderSingle
            defaultValue={
              filtro?.areaInfluencia.tipo === 'ISOCRONA' ? filtro?.areaInfluencia.valor : 5
            }
            min={0}
            max={10}
            step={1}
            onChange={(e) => {
              selectAreaInfluencia('ISOCRONA', e)
            }}
            tipFormatter={(value) => `${value} min `}
            marks={marksTempo}
          ></SliderSingle>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
