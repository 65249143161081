import React, {useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {TypologiesPriceFormData, TypologiesPriceFormSchema} from './formValidations'
import {FormInputAndLabel} from '../../../../../../components/UI/Inputs/FormInputAndLabel'
import {useParams} from 'react-router-dom'
import {addTypologyPrice} from '../../../../../../../services/http/urbit-gis/routes'
import Swal from 'sweetalert2'
import {MoneyInput} from '../../../../../../components/UI/Inputs/MoneyInput'
import {useEmpreendimentos} from '../../../context/useEmpreendimentos'
import {SelectTypologiy} from './SelectTypology'

interface Props {
  closeModal: () => void
  show: boolean
  handleRefetch: () => void
  totalUnits: number
  stockUnits: number
  sales: number
  area: number
}

export function ModalHistoryCreate({
  closeModal,
  show,
  sales: salesDefault,
  handleRefetch,
  totalUnits,
  stockUnits,
  area,
}: Props) {
  const {id: idLaunch} = useParams<{id: string}>()
  const {typologies} = useEmpreendimentos()
  
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    reset,
    control,
    watch,
  } = useForm<TypologiesPriceFormData>({
    resolver: zodResolver(TypologiesPriceFormSchema),
    defaultValues: {
      id_real_estate_launches: parseInt(idLaunch),
      id_real_estate_launches_typologies: 0,
      price_date: '',
      price: null,
      price_m2: null,
      stock: stockUnits || 0,
      sales: salesDefault || 0 ,
      source: 'Urbit',
      launch_price: 1,
    },
  })

  // console.log(errors)
  const checkboxValue = watch('launch_price')
  const stock = watch('stock')
  const sales = watch('sales')
  const price = watch('price')
  const price_m2 = watch('price_m2')

  // Função chamada no submit
  const onSubmit = async (data: TypologiesPriceFormData) => {
    try {
      const updatedData = {
        ...data,
        launch_price: checkboxValue ? 1 : 0, // Convert to 1 or 0
      }
      const result = await addTypologyPrice(updatedData)

      if (result && result.status === 201) {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Preço adicionado com sucesso.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          closeModal()
          reset()
          handleRefetch()
        })
      } else {
        throw new Error(result.message || 'Ocorreu um erro desconhecido')
      }
    } catch (error) {
      let errorMessage = 'Houve um erro ao salvar o empreendimento.'
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message
      }
      Swal.fire({
        title: 'Erro!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  // Funções de onChange para atualizar o valor do outro campo
  const handleStockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stockValue = Number(event.target.value)
    if (!isNaN(stockValue) && stockValue >= 0 && stockValue <= totalUnits) {
      setValue('stock', stockValue)
      setValue('sales', Math.max(0, totalUnits - stockValue))
    }
  }

  const handleSalesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const salesValue = Number(event.target.value)
    if (!isNaN(salesValue) && salesValue >= 0 && salesValue <= totalUnits) {
      setValue('sales', salesValue)
      setValue('stock', Math.max(0, totalUnits - salesValue))
    }
  }

  // Atualiza o preço por metro quadrado com base no preço e na área
  useEffect(() => {
    if (price && area) {
      setValue('price_m2', price / area)
    }
  }, [price, area, setValue])

  // Atualiza o preço com base no preço por metro quadrado e na área
  useEffect(() => {
    if (price_m2 && area) {
      setValue('price', price_m2 * area)
    }
  }, [price_m2, area, setValue])

  return (
    <Modal centered animation show={show} onHide={closeModal} size='sm'>
      <Modal.Header className='py-4' closeButton>
        <Modal.Title>Cadastrar</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-lg pt-0'>
        <form>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row mt-4'>
                <div className='form-group'>
                  <span className='col-form-label mb-4'>Tipologia</span>
                  <Controller
                    name='id_real_estate_launches_typologies'
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <SelectTypologiy
                        data={typologies}
                        selectedValue={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='col-form-label'>Data</label>
                    <input type='date' className='form-control' {...register('price_date')} />
                  </div>
                  {errors.price_date && (
                    <span className='text-danger'>{errors.price_date?.message}</span>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <MoneyInput
                    label='Preço'
                    name='price'
                    control={control}
                    error={errors.price?.message}
                  />
                </div>
                <div className='col-12 mb-3'>
                  <MoneyInput
                    label='Preço m²'
                    name='price_m2'
                    control={control}
                    error={errors.price_m2?.message}
                  />
                </div>
              </div>

              <div className='form-group d-flex all-start-center'>
                <input
                  className='me-1 form-check'
                  type='checkbox'
                  name='launch_price'
                  id='launch_price'
                  onChange={(e)=> {
                    setValue('launch_price', Number(e.target.checked))
                  }}
                />
                <span>Preço de lançamento</span>
              </div>
              <br />
              <span className='text-muted'>Total de unidades: {totalUnits}</span><br />
              <span className='text-muted'>Unidades em estoque: {stockUnits}</span>

              <div className='row'>
                <div className='col-6'>
                  <FormInputAndLabel
                    type='number'
                    label='Estoque'
                    className='form-control'
                    placeholder='0'
                    value={stock}
                    onChange={handleStockChange}
                    error={errors.stock?.message || ''}
                  />
                </div>
                <div className='col-6'>
                  <FormInputAndLabel
                    type='number'
                    disabled
                    label='Vendas'
                    className='form-control'
                    placeholder='0'
                    value={sales}
                    onChange={handleSalesChange}
                    error={errors.sales?.message || ''}
                  />
                </div>
              </div>

              <div className='w-100 mt-4 d-flex justify-content-end'>
                <button type='button' onClick={closeModal} className='btn btn-danger me-4'>
                  Cancelar
                </button>
                <button onClick={handleSubmit(onSubmit)} type='button' className='btn btn-success'>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
