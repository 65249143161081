import {ReactChild, useContext} from 'react'
import {FilterContext} from '../../context/FilterContext'
import {formatNumber} from '../../../../components/util'

type Props = {
  titulo: string
  children?: ReactChild
  showSubHeader?: boolean
}

export function HeaderPerformance({titulo, showSubHeader = true, children}: Props) {
  const {filter} = useContext(FilterContext)
  return (
    <>
      <div className='header-report  '>
        <div className='d-flex flex-row all-start-between'>
          <div className=' '>
            <h1 className='fw-bolder'>{titulo}</h1>
            {showSubHeader ? (
              <span>
                PERFORMANCE {filter.anoLancamento[0]} A {filter.anoLancamento[1]}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div>{children}</div>
        </div>
        <div>
          <ul className='filter-list'>
            <li>
              <h3>Filtros</h3>
            </li>
            <li>
              Área de Influência: <br></br>
              {filter.areaInfluencia.tipo === 'zonaValor' ? 'Zona de Valor' : ''}
              {filter.areaInfluencia.tipo === 'raio'
                ? `Raio de ${filter.areaInfluencia.valor} metros `
                : ''}{' '}
              {filter.areaInfluencia.tipo === 'isocrona'
                ? `Isocrona de ${filter.areaInfluencia.valor}min `
                : ''}{' '}
              {filter.areaInfluencia.tipo === 'definirManualmente' ? `Definido manualmente ` : ''}
            </li>
            {filter.areaUtil[0] !== 10 || filter.areaUtil[1] !== 1000 ? (
              <li>
                Área útil:<br></br> {formatNumber(filter.areaUtil[0], 'inteiro')} até{' '}
                {formatNumber(filter.areaUtil[1], 'inteiro')}{' '}
              </li>
            ) : (
              ''
            )}
            {filter.dormitorios ? (
              <li>
                Dormitórios:<br></br>{' '}
                {filter.dormitorios.length > 0 ? filter.dormitorios.join(',') : 'Todos'}{' '}
              </li>
            ) : (
              ''
            )}
            {filter.estagioObra ? (
              <li>
                Estágio Obra: <br></br>
                {filter.estagioObra}{' '}
              </li>
            ) : (
              ''
            )}
            {filter.incorporadora ? (
              <li>
                Incorporadora:<br></br> {filter.incorporadora.join(',')}{' '}
              </li>
            ) : (
              ''
            )}
            {filter.mcmv ? (
              <li>
                MCMV:<br></br> {filter.mcmv === 1 ? 'Sim' : 'Não'}{' '}
              </li>
            ) : (
              ''
            )}
            {filter.percentualVendido[0] !== 0 || filter.percentualVendido[1] !== 100 ? (
              <li>
                Percentual Venda:<br></br> {filter.percentualVendido[0]}% até{' '}
                {filter.percentualVendido[1]}%{' '}
              </li>
            ) : (
              ''
            )}
            {filter.precoM2[0] !== 1000 || filter.precoM2[1] !== 60000 ? (
              <li>
                Preço m²:<br></br> {formatNumber(filter.precoM2[0])} até{' '}
                {formatNumber(filter.precoM2[1])}{' '}
              </li>
            ) : (
              ''
            )}
            {filter.precoTotal[0] !== 100000 || filter.precoTotal[1] !== 30000000 ? (
              <li>
                Preço Total:<br></br> {formatNumber(filter.precoTotal[0])} até{' '}
                {formatNumber(filter.precoTotal[1])}{' '}
              </li>
            ) : (
              ''
            )}
            <li>
              Tipologias:<br></br> {filter.tipologia.join(',')}{' '}
            </li>
            {/* <li>segmentacaoArea: {filter.segmentacaoArea} </li>
            <li>segmentacaoPreco: {filter.segmentacaoPreco} </li> */}
          </ul>
        </div>
      </div>
    </>
  )
}
