import { Dicionario } from "../../../../../utils/Dicionario"


export function LegendaChoropleth({cores, valores, title, field}) {
  // var colorsString = '' cores.join(',');

  return (
    <>
      <div className='band-title'>{title} </div>
      <div className='band' style={{background: `linear-gradient(to right, ${cores} )`}}></div>
      <div className='band-labels'>
        {valores.map((item, index) => {
          return (
            <div className='band-label' key={index}>
              {item}
            </div>
          )
        })}
      </div>
    </>
  )
}

export function LegendaBand({labels}) {
  return (
    <div className='band-labels'>
      <div className='band-label'>1</div>
      <div className='band-label'>2</div>
      <div className='band-label'>3</div>
    </div>
  )
}

export function Legenda({style, title, visivel, innerRef}:{style:any, title:string, visivel:boolean, innerRef?:any}) {
  if (style.length === 0) {
    return <></>
  }
  const series = style

  if (!visivel) {
    return <></>
  }
  if (typeof series['fill-color'] !== 'undefined') {
    if (series['fill-color'][0] === 'interpolate') {
      let cores = series['fill-color'].filter((valor, index) => index % 2 === 0 && index > 2)
      let valores = series['fill-color'].filter((valor, index) => index % 2 !== 0 && index > 2)
      let conversaoCampo = series['fill-color'][2][1][1]
      return (
        <>
          <div className='legenda' style={{right: '61px'}} ref={innerRef} >
            {/* <div className="band-title"></div> */}
            <LegendaChoropleth
              cores={cores.join(',')}
              valores={valores}
              title={title ? title + ' /' + Dicionario.translate(conversaoCampo) : ''}
              field={'teste'}
            ></LegendaChoropleth>
            {/* <LegendaBand labels={'itens'}></LegendaBand> */}
          </div>
        </>
      )
    } else {
      return <></>
    }
  }else  if (typeof series['circle-color'] !== 'undefined') {
    if (series['circle-color'][0] === 'interpolate') {
      let cores = series['circle-color'].filter((valor, index) => index % 2 === 0 && index > 2)
      let valores = series['circle-color'].filter((valor, index) => index % 2 !== 0 && index > 2)
      let conversaoCampo = series['circle-color'][2][1][1]
      return (
        <>
          <div className='legenda' style={{right: '61px'}} ref={innerRef} >
            {/* <div className="band-title"></div> */}
            <LegendaChoropleth
              cores={cores.join(',')}
              valores={valores}
              title={title ? title + ' /' + Dicionario.translate(conversaoCampo) : ''}
              field={'teste'}
            ></LegendaChoropleth>
            {/* <LegendaBand labels={'itens'}></LegendaBand> */}
          </div>
        </>
      )
    } else {
      return <></>
    }
  } else {
    return <></>
  }
}
