import {HeaderPerformance} from '../Common/HeaderPerformance'
import {ModalAvm} from '../../../../components/UI/Modals/modal-avm'
import {useContext} from 'react'
import {FilterContext} from '../../context/FilterContext'
import {Buttons} from './ButtonsAVM'
import {QueryGetAvmLeadInteligenciaMercado} from '../../../../graphql'
import {Spinner} from 'react-bootstrap'
import {formatNumber} from '../../../explorer/components/common'

export function Avm({id}: {id: number}) {
  const {endereco} = useContext(FilterContext)

  let columnsData = [
    {
      name: 'Tipo Avaliação',
      selector: (row) => row.tipo,
      sortable: true,
      wrap: true,
      width: '240px',
    },
    {
      name: 'Quartos/Suítes',
      selector: (row) => row.quartos,
      sortable: true,
      wrap: true,
      // width: '300px',
    },
    {
      name: 'Banheiros',
      selector: (row) => row.banheiros,
      sortable: true,
    },
    {
      name: 'Vagas',
      selector: (row) => row.vagas,
      sortable: true,
      right: true,
    },
    {
      name: 'Área',
      selector: (row) => row.area,
      sortable: true,
      center: true,
    },
    {
      name: 'Valor Máximo',
      selector: (row) => row.valor_maximo,
      sortable: true,
      center: true,
    },
    {
      name: 'Valor Médio',
      selector: (row) => row.valor_medio,
      sortable: true,
      center: true,
    },
    {
      name: 'Valor Mínimo',
      selector: (row) => row.valor_minimo,
      sortable: true,
      center: true,
    },
  ]

  const {
    data: dataLead,
    error,
    loading,
  } = QueryGetAvmLeadInteligenciaMercado({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 1000,
      },
      filtro: {
        fields: [
          {
            field: ['id_inteligencia_mercado_projeto'],
            operator: '=',
            value: String(id),
          },
          {
            field: ['origem_lead'],
            operator: 'LIKE',
            value: 'inteligencia-mercado',
          },
        ],
      },
    },
  })

  if (!dataLead) {
    return (
      <div className='row'>
        <Spinner variant='success' animation='border' role='status'>
          <span className='visually-hidden'>Carregando...</span>
        </Spinner>
      </div>
    )
  }

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'AVM - Avaliação automática de Imóveis'} showSubHeader={false}>
          {/* <button className='btn btn-primary pull-right' onClick={exportar}>
            <BiExport></BiExport> Exportar
          </button> */}

          <ModalAvm
            endereco={endereco}
            origem={'inteligencia-mercado'}
            idInteligenciaMercado={id}
          ></ModalAvm>
        </HeaderPerformance>
        <div className='content pt-5'>
          <div className='row g-5 g-xl-8'>
            <div className='card pt-10 bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
              <div className='table-responsive'>
                <table className='table table-striped gs-7'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-50px'>Tipo de Avalição</th>
                      <th className='min-w-50px'>Quartos/Suítes</th>
                      <th className='min-w-50px'>Banheiros</th>
                      <th className='min-w-50px'>Vagas</th>
                      <th className='min-w-50px'>Area</th>
                      <th className='min-w-150px'>Valor Max</th>
                      <th className='min-w-100px '>Valor Med</th>
                      <th className='min-w-100px '>Valor Min</th>
                      <th className='min-w-100px text-end'>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataLead.GetAvmLead.result.length > 0 ? (
                      dataLead.GetAvmLead.result.map((response) => {
                        return (
                          <tr key={response.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <p className='text-dark  fs-6'>
                                    {response.id_tipo_imovel === 2 ? 'Apartamento' : ''}
                                    {response.id_tipo_imovel === 1 ? 'Casa' : ''}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>
                                {response.quartos}/{response.suites}
                              </p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>{response.banheiros}</p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>{response.vagas}</p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>{response.area}</p>
                            </td>

                            <td>
                              <p className='text-dark  d-block fs-6'>
                                {formatNumber(response.result_maximo, 'currency')}
                              </p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>
                                {formatNumber(response.result_medio, 'currency')}
                              </p>
                            </td>
                            <td>
                              <p className='text-dark  d-block fs-6'>
                                {formatNumber(response.result_minimo, 'currency')}
                              </p>
                            </td>
                            <td>
                              <Buttons id={response.id} />
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className='text-center'>
                          <span>Nenhuma avaliação encontrada.</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
