import {useContext, useState} from 'react'
import {DataContext} from '../context/DataContext'
import {FilterContext} from '../context/FilterContext'
import {formatValores} from './Common/common'
import {HeaderPerformance} from './Common/HeaderPerformance'

function Segmentacao() {
  const {filter, setFilter} = useContext(FilterContext)
  const {segmentacao} = useContext(DataContext)
  const configSegmentacaoArea = segmentacao.area
  const configSegmentacaoPreco = segmentacao.preco

  function onChangeArea(opcao: number) {
    let ftemp = {...filter}
    ftemp.segmentacaoArea = opcao
    setFilter({...ftemp})
  }

  function onChangePreco(opcao: number) {
    filter['segmentacaoPreco'] = opcao
    setFilter({...filter})
  }

  const [valores, setValores] = useState(Array(12).fill(''))
  const [area, setArea] = useState(Array(12).fill(''))

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Definir Segmentação'}></HeaderPerformance>
        <div className='content pt-5'>
          <div className='row g-5 g-xl-8'>
            <div className='col-md-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Segmentação por área</span>
                    <span className='text-muted fw-semibold fs-7'>
                      Defina segmentação por área de acordo com seu estudo.
                    </span>
                  </h3>
                  <div className='card-toolbar' data-kt-buttons='true'>
                    {/* {filtroDomitorio ? <SelectDormitorios onChange={onChange}></SelectDormitorios> : <></>} */}
                  </div>
                </div>
                <div className='card-body' id='segmentacao'>
                  <div className='wrapper'>
                    {Object.entries(configSegmentacaoArea).map((opcoes, index) => {
                      return (
                        <div className='card' key={index}>
                          <input
                            className='input'
                            type='radio'
                            name='segmentacao_area'
                            value={index}
                            checked={filter?.segmentacaoArea === index ? true : false}
                            onChange={() => onChangeArea(index)}
                          />
                          <span className='check'></span>
                          <label className='label'>
                            <div className='title'>{`Opção ${index + 1}`}</div>
                            <div className='price py-5 px-5 '>
                              <table className='table align-middle table-row-dashed fs-7 gy-1 dataTable no-footer  w-100'>
                                <tbody className='fw-bold text-gray-600 '>
                                  {opcoes[1].map((item, index) => {
                                    return (
                                      <tr className='' key={index}>
                                        <td>
                                          {index === 0
                                            ? 'Abaixo de'
                                            : index === 6
                                            ? 'Acima de '
                                            : 'Entre'}{' '}
                                          <strong className='text-gray-700'>
                                            {index === 0
                                              ? item[1] + 'm²'
                                              : index === 6
                                              ? item[0] + 'm²'
                                              : item[0] + 'm² - ' + item[1] + 'm²'}
                                          </strong>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </label>
                        </div>
                      )
                    })}
                    {/* <div className='card'>
                      <input
                        className='input'
                        type='radio'
                        name='segmentacao_area'
                        value={0}
                        checked={false}
                        onChange={() => onChangeArea(0)}
                      />
                      <span className='check'></span>
                      <label className="label">
                        <div className="title">Personalizada </div>
                        <div className="price py- px-5 ">
                          <table className="table align-middle table-row-dashed fs-7 gy-1 dataTable no-footer  w-100">
                            <tbody className="fw-bold text-gray-600 ">
                              <tr className="">
                                <td className='group-input-segmentacao'>Abaixo de <input type="text" className='input-segmentacao-area' />
                                 <strong className="text-gray-700">m²</strong></td>
                              </tr>
                              {[...Array(6)].map((_, index) => (
                                <tr key={index}>
                                  <td className='group-input-segmentacao'>
                                    Entre <input
                                      type='text'
                                      className='input-segmentacao-area'
                                    />{' '}
                                    <strong className='text-gray-700'>m²</strong> -
                                     <input
                                      type='text'
                                      className='input-segmentacao-area'
                                    />{' '}
                                    <strong className='text-gray-700'>m²</strong>
                                  </td>
                                </tr>
                              ))}
                              <tr className="">
                                <td className='group-input-segmentacao'>Acima de <input type="text" className='input-segmentacao-area'/> 
                                <strong className="text-gray-700">m²</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Segmentação por valor</span>

                    <span className='text-muted fw-semibold fs-7'>
                      Defina segmentação por valor de acordo com seu estudo.
                    </span>
                  </h3>
                  <div className='card-toolbar' data-kt-buttons='true'>
                    {/* {filtroDomitorio ? <SelectDormitorios onChange={onChange}></SelectDormitorios> : <></>} */}
                  </div>
                </div>
                <div className='card-body' id='segmentacao'>
                  <div className='wrapper'>
                    {Object.entries(configSegmentacaoPreco).map((opcoes, index) => {
                      return (
                        <div className='card' key={index}>
                          <input
                            className='input'
                            type='radio'
                            name='segmentacao_preco'
                            value={index}
                            onChange={() => onChangePreco(index)}
                            checked={filter?.segmentacaoPreco === index ? true : false}
                          />
                          <span className='check'></span>
                          <label className='label'>
                            <div className='title'>{`Opção ${index + 1}`}</div>
                            <div className='price py-2 px-5 '>
                              <table className='table align-middle table-row-dashed fs-7 gy-1 dataTable no-footer  w-100'>
                                <tbody className='fw-bold text-gray-600 '>
                                  {opcoes[1].map((item, index) => {
                                    return (
                                      <tr className='' key={index}>
                                        <td>
                                          {index === 0
                                            ? 'Abaixo de'
                                            : index === 6
                                            ? 'Acima de '
                                            : 'Entre'}{' '}
                                          <strong className='text-gray-700'>
                                            {index === 0
                                              ? formatValores(item[1])
                                              : index === 6
                                              ? formatValores(item[0])
                                              : formatValores(item[0]) +
                                                ' - ' +
                                                formatValores(item[1])}
                                          </strong>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </label>
                        </div>
                      )
                    })}
                    {/* <div className='card'>

                      <span className='check'></span>
                      <label className="label">
                        <div className="title">Personalizada </div>
                        <div className="price py- px-5 ">
                          <table className="table align-middle table-row-dashed fs-7 gy-1 dataTable no-footer  w-100">
                            <tbody className="fw-bold text-gray-600">
                              <tr className="">
                                <td className='group-input-segmentacao'>
                                  Abaixo de <input
                                    type="text"
                                    className='input-segmentacao-valor'
                                  /> <strong className="text-gray-700">Mil</strong>
                                </td>
                              </tr>
                              {[...Array(6)].map((_, index) => (
                                <tr key={index}>
                                  <td className='group-input-segmentacao'>
                                    Entre <input
                                      type='text'
                                      className='input-segmentacao-valor'
                                    />{' '}
                                    <strong className='text-gray-700'>Mil</strong> - <input
                                      type='text'
                                      className='input-segmentacao-valor'
                                    />{' '}
                                    <strong className='text-gray-700'>Mil</strong>
                                  </td>
                                </tr>
                              ))}

                              <tr className="">
                                <td className='group-input-segmentacao'>
                                  Acima de <input
                                    type="text"
                                    className='input-segmentacao-valor'
                                  /> <strong className="text-gray-700">Mil</strong>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Segmentacao
