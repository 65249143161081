import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {TypesDeleteCorretorResponse} from '../type'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {
  TypesDeleteMercadoSecundarioVSOResponse,
  TypesGetMercadoSecundarioByIDResponse,
  TypesGetMercadoSecundarioResponse,
  TypesGetMercadoSecundarioVSOByIDResponse,
  TypesGetMercadoSecundarioVSOResponse,
  TypesPutMercadoSecundarioResponse,
  TypesPutMercadoSecundarioVSOResponse,
  TypesSetMercadoSecundarioResponse,
  TypesSetMercadoSecundarioVSOResponse,
} from '../type/mercado-secundario'
import {
  GET_MERCADO_SECUNDARIO,
  GET_MERCADO_SECUNDARIO_BY_ID,
  GET_MERCADO_SECUNDARIO_VSO,
  GET_MERCADO_SECUNDARIO_VSO_BY_ID,
} from '../query/mercado-secundario'
import {
  DELETE_MERCADO_SECUNDARIO,
  DELETE_MERCADO_SECUNDARIO_VSO,
  PUT_MERCADO_SECUNDARIO,
  PUT_MERCADO_SECUNDARIO_VSO,
  SET_MERCADO_SECUNDARIO,
  SET_MERCADO_SECUNDARIO_VSO,
} from '../mutations/mercado-secundario'
import {
  PutMercadoSecundarioVSOFormData,
  PutMercadoSecundarioVSOFormSchema,
  SetMercadoSecundarioVSOFormData,
  SetMercadoSecundarioVSOFormSchema,
  PutMercadoSecundarioFormData,
  PutMercadoSecundarioFormSchema,
  SetMercadoSecundarioFormData,
  SetMercadoSecundarioFormSchema,
} from '../formValidations/MercadoSecundario'

interface Props {
  variables: any
}

export function QueryGetMercadoSecundario({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetMercadoSecundarioResponse>(
    GET_MERCADO_SECUNDARIO,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error}
}

export function QueryGetMercadoSecundarioByID({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetMercadoSecundarioByIDResponse>(
    GET_MERCADO_SECUNDARIO_BY_ID,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error}
}

export function MutationDeleteMercadoSecundario() {
  const [deleteMercadoSecundario, {loading, data, error}] =
    useMutation<TypesDeleteCorretorResponse>(DELETE_MERCADO_SECUNDARIO, {
      refetchQueries: [GET_MERCADO_SECUNDARIO, GET_MERCADO_SECUNDARIO_BY_ID],
    })

  async function HandleDeleteMercadoSecundario({variables}: Props) {
    try {
      const result = await deleteMercadoSecundario({
        variables: {...variables},
        refetchQueries: [GET_MERCADO_SECUNDARIO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteMercadoSecundario, loading, error, data}
}

export function MutationCreateMercadoSecundario() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<SetMercadoSecundarioFormData>({
    resolver: zodResolver(SetMercadoSecundarioFormSchema),
  })

  const [setMercadoSecundarioBody, {error, loading, data: DataSetMercadoSecundario}] =
    useMutation<TypesSetMercadoSecundarioResponse>(SET_MERCADO_SECUNDARIO, {
      refetchQueries: [GET_MERCADO_SECUNDARIO],
    })

  async function FormMercadoSecundarioSet(data: SetMercadoSecundarioFormData) {
    return await setMercadoSecundarioBody({
      variables: {
        data: data
      },
    })
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormMercadoSecundarioSet,
    loading,
    errors,
    error,
    DataSetMercadoSecundario,
  }
}

export function MutationPutMercadoSecundario() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<PutMercadoSecundarioFormData>({
    resolver: zodResolver(PutMercadoSecundarioFormSchema),
  })

  const [PutMercadoSecundarioBody, {error, loading, data: DataSetMercadoSecundario}] =
    useMutation<TypesPutMercadoSecundarioResponse>(PUT_MERCADO_SECUNDARIO, {
      refetchQueries: [GET_MERCADO_SECUNDARIO, GET_MERCADO_SECUNDARIO_BY_ID],
    })

  async function FormMercadoSecundario(data: PutMercadoSecundarioFormData) {
    return await PutMercadoSecundarioBody({
      variables: {
        data: data,
        putMercadoSecundarioId: data.id,
      },
    })
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormMercadoSecundario,
    loading,
    errors,
    error,
    DataSetMercadoSecundario,
  }
}

// VSO SERVICES

export function QueryGetMercadoSecundarioVSO({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetMercadoSecundarioVSOResponse>(
    GET_MERCADO_SECUNDARIO_VSO,
    {
      variables: {...variables},
    }
  )
  return {data, loading, error}
}

export function QueryGetMercadoSecundarioVSOByID() {
  const [getData, {data, loading, error}] =
    useLazyQuery<TypesGetMercadoSecundarioVSOByIDResponse>(GET_MERCADO_SECUNDARIO_VSO_BY_ID)
  return {data, loading, error, getData}
}

export function MutationCreateMercadoSecundarioVSO() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<SetMercadoSecundarioVSOFormData>({
    resolver: zodResolver(SetMercadoSecundarioVSOFormSchema),
  })

  const [SetMercadoSecundarioBody, {error, loading, data: DataSetMercadoSecundarioVSO}] =
    useMutation<TypesSetMercadoSecundarioVSOResponse>(SET_MERCADO_SECUNDARIO_VSO, {
      refetchQueries: [GET_MERCADO_SECUNDARIO_VSO],
    })

  async function FormMercadoSecundarioVSO({vso, nome}: SetMercadoSecundarioVSOFormData) {
    try {
      return await SetMercadoSecundarioBody({
        variables: {
          mercadoSecundarioVso: {
            vso: vso,
            nome: nome,
          },
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {
    register,
    handleSubmit,
    reset,
    setValue,
    FormMercadoSecundarioVSO,
    loading,
    errors,
    error,
    DataSetMercadoSecundarioVSO,
  }
}

export function MutationPutMercadoSecundarioVSO() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<PutMercadoSecundarioVSOFormData>({
    resolver: zodResolver(PutMercadoSecundarioVSOFormSchema),
  })

  const [PutMercadoSecundarioBody, {error, loading, data: DataSetMercadoSecundarioVSO}] =
    useMutation<TypesPutMercadoSecundarioVSOResponse>(PUT_MERCADO_SECUNDARIO_VSO, {
      refetchQueries: [
        GET_MERCADO_SECUNDARIO_VSO,
        GET_MERCADO_SECUNDARIO_BY_ID,
        GET_MERCADO_SECUNDARIO_VSO_BY_ID,
      ],
    })

  async function FormMercadoSecundarioVSO({vso, id, nome}: PutMercadoSecundarioVSOFormData) {
    try {
      return await PutMercadoSecundarioBody({
        variables: {
          mercadoSecundarioVso: {
            vso: vso,
            nome: nome,
            id: id,
          },
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {
    register,
    handleSubmit,
    reset,
    setValue,
    FormMercadoSecundarioVSO,
    loading,
    errors,
    error,
    DataSetMercadoSecundarioVSO,
  }
}

export function MutationDeleteMercadoSecundarioVSO() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<SetMercadoSecundarioVSOFormData>({
    resolver: zodResolver(SetMercadoSecundarioVSOFormSchema),
  })

  const [DeleteMercadoSecundarioBody, {error, loading, data: DataSetMercadoSecundarioVSO}] =
    useMutation<TypesDeleteMercadoSecundarioVSOResponse>(DELETE_MERCADO_SECUNDARIO_VSO, {
      refetchQueries: [GET_MERCADO_SECUNDARIO_VSO],
    })

  async function FormMercadoSecundarioVSO(idDelete: number) {
    try {
      return await DeleteMercadoSecundarioBody({
        variables: {
          mercadoSecundarioVso: {
            id: idDelete,
          },
        },
      })
    } catch (e) {
      console.error('Erro na requisição:', e.message)
      return e.message
    }
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormMercadoSecundarioVSO,
    loading,
    errors,
    error,
    DataSetMercadoSecundarioVSO,
  }
}
