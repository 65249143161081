import {Page, Document, StyleSheet, PDFViewer,Image} from '@react-pdf/renderer'
import {QueryGetEmpreendimentoObservacoes} from '../../graphql/services/Observacoes'
import {useLocation} from 'react-router-dom'
import { Header } from './Header'
import { Info } from './Info'
import { Footer } from './Footer'
import { Observacoes } from './Observacoes'
import { QueryGetAvmLeadByEmpreendimentoId } from '../../graphql/services/AVM'
import { Avaliacoes } from './Avaliacoes'
import { QueryGetEmpreendimentoCorretores, QueryGetEmpreendimentoImoveis } from '../../graphql/services'
import { Corretores } from './Corretores'
import { QueryGetEmpreendimentoProprietarios } from '../../graphql/services/EmpreendimentoProprietarios'
import { Proprietarios } from './Proprietarios'
import { Imoveis } from './Imoveis'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: 'column',
    color: '#1d1d1deb',
    paddingBottom: 65,
  },
  logo: {
    width: 150,
  },
  aviso: {
    color: '#ff0000',
  },
})

const MeuEmpreendimentoReport = ({data, mapImage}) => {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  let printIntes = []
  if (searchParams.get('param')) {
    printIntes = searchParams.get('param').split(',')
  }

  const printElement = {
    info: printIntes.find((i) => i === 'info') ? true : false,
    corretores: printIntes.find((i) => i === 'corretores') ? true : false,
    proprietarios: printIntes.find((i) => i === 'proprietarios') ? true : false,
    observacoes: printIntes.find((i) => i === 'obs') ? true : false,
    fotos: printIntes.find((i) => i === 'fotos') ? true : false,
    condicaoComercial: printIntes.find((i) => i === 'condicaoComercial') ? true : false,
    avaliacoes: printIntes.find((i) => i === 'avaliacoes') ? true : false,
    imoveis: printIntes.find((i) => i === 'imoveis') ? true : false,
    // atividades:false,
    // fichaTerritorial:false,
  }
 


  const {data: observacoes} = QueryGetEmpreendimentoObservacoes({
    variables: {
      pagination: {
        quantidade: null,
        pagina: null,
      },
      idEmpreendimento: data.GetEmpreendimento.id,
    },
  })
  const { data:avaliacoes } = QueryGetAvmLeadByEmpreendimentoId({
    variables: {
        idEmpreendimento: data.GetEmpreendimento.id,
        pagination: {
            pagina: null,
            quantidade: null
        }
    }
})
const corretores = QueryGetEmpreendimentoCorretores({
  variables: {
    pagination: {
      quantidade: null,
      pagina: null,
    },
    idEmpreendimento: data.GetEmpreendimento.id,
  },
})

const {data: dataImoveis} = QueryGetEmpreendimentoImoveis({
  variables: {
    idEmpreendimento: data.GetEmpreendimento.id,
    pagination: {
      quantidade: 50,
      pagina: 0,
    },
  },
})


  // const {data: condicaoComercial} = QueryGetCondicaoComercial({
  //   variables: {
  //     idImovel: data.GetImovel.id,
  //     pagination: {
  //       pagina: null,
  //       quantidade: null,
  //     },
  //   },
  // })
  const proprietarios = QueryGetEmpreendimentoProprietarios({variables: {idEmpreendimento: data.GetEmpreendimento.id}})
  // const {data: fotos} = useQuery<GetImovelImagensResponse>(GET_IMOVEL_IMAGENS, {
  //   variables: {idImovel: data.GetImovel.id},
  // })
  if (!dataImoveis) {
    return <div>Carregando Imóveis</div>
  }

  if (!avaliacoes) {
    return <div>Carregando avaliações</div>
  }

  if (!corretores) {
    return <div>Carregando corretores</div>
  }

  if (!observacoes) {
    return <div>Carregando corretores</div>
  }
  
  // if (!condicaoComercial) {
  //   return <div>Carregando condição comercial</div>
  // }

  if (!proprietarios) {
    return <div>Carregando Proprietários</div>
  }
  // if (!fotos) {
  //   return <div>Carregando Proprietários</div>
  // }
  return (
    <PDFViewer>
      <Document>
        <Page size='A4' style={styles.page}>
          <Header />
          <Image src={`${mapImage}`}/>     
          {printElement.imoveis && <Imoveis imoveis={dataImoveis} />}
          {printElement.info && <Info data={data} />}
          {printElement.observacoes && <Observacoes observacoes={observacoes} />}
          {printElement.avaliacoes && <Avaliacoes avaliacoes={avaliacoes} />}
          {printElement.corretores && <Corretores corretores={corretores} />}
          {printElement.proprietarios && <Proprietarios proprietarios={proprietarios} /> }
          
          {/*{printElement.condicaoComercial ? (
            <CondicaoComercial condicaoComercial={condicaoComercial} />
          ) : (
            ''
          )}

          
          {printElement.fotos ? <Fotos fotos={fotos} /> : ''} */}

          <Footer />
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default MeuEmpreendimentoReport
