import React, {FunctionComponent, useEffect, useState} from 'react'
import {Explorer} from '../../Map/Explorer'
import {BiMap, BiMapPin, BiPin, BiPointer, BiPolygon, BiRuler, BiSelection} from 'react-icons/bi'
import {exportImage} from '../../../../../utils/ExportPdf'
import {Layout} from '../Layout'
import {MapControll} from '../../../../../../store/mapControll'
import {Meassure} from './Measure'
import {mapStore} from '../../../../../../store/mapStore'
import {DrawCircle} from './DrawCircle'
import { DrawArea } from './DrawArea'

export const MapDrawControl: FunctionComponent = () => {
  // const [measure, setMeasure] = useState(false)
  // const {setInfoBox} = mapStore()

  var isMouseDown: boolean, initX: number, initY: number
  useEffect(() => {
    let draggable = document.getElementById('draggable')
    let draggableMove = document.getElementById('mydraggable')
    if (draggable !== null) {
      var height = draggable.offsetHeight,
        width = draggable.offsetWidth

      draggable.style.left =
        document.getElementById('kt_aside_nav_tab_explorer').offsetWidth + 25 + 'px'
      draggable.style.top = '225px'

      draggableMove.addEventListener('mousedown', function (e) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isMouseDown = true
        document.body.classList.add('no-select')
        // eslint-disable-next-line react-hooks/exhaustive-deps
        initX = e.offsetX + 16
        // eslint-disable-next-line react-hooks/exhaustive-deps
        initY = e.offsetY + 8
      })

      draggable.addEventListener('mouseup', function () {
        isMouseDown = false
        document.body.classList.remove('no-select')
      })
      document.addEventListener('mousemove', function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY

          if (cx < 480) {
            cx = 480
          }
          if (cy < 0) {
            cy = 0
          }
          if (window.innerWidth - e.clientX + initX < width) {
            cx = window.innerWidth - width
          }
          if (e.clientY > window.innerHeight - height + initY) {
            cy = window.innerHeight - height
          }
          draggable.style.left = cx + 'px'
          draggable.style.top = cy + 'px'
        }
      })
    }
  })
  const meassure = new Meassure()
  const drawCircle = new DrawCircle()

  // console.log(drawCircle)
  function clearActiveAction() {
    document.querySelectorAll('.btn-action-map').forEach(function (x) {
      x.classList.remove('active')
    })
  }

  function onClickMeasure(e) {
    Layout.setShowTerritorioMenu(false)
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)

    if (!e.currentTarget.classList.contains('active')) {
      clearActiveAction()
      e.currentTarget.classList.add('active')
      meassure.setActive(true)
      MapControll.setMapActionClick('meassure', (event, callback) => {
        meassure.controllClick(event, callback)
      })
    } else {
      meassure.setActive(false)
      meassure.setMeassureDeactive()
      MapControll.removeMapActionClick('meassure')
      clearActiveAction()
      e.currentTarget.classList.remove('active')
    }
  }
  async function onClickPrint() {
    // const convas = document.querySelector("#urbit-map .gm-style").cloneNode(true)
    await exportImage(document.querySelector('#urbit-map'), 'print')
    // html2canvas(document.querySelector("#map"), {useCORS: true}).then(canvas => {

    //   const image = canvas.toDataURL('image/png', 1.0)
    //   downloadImage(image, 'printmap')
    //   // document$('#screenshot').empty().html(canvas);
    // });

    // setMeasure(true)
    // Explorer.setMeasure(true)
    //console.log('print')
  }

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement('a')
    // fakeLink.style = "display:none;";
    fakeLink.download = fileName

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)

    fakeLink.remove()
  }
  function onClickMapDrawCircle(e) {
    Layout.setShowTerritorioMenu(false)
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)

    if (!e.currentTarget.classList.contains('active')) {
      clearActiveAction()
      e.currentTarget.classList.add('active')
      drawCircle.setActive(true)
      // console.log('remove draw-circle')
      MapControll.setMapActionMove('draw-circle', (event, callback) => {
        drawCircle.controllMove(event, callback)
      })
    } else {
      // console.log('init draw-circle')
      drawCircle.setActive(false)
      MapControll.removeMapActionMove('draw-circle')
      clearActiveAction()
      e.currentTarget.classList.remove('active')
    }
  }
  function onClickMapDraw(e, type) {
    Layout.setShowTerritorioMenu(false)
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)
    // // Explorer.setDraw(explorer.draw)

    if (!e.currentTarget.classList.contains('active')) {
      clearActiveAction()
      e.currentTarget.classList.add('active')
      MapControll.setMode('DRAW_CIRCLE')
      // MapControll.setInfoBox('distancia', '0m', true)
      Explorer.setDrawMode(type)
    } else {
      clearActiveAction()
      e.currentTarget.classList.remove('active')
      Explorer.setDrawMode('')
      MapControll.setMode('')
      // MapControll.setInfoBox('distancia', '', false)
    }
  }

  // const onClickMapDrawFree= (e, type)=>{
  //   if (!e.currentTarget.classList.contains('active')) {
  //       e.currentTarget.classList.add('active')
  //       DrawFree.draw()
  //   }else{
  //       e.currentTarget.classList.remove('active')
  //       DrawFree.limpa('')
  //   }
  // }
  //   function handlePositionReceived({coords}) {
  //     // const {latitude, longitude} = coords
  //     // setLocation({latitude, longitude})
  //   }
  return (
    <>
      <div id='draggable' className='no-select p-7 shadow'>
        <div id='mydraggable'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            fill='currentColor'
            viewBox='0 0 16 16'
          >
            <path
              fillRule='evenodd'
              d='M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z'
            />
          </svg>
        </div>

        <ul className='list-unstyled mb-0 d-flex flex-column align-items-center pt-3 mt-3 border-top'>
          <li className='mb-4'>
            <button
              className='d-flex p-3 btn btn-success  btn-action-map'
              onClick={(e) => {
                onClickMapDraw(e, 'marker')
              }}
            >
              {' '}
              <BiMap className='fs-1'></BiMap>
            </button>
          </li>
          <li className='mb-4'>
            <button
              className='d-flex p-4  btn-action-map'
              onClick={(e) => {
                onClickMapDraw(e, '')
              }}
            >
              <BiPointer className='fs-1'></BiPointer>
            </button>
          </li>

          <li className='mb-4'>
            <button
              className='d-flex p-4 btn-action-map'
              onClick={(e) => {
                onClickMapDrawCircle(e)
              }}
            >
              <BiPolygon className='fs-1'></BiPolygon>
            </button>
          </li>

          <li className='mb-4'>
            <button
              className='d-flex p-3 btn-action-map'
              onClick={(e) => {
                onClickMapDraw(e, 'polygon')
              }}
            >
              <BiSelection className='fs-1'></BiSelection>
            </button>
          </li>
          <li className='mb-4'>
            <button
              className='d-flex p-3 btn-action-map'
              onClick={(e) => {
                // onClickMapDraw(e, 'polyline')
                onClickMeasure(e)
              }}
            >
              <BiRuler className='fs-1'></BiRuler>
            </button>
          </li>
          {/* <li className='mb-4'>
            <DrawArea></DrawArea>
          </li> */}
          {/* <li className='mb-4'>
            <button
              className='d-flex p-3 btn-action-map'
              onClick={(e) => {
                // onClickMapDraw(e, 'polygon')
                onClickPrint()
              }}
            >
              <BiPrinter className='fs-1'></BiPrinter>
            </button>
          </li> */}
          {/* <li className='mb-auto'>
            <div className='dropdown'>
              <button
                className='d-flex p-2 dropdown-toggle'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  fill='currentColor'
                  viewBox='0 0 16 16'
                >
                  <path
                    className='fill-secondary'
                    d='M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z'
                  ></path>
                  <path d='M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z'></path>
                  <path d='M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z'></path>
                </svg>
              </button>
              <ul className='dropdown-menu p-2 shadow border-0'>
                <li>
                  <button className='dropdown-item'>UI UX Design</button>
                </li>
                <li>
                  <button className='dropdown-item'>Web Development</button>
                </li>
                <li>
                  <button className='dropdown-item'>Mobile Development</button>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li className='mb-4'>
            <button className='d-flex p-2 btn-action-map'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                fill='currentColor'
                viewBox='0 0 16 16'
              >
                <path
                  fillRule='evenodd'
                  d='M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z'
                />
              </svg>
            </button>
          </li>
          <li>
            <button className='d-flex p-2'>
              <svg
                width='17'
                height='17'
                viewBox='0 0 15 17'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M12.7861 0.130831C12.2842 -0.145667 10.846 0.0254157 9.11824 0.555946C6.0944 2.60779 3.54282 5.63141 3.36471 10.4857C3.33222 10.602 3.03401 10.4701 2.97483 10.4338C2.15854 8.88026 1.8348 7.24374 2.5165 4.88371C2.64356 4.67346 2.22816 4.41482 2.15331 4.48913C2.00363 4.63775 1.37995 5.29788 0.965709 6.01102C-1.08809 9.54846 0.255001 14.0807 3.84799 16.0646C7.4404 18.0513 11.9767 16.7697 13.9772 13.2017C16.2915 9.08418 14.1425 0.883135 12.7861 0.130831Z'></path>
              </svg>
            </button>
          </li> */}
        </ul>
      </div>

      {/* <div className='buttons-map-draw'>
        <button
          className=' btn btn-light btn-action-map'
          onClick={(e) => {
            onClickMapDraw(e, '')
          }}
        >
         
        </button>
        <button
          className=' btn btn-light btn-action-map'
          onClick={(e) => {
            onClickMapDraw(e, 'rectangle')
          }}
        >
          <img src={`${IconPath}rectangle-pt-svgrepo-com.svg`} alt='' width='25px' />
        </button>
        <button
          className=' btn btn-light  btn-action-map'
          onClick={(e) => {
            onClickMapDraw(e, 'circle')
          }}
        >
          <img src={`${IconPath}polygon-svgrepo-com.svg`} alt='' width='25px' />
        </button>
        <button
          className=' btn btn-light  btn-action-map'
          onClick={(e) => {
            onClickMapDraw(e, 'marker')
          }}
        >
          <img src={`${IconPath}layer-poi-svgrepo-com.svg`} alt='' width='25px' />
        </button>
        <button
          className=' btn btn-light  btn-action-map'
          onClick={(e) => {
            onClickMapDraw(e, 'polygon')
          }}
        >
          <img src={`${IconPath}polygon-pt-svgrepo-com.svg`} alt='' width='25px' />
        </button>

         <button className=" btn btn-light  " onClick={(e)=>{onClickMapDrawFree(e,'free') }} >
        <img src={`${IconPath}polygon-pt-svgrepo-com.svg` } alt="" width='25px'/>
            </button> 
      </div> */}
      <div>{/* <img id="map_image" src={'' }></img> */}</div>
    </>
  )
}
