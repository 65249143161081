import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'

import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import '../global.css'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import {Footer} from '../../../_metronic/layout/components/Footer'

import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {Table} from './partials/TableRelatorio'
import {KTSVG} from '../../../_metronic/helpers'
import {Form, FormLabel} from 'react-bootstrap'
import {GetEmpreendimentoTipoChosen} from './components/Chosen/GetEmpreendimentoTipoChosen'
import {ListsWidget6} from '../../../_metronic/partials/widgets'
import {InfosRelatorios} from './partials/InfosRelatorios'

export function RelatorioEmpreendimento() {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const [toogleFilter, setToogleFilter] = useState(false)
  const [toogleInfos, setInfos] = useState(true)

  const [descart, setDescart] = React.useState(false)

  const [filter, setFilter] = useState([])

  function addFilter(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    const filterArray = []

    if (data.get('nome')) {
      filterArray.push({
        field: ['nome', 'endereco'],
        operator: 'LIKE',
        value: data.get('nome'),
      })
    }

    setFilter([...filterArray])
    return false
  }

  const handleChecked = (e) => {
    setDescart(e.target.checked)
  }

  function toggleInfos() {
    if (toogleInfos === true) {
      setInfos(false)
    } else if (toogleInfos === false) {
      setInfos(true)
      setToogleFilter(false)
    }
  }

  function toggleFilter() {
    if (toogleFilter === false) {
      setInfos(false)
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Relatório - Empreendimentos' />
      {/* <Empreendimentos /> */}
      <div className='wrapper d-flex flex-column flex-column-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            {/* <HeaderPage /> */}
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Relatório</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/dashboard' className='text-muted'>
                        Dashboard
                      </a>
                    </li>
                    <li className='breadcrumb-item text-muted'>Empreendimentos</li>
                    <li className='breadcrumb-item text-muted'>Relatórios</li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'></div>
              </div>
              <div className='content  d-flex flex-column flex-column-fluid '>
                <div className={toogleFilter === true ? 'card show mb-10' : ' card hide mb-10'}>
                  <div className='border-0 pt-6 ps-5 pe-5'>
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className='card-xxl-stretch '>
                          <div className='card-form px-4 py-8 mb-2'>
                            <Form className='form form-horizontal' onSubmit={addFilter}>
                              <div className='row'>
                                <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Nome/Endereço</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Digite o nome ou Endereço'
                                    name='nome'
                                  />
                                </div>
                                <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Situação</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Situação'
                                  />
                                </div>
                                <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Zoneamento</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Zoneamento'
                                  />
                                </div>
                              </div>

                              <div className='row  mt-3'>
                                <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tipo</FormLabel>
                                  <GetEmpreendimentoTipoChosen />
                                </div>
                                <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tags</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Tags'
                                  />
                                </div>
                                <div className='col-md-4'>
                                  <div className='form-check'>
                                    <label className='form-check-label'>
                                      <input
                                        className='form-check-input'
                                        // checked={check}
                                        // onChange={handleChecked}
                                        type='checkbox'
                                        id='gridCheck'
                                      />
                                      Exibir Descartados
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row  mt-3'>
                                <div className='col-md-4'>
                                  <div className='col-md-2'>
                                    <button type='submit' className='btn btn-primary'>
                                      Buscar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={toogleInfos === true ? 'card show mb-10' : ' card hide mb-10'}>
                  <div className='border-0 pt-2'>
                    <div className='col-xl-12'>
                      <div className='card-xxl-stretch '>
                        <InfosRelatorios
                          icon='/media/icons/duotune/general/gen046.svg'
                          title='Informações Relevantes'
                          className='card-xl-stretch mb-xl-8'
                          filter={[]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='content  d-flex flex-column flex-column-fluid'>
                <Table
                  toggleInfos={toggleInfos}
                  active={descart}
                  filtro={filter}
                  toggleFilter={toggleFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
