import {ReactChild, useContext} from 'react'
import { useImoveisStore } from '../context/DataContext'
import { formatNumber } from '../../../components/util'


type Props = {
  titulo: string
  children?: ReactChild
  showSubHeader?: boolean
}

export function Header({titulo, showSubHeader = true, children}: Props) {
  // const {filter} = useContext(FilterContext)
  // const {filtro} = useImoveisStore()

  return (
    <>
      <div className='header-report  '>
        <div className='d-flex flex-row all-start-between'>
          <div className=' '>
            <h1 className='fw-bolder'>{titulo}</h1>
            {showSubHeader ? (
              <></>
              // <span>
              //   PERFORMANCE {filtro.anoLancamento[0]} A {filtro.anoLancamento[1]}
              // </span>
            ) : (
              <></>
            )}
          </div>

          <div>{children}</div>
        </div>
        <div>
          {/* <ul className='filter-list'> */}
            {/* <li>
              <h3>Filtros</h3>
            </li>
            <li>
              Área de Influência: <br></br>
              {filtro.areaInfluencia.tipo === 'ZONAVALOR' ? 'Zona de Valor' : ''}
              {filtro.areaInfluencia.tipo === 'RAIO'
                ? `Raio de ${filtro.areaInfluencia.valor} metros `
                : ''}{' '}
              {filtro.areaInfluencia.tipo === 'ISOCRONA'
                ? `Isocrona de ${filtro.areaInfluencia.valor}min `
                : ''}{' '}
              {filtro.areaInfluencia?.tipo === 'DEFINIRMANUALMENTE' ? `Definido manualmente ` : ''}
            </li>
            {filtro.faixaMetragem ? filtro.faixaMetragem[0] !== 10 || filtro.faixaMetragem[1] !== 1000 ? (
              <li>
                Área útil:<br></br> {formatNumber(filtro.faixaMetragem[0], 'inteiro')} até{' '}
                {formatNumber(filtro.faixaMetragem[1], 'inteiro')}{' '}
              </li>
            ) : 
              ''
            : ''  } */}
            {/* {filtro.dormitorios ? (
              <li>
                Dormitórios:<br></br>
                {filtro.dormitorios.length > 0 ? filtro.dormitorios.join(',') : 'Todos'}
              </li>
            ) : (
              ''
            )} */}
          
            
           
           
            
            {/* {filtro.faixaTicket[0] !== 100000 || filtro.faixaTicket[1] !== 30000000 ? (
              <li>
                Preço Total:<br></br> {formatNumber(filtro.faixaTicket[0])} até{' '}
                {formatNumber(filtro.faixaTicket[1])}{' '}
              </li>
            ) : (
              ''
            )} */}
            {/* <li>
              Tipologias:<br></br> {filtro.tipologia.join(',')}{' '}
            </li> */}
            {/* <li>segmentacaoArea: {filter.segmentacaoArea} </li>
            <li>segmentacaoPreco: {filter.segmentacaoPreco} </li> */}
          {/* </ul> */}
        </div>
      </div>
    </>
  )
}
