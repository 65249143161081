import {useState} from 'react'
import {Button, Offcanvas} from 'react-bootstrap'


import {BiLink} from 'react-icons/bi'
import { Loader } from './Loader/Loader'
import { QueryGetImovel } from '../../graphql'

type Props = {
  id: number
  nome: string
}

const PanelImovel: React.FC<Props> = ({id, nome, children}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const {data} = QueryGetImovel({variables: {getImovelId: id}})
  
  return (
    <>
      <Button variant='primary pulse' className='ms-2' onClick={handleShow}>
        {nome}
        <span className='pulse-ring ' style={{height: 'auto'}}></span>
      </Button>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h1>Local associado ao projeto</h1>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-5'>
          {!data ? <Loader text={'Carregando dados do imóvel...'}></Loader> : <></>}
          {data ? (
            <>
              <div className='row'>
                <div className='col-12 '>
                  <div className='row mb-3 d-flex flex-row  align-items-center justify-content-between'>
                    <h2 className='w-50'>{nome}</h2>
                    <a
                      href={`/meus-imoveis/details/${id}#informacoes-lote`}
                      className='btn btn-info w-25'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Abrir <BiLink></BiLink>
                    </a>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Endereço</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>{data.GetImovel.endereco}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Número</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.numero || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Complemento</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.complemento || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Cep</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.cep || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Bairro</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.bairro || '-'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Área IPTU</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.area_iptu || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Área Real</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.area_real || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Cabas</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.cabas || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Camax</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.camax || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Zoneamento</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.zoneamento || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Número Contribuinte</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.numero_contribuinte || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <label className='col-lg-4 fw-semibold text-muted'>Nome Contribuinte</label>
                    <div className='col-lg-8'>
                      <span className='fw-bold fs-6 text-gray-800'>
                        {data.GetImovel.nome_contribuinte || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default PanelImovel
