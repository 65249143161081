import {BiDownload, BiExport, BiLoader} from 'react-icons/bi'
import {useContext, useState} from 'react'
import {DataContext} from '../../context/DataContext'
import {FilterContext} from '../../context/FilterContext'
import {ExportSlide} from './ExportSlide'
import {default as legendaRenda} from '../../../../../img/legenda-renda.png'

import {formatDistancia, formatNumber} from '../../../explorer/components/common'
import {
  CloseLoaderInfoOverlay,
  LoaderInfoOverlay,
} from '../../../../components/UI/Loader/LoaderOverlay'

import {Mapas} from './Mapas'
import LegendaIsocronas from './MapBox/LegendaIsocronas'
import {Dicionario} from '../../../../utils/Dicionario'

export function ExportarEstudo({id}: {id: number}) {
  const {data, cacheResult} = useContext(DataContext)
  const {endereco, dadosProjetoSalvo, filter} = useContext(FilterContext)
  const [mapImage, setMapImage] = useState({
    map1: null,
    map2: null,
    map3: null,
  })

  const [legendaDistancia, setLegendaDistancia] = useState(null)
  const [generateMap, setGenerateMap] = useState(false)
  const handleExport = async () => {

    
    // const COLORS_SPECTRUM = [
    //   '56B4E4',
    //   '126CB0',
    //   '672C7E',
    //   'E92A31',
    //   'F06826',
    //   'E9AF1F',
    //   '51B747',
    //   '189247',
    // ]
    const slide = new ExportSlide('IM')
    slide.filterAno = filter.anoLancamento
    slide.addCapa('Inteligência de Mercado', endereco.endereco_completo)
    
    
    //SLIDE -   Sociodemografico
    slide.addSlide('Sociodemográfico', (slideT) => {
      slideT.addImage({data: mapImage.map1, x: 0.6, y: 1.4, w: 6.8, h: 5.5})
      slideT.addImage({data: legendaDistancia, x: 0.7, y: 6.2, w: 0.9, h: 0.6})
      // slideT.addImage({data: legendaRenda, x: 0.7, y: 6.2, w: 0.9, h: 0.6})
      slideT.addImage({data: legendaRenda, x: 4.5, y: 6.2, w: 2.6, h: 0.6})

      let tableData = []
      tableData.push([
        {text: 'Indicador', options: {color: '333333', align: 'left', bold: true}},
        {text: 'Valor', options: {color: '333333', align: 'center', bold: true}},
      ])
      
      // eslint-disable-next-line array-callback-return
      Object.entries(cacheResult.sociodemografico).map((itemEntries) => {
        let item = itemEntries[1]
        let valor = ''
        if (item['resultado']) {
          if (item['tipo'] === 'qtde') {
            if (item['resultado']['itens'].length > 0) {
              valor = item['resultado']['total']
            } else {
              valor = 'NÃO'
            }
          } else {
            console.log("item['resultado']")
            console.log(item['resultado'])
            console.log("item['resultado']['itens'][0]")
            console.log(item['resultado']['itens'][0])

            console.log("item['key']")
            console.log(item['key'])
            console.log("item['format']")
            console.log(item['format'])
            if (item['resultado']['itens'].length > 0 ){
              valor = item['format'](item['resultado']['itens'][0][item['key']])
            }else{
              valor ='N/A'
            }
          }

          tableData.push([
            {
              text: item['nome'] + ' (' + formatDistancia(item['distancia']) + ')',
              options: {color: '333333', align: 'left', bold: false},
            },
            {text: valor, options: {color: '333333', align: 'center', bold: false}},
          ])
        }
      })
      const slideconfigTable1 = {
        slide: slideT,
        tableData: tableData,
        //
        config: {
          x: 7.8,
          y: 1.4,
          w: 5.2,
          margin: 3,
          fill: {color: 'ffffff'},
          border: {pt: 0, color: 'e0e0e0'},
          fontSize: 10,
        },
      }

      slide.addTable(slideconfigTable1)
    })

    const removecolunas = [
      'id',
      'geom',
      'entretenimento',
      'bairro',
      'cep',
      'site',
      'areatotal',
      'areaconstruida',
      'areabruta',
      'telefone',
      'rua',
      'salascinema',
      'lojaancora',
    ]

    let count = 0.2
    let start = 1.3
    cacheResult.sociodemografico.map((item) => {
      if (item.tipo === 'qtde') {
        let tableData = []
        if (item.resultado?.itens.length > 0) {
          slide.addSlide('Sociodemográfico', (slideT) => {
            let tmp = []
            slide.addText(
              Dicionario.translate(item.resultado?.service) + ' em ' + item.distancia + 'metros',
              {
                x: 0.5,
                y: start,
                w: '100%',
                h: 0.6,
                align: 'left',
                fontSize: 24,
                textTransform: 'upper',
                color: '333',
                fontFace: 'Arial',
              },
              slideT
            )

            Object.entries(item.resultado?.itens[0]).map((col, index) => {
              if (removecolunas.indexOf(col[0]) === -1) {
                tmp.push({
                  text: Dicionario.translate(col[0]),
                  options: {color: '333333', align: 'left', bold: true},
                })
              }
            })

            tableData.push(tmp)
            item.resultado?.itens.map((item, index) => {
              let tmpData2 = []
              Object.entries(item).map((col, indexCol) => {
                if (removecolunas.indexOf(col[0]) === -1) {
                  tmpData2.push({
                    text: col[1],
                    options: {color: '333333', align: 'left', bold: false},
                  })
                }
              })
              tableData.push(tmpData2)
              return false
            })
            const slideconfigTable1 = {
              slide: slideT,
              tableData: tableData,
              config: {
                x: 0.5,
                y: start + 0.8,
                w: '92%',
                fill: {color: 'ffffff'},
                border: {pt: 0, color: 'e0e0e0'},
                fontSize: 10,
                autoPage: true,
                addHeaderToEach: true,
                colW: [3, 1.2, 2, 2, 3.5, 0.9],
              },
            }
            slide.addTable(slideconfigTable1)
            count = count + 0.2
          })
        }
      }
      //   // eslint-disable-next-line array-callback-return
      //   list ? (
      //     list.length > 0 ? (
      //       // eslint-disable-next-line array-callback-return
      //       Object.entries(list[0]).map((col, index) => {
      //         if (removecolunas.indexOf(col[0]) < -1) {
      //           return <th key={index}>{Dicionario.translate(col[0])}</th>
      //         }
      //       })
      //     ) : (
      //       <></>
      //     )
      //   ) : (
      //     <></>
      //   )
      // }
      //     let item = itemEntries[1]
      //     let valor = ''
      //     if (item['resultado']) {

      // tableData.push([
      //   {text: 'Estação', options: {color: '333333', align: 'left', bold: true}},
      //   {text: 'Linha', options: {color: '333333', align: 'center', bold: true}},
      //   {text: 'Situação', options: {color: '333333', align: 'center', bold: true}},
      //   {text: 'Tecnologia', options: {color: '333333', align: 'center', bold: true}},
      //   {text: 'Distancia', options: {color: '333333', align: 'center', bold: true}},
      // ])
      // tableData.push([
      //   {
      //     text: 'MARECHAL DEODORO',
      //     options: {color: '333333', align: 'left', bold: false},
      //   },
      //   {text: 'LINHA 3 - VERMELHA', options: {color: '333333', align: 'center', bold: false}},
      //   {text: 'Operando', options: {color: '333333', align: 'center', bold: false}},
      //   {text: 'Metro', options: {color: '333333', align: 'center', bold: false}},
      //   {text: '402', options: {color: '333333', align: 'center', bold: false}},
      // ])
    })

    //SLIDE -   Resumo Mercado
    slide.addSlide('Resumo Mercado', (slideT) => {
      slideT.addImage({data: mapImage.map2, x: 0.6, y: 1.4, w: 7, h: 5.5})
      slideT.addImage({data: legendaDistancia, x: 0.7, y: 6.2, w: 0.9, h: 0.6})

      let tableData = []
      tableData.push([
        {text: '#', options: {color: '333333', align: 'center', bold: true, w: 0.05}},
        {text: 'Produto', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Incorporadora', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Distância', options: {color: '333333', align: 'center', bold: true}},
      ])

      data.sort(function (a, b) {
        return a.distancia - b.distancia
      })
      let count = 1
      // eslint-disable-next-line array-callback-return
      data.map((item, index) => {
        if (!Array.isArray(item)) {
          if (item.computed && !item.removed) {
            if (count <= 15) {
              tableData.push([
                {text: index + 1, options: {color: '333333', align: 'center'}},
                {
                  text: item.nome,
                  options: {color: '333333', align: 'center', bold: false},
                },
                {
                  text: item.nome_incorporador,
                  options: {color: '333333', align: 'center', bold: false},
                },
                {
                  text: formatNumber(Math.round(item.distancia), 'inteiro'),
                  options: {color: '333333', align: 'center', bold: false},
                },
              ])
            }
            count++
          }
        }
      })

      const slideconfigTable1 = {
        slide: slideT,
        tableData: tableData,
        config: {
          x: 7.8,
          y: 1.4,
          w: 5.2,
          margin: 3,
          fontSize: 10,
          fill: {color: 'ffffff'},
          border: {pt: 1, color: 'd0d0d0'},
        },
      }

      slide.addTable(slideconfigTable1)
    })

    //SLIDE -   Performance

    slide.addSlide('Performance', (slideT) => {
      //Linha 1
      slide.addBox({
        slide: slideT,
        titulo: 'PREÇO MÉDIO M² ESTOQUE',
        subtitulo: 'Valor do preço médio do m² do estoque',
        valor: formatNumber(Math.round(cacheResult.performance.precoMedioM2Estoque), 'currency'),
        config: {
          x: 0.4,
          y: 1.2,
          w: 3.3,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      slide.addBox({
        slide: slideT,
        titulo: 'VGV LANÇADO',
        subtitulo: 'Total do VGV lançado',
        valor: formatNumber(Math.round(cacheResult.performance.vgvLancado), 'currency'),
        config: {
          x: 3.8,
          y: 1.2,
          w: 3.2,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      //Linha 2
      slide.addBox({
        slide: slideT,
        titulo: 'VGV VENDIDO',
        subtitulo: 'Total do VGV Vendido',
        valor: formatNumber(Math.round(cacheResult.performance.vgvVendido), 'currency'),
        config: {
          x: 0.4,
          y: 2.5,
          w: 3.3,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      slide.addBox({
        slide: slideT,
        titulo: 'VGV ESTOQUE',
        subtitulo: 'Total do VGV do estoque',
        valor: formatNumber(Math.round(cacheResult.performance.vgvEstoque), 'currency'),
        config: {
          x: 3.8,
          y: 2.5,
          w: 3.3,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      //Linha 3
      slide.addBox({
        slide: slideT,
        titulo: 'PRODUTOS LANÇADOS',
        subtitulo: 'Total de Produtos Lançados',
        valor: cacheResult.performance.totalProdutosLancados,
        config: {
          x: 0.4,
          y: 3.9,
          w: 3.3,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      slide.addBox({
        slide: slideT,
        titulo: 'UNIDADES LANÇADAS',
        subtitulo: 'Total de unidades lançadas',
        valor: cacheResult.performance.unidadesLancadas,
        config: {
          x: 3.8,
          y: 3.9,
          w: 3.2,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      //Linha 4

      slide.addBox({
        slide: slideT,
        titulo: 'UNIDADES VENDIDAS',
        subtitulo: 'Total de unidades vendidas',
        valor: cacheResult.performance.unidadesVendidas,
        config: {
          x: 0.4,
          y: 5.2,
          w: 3.2,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      slide.addBox({
        slide: slideT,
        titulo: 'ESTOQUE',
        subtitulo: 'Total de unidades em estoque',
        valor: cacheResult.performance.estoque,
        config: {
          x: 3.8,
          y: 5.2,
          w: 3.2,
          h: 1.3,
          margin: 15,
          fill: {color: 'ffffff'},
          borderColor: '333333',
          borderTopLeftRadius: 5,
          lineSpacingMultiple: 1.5,
        },
      })

      let listaEmpLancados = []
      let listaUnidadesLancados = []
      listaEmpLancados.push([
        {text: 'Ano', options: {color: '333333', align: 'left', bold: true}},
        {text: 'Qtde', options: {color: '333333', align: 'center', bold: true}},
      ])
      // eslint-disable-next-line array-callback-return
      Object.entries(cacheResult.performance.lancamentosPorAno).map((item, index) => {
        listaEmpLancados.push([
          {
            text: item[0],
            options: {color: '333333', align: 'left', bold: true},
          },
          {text: item[1], options: {color: '333333', align: 'center', bold: false}},
        ])
      })

      listaUnidadesLancados.push([
        {text: 'Ano', options: {color: '333333', align: 'left', bold: true}},
        {text: 'Qtde', options: {color: '333333', align: 'center', bold: true}},
      ])
      // eslint-disable-next-line array-callback-return
      Object.entries(cacheResult.performance.unidadesLancadasPorAno).map((item, index) => {
        listaUnidadesLancados.push([
          {
            text: item[0],
            options: {color: '333333', align: 'left', bold: true},
          },
          {text: item[1], options: {color: '333333', align: 'center', bold: false}},
        ])
      })

      //Linha 3
      slide.addBoxList({
        slide: slideT,
        titulo: 'LANÇAMENTOS POR ANO',
        subtitulo: 'Total de empreendimentos lançados',
        valor: '7',
        lista: listaEmpLancados,
        config: {
          x: 7.1,
          y: 1.2,
          w: 3.2,
          h: 1.2,
          fill: {color: 'ffffff'},
        },
      })

      //Linha 3
      slide.addBoxList({
        slide: slideT,
        titulo: 'UNIDADES LANÇADAS POR ANO',
        subtitulo: 'Total de unidades lançadas',
        valor: '7',
        lista: listaUnidadesLancados,
        config: {
          x: 10,
          y: 1.2,
          w: 3.2,
          h: 1.2,
          fill: {color: 'ffffff'},
        },
      })
    })

    //SLIDE -   Performance -   1
    slide.addSlide('Performance - Resumo Mercado ', (slideT) => {
      //COL1 Preço médio estoque

      let chartDataPrecoMedio = []
      let chartDataPrecoMedioLabels = []
      let chartDataPrecoMedioValues = []
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.precoMedioEstoque.chartData.map((item) => {
        chartDataPrecoMedioLabels.push(item.x)
        chartDataPrecoMedioValues.push(item.y[2])
      })

      chartDataPrecoMedio.push({
        name: 'Preço Médio ',
        labels: chartDataPrecoMedioLabels,
        values: chartDataPrecoMedioValues,
      })

      const slideconfig1 = {
        slide: slideT,
        type: 'bar',
        charData: chartDataPrecoMedio,
        config: {
          x: 0.5,
          y: 1.4,
          w: 6.0,
          h: 3,
          chartArea: {fill: {color: 'ffffff'}},
          plotArea: {fill: {color: 'FFFFFF'}},
          catAxisLineShow: false,
          valAxisLineShow: false,
          barDir: 'col',
          title: 'Preço Médio em estoque - R$/m²',
          // catAxisLineStyle:'dashad',
          valAxisHidden: false,
          valGridLine: {
            size: 1,
            style: 'dash',
            color: 'e4e4e4',
          },
          //showPercent:true,
          // barGapWidthPct: 25,
          chartColors: ['a7a7a7'],

          // chartColorsOpacity: 50,
          valAxisMinVal: 0,
          lineSize: 0,
          // valAxisMaxVal: 100,

          catAxisLabelColor: '333333',
          catAxisLabelFontFace: 'Arial',
          catAxisLabelFontSize: 11,
          catAxisLabelFrequency: 1,
          catAxisOrientation: 'minMax',

          // dataBorder: {pt: 1, color: '525252'},
          // dataLabelColor: '696969',
          dataLabelFontFace: 'Arial',
          dataLabelFontSize: 10,
          dataLabelPosition: 'outEnd',
          showValue: true,

          // valAxisHidden: true,
          catAxisTitle: 'Dormitórios',
          showCatAxisTitle: true,

          showLegend: true,
          showTitle: true,
        },
      }
      slide.addChartSingle(slideconfig1)

      let tableData = []
      tableData.push([
        {text: 'Dormitórios', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Mínimo', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Mediana', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Máximo', options: {color: '333333', align: 'center', bold: true}},
        // {text: 'Total Unidades', options: {color: '333333', align: 'center', bold: true}},
        // {text: 'Vendidas', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Estoque', options: {color: '333333', align: 'center', bold: true}},
      ])
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.precoMedioEstoque.tableData.map((item) => {
        if (!Array.isArray(item)) {
          tableData.push([
            {text: item.dormitorios, options: {color: '333333', align: 'center'}},
            {
              text: formatNumber(Math.round(item.min), 'currency'),
              options: {color: '333333', align: 'center', bold: false},
            },
            {
              text: formatNumber(Math.round(item.mediana), 'currency'),
              options: {color: '333333', align: 'center', bold: false},
            },
            {
              text: formatNumber(Math.round(item.max), 'currency'),
              options: {color: '333333', align: 'center', bold: false},
            },
            // {text: item.total_unidades, options: {color: '333333', align: 'center', bold: false}},
            // {text: item.vendas, options: {color: '333333', align: 'center', bold: false}},
            {text: item.estoque, options: {color: '333333', align: 'center', bold: false}},
          ])
        }
      })

      const slideconfigTable1 = {
        slide: slideT,
        tableData: tableData,
        config: {
          x: 0.3,
          y: 4.4,
          w: 6.5,
          margin: 3,
          fontSize: 10,
          fill: {color: 'ffffff'},
          border: {pt: 1, color: 'd0d0d0'},
        },
      }

      slide.addTable(slideconfigTable1)

      let chartDataVsoPrograma = []
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.vsoPrograma.chartData.map((item) => {
        chartDataVsoPrograma.push({
          name: item.name,
          labels: item.data.map((dataItem, index) => dataItem.x),
          values: item.data.map((dataItem, index) => dataItem.y),
        })
      })
      //COL2 VSO Programa
      const slideconfig = {
        slide: slideT,
        type: 'bar',
        charData: chartDataVsoPrograma,
        config: {
          x: 7.0,
          y: 1.4,
          w: 6.0,
          h: 3,
          chartArea: {fill: {color: 'ffffff'}},
          plotArea: {fill: {color: 'FFFFFF'}},
          catAxisLineShow: false,
          valAxisLineShow: false,
          barDir: 'col',
          title: 'VSO Por Programa',
          // catAxisLineStyle:'dashad',
          valAxisHidden: false,
          valGridLine: {
            size: 1,
            style: 'dash',
            color: 'e4e4e4',
          },
          //showPercent:true,
          // barGapWidthPct: 25,
          chartColors: ['#a7a7a7', '#525252'],

          // chartColorsOpacity: 50,
          valAxisMinVal: 0,
          lineSize: 0,
          valAxisMaxVal: 100,

          catAxisLabelColor: '333333',
          catAxisLabelFontFace: 'Arial',
          catAxisLabelFontSize: 11,
          catAxisLabelFrequency: 1,
          catAxisOrientation: 'minMax',

          // dataBorder: {pt: 1, color: '525252'},
          // dataLabelColor: '696969',
          dataLabelFontFace: 'Arial',
          dataLabelFontSize: 10,
          dataLabelPosition: 'outEnd',
          showValue: true,

          // valAxisHidden: true,
          catAxisTitle: 'Dormitórios',
          showCatAxisTitle: true,

          showLegend: true,
          showTitle: true,
        },
      }
      slide.addChartSingle(slideconfig)

      let tableDataVSO = []
      tableDataVSO.push([
        {text: 'Dormitórios', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Total Unidades', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Estoque', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Vendidas', options: {color: '333333', align: 'center', bold: true}},
      ])
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.vsoPrograma.tableData.map((item) => {
        if (!Array.isArray(item)) {
          tableDataVSO.push([
            {text: item.domitorios, options: {color: '333333', align: 'center', bold: false}},
            {text: item.total_unidades, options: {color: '333333', align: 'center', bold: false}},
            {text: item.estoque, options: {color: '333333', align: 'center', bold: false}},
            {text: item.vendidas, options: {color: '333333', align: 'center', bold: false}},
          ])
        }
      })
      const slideconfigTable = {
        slide: slideT,
        tableData: tableDataVSO,
        config: {
          x: 7.0,
          y: 4.4,
          w: 6.0,
          margin: 3,
          fontSize: 10,
          fill: {color: 'ffffff'},
          border: {pt: 1, color: 'd0d0d0'},
        },
      }

      slide.addTable(slideconfigTable)
    })

    //SLIDE -   Performance - Resumo Mercado 2
    slide.addSlide('Performance - Resumo Mercado', (slideT) => {
      //COL1 - Faixa Metragem

      let chartDataVSOFaixaMetragem = []
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.faixaMetragem.chartData.map((item) => {
        chartDataVSOFaixaMetragem.push({
          name: item.name,
          labels: item.data.map((dataItem, index) => dataItem.x),
          values: item.data.map((dataItem, index) => dataItem.y),
        })
      })

      const slideconfig1 = {
        slide: slideT,
        type: 'bar',
        charData: chartDataVSOFaixaMetragem,
        config: {
          x: 0.5,
          y: 1.4,
          w: 6.0,
          h: 3,
          chartArea: {fill: {color: 'ffffff'}},
          plotArea: {fill: {color: 'FFFFFF'}},
          catAxisLineShow: false,
          valAxisLineShow: false,
          barDir: 'col',
          title: 'VSO por Faixa de Metragem',
          // catAxisLineStyle:'dashad',
          valAxisHidden: false,
          valGridLine: {
            size: 1,
            style: 'dash',
            color: 'e4e4e4',
          },
          //showPercent:true,
          // barGapWidthPct: 25,
          chartColors: ['#a7a7a7', '#525252'],

          // chartColorsOpacity: 50,
          valAxisMinVal: 0,
          lineSize: 0,
          valAxisMaxVal: 100,

          catAxisLabelColor: '333333',
          catAxisLabelFontFace: 'Arial',
          catAxisLabelFontSize: 11,
          catAxisLabelFrequency: 1,
          catAxisOrientation: 'minMax',

          // dataBorder: {pt: 1, color: '525252'},
          // dataLabelColor: '696969',
          dataLabelFontFace: 'Arial',
          dataLabelFontSize: 10,
          dataLabelPosition: 'outEnd',
          showValue: true,

          // valAxisHidden: true,
          catAxisTitle: 'Dormitórios',
          showCatAxisTitle: true,

          showLegend: true,
          showTitle: true,
        },
      }
      slide.addChartSingle(slideconfig1)

      let tableData = []
      tableData.push([
        {text: 'Metragem', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Total Unidades', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Estoque', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Vendidas', options: {color: '333333', align: 'center', bold: true}},
      ])
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.faixaMetragem.tableData.map((item) => {
        if (!Array.isArray(item)) {
          tableData.push([
            {text: item.metragem, options: {color: '333333', align: 'center', bold: false}},
            {text: item.total_unidades, options: {color: '333333', align: 'center', bold: false}},
            {text: item.estoque, options: {color: '333333', align: 'center', bold: false}},
            {text: item.vendidas, options: {color: '333333', align: 'center', bold: false}},
          ])
        }
      })

      const slideconfigTable1 = {
        slide: slideT,
        tableData: tableData,
        config: {
          x: 0.5,
          y: 4.4,
          w: 6.0,
          margin: 3,
          fontSize: 10,
          fill: {color: 'ffffff'},
          border: {pt: 1, color: 'd0d0d0'},
        },
      }

      slide.addTable(slideconfigTable1)

      //COL2 Faixa Preço
      let chartDataVSOFaixaPreco = []
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.faixaTicket.chartData.map((item) => {
        chartDataVSOFaixaPreco.push({
          name: item.name,
          labels: item.data.map((dataItem, index) => dataItem.x),
          values: item.data.map((dataItem, index) => dataItem.y),
        })
      })
      const slideconfig = {
        slide: slideT,
        type: 'bar',
        charData: chartDataVSOFaixaPreco,
        config: {
          x: 7.0,
          y: 1.4,
          w: 6.0,
          h: 3,
          chartArea: {fill: {color: 'ffffff'}},
          plotArea: {fill: {color: 'FFFFFF'}},
          catAxisLineShow: false,
          valAxisLineShow: false,
          barDir: 'col',
          title: 'VSO por Faixa de Preço',
          // catAxisLineStyle:'dashad',
          valAxisHidden: false,
          valGridLine: {
            size: 1,
            style: 'dash',
            color: 'e4e4e4',
          },
          //showPercent:true,
          // barGapWidthPct: 25,
          chartColors: ['#a7a7a7', '#525252'],

          // chartColorsOpacity: 50,
          valAxisMinVal: 0,
          lineSize: 0,
          valAxisMaxVal: 100,

          catAxisLabelColor: '333333',
          catAxisLabelFontFace: 'Arial',
          catAxisLabelFontSize: 11,
          catAxisLabelFrequency: 1,
          catAxisOrientation: 'minMax',

          // dataBorder: {pt: 1, color: '525252'},
          // dataLabelColor: '696969',
          dataLabelFontFace: 'Arial',
          dataLabelFontSize: 10,
          dataLabelPosition: 'outEnd',
          showValue: true,

          // valAxisHidden: true,
          catAxisTitle: 'Dormitórios',
          showCatAxisTitle: true,

          showLegend: true,
          showTitle: true,
        },
      }
      slide.addChartSingle(slideconfig)

      let tableDataPreco = []
      tableDataPreco.push([
        {text: 'Preço (R$)', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Total Unidades', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Estoque', options: {color: '333333', align: 'center', bold: true}},
        {text: 'Vendidas', options: {color: '333333', align: 'center', bold: true}},
      ])
      // eslint-disable-next-line array-callback-return
      cacheResult.performanceResumoMercado.faixaTicket.tableData.map((item) => {
        if (!Array.isArray(item)) {
          tableDataPreco.push([
            {text: item.preco, options: {color: '333333', align: 'center', bold: false}},
            {text: item.total_unidades, options: {color: '333333', align: 'center', bold: false}},
            {text: item.estoque, options: {color: '333333', align: 'center', bold: false}},
            {text: item.vendidas, options: {color: '333333', align: 'center', bold: false}},
          ])
        }
      })

      const slideconfigTable = {
        slide: slideT,
        tableData: tableDataPreco,
        config: {
          x: 7.0,
          y: 4.4,
          w: 6.0,
          margin: 3,
          fontSize: 10,
          fill: {color: 'ffffff'},
          border: {pt: 1, color: 'd0d0d0'},
        },
      }

      slide.addTable(slideconfigTable)
    })

    //SLIDE -   Analise Concorrentes
    // let vsoChart = vsoIdeal.map((item) => {
    //   return item.vso
    // })

    // let dataArrayDormi = Array(7).fill([], 0)
    // let dataArrayDormiLabels = Array(7).fill([], 0)
    // // eslint-disable-next-line array-callback-return
    // dataArrayDormi.map((val, index) => {
    //   dataArrayDormi[index] = [] //Array(vsoChart.length).fill(null, 0)
    //   dataArrayDormiLabels[index] =[] // Array(vsoChart.length).fill(null, 0)
    // })

    // console.log(vsoChart)
    // // eslint-disable-next-line array-callback-return
    // data.filter((item) => {
    //   // eslint-disable-next-line array-callback-return
    //   item.tipologias.filter((itemTipologia,index) => {
    //     if (itemTipologia.computed) {
    //       let indexVso = vsoIdeal.findIndex(
    //         (fase, index) => index === Number(item.fase_projeto_ideal)
    //       )
    //       dataArrayDormi[Number(itemTipologia.bedrooms)].push({y:itemTipologia.vendas_perc_tip,x:Number(item.fase_projeto)})
    //       dataArrayDormiLabels[Number(itemTipologia.bedrooms)].push(index)
    //     }
    //   })
    // })
    // console.log(dataArrayDormi)
    // console.log(dataArrayDormiLabels)
    // let vsoChartAnaliseConcorrentesLabel = []
    // let vsoChartAnaliseConcorrentes = []
    // vsoChartAnaliseConcorrentesLabel = [
    //   0,
    //   3,
    //   6,
    //   8,
    //   11,
    //   14,
    //   17,
    //   19,
    //   22,
    //   25,
    //   28,
    //   31,
    //   33,
    //   36,
    //   39,
    //   42,
    //   44,
    //   47,
    //   50,
    //   53,
    //   56,
    //   58,
    //   61,
    //   64,
    //   67,
    //   69,
    //   72,
    //   75,
    //   78,
    //   81,
    //   83,
    //   86,
    //   89,
    //   92,
    //   94,
    //   97,
    //   100,
    // ]

    // vsoIdeal.map((item, index) => {
    //   vsoChartAnaliseConcorrentes[index] = item.vso
    // })

    // dataArrayDormi.map((d, index) => {
    //   if (!isAllEmpty(d)) {

    //     console.log(d)

    //     // let fase = Math.round((Number(d.fase_projeto) * 36) / 100)
    //     // if (d.vso >= vsoIdeal[fase].vso) {
    //     //   return 'Acima'
    //     // }
    //     // fase = Math.round((Number(d.fase_projeto) * 36) / 100)
    //     // if (d.vso < vsoIdeal[fase].vso) {
    //     //   return 'Abaixo'
    //     // }

    //     console.log(dataArrayDormiLabels)
    //     console.log(dataArrayDormi)
    //     slide.addSlide(`Análise Concorrentes - ${index} Dormitório(s)`, (slideT) => {
    //       const slideconfig = {
    //         slide: slideT,
    //         charData: [
    //           {
    //             type: 'scatter',
    //             data: [
    //               {
    //                 name: 'VSO',
    //                 labels: dataArrayDormiLabels[index], //dataArrayDormiLabels[index],
    //                 values: dataArrayDormi[index],
    //               },
    //             ],
    //             options: {chartColors: ['FF0000']},
    //           },
    //           // {
    //           //   type: 'line',
    //           //   data: [
    //           //     {
    //           //       name: 'VSO Ideal',
    //           //       labels: vsoChartAnaliseConcorrentesLabel,
    //           //       values: vsoChartAnaliseConcorrentes,
    //           //     },
    //           //   ],
    //           //   options: {chartColors: ['333']},
    //           // },
    //         ],
    //         config: {
    //           x: 0.5,
    //           y: 2.0,
    //           w: '50%',
    //           h: 4,
    //           xAxisMimVal: 0,
    //           xAxisMaxVal: 100,
    //           yAxisMimVal: 0,
    //           yAxisMaxVal: 100,
    //           catAxisLabelColor: '494949',
    //           catAxisLabelFontFace: 'Arial',
    //           catAxisLabelFontSize: 10,
    //           catAxisOrientation: 'minMax',
    //           // showLabel: true,
    //           dataLabelPosition: 'b',
    //           //
    //           // showLegend: true,
    //           // dataLabelFormatScatter: 'custom',
    //           // legendPos: 'b',
    //           //
    //           showTitle: true,
    //           titleFontFace: 'Calibri Light',
    //           titleFontSize: 14,
    //           title: 'Vendas Real x Curva Ideal de Vendas',
    //         },
    //       }
    //       slide.addChart(slideconfig)

    //       let tableData = []
    //       tableData.push([
    //         {text: '', options: {color: '333333', align: 'center', bold: true}},
    //         {text: 'Acima da Curva', options: {color: '333333', align: 'center', bold: true}},
    //         {text: 'Abaixo da Curva', options: {color: '333333', align: 'center', bold: true}},
    //       ])
    //       // data.map((item,index) => {
    //       //   if (!Array.isArray(item)) {
    //       //     if (item.computed && !item.removed) {

    //       //     }
    //       //   }
    //       // })
    //       tableData.push([
    //         {text: 'Total unidades', options: {color: '333333', align: 'center'}},
    //         {text:formatNumber(Math.round(1270), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //         {text:formatNumber(Math.round(1283), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //       ])
    //       tableData.push([
    //         {text: 'Preço mediano', options: {color: '333333', align: 'center'}},
    //         {text:formatNumber(Math.round(1270), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //         {text:formatNumber(Math.round(1283), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //       ])
    //       tableData.push([
    //         {text: 'Ticket mediano', options: {color: '333333', align: 'center'}},
    //         {text:formatNumber(Math.round(1270), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //         {text:formatNumber(Math.round(1283), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //       ])
    //       tableData.push([
    //         {text: 'Área privativa mediana', options: {color: '333333', align: 'center'}},
    //         {text:formatNumber(Math.round(1270), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //         {text:formatNumber(Math.round(1283), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //       ])
    //       tableData.push([
    //         {text: 'Moda de produto com/sem vaga', options: {color: '333333', align: 'center'}},
    //         {text:formatNumber(Math.round(1270), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //         {text:formatNumber(Math.round(1283), 'inteiro'), options: {color: '333333', align: 'center', bold: false}},
    //       ])
    //       const slideconfigTable1 = {
    //         slide: slideT,
    //         tableData: tableData,
    //         config: {
    //           x: 7.8,
    //           y: 2.0,
    //           w: 5.2,
    //           margin: 3,
    //           fontSize: 10,
    //           fill: {color: 'ffffff'},
    //           border: {pt: 1, color: 'd0d0d0'},
    //         },
    //       }

    //       slide.addTable(slideconfigTable1)

    //     })

    //   }
    // })

    // SLIDE -   Mercado revenda
    // slide.addSlide('Mercado Revenda', (slideT) => {
    //   slide.addBox({
    //     slide: slideT,
    //     titulo: 'PREÇO MEDIANO M²',
    //     subtitulo: 'Valor do preço médio do m² ',
    //     valor: formatNumber(
    //       Math.round(cacheResult.performanceMercadoRevenda.precoMedianoM2),
    //       'currency'
    //     ),
    //     config: {
    //       x: 9.8,
    //       y: 1.4,
    //       w: 3.3,
    //       h: 1.3,
    //       margin: 15,
    //       fill: {color: 'ffffff'},
    //       borderColor: '333333',
    //       borderTopLeftRadius: 5,
    //       lineSpacingMultiple: 1.5,
    //     },
    //   })

    //   slide.addBox({
    //     slide: slideT,
    //     titulo: 'PREÇO Q1',
    //     subtitulo: 'Preço médiodo m² - Primeiro Quartil ',
    //     valor: formatNumber(
    //       Math.round(cacheResult.performanceMercadoRevenda.precoQ1m2),
    //       'currency'
    //     ),
    //     config: {
    //       x: 9.8,
    //       y: 2.6,
    //       w: 3.3,
    //       h: 1.3,
    //       margin: 15,
    //       fill: {color: 'ffffff'},
    //       borderColor: '333333',
    //       borderTopLeftRadius: 5,
    //       lineSpacingMultiple: 1.5,
    //     },
    //   })

    //   slide.addBox({
    //     slide: slideT,
    //     titulo: 'PREÇO Q3',
    //     subtitulo: 'Preço médiodo m² - Terceiro Quartil ',
    //     valor: formatNumber(
    //       Math.round(cacheResult.performanceMercadoRevenda.precoQ3m2),
    //       'currency'
    //     ),
    //     config: {
    //       x: 9.8,
    //       y: 3.9,
    //       w: 3.3,
    //       h: 1.3,
    //       margin: 15,
    //       fill: {color: 'ffffff'},
    //       borderColor: '333333',
    //       borderTopLeftRadius: 5,
    //       lineSpacingMultiple: 1.5,
    //     },
    //   })

    //   slide.addBox({
    //     slide: slideT,
    //     titulo: 'TOTAL DE AMOSTRAS',
    //     subtitulo: 'Total de amostras utilizadas',
    //     valor: formatNumber(
    //       Math.round(cacheResult.performanceMercadoRevenda.totalAmostras),
    //       'inteiro'
    //     ),
    //     config: {
    //       x: 9.8,
    //       y: 5.4,
    //       w: 3.3,
    //       h: 1.3,
    //       margin: 15,
    //       fill: {color: 'ffffff'},
    //       borderColor: '333333',
    //       borderTopLeftRadius: 5,
    //       lineSpacingMultiple: 1.5,
    //     },
    //   })

    //   slideT.addImage({data: mapImage.map3, x: 0.6, y: 1.4, w: 8, h: 5.5})
    //   slideT.addImage({data: legendaDistancia, x: 0.7, y: 6.2, w: 0.9, h: 0.6})
    //   // slideT.addImage({data: imageLegenda3, x: 6.3, y: 6.2, w: 2.8, h: 0.6})
    // })
    

    slide.salvar('Urbit - Inteligência de Mercado - ' + dadosProjetoSalvo.nome)
    

    // CloseLoaderInfoOverlay()

    setMapImage({
      map1: null,
      map2: null,
      map3: null,
    })
    setGenerateMap(false)
  }
  
  return (
    <>
      {!generateMap ? (
        <button
          className='btn btn-icon-gray-700 btn-text-gray-700 btn-secondary'
          style={{width: '100% !important'}}
          onClick={async () => {
            // LoaderInfoOverlay('Gerando estudo...')
            setGenerateMap(true)
          }}
        >
          <BiExport></BiExport> Gerar PPT
        </button>
      ) : (
        <></>
      )}
      {mapImage.map1 && mapImage.map2 && mapImage.map3 ? (
        <button
          className='btn btn-icon-gray-700 btn-text-gray-700 btn-secondary'
          style={{width: '100% !important'}}
          onClick={async () => {
            
            // LoaderInfoOverlay('Gerando estudo...')
            await handleExport()
          }}
        >
          <BiDownload></BiDownload> Baixar Arquivo
        </button>
      ) : (
        <></>
      )}

      {generateMap && !mapImage.map1 && !mapImage.map2 && !mapImage.map3 ? (
        <button
          className='btn btn-icon-gray-700 btn-text-gray-700 btn-secondary disabled'
          style={{width: '100% !important'}}
        >
          <BiLoader></BiLoader> Carregando...
        </button>
      ) : (
        <></>
      )}

      <div style={{position: 'fixed', width: '1000', height: '850', left: '-1000px', top: 0}}>
        <LegendaIsocronas setLegendaDistancia={setLegendaDistancia} />
      </div>
      {generateMap ? <Mapas data={data} setMapImageExport={setMapImage}></Mapas> : <></>}
    </>
  )
}
