import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ToogleTokens} from '../../../components/UI/ToogleTokens'
import {ListCheck} from '../../../components/UI/ListCheck'
import {
  MutationCreateUserAreaAtuacao,
  MutationCreateUserSubAreaAtuacao,
  QueryGetAreaAtuacao,
  QueryGetSubAreaAtuacao,
} from '../../../graphql'
import {
  setUserAreaAtuacaoFormData,
  setUserSubAreaAtuacaoFormData,
} from '../../../graphql/formValidations'
import secureLocalStorage from 'react-secure-storage'
import { Loader } from '../../../components/UI/Loader/Loader'
import Swal from 'sweetalert2'

interface Props {
  show: boolean
}

export function ModalPerfil({show}: Props) {
  const [showModal, setShowModal] = useState(show)
  const [tokens, setTokens] = useState([])
  const [subArea, setSubArea] = useState([])
  const {Form, loading: loadingSet} = MutationCreateUserAreaAtuacao()
  const area_atuacao = localStorage.getItem('area_atuacao')

  useEffect(() => {
    if (area_atuacao === 'ok') {
      setShowModal(false)
    } else {
      setShowModal(true)
    }
  }, [area_atuacao])

  useEffect(() => {
    setShowModal(show)
  }, [show])

  const {
    Form: formSub,
    loading: loadingSetSub,
  } = MutationCreateUserSubAreaAtuacao()
  const {
    data: dataAreaAtuacao,
    loading
  } = QueryGetAreaAtuacao({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 20,
      },
    },
  })
  const {
    data: dataSubAreaAtuacao,
    loading: loadingSub
  } = QueryGetSubAreaAtuacao({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 20,
      },
    },
  })

  const itens = dataAreaAtuacao ? dataAreaAtuacao.GetAreaAtuacao.result : []
  const itensSubArea = dataSubAreaAtuacao ? dataSubAreaAtuacao.GetSubAreaAtuacao.result : []

  function salvar(e) {
    const idUser = secureLocalStorage.getItem('id_user')
    let data: setUserAreaAtuacaoFormData = []
    // eslint-disable-next-line array-callback-return
    tokens.map((id) => {
      data.push({
        id_user: Number(idUser),
        situacao: 1,
        id_area_atuacao: id,
      })
    })
    Form(data)

    let dataSub: setUserSubAreaAtuacaoFormData = []
    // eslint-disable-next-line array-callback-return
    subArea.map((id) => {
      dataSub.push({
        id_user: Number(idUser),
        situacao: 1,
        id_sub_area_atuacao: id,
      })
    })
    formSub(dataSub).then(()=>{
        Swal.fire('Sucesso!', 'Obrigado pela sua informação. Logo mais teremos muitas novidades.', 'success')
        setShowModal(false)
    })
   localStorage.setItem('area_atuacao', 'ok')
  }
  return (
    <Modal
      centered
      size='xl'
      animation
      show={showModal}
      onHide={() => {
        setShowModal(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Para melhor experiência informe sua área de atuação.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            return salvar(e)
          }}
        >
          <div className='row'>
          {loading ? <Loader text={'Carregando...'}></Loader>:<></> }
          {!loading ?<ToogleTokens itens={itens} onChange={setTokens}></ToogleTokens>:<></>}
          </div>
          <div className='row'>
            {loadingSub ? <Loader text={'Carregando...'}></Loader>:<></> }
            {!loadingSub ?<ListCheck itens={itensSubArea} onChange={setSubArea}></ListCheck> : <></>}
          </div>
          <button
            type='button'
            className='btn btn-default pull-left'
            onClick={() => setShowModal(false)}
          >
            Responder depois
          </button>
          {loadingSet === true || loadingSetSub === true ? (
            <button className='btn btn-primary pull-right disabled' disabled>
              Enviando...
            </button>
          ) : (
            <button className='btn btn-primary pull-right'>Salvar</button>
          )}
        </form>
      </Modal.Body>
    </Modal>
  )
}
