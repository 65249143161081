import { useEffect, useState } from "react"
import { debounce } from "../../inteligencia-mercado/components/Common/common"


const useLayoutControl = () => {

    const [windowHeight, setWindowHeight] = useState({
        h: window.innerHeight - 90,
        w: window.innerWidth - 345,
    })
    useEffect(() => {
        window.addEventListener(
          'resize',
          function (event) {
            debounce(
              setWindowHeight({
                ...{
                  h: window.innerHeight - 90,
                  w: window.innerWidth - 345,
                },
              }),
              1000
            )
          },
          true
        )
      }, [])  

    return {windowHeight}
}

export default useLayoutControl